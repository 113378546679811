import React from "react";

import Tooltip from "./Tooltip";

import useMouseOver from "../utils/useMouseOver";

const useTooltip = (ref, contents, config) => {
  const mouseOver = useMouseOver(ref);

  return mouseOver && contents ? <Tooltip container={ref} contents={contents} {...config} /> : null;
};

export default useTooltip;
