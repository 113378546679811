import PropTypes from "prop-types";
import React, { useContext, useCallback } from "react";
import { useForm } from "react-final-form";
import styled from "styled-components";

import { FontColors, StyledCaption2, Button, FinalFormField } from "yuka";

import { useFormValue } from "utils/hooks/form";
import { fetchQuery, getCachedById, QUERY_KEYS } from "api";

import { FieldPrefixContext, InputRow } from "forms/FormComponents";

const StyledIcon = styled.span`
  ${FontColors.theme50};
  display: flex;
  flex-basis: 30px;
  justify-content: center;
  margin: 10px 0;
`;

const StyledInputRow = styled(InputRow)`
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;

  // Workaround to fix bottom aligned default alignment
  > .flex-input + .btn {
    margin-top: 0;
  }
`;

const StyledHelpText = styled.div`
  margin-bottom: 8px;
`;

/**
 * Renders two inputs for sources representing the client and the entity.
 * Wrapped by `<SourceCategorizationFields />`
 *
 * @param {object} props
 * @returns {React.Element}
 */
const TicketParticipantField = props => {
  const name = useContext(FieldPrefixContext);
  const { change } = useForm();

  const individualValue = useFormValue(`${name}individual`);
  const entityValue = useFormValue(`${name}entity`);

  const loadOptions = useCallback(
    search =>
      fetchQuery(QUERY_KEYS.SOURCES.list({ "filter[search]": search })).then(sources =>
        sources.map(source => ({
          value: source.searchId,
          label: `${source.name} (${source.category || "Unknown"} #${source.searchId})`,
          ...source,
        }))
      ),
    []
  );

  const loadOptionFromValue = useCallback(
    value =>
      typeof value === "number"
        ? fetchQuery(QUERY_KEYS.SOURCES.list({ "filter[searchId]": value })).then(([source]) => ({
            value: source.searchId,
            label: `${source.name} (${source.category || "Unknown"} #${source.searchId})`,
            ...source,
          }))
        : Promise.resolve(null),
    []
  );

  const onIndividualChange = useCallback(
    individual => {
      // autofill the entity if possible
      const field = `${name}entity`;
      if (!entityValue && individual) {
        const individualSource = getCachedById(QUERY_KEYS.SOURCES, individual, "searchId");
        if (individualSource && individualSource.parent) {
          change(field, individualSource.parent);
        }
      }
    },
    [entityValue, change, name]
  );

  return (
    <>
      <StyledInputRow>
        <FinalFormField
          type="autocomplete"
          handleChange={onIndividualChange}
          loadOptions={loadOptions}
          name="individual"
          placeholder={props.sourceType.includes("Broker") ? "Registered Rep" : "Person's Name"}
          popup={props.popup}
        />
        <StyledIcon>@</StyledIcon>
        <FinalFormField
          type="autocomplete"
          loadOptions={loadOptions}
          name="entity"
          placeholder={props.sourceType.includes("Broker") ? "Trading Group" : "Entity"}
          title=""
          popup={props.popup}
          attachToBody={!props.popup}
          loadOptionFromValue={loadOptionFromValue}
        />
        <Button onClick={props.add} disabled={!individualValue && !entityValue}>
          Add
        </Button>
      </StyledInputRow>
      <StyledHelpText>
        <StyledCaption2>Click add to save changes</StyledCaption2>
      </StyledHelpText>
    </>
  );
};

TicketParticipantField.propTypes = {
  sourceType: PropTypes.string.isRequired,
  popup: PropTypes.bool,
  add: PropTypes.func.isRequired,
};

TicketParticipantField.defaultProps = {
  popup: true,
};

export default TicketParticipantField;
