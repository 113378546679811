import axios from "axios";

import { get } from "utils";

import MIDDLEWARE_CLASSES from "./middleware";
import qs from "qs";

const setAxiosDefaults = () => {
  axios.defaults.responseType = "json";
  axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers["Content-Type"] = "application/vnd.api+json";
  // Forwards array query params without the auto-appended [].
  axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: "repeat" });
  if (window.location.hostname === "localhost") {
    axios.defaults.baseURL = "http://localhost:8002/api/";
  } else {
    // Trim subdomain
    const domain = window.location.hostname.split(".").slice(1).join(".");
    axios.defaults.baseURL = `https://xe.${domain}/api/`;
  }

  // setup middleware
  MIDDLEWARE_CLASSES.forEach(MiddlewareClass => {
    const middleware = new MiddlewareClass();

    axios.interceptors.request.use(middleware.processRequest, middleware.processRequestError);

    axios.interceptors.response.use(middleware.processResponse, middleware.processResponseError);
  });
};

const getRelatedObj = (relationship, includes) =>
  relationship ? get(includes, relationship) : relationship;
const getRelatedId = relationship => relationship?.[1];

export { QueryClientProvider } from "./queryClient";
export { default as useFetch } from "./useFetch";
export { default as useInfiniteFetch } from "./useInfiniteFetch";
export { default as fetchQuery } from "./fetchQuery";
export { fetchFullQueryResponse } from "./fetchQueryResponse";
export { default as fetchQueryResponse } from "./fetchQueryResponse";
export { default as getCachedById } from "./getCachedById";
export { default as useWrite } from "./useWrite";
export { default as useDelete } from "./useDelete";
export { default as QUERY_KEYS } from "./keyBuilderFactory";
export * from "./constants";
export { setAxiosDefaults, getRelatedObj, getRelatedId };
