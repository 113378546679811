import PropTypes from "prop-types";

import { required } from "utils/form/inputValidations";
import { Input, InputRow, Select, DatePicker, Checkbox } from "forms/FormComponents";

import { DOCUMENT_TYPE_OPTIONS_MAP, SPV, SPV_CLASS, CONNECTION } from "./constants";

const STATUS_OPTIONS = [
  { label: "Archived", value: "Archived" },
  { label: "Draft", value: "Draft" },
  { label: "Live", value: "Live" },
  { label: "Partially Executed", value: "Partially Executed" },
  { label: "Fully Executed", value: "Fully Executed" },
];

const DocumentDetailsFieldSet = ({ documentTypeOptions }) => (
  <>
    <InputRow>
      <Input name="name" title="Name" placeholder="e.g. TD Fee Agreement" />
    </InputRow>
    <InputRow>
      <Select
        popup
        name="documentType"
        title="Type"
        placeholder="Select document type"
        options={DOCUMENT_TYPE_OPTIONS_MAP[documentTypeOptions]}
        validate={[required]}
        noSort
      />
    </InputRow>
    <InputRow>
      <Select
        popup
        name="status"
        title="Status"
        placeholder="Select document status"
        options={STATUS_OPTIONS}
        validate={[required]}
      />
    </InputRow>
    {![SPV, SPV_CLASS].includes(documentTypeOptions) && (
      <InputRow>
        <DatePicker
          name="expiresDate"
          title="Expiration (optional)"
          placeholder="Select document expiration date"
          minDate={new Date()}
        />
      </InputRow>
    )}
    {![SPV, SPV_CLASS, CONNECTION].includes(documentTypeOptions) && (
      <InputRow>
        <Checkbox name="isPinned" title="Required in Deal File" />
      </InputRow>
    )}
  </>
);

DocumentDetailsFieldSet.propTypes = {
  documentTypeOptions: PropTypes.string.isRequired,
};

export default DocumentDetailsFieldSet;
