import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { StyledBody1 } from "yuka";

import { DelimitedNumberInput, InputRow, Select } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";
import { isInteger, isNotNegative, isValidDecimal } from "utils/form/inputValidations";
import CommissionUtils from "utils/commissions/CommissionUtils";

import { COMMISSION_TYPE_FRACTION_OF_FEES, COMMISSION_TYPE_OPTIONS } from "./constants";

const StyledAmountContainer = styled.div`
  width: 380px;
  margin-right: 12px;
`;

const StyledTypeContainer = styled.div`
  width: 132px;
  margin-top: 22px;
  margin-right: 43px;
`;

const StyledCalculatedFeesContainer = styled.div`
  margin-top: 28px;
  width: 64px;
  text-align: center;
`;

const StyledFractionOfFeesContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledDivisionSpan = styled.span`
  margin-top: 20px;
`;

/**
 * Single input row for the CommissionFieldSet
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const CommissionRow = ({ title, connection, type, isBuy }) => {
  const commission = useFormValue(`${isBuy ? "buy" : "sell"}`, {});
  const commissionAmount = useFormValue(`${isBuy ? "buy" : "sell"}.${type}CommissionAmount`, 0);
  const commissionAmountNumerator = useFormValue(
    `${isBuy ? "buy" : "sell"}.${type}CommissionAmountNumerator`,
    0
  );
  const commissionAmountDenominator = useFormValue(
    `${isBuy ? "buy" : "sell"}.${type}CommissionAmountDenominator`,
    0
  );
  const commissionType = useFormValue(`${isBuy ? "buy" : "sell"}.${type}CommissionType`, 0);

  const clientToZFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        commission.clientToZCommissionAmount,
        commission.clientToZCommissionType,
        connection
      ),
    [commission.clientToZCommissionAmount, commission.clientToZCommissionType, connection]
  );

  const clientToBrokerFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        commission.clientToBrokerCommissionAmount,
        commission.clientToBrokerCommissionType,
        connection
      ),
    [commission.clientToBrokerCommissionAmount, commission.clientToBrokerCommissionType, connection]
  );

  return (
    <InputRow>
      <StyledAmountContainer>
        {commissionType === COMMISSION_TYPE_FRACTION_OF_FEES ? (
          <StyledFractionOfFeesContainer>
            <DelimitedNumberInput
              title={title}
              name={`${type}CommissionAmountNumerator`}
              placeholder="E.g. 1"
              validate={[isNotNegative, isInteger]}
            />
            <StyledDivisionSpan> / </StyledDivisionSpan>
            <DelimitedNumberInput
              title={title}
              name={`${type}CommissionAmountDenominator`}
              placeholder="E.g. 3"
              validate={[isNotNegative, isInteger]}
            />
          </StyledFractionOfFeesContainer>
        ) : (
          <DelimitedNumberInput
            title={title}
            name={`${type}CommissionAmount`}
            placeholder="E.g. 2"
            validate={[isNotNegative, isValidDecimal]}
          />
        )}
      </StyledAmountContainer>
      <StyledTypeContainer>
        <Select
          name={`${type}CommissionType`}
          options={COMMISSION_TYPE_OPTIONS(type)}
          placeholder="Unit"
          noSort
          popup
        />
      </StyledTypeContainer>
      <StyledCalculatedFeesContainer>
        <StyledBody1>
          {CommissionUtils.calculatedFeesDisplay(
            commissionAmount,
            commissionType,
            connection,
            clientToZFee,
            clientToBrokerFee,
            commissionAmountNumerator,
            commissionAmountDenominator
          )}
        </StyledBody1>
      </StyledCalculatedFeesContainer>
    </InputRow>
  );
};
CommissionRow.propTypes = {
  connection: PropTypes.shape({
    shareQuantity: PropTypes.number.isRequired,
    totalValue: PropTypes.number.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isBuy: PropTypes.bool.isRequired,
};

export default CommissionRow;
