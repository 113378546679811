import PropTypes from "prop-types";

import { ZXLink } from "utils/routing";
import { HyperLink } from "yuka";

const TicketTitle = ({ ticket, title }) => (
  <span>
    {title} (
    <ZXLink component={HyperLink} url={ticket.zxUrl} profileType="ticket">
      {ticket.referenceNumber.toUpperCase()}
    </ZXLink>
    )
  </span>
);

TicketTitle.propTypes = {
  title: PropTypes.node.isRequired,
  ticket: PropTypes.shape({
    zxUrl: PropTypes.string,
    referenceNumber: PropTypes.string,
  }).isRequired,
};

export default TicketTitle;
