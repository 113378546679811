import {
  FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE,
  FEE_AGREEMENT_TYPE_RATE_CARD,
  FEE_AGREEMENT_TYPE_SUM_AND_SPLIT,
  FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_TRANSACTION,
  FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_FEES,
  FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES,
} from "utils/constants";

const feeAgreementOptions = [
  { label: "Standard Fee Share", value: FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE },
  { label: "Rate Card", value: FEE_AGREEMENT_TYPE_RATE_CARD },
  { label: "Sum and Split", value: FEE_AGREEMENT_TYPE_SUM_AND_SPLIT },
];

const divisionOfFeesOptions = [
  { label: "% of total transaction", value: FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_TRANSACTION },
  { label: "% of fees", value: FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_FEES },
  { label: "Fraction of fees", value: FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES },
];

export {
  feeAgreementOptions,
  divisionOfFeesOptions,
  FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE,
  FEE_AGREEMENT_TYPE_RATE_CARD,
  FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES,
};
