import React from "react";
import styled from "styled-components";

import { Fonts } from "yuka";

import InvestorFieldArray from "./InvestorFieldArray";

const StyledContainer = styled.div`
  display: flex;
  min-width: 840px;
  margin-bottom: 8px;
`;

const StyledHeader = styled(Fonts.Body1theme80)`
  min-width: 214px;
`;

const InvestorListForm = () => (
  <>
    <StyledContainer>
      <StyledHeader>Client name</StyledHeader>
      <StyledHeader>Client email</StyledHeader>
      <StyledHeader>CC Market Operator</StyledHeader>
      <StyledHeader>In addition to SPV documents</StyledHeader>
    </StyledContainer>
    <InvestorFieldArray />
  </>
);

export default InvestorListForm;
