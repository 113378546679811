import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { FormModal, ModalStyles } from "yuka";

import { getRelatedId, useWrite, QUERY_KEYS } from "api";
import { DataTypes } from "api/constants";
import { InputRow } from "forms/FormComponents";
import MarketOperatorSelect from "forms/FormComponents/MarketOperatorSelect";
import LoadingSpinner from "utils/LoadingSpinner";

/**
 * A form to edit client ZX reps
 *
 * @returns {Element}
 */
const EditClientZXRepsForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_PROFILES.detail(props.source.apiId));

  const wrappedOnSubmit = (values, ...params) => {
    const cleanedValues = {
      ...values,
      zxRepresentatives: values.zxRepresentatives.map(id => ({
        apiId: id,
        apiType: DataTypes.MARKET_OPERATORS,
      })),
    };
    onSubmit(cleanedValues, ...params).then(() => {
      props.onClose();
    });
  };

  const initialValues = useMemo(
    () => ({
      ...props.sourceProfile,
      apiType: DataTypes.CLIENT_PROFILES,
      apiId: props.source.apiId,
      zxRepresentatives: props.sourceProfile?.zxRepresentatives.map(getRelatedId),
    }),
    [props.sourceProfile, props.source.apiId]
  );

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={props.onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit ZX Representatives"
          modalStyle={ModalStyles.SCROLLABLE}
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <InputRow>
              <MarketOperatorSelect
                placeholder="Select ZX Reps"
                label="ZX Representative(s)"
                name="zxRepresentatives"
                multiple
              />
            </InputRow>
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

EditClientZXRepsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  sourceProfile: PropTypes.shape({
    zxRepresentatives: PropTypes.arrayOf(
      PropTypes.shape({
        apiId: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default EditClientZXRepsForm;
