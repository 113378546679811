import { createRoot } from "react-dom/client";
import { setSetting } from "yuka";

import tokenizedStringCompare from "utils/tokenizedStringCompare";

import { setAxiosDefaults } from "api";

import ExecutionApp from ".";

const setupYuka = () => {
  setSetting("Select.matchFn", tokenizedStringCompare);
};

document.addEventListener("DOMContentLoaded", () => {
  setAxiosDefaults();
  setupYuka();
  const container = document.getElementById("application");
  const root = createRoot(container);
  root.render(<ExecutionApp />);
});
