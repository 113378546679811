import React from "react";
import PropTypes from "prop-types";
import { List, Card, CardStyles } from "yuka";
import styled from "styled-components";

import { useFetch, QUERY_KEYS } from "api";
import CreateClientNoteForm from "forms/CreateClientNoteForm";

import ClientNoteItem from "./ClientNoteItem";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const NotesCard = ({ notes: initialNotes, sourceId }) => {
  const {
    isError,
    isLoading,
    data: notes,
  } = useFetch(QUERY_KEYS.CLIENT_NOTES.list(sourceId), {
    initalData: initialNotes,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  return (
    <StyledCard cardStyle={CardStyles.SECTIONED} title="Notes">
      <CreateClientNoteForm sourceId={sourceId} />
      {notes.length > 0 && (
        <List divider>
          {notes.map(note => (
            <ClientNoteItem key={note.apiId} note={note} sourceId={sourceId} />
          ))}
        </List>
      )}
    </StyledCard>
  );
};

NotesCard.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string.isRequired,
    })
  ),
  sourceId: PropTypes.string.isRequired,
};

export default NotesCard;
