import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardStyles,
  ActionChip,
  EyeIcon,
  EyeOffIcon,
  UploadIcon,
  ColorPalette,
  Table,
} from "yuka";
import { useInfiniteFetch, useWrite, DataTypes, QUERY_KEYS } from "api";
import { SPVDocumentsForm } from "forms/DocumentsForm";
import BulkUploadDocumentsForm from "forms/BulkUploadDocumentsForm";
import useFileDragAndDrop from "utils/hooks/form/useFileDragAndDrop";
import { useDispatch, ACTIONS } from "utils/StateProvider";

import { useDocumentsTableConfig, columns } from "./documentsTableConfig";

const StyledContainer = styled.div`
  height: 100%;
  min-height: 0;
`;

const StyledActions = styled.div`
  padding: 0 16px;
`;

const StyledDropzone = styled.div`
  height: 100%;
  display: flex;
  border: 1px dashed ${ColorPalette.faintWhite};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

/**
 * Renders a card for the spv documents tab
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const DocumentsCard = ({ spv }) => {
  const [includeArchived, setIncludeArchived] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const { sortState, onSort } = useDocumentsTableConfig(includeArchived);

  const {
    data: documents,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteFetch(
    QUERY_KEYS.SPV_DOCUMENTS.list(spv.apiId, {
      "filter[isArchived]": includeArchived ? undefined : false,
    })
  );

  const dispatch = useDispatch();
  const { onSubmit } = useWrite(QUERY_KEYS.SPV_DOCUMENTS.list(spv.apiId), { silent: true });
  const [totalFiles, setTotalFiles] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);

  const handleUpload = files => {
    setTotalFiles(files.length);
    setCompletedFiles(0);
    // Spread files to get an array rather than FileList
    return Promise.all(
      [...files].map(file =>
        onSubmit({ apiType: DataTypes.EXPIRABLE_DOCUMENT, file }).then(() => {
          setCompletedFiles(completed => completed + 1);
        })
      )
    );
  };

  useEffect(() => {
    if (completedFiles === totalFiles && totalFiles > 0) {
      dispatch({
        type: ACTIONS.addToast,
        message: "Upload Complete",
      });
      setTotalFiles(0);
    }
  }, [completedFiles, totalFiles, setTotalFiles, dispatch]);

  const [containerRef, fileInputRef, isDragging] = useFileDragAndDrop({
    multiple: true,
    onChange: handleUpload,
  });

  const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);

  const pinnedDocuments = useMemo(
    () => (documents ? documents.filter(document => document.isPinned) : []),
    [documents]
  );

  const unpinnedDocuments = useMemo(
    () => (documents ? documents.filter(document => !document.isPinned) : []),
    [documents]
  );

  return (
    <StyledCard title="Documents" cardStyle={CardStyles.SECTIONED}>
      <StyledActions>
        {includeArchived ? (
          <ActionChip
            onClick={() => setIncludeArchived(prev => !prev)}
            leadingIcon={EyeIcon}
            text="Hide archived documents"
          />
        ) : (
          <ActionChip
            onClick={() => setIncludeArchived(prev => !prev)}
            leadingIcon={EyeOffIcon}
            text="Show archived documents"
          />
        )}
        <ActionChip onClick={openForm} leadingIcon={UploadIcon} text="Upload new SPV document" />
        <BulkUploadDocumentsForm fileInputRef={fileInputRef} />
      </StyledActions>
      {formOpen && <SPVDocumentsForm closeModal={closeForm} />}
      <StyledContainer ref={containerRef} $dragging={isDragging}>
        {isDragging ? (
          <StyledDropzone>Drop files here to upload</StyledDropzone>
        ) : (
          <>
            {pinnedDocuments.length > 0 && (
              <Table
                usePercentageColumnWidths
                emptyTablePlaceholder="No SPV Documents"
                paginationFunc={fetchNextPage}
                isPaginationLoading={isFetchingNextPage}
                isLoading={isLoading}
                sortState={sortState}
                onSort={onSort}
                data={pinnedDocuments || []}
                columns={columns}
              />
            )}
            {unpinnedDocuments.length > 0 && (
              <Table
                usePercentageColumnWidths
                emptyTablePlaceholder="No SPV Documents"
                paginationFunc={fetchNextPage}
                isPaginationLoading={isFetchingNextPage}
                isLoading={isLoading}
                sortState={sortState}
                onSort={onSort}
                data={unpinnedDocuments || []}
                columns={columns}
              />
            )}
          </>
        )}
      </StyledContainer>
    </StyledCard>
  );
};

DocumentsCard.propTypes = {
  spv: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentsCard;
