import { QUERY_KEYS, fetchQuery } from "api";

const loadCompanyOptions = name =>
  fetchQuery(
    QUERY_KEYS.COMPANY.list({
      "filter[name]": name,
    })
  ).then(companies =>
    companies.map(company => ({
      value: company,
      label: company.name,
    }))
  );

export { loadCompanyOptions };
