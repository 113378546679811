import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { noop } from "utils";
import useFilter from "utils/hooks/form/useFilter";

const FilterForm = ({ name, handleSubmit, children, onChange }) => {
  useFilter(name, { onChange });

  return <form onSubmit={handleSubmit}>{children}</form>;
};

FilterForm.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func,
};

FilterForm.defaultProps = {
  handleSubmit: noop,
  onChange: noop,
};

/**
 * A wrapper for Filter components that need to opt-in to useFilter to use global state for their
 * form's values.
 * @param name {String} - a unique key to use to lookup form values in the global store.
 * @param initialValues {object} - initial values for the rendered form to use
 * @param filterComponent - React component to render as the filter.
 * @param props - arbitrary props to forward to the rendered component.
 * @returns {JSX.Element}
 */
const Filter = ({ name, initialValues, filterComponent, onChange, ...props }) => {
  // Filters do not use an onSubmit handler; they are not submitted.
  const FilterComponent = filterComponent;
  return (
    <Form onSubmit={noop} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <FilterForm
          name={name}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          onChange={onChange}
          {...props}
        >
          <FilterComponent name={name} {...props} />
        </FilterForm>
      )}
    </Form>
  );
};

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  initialValues: PropTypes.shape({}),
  filterComponent: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  initialValues: {},
  onChange: noop,
};

export default Filter;
