import { useMemo } from "react";
import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";
import useTableSort from "utils/tables/useTableSort";
import { BadgeCell, DateCell } from "yuka";
import DropdownCell from "./DropdownCell";
import PinCell from "./PinCell";
import PreviewCell from "./PreviewCell";
import UploadCell from "./UploadCell";

const TABLE_NAME = "DOCUMENTS";

const useDocumentsTableConfig = includeArchived => {
  const currentTableSort = useSelector(state => state?.tableSort[TABLE_NAME]);
  const dispatch = useDispatch();
  const onSort = useTableSort(ACTIONS, dispatch, TABLE_NAME);

  const sortState = useMemo(() => {
    if (includeArchived) {
      return [{ id: "latestVersionOrSelf" }, { id: "updatedOn", desc: true }];
    }
    return currentTableSort && currentTableSort.length ? currentTableSort : [];
  }, [currentTableSort, includeArchived]);

  return {
    sortState,
    onSort,
  };
};

const UploadColumn = {
  id: "upload",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: UploadCell,
};
const DropdownColumn = {
  id: "dropdown",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: DropdownCell,
};

const PinColumn = {
  id: "pin",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: PinCell,
};

const NameColumn = {
  id: "name",
  accessor: "name",
  width: 28,
  header: "Document Name",
  sortable: true,
  sticky: true,
  cellRenderer: PreviewCell,
};

const TypeColumn = {
  id: "type",
  accessor: "documentType",
  width: 28,
  header: "Type",
  sortable: true,
};

const CategoryColumn = {
  id: "category",
  accessor: "category",
  width: 27,
  header: "Category",
  sortable: true,
  cellRenderer: BadgeCell,
};

const ExpirationColumn = {
  id: "expiresDate",
  accessor: "expiresDate",
  width: 17,
  header: "Expiration",
  sortable: true,
  emptyPlaceholder: "--",
  cellRenderer: DateCell,
};

const columns = [
  UploadColumn,
  DropdownColumn,
  PinColumn,
  NameColumn,
  TypeColumn,
  CategoryColumn,
  ExpirationColumn,
];

export { useDocumentsTableConfig, columns };
