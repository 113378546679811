function keyBy(arr, keyFn) {
  let keyed = {};
  for (const value of arr.values()) {
    let key;
    if (typeof keyFn === "string") {
      key = value[keyFn];
    } else {
      key = keyFn(value);
    }
    keyed[key] = value;
  }
  return keyed;
}

export default keyBy;
