import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";

import { useWrite, QUERY_KEYS, DataTypes } from "api";
import { PlusIcon } from "yuka";

import { Input, InputRow } from "forms/FormComponents";

const StyledInputRow = styled(InputRow)`
  margin-top: 16px;
  margin-bottom: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

/**
 * A basic form to create a task on a Connection
 *
 * @returns {Element}
 */
const CreateConnectionTaskForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.TASKS.list());
  const wrappedOnSubmit = (values, form) =>
    values.text
      ? onSubmit(values).then(() => {
          form.reset();
        })
      : null;
  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        apiType: DataTypes.TASK,
        phase: props.phase,
        tradeId: props.connection.tradeId,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <StyledContainer>
            <StyledInputRow>
              <Input
                name="text"
                placeholder="Add a task"
                leadingIcon={PlusIcon}
                helpText="Press enter to Add"
              />
            </StyledInputRow>
          </StyledContainer>
          <button type="submit" hidden disabled={submitting}></button>
        </form>
      )}
    </Form>
  );
};

CreateConnectionTaskForm.propTypes = {
  phase: PropTypes.string.isRequired,
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
};

export default CreateConnectionTaskForm;
