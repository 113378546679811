import axios from "axios";
import { useMutation } from "react-query";

import { queryClient } from "./queryClient";

import { useDispatch, ACTIONS } from "utils/StateProvider";

const destroy = ({ key: [type, , ...routeSegments] }) => {
  const queryParams = routeSegments.pop();
  return axios
    .delete(`${type}${routeSegments.join("/")}${routeSegments.length ? "/" : ""}`, {
      params: queryParams,
    })
    .then(response => response.data);
};

const useDelete = (queryKey, config) => {
  const dispatch = useDispatch();
  const mutation = useMutation(destroy, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey[0], "list"]);

      if (!config?.silent) {
        if (config?.message) {
          dispatch({ type: ACTIONS.addToast, message: config.message });
        } else {
          dispatch({ type: ACTIONS.addToast, message: "Successfully deleted" });
        }
      }

      return Promise.resolve();
    },
  });

  const onSubmit = () => {
    return mutation.mutateAsync({ key: queryKey });
  };

  return {
    onSubmit,
    isIdle: mutation.isIdle,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    reset: mutation.reset,
  };
};

export default useDelete;
