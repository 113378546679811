import { useState } from "react";
import styled from "styled-components";
import { Table, StyledHeadline4, Card, Button } from "yuka";

import columns from "./columns";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

// EXE-83 Support template upload
const TemplateUploadForm = () => null;

const TemplateView = () => {
  const data = [{ name: "Coming soon..." }];
  const [uploadOpen, setUploadOpen] = useState(false);

  const handleUpload = () => setUploadOpen(true);

  return (
    <>
      <StyledHeader>
        <StyledHeadline4>Templates</StyledHeadline4>
        <Button onClick={handleUpload} disabled>
          Upload New Template
        </Button>
      </StyledHeader>
      <Card>
        <Table data={data} columns={columns} usePercentageColumnWidths />
      </Card>
      {uploadOpen && <TemplateUploadForm />}
    </>
  );
};

export default TemplateView;
