function compact(arr, fn = Boolean) {
  const picked = [];
  arr.forEach(val => {
    if (fn(val)) {
      picked.push(val);
    }
  });
  return picked;
}

export default compact;
