import React from "react";
import styled from "styled-components";

import { StyledHeadline2 } from "yuka";

import { Checkbox, InputRow } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";

import ClientAgreementDetails from "./ClientAgreementDetails";
import ClientFirmOrderDetails from "./ClientFirmOrderDetails";
import {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
} from "./constants";

const StyledHeadline = styled(StyledHeadline2)`
  margin-bottom: 24px;
`;

const DocumentDetails = () => {
  const engagementType = useFormValue("engagementType");
  const needsKYC = useFormValue("needsKyc", false);
  return (
    <>
      {engagementType === BUYER_AGREEMENT && <ClientAgreementDetails isBuy />}
      {engagementType === SELLER_AGREEMENT && <ClientAgreementDetails isBuy={false} />}
      {engagementType === BUYER_FIRM_ORDER && <ClientFirmOrderDetails isBuy />}
      {engagementType === SELLER_FIRM_ORDER && <ClientFirmOrderDetails isBuy={false} />}
      {needsKYC && (
        <>
          <StyledHeadline>KYC Details</StyledHeadline>
          {engagementType !== SELLER_AGREEMENT && engagementType !== SELLER_FIRM_ORDER && (
            <InputRow>
              <Checkbox
                name="sendBuysideQuestionnaire"
                label="Send buyside investor questionnaire"
              />
            </InputRow>
          )}
          {engagementType !== BUYER_AGREEMENT && engagementType !== BUYER_FIRM_ORDER && (
            <>
              <InputRow>
                <Checkbox
                  name="sendSellsideQuestionnaire"
                  label="Send sellside investor questionnaire"
                />
              </InputRow>
              <InputRow>
                <Checkbox name="sendWireInstructions" label="Send wire instructions onboarding" />
              </InputRow>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DocumentDetails;
