import { useState, useRef, useCallback, useEffect } from "react";

/**
 * Drag and drop handlers for files that can be injected into any form.
 *
 * Inspired from: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
 * Count ref from: https://github.com/KarimMokhtar/react-drag-drop-files/blob/dev/src/useDragging.tsx
 * in order to prefer drag state flickering
 */
const useFileDragAndDrop = ({ multiple = false, onChange = () => null } = {}) => {
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const dragCount = useRef(0);

  const onDrop = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer?.files;

      if (files.length && fileInputRef.current) {
        let droppedFiles = files;
        if (!multiple) {
          // Need to strip extra docs but still have a FileList - FileList isn't constructable
          // so we need to create a DataTransfer first and re-access the files attr
          droppedFiles = new DataTransfer();
          droppedFiles.items.add(files[0]);
          droppedFiles = droppedFiles.files;
        }
        fileInputRef.current.files = droppedFiles;
        onChange(droppedFiles);
      }
      setIsDragging(false);
      dragCount.current = 0;
    },
    [fileInputRef, multiple, onChange]
  );

  const onDrag = useCallback(e => {
    if (containerRef.current?.contains(e.target) || e.target === containerRef.current) {
      e.stopPropagation();
      e.preventDefault();
    } else if (dragCount.current > 0) {
      // Sometimes we drag out without enough events firing. Just reset fully if we know we're
      // outside the element
      dragCount.current = 0;
      setIsDragging(false);
    }
  }, []);

  const onDragIn = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    const items = e.dataTransfer.items;
    dragCount.current++;

    if (items.length) {
      setIsDragging(true);
    }
  }, []);

  const onDragOut = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    dragCount.current--;
    if (dragCount.current === 0) {
      setIsDragging(false);
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("dragenter", onDragIn);
      document.addEventListener("dragover", onDrag);
      container.addEventListener("dragleave", onDragOut);
      container.addEventListener("drop", onDrop);
    }

    return () => {
      if (container) {
        container.removeEventListener("dragenter", onDragIn);
        document.removeEventListener("dragover", onDrag);
        container.removeEventListener("dragleave", onDragOut);
        container.removeEventListener("drop", onDrop);
      }
    };
  });

  return [containerRef, fileInputRef, isDragging];
};

export default useFileDragAndDrop;
