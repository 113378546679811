import { queryClient } from "./queryClient";

import { fetchResponse, fetchResponseWithFilename } from "./fetchInternals";

/**
 * A simplified alternative to fetchQuery that returns the full response as opposed to the response data.
 * Primarily used for file download
 *
 * @param {Array} queryKey
 *
 * @returns {Promise}
 */
const fetchQueryResponse = queryKey => queryClient.fetchQuery(queryKey, fetchResponse);

const fetchFullQueryResponse = queryKey =>
  queryClient.fetchQuery(queryKey, fetchResponseWithFilename);

export { fetchFullQueryResponse };
export default fetchQueryResponse;
