import PropTypes from "prop-types";
import React, { useRef } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { overline } from "../Typography";

import { ArrowDownIcon, ArrowUpIcon } from "../Icons";
import { ColorPalette, Orientations } from "../StylingConstants";
import { getThemeColorStyle } from "./StyledComponents";
import { useTooltip } from "../Tooltip";
import { useYukaTheme } from "../ThemeContext";

const HEADER_COMPONENT_GAP = 4;
const DEFAULT_HEADER_LEADING_ICON_SIZE = 16;

const StyledHeader = styled.div`
  ${overline};
  display: flex;
  flex-direction: row;
  justify-content: ${props => {
    if (props.$column.align === "right") {
      return "flex-end";
    } else if (props.$column.align === "center") {
      return "center";
    }
    return "flex-start";
  }};
  align-items: center;
  gap: ${HEADER_COMPONENT_GAP}px;
  height: ${props => props.theme.tableStyles.header.height}px;
  padding: 0 ${props => props.theme.tableStyles.header.horizontalPadding}px;
  border-top: 1px solid ${props => getThemeColorStyle("border", props)};
  ${props =>
    !props.$isFinalColumn &&
    (props.$showBorders || props.$isFinalStickyColumn) &&
    css`
      border-right: 1px solid
        ${props => getThemeColorStyle(props.$isFinalStickyColumn ? "thickBorder" : "border", props)};
    `}
  border-bottom: 1px solid ${props => getThemeColorStyle("border", props)};
  ${props =>
    props.$column.sortable
      ? css`
          &:hover {
            background-color: ${props => props.theme.tableStyles.colors.headerHover};
            cursor: pointer;
          }
          &:active {
            background-color: ${props => props.theme.tableStyles.colors.headerActive};
          }
        `
      : css`
          cursor: default;
        `}

  svg:not(:root) {
    flex-shrink: 0;
  }
`;

const StyledHeaderText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DefaultHeaderRenderer = ({
  column,
  manualSortBy = false,
  onSort = _.noop,
  sortState = [],
  numberOfStickyColumns,
  ...props
}) => {
  const theme = useYukaTheme();
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  let [sortingByThisColumnAsc, sortingByThisColumnDesc] = [false, false];
  let nextSortingCriteria = { id: column.id };

  if (sortState.length) {
    const currentSort = sortState[0];
    // props.sortState looks like [{ id: "string", desc: false, [hideArrow]: true }]
    // If currentSort.hideArrow, both of these will be false, despite the data being sorted
    // in the table. This way we can have a default sort state that's not indicated visually.
    sortingByThisColumnAsc =
      !currentSort.hideArrow && currentSort.id === column.id && !currentSort.desc;
    sortingByThisColumnDesc =
      !currentSort.hideArrow && currentSort.id === column.id && currentSort.desc === true;
    // Sort order by clicking the headers goes in the cycle "unsorted, ascending, descending, unsorted".
    if (sortingByThisColumnAsc) {
      nextSortingCriteria.desc = true;
    } else if (sortingByThisColumnDesc) {
      nextSortingCriteria = null;
    } else {
      nextSortingCriteria.desc = false;
    }
  } else if (!manualSortBy) {
    sortingByThisColumnAsc = column.isSorted === true && !column.isSortedDesc;
    sortingByThisColumnDesc = column.isSorted === true && column.isSortedDesc === true;
  }

  const onClick = column.sortable ? () => onSort(nextSortingCriteria) : _.noop;

  const headerContentFromHeaderRenderer = column.headerRenderer
    ? column.headerRenderer({ header: column.header, ...column, ...props })
    : column.header;

  // Attach a tooltip to headers with truncated content.
  // Allow column-specified padding.
  let actualPadding =
    2 *
    (column.padding || column.padding === 0
      ? column.padding
      : theme.tableStyles.header.horizontalPadding);
  if (sortingByThisColumnAsc || sortingByThisColumnDesc) {
    actualPadding += 16 + HEADER_COMPONENT_GAP;
  }
  if (column.headerLeadingIcon) {
    actualPadding +=
      (column.headerLeadingIconSize || DEFAULT_HEADER_LEADING_ICON_SIZE) + HEADER_COMPONENT_GAP;
  }

  let tooltipContent = null;
  if (typeof headerContentFromHeaderRenderer === "string") {
    // We can only apply tooltips to string-type cell content.
    if (
      headerRef.current?.scrollWidth &&
      containerRef.current?.offsetWidth &&
      headerRef.current?.scrollWidth > containerRef.current?.offsetWidth - actualPadding
    ) {
      tooltipContent = headerContentFromHeaderRenderer;
    }
  }
  const tooltip = useTooltip(containerRef, tooltipContent, {
    orientation:
      column.headerTooltip && typeof column.headerTooltip === "function"
        ? Orientations.HORIZONTAL
        : Orientations.VERTICAL,
  });

  return (
    <>
      <StyledHeader
        $column={column}
        $showBorders={props.showBorders}
        $isFinalColumn={props.columns.length === column.index + 1}
        $isFinalStickyColumn={column.sticky && numberOfStickyColumns === column.index + 1}
        onClick={onClick}
        ref={containerRef}
      >
        {column.headerLeadingIcon && (
          <column.headerLeadingIcon
            size={column.headerLeadingIconSize}
            color={column.headerLeadingIconColor}
          />
        )}
        {typeof headerContentFromHeaderRenderer === "string" ? (
          <StyledHeaderText ref={headerRef}>{headerContentFromHeaderRenderer}</StyledHeaderText>
        ) : (
          headerContentFromHeaderRenderer
        )}
        {sortingByThisColumnAsc && <ArrowUpIcon color={ColorPalette.white80} />}
        {sortingByThisColumnDesc && <ArrowDownIcon color={ColorPalette.white80} />}
      </StyledHeader>
      {tooltipContent ? tooltip : null}
    </>
  );
};

DefaultHeaderRenderer.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    index: PropTypes.number,
    sticky: PropTypes.bool,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
    sortable: PropTypes.bool,
    padding: PropTypes.number,
    renderHeaderOverflow: PropTypes.bool,
    headerRenderer: PropTypes.func,
    header: PropTypes.string.isRequired,
    headerTooltip: PropTypes.func,
    headerLeadingIcon: PropTypes.func,
    headerLeadingIconSize: PropTypes.number,
    headerLeadingIconColor: PropTypes.string,
  }).isRequired,
  showBorders: PropTypes.bool,
  numberOfStickyColumns: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manualSortBy: PropTypes.bool,
  onSort: PropTypes.func,
  sortState: PropTypes.arrayOf(PropTypes.shape({})),
};

DefaultHeaderRenderer.displayName = "DefaultHeaderRenderer";

export default DefaultHeaderRenderer;
