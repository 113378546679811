import PropTypes from "prop-types";
import { useContext } from "react";
import { getRelatedObj } from "../../../api";
import { RelationshipsContext } from "../index";

const MarketOperatorCell = ({ value: connectedTicketId }) => {
  const relationships = useContext(RelationshipsContext);
  const connectedTicket = getRelatedObj(connectedTicketId, relationships);
  const marketOperators = connectedTicket.marketOperators.map(marketOperatorId =>
    getRelatedObj(marketOperatorId, relationships)
  );
  const marketOperatorNames = marketOperators.map(marketOperator => marketOperator.name);
  return marketOperatorNames.join(", ");
};

MarketOperatorCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

export default MarketOperatorCell;
