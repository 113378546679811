import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { SelectInput, RadioGroup } from "yuka";

import { USER_TYPE_OPTIONS, SUBTYPE_OPTIONS } from "./constants";

/**
 * A complex custom field built from underlying display elements to coerce into a single
 * API value
 */
const ClientTypeField = forwardRef(({ onChange, value, showError, initialUserType }, ref) => {
  const [userType, setUserType] = useState(initialUserType);

  const setMainUserType = type => {
    onChange?.(null);
    setUserType(type);
  };
  const setSubUserType = type => {
    onChange?.(type);
  };

  return (
    <>
      <SelectInput
        ref={ref}
        name="user-type"
        onChange={setMainUserType}
        placeholder="Select Client Type"
        label="Client User Type"
        options={USER_TYPE_OPTIONS}
        showError={showError}
      />
      {Boolean(userType) && (
        <RadioGroup
          items={SUBTYPE_OPTIONS[userType]}
          label={`${userType} Type`}
          name="sourceType"
          onChange={setSubUserType}
          value={value}
          showError={showError}
        />
      )}
    </>
  );
});

ClientTypeField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  showError: PropTypes.bool,
  initialUserType: PropTypes.string,
};

export default ClientTypeField;
