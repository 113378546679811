import React from "react";

import { DefinitionListHeader, Definition, StyledHeadline2 } from "yuka";

import { useFormValue } from "utils/hooks/form";
import { expandedMoneyFormat, nullBooleanFormat, shortMoneyFormat } from "utils/displayFormatUtils";

import {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  commissionDisplay,
} from "./constants";

const ReviewAndSend = () => {
  const name = useFormValue("name");
  const email = useFormValue("email");
  const engagementType = useFormValue("engagementType");
  const company = useFormValue("company");
  const commissionAmount = useFormValue("commissionAmount");
  const commissionType = useFormValue("commissionType");
  const isSolicited = useFormValue("isSolicited", false);
  const isCompanyAffiliate = useFormValue("isCompanyAffiliate");
  const isCompanyInsider = useFormValue("isCompanyInsider");
  const security = useFormValue("security");
  const pricePerShare = useFormValue("pricePerShare");
  const shareQuantity = useFormValue("shareQuantity");
  const contingencyOnQuantity = useFormValue("isContingentOnQuantity", false);
  const minimumQuantity = useFormValue("minimumQuantity", false);
  const effectiveDate = useFormValue("effectiveDate");
  const expirationDate = useFormValue("expirationDate", false);
  const currentPosition = useFormValue("currentPosition", false);
  const needsKyc = useFormValue("needsKyc");
  const sendBuysideQuestionnaire = useFormValue("sendBuysideQuestionnaire", false);
  const sendSellsideQuestionnaire = useFormValue("sendSellsideQuestionnaire", false);
  const sendWireInstructions = useFormValue("sendWireInstructions", false);
  return (
    <>
      <StyledHeadline2>Review and Send</StyledHeadline2>
      <div>
        <DefinitionListHeader title="Client" />
        <Definition label="Name" value={name} />
        <Definition label="Email" value={email} />
      </div>
      {(engagementType === BUYER_AGREEMENT || engagementType === SELLER_AGREEMENT) && (
        <div>
          <DefinitionListHeader
            title={`${engagementType === BUYER_AGREEMENT ? "Buyer" : "Seller"} Agreement`}
          />
          <Definition label="Company" value={company?.name || company} />
          <Definition
            label="Commission"
            value={commissionDisplay(commissionAmount, commissionType)}
          />
          <Definition label="Order Solicited" value={isSolicited ? "Solicited" : "Unsolicited"} />
          <Definition
            label={`${engagementType === BUYER_AGREEMENT ? "Buyer" : "Seller"} is Affliate`}
            value={nullBooleanFormat(isCompanyAffiliate)}
          />
          <Definition
            label={`${engagementType === BUYER_AGREEMENT ? "Buyer" : "Seller"} is Company Insider`}
            value={nullBooleanFormat(isCompanyInsider)}
          />
        </div>
      )}
      {(engagementType === BUYER_FIRM_ORDER || engagementType === SELLER_FIRM_ORDER) && (
        <div>
          <DefinitionListHeader
            title={`${engagementType === BUYER_AGREEMENT ? "Buyer" : "Seller"} Firm Order`}
          />
          <Definition label="Company" value={company?.name || company} />
          <Definition label="Security" value={security} />
          <Definition label="Price" value={expandedMoneyFormat(pricePerShare)} />
          <Definition label="Value" value={shortMoneyFormat(pricePerShare * shareQuantity)} />
          <Definition
            label="Commission"
            value={commissionDisplay(commissionAmount, commissionType)}
          />
          <Definition
            label="Contingency on Quantity"
            value={contingencyOnQuantity ? "AON (All or None)" : "Min Quantity"}
          />
          {!contingencyOnQuantity && (
            <Definition label="Minimum Quantity" value={minimumQuantity} />
          )}
          {effectiveDate && (
            <Definition label="Effective Date" value={effectiveDate?.toLocaleDateString()} />
          )}
          {expirationDate ? (
            <Definition label="Expiration Date" value={expirationDate.toLocaleDateString()} />
          ) : (
            <Definition label="Expiration" value="GTC (Good 'til Cancelled)" />
          )}
          <Definition label="Order Solicited" value={nullBooleanFormat(isSolicited)} />
          <Definition
            label={`${engagementType === BUYER_AGREEMENT ? "Buyer" : "Seller"} is Affliate`}
            value={nullBooleanFormat(isCompanyAffiliate)}
          />
          <Definition
            label={`${engagementType === BUYER_AGREEMENT ? "Buyer" : "Seller"} is Company Insider`}
            value={nullBooleanFormat(isCompanyInsider)}
          />
          {currentPosition && <Definition label="Current Position" value={currentPosition} />}
        </div>
      )}
      {needsKyc && (
        <div>
          <DefinitionListHeader title="Know Your Customer" />
          <Definition
            label="Investor Questionnaire"
            value={
              sendBuysideQuestionnaire
                ? "Send Buyside Questionnaire via DocuSign"
                : sendSellsideQuestionnaire
                ? "Send Sellside Questionnaire via DocuSign"
                : "Not Sent"
            }
          />
          {sendWireInstructions && (
            <Definition label="Wire Instructions" value="Send via DocuSign" />
          )}
        </div>
      )}
    </>
  );
};

export default ReviewAndSend;
