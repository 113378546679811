import React from "react";
import styled from "styled-components";
import { Table } from "yuka";

import { QUERY_KEYS, useInfiniteFetch } from "api";
import Filter from "forms/Filter";
import { SPV_TABLE_FILTERS } from "forms/filterNames";

import { spvTableColumns, useSPVTableConfig } from "./tableConfig";
import SPVTableFilter, { useSPVTableFilterConfig } from "./SPVTableFilter";

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

/**
 * Renders a table of SPV objects
 * @returns {JSX.Element}
 */
const SPVTable = () => {
  const { queryParams, onChangeFilters, filterProps } = useSPVTableFilterConfig();

  const { onRowClick, sortState, onSort } = useSPVTableConfig();

  const spvTableQueryParams = {
    "page[size]": 50,
    ...queryParams,
  };

  const { isLoading, data, fetchNextPage, isFetching, isFetchingNextPage } = useInfiniteFetch(
    QUERY_KEYS.SPV.list({ ...spvTableQueryParams })
  );
  return (
    <>
      <PaddedWrapper>
        <Filter
          name={SPV_TABLE_FILTERS}
          filterComponent={SPVTableFilter}
          onChange={onChangeFilters}
          isLoading={isFetching}
          {...filterProps}
        />
      </PaddedWrapper>
      <Table
        usePercentageColumnWidths
        sortState={sortState}
        onSort={onSort}
        onRowClick={onRowClick}
        paginationFunc={fetchNextPage}
        isPaginationLoading={isFetchingNextPage}
        isLoading={isLoading}
        data={data || []}
        columns={spvTableColumns}
      />
    </>
  );
};

export default SPVTable;
