import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { FinalFormField } from "yuka";
import { useMemo } from "react";

import { DatePicker, InputRow } from "forms/FormComponents";
import NoticedByField from "forms/fields/NoticedByField";

import { required, beforeToday } from "utils/form/inputValidations";

const CompanyDecisionFieldSet = ({ buy, sell }) => {
  const sources = useMemo(() => {
    return [...buy.sources, ...sell.sources];
  }, [buy.sources, sell.sources]);
  return (
    <>
      <InputRow>
        <DatePicker
          maxDate={new Date()}
          name="companyNoticedOn"
          placeholder="Company Noticed Date"
          validate={[required, beforeToday]}
        />
      </InputRow>
      <InputRow>
        <FinalFormField
          component={NoticedByField}
          type="custom-block"
          name="noticedBy"
          validate={[required]}
          sources={sources}
        />
      </InputRow>
    </>
  );
};

CompanyDecisionFieldSet.propTypes = {
  buy: PropTypes.shape({
    sources: PropTypes.arrayOf(PropTypes.array).isRequired,
  }).isRequired,
  sell: PropTypes.shape({
    sources: PropTypes.arrayOf(PropTypes.array).isRequired,
  }).isRequired,
};

const cleanSubmitValues = values => {
  values.companyNoticedOn = DateTime.fromJSDate(values.companyNoticedOn).toISO();
};

export default CompanyDecisionFieldSet;
export { cleanSubmitValues };
