import { useCallback } from "react";
import { fetchQuery, QUERY_KEYS } from "api";
import { InputRow, Select, Input } from "forms/FormComponents";
import MarketOperatorSelect from "forms/FormComponents/MarketOperatorSelect";
import { isEmail } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

const ClientInfoFieldSet = () => {
  const sourceType = useFormValue("sourceType");

  const loadParents = useCallback(
    search =>
      fetchQuery(QUERY_KEYS.SOURCES.list({ "filter[search]": search })).then(sources =>
        sources.map(source => ({
          value: source.searchId,
          label: `${source.name} (${source.category || "Unknown"} #${source.searchId})`,
          ...source,
        }))
      ),
    []
  );

  if (!sourceType) {
    return null;
  }

  return (
    <>
      <InputRow>
        <Select
          name="parent"
          title="Linked To (Optional)"
          placeholder="Organization/entity this client is part of"
          loadOptions={loadParents}
        />
      </InputRow>
      <p>
        Note: If linked with an entity from ZX, the contacts have been automatically imported and
        can be updated from the profile
      </p>
      <InputRow>
        <Input name="phone" title="Phone (Optional)" placeholder="Enter client's phone number" />
      </InputRow>
      <InputRow>
        <Input
          name="email"
          title="Email (Optional)"
          placeholder="Enter client's email address"
          validate={[isEmail]}
        />
      </InputRow>
      <InputRow>
        <MarketOperatorSelect
          name="zxRepresentatives"
          title="Assigned ZX Reps (Optional)"
          placeholder="ZX Rep's names"
          multiple
        />
      </InputRow>
    </>
  );
};

export default ClientInfoFieldSet;
