import {
  PHASE_EXECUTION,
  PHASE_INVOICING,
  PHASE_SETTLEMENT,
  PHASE_COMPANY_DECISION,
  PHASE_DEAL_FILE_REVIEW,
  PHASE_FEE_COLLECTION_AND_PAYMENT,
} from "utils/constants";

const TabNames = {
  live: "Live",
  settled: "Settled",
  disconnected: "Disconnected",
  closed: "Closed",
  all: "All",
};

const PHASES_LIVE = [PHASE_EXECUTION, PHASE_COMPANY_DECISION, PHASE_SETTLEMENT];
const PHASES_SETTLED = [PHASE_DEAL_FILE_REVIEW, PHASE_INVOICING, PHASE_FEE_COLLECTION_AND_PAYMENT];

export { TabNames, PHASES_LIVE, PHASES_SETTLED };
