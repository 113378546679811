import {
  COMMISSION_TYPE_PERCENT_TOTAL,
  COMMISSION_TYPE_PERCENT_FEES,
  COMMISSION_TYPE_FRACTION_OF_FEES,
  COMMISSION_TYPE_PRICE_PER_SHARE,
  COMMISSION_TYPE_LUMP_SUM,
  COMMISSION_TYPE_NON_APPLICABLE,
} from "utils/constants";

const CLIENT_TO_Z = "clientToZ";
const CLIENT_TO_BROKER = "clientToBroker";
const BROKER_TO_Z = "brokerToZ";
const Z_TO_BROKER = "zToBroker";

const COMMISSION_TYPE_OPTIONS = type => {
  if (type === CLIENT_TO_Z || type === CLIENT_TO_BROKER) {
    // Exclude percent fees
    return [
      { label: COMMISSION_TYPE_PERCENT_TOTAL, value: COMMISSION_TYPE_PERCENT_TOTAL },
      { label: COMMISSION_TYPE_PRICE_PER_SHARE, value: COMMISSION_TYPE_PRICE_PER_SHARE },
      { label: COMMISSION_TYPE_LUMP_SUM, value: COMMISSION_TYPE_LUMP_SUM },
      { label: COMMISSION_TYPE_NON_APPLICABLE, value: COMMISSION_TYPE_NON_APPLICABLE },
    ];
  }
  return [
    { label: COMMISSION_TYPE_PERCENT_TOTAL, value: COMMISSION_TYPE_PERCENT_TOTAL },
    { label: COMMISSION_TYPE_PERCENT_FEES, value: COMMISSION_TYPE_PERCENT_FEES },
    { label: COMMISSION_TYPE_FRACTION_OF_FEES, value: COMMISSION_TYPE_FRACTION_OF_FEES },
    { label: COMMISSION_TYPE_PRICE_PER_SHARE, value: COMMISSION_TYPE_PRICE_PER_SHARE },
    { label: COMMISSION_TYPE_LUMP_SUM, value: COMMISSION_TYPE_LUMP_SUM },
    { label: COMMISSION_TYPE_NON_APPLICABLE, value: COMMISSION_TYPE_NON_APPLICABLE },
  ];
};

export {
  CLIENT_TO_Z,
  CLIENT_TO_BROKER,
  BROKER_TO_Z,
  Z_TO_BROKER,
  COMMISSION_TYPE_FRACTION_OF_FEES,
  COMMISSION_TYPE_OPTIONS,
};
