import styled from "styled-components";
import { createContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Card, CardStyles, useCurrentTab, TabList } from "yuka";

import { getRelatedId, useInfiniteFetch, QUERY_KEYS } from "api";
import { useMpSource, mpTrack } from "utils/mixpanel";

import { taskColumns, documentColumns } from "./columns";

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

const TASKS_TAB = "Task alerts";
const DOCUMENTS_TAB = "Expiring Client Documents";
const tabs = [{ label: TASKS_TAB }, { label: DOCUMENTS_TAB }];

const RelationshipsContext = createContext(null);
const TABLE_NAME = "TASK_ALERTS_TABLE";

const TaskView = () => {
  const currentTab = useCurrentTab();
  const mpSource = useMpSource();
  const navigate = useNavigate();

  const {
    data: documents,
    fetchNextPage: fetchNextDocumentsPage,
    isFetchingNextPage: isFetchingNextDocumentsPage,
    isLoading: isLoadingDocuments,
  } = useInfiniteFetch(QUERY_KEYS.EXPIRING_DOCUMENTS.list());

  const {
    data: tasks,
    fetchNextPage: fetchNextTasksPage,
    isFetchingNextPage: isFetchingNextTasksPage,
    isLoading: isLoadingTasks,
    relationships,
  } = useInfiniteFetch(
    QUERY_KEYS.TASKS.list({
      include: "connection,send_reminder_to",
      "filter[is_task_alert]": true,
      sort: "send_reminder_on",
    })
  );

  const navigateToConnectionProfile = useCallback(
    ({ row: { original: originalData } }) => {
      mpTrack("click into connection profile", {
        source: `${mpSource} - ${TABLE_NAME}`,
        tradeId: originalData.tradeId,
        company: getRelatedId(originalData.company),
      });
      navigate(`/connections/all/${originalData.tradeId}/`);
    },
    [mpSource, navigate]
  );

  return (
    <Card cardStyle={CardStyles.SECTIONED} title="Task List">
      <PaddedWrapper>
        <TabList tabs={tabs} defaultTab={TASKS_TAB} />
      </PaddedWrapper>
      {currentTab === TASKS_TAB && (
        <RelationshipsContext.Provider value={relationships}>
          <Table
            data={tasks}
            columns={taskColumns}
            emptyTablePlaceholder="No expiring tasks"
            paginationFunc={fetchNextTasksPage}
            isPaginationLoading={isFetchingNextTasksPage}
            isLoading={isLoadingTasks}
            onRowClick={navigateToConnectionProfile}
            usePercentageColumnWidths
          />
        </RelationshipsContext.Provider>
      )}
      {currentTab === DOCUMENTS_TAB && (
        <Table
          data={documents}
          columns={documentColumns}
          usePercentageColumnWidths
          emptyTablePlaceholder="No expired or soon to be expired documents"
          paginationFunc={fetchNextDocumentsPage}
          isPaginationLoading={isFetchingNextDocumentsPage}
          isLoading={isLoadingDocuments}
        />
      )}
    </Card>
  );
};

export default TaskView;
export { RelationshipsContext };
