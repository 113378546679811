import React, { useMemo, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";

import { ModalStyles, FontColors, FormModal } from "yuka";

import { getRelatedObj, useWrite, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import { noop } from "utils";
import { COMPANY_DECISION_BLOCKED, PHASE_COMPANY_DECISION } from "utils/constants";
import { mpTrack } from "utils/mixpanel";
import LoadingSpinner from "utils/LoadingSpinner";
import DisconnectForm from "forms/DisconnectForm";
import calculator from "forms/withConnectionForm/calculateDecorator";

import { RelationshipsContext } from "../../ExecutionApp/ConnectionProfileView";

import getFieldSetForPhase, { getNextPhase } from "./getFieldSetForPhase";
import EmailRecipientsFieldSet from "./EmailRecipientsFieldSet";

const StyledLightText = styled.span`
  ${FontColors.theme50}
`;

/**
 * Renders the form to update details relevant to a connection.
 *
 * This renders in a modal with a collection of tabs for individual views.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const AdvanceConnectionPhaseForm = props => {
  const relationships = useContext(RelationshipsContext);
  const [showDisconnectForm, setShowDisconnectForm] = useState(false);
  const buy = getRelatedObj(props.connection.buy, relationships);
  const sell = getRelatedObj(props.connection.sell, relationships);

  const initialValues = React.useMemo(
    () => ({
      ...props.connection,
      companyNoticedOn: null,
      buy: { ...buy },
      sell: { ...sell },
    }),
    [props.connection, buy, sell]
  );

  const [Fields, cleanSubmitValues, sendEmails] = useMemo(() => {
    const fieldConfig = getFieldSetForPhase(props.connection);
    const _fields = fieldConfig?.fieldSet || fieldConfig;
    const _clean = fieldConfig?.cleanSubmitValues || noop;
    const _emails = fieldConfig?.sendEmails || false;
    return [_fields, _clean, _emails];
  }, [props.connection]);

  // Final form doesn't update decorators when the reference changes. Memoize it on initial load
  // to reduce console pollution
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const decorators = React.useMemo(() => [calculator(props)], []);

  const { onSubmit } = useWrite(
    QUERY_KEYS.CONNECTIONS.detail(props.connection.tradeId, ["advance"])
  );

  const wrappedOnSubmit = (values, ...params) => {
    const cleanedValues = {
      ...values,
    };
    cleanSubmitValues(cleanedValues);
    if (
      cleanedValues.phase === PHASE_COMPANY_DECISION &&
      cleanedValues.companyDecision === COMPANY_DECISION_BLOCKED &&
      !cleanedValues?.convertToForward
    ) {
      setShowDisconnectForm(true);
      return;
    }
    return onSubmit(cleanedValues, ...params).then(() => {
      mpTrack("advance connection phase", {
        phase: getNextPhase(props.connection),
        "previous phase": props.connection.phase,
      });
      queryClient.invalidateQueries(
        QUERY_KEYS.TASKS.list({ "filter[trade_id]": props.connection.tradeId })
      );
      props.onClose();
    });
  };

  const title = (
    <>
      <StyledLightText>Advance to: </StyledLightText>
      {getNextPhase(props.connection)}
    </>
  );

  if (showDisconnectForm) {
    return (
      <DisconnectForm
        connection={props.connection}
        onClose={() => {
          setShowDisconnectForm(false);
          props.onClose();
        }}
        disconnectSource="Issuer"
        disconnectReason="Company Blocked Trade"
      />
    );
  }

  return (
    <Form onSubmit={wrappedOnSubmit} decorators={decorators} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={props.onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title={title}
          modalStyle={ModalStyles.SCROLLABLE}
          requireDirtyForSubmit={false}
        >
          {submitting && <LoadingSpinner />}
          <Fields connection={props.connection} buy={buy} sell={sell} />
          {sendEmails && <EmailRecipientsFieldSet buy={buy} sell={sell} />}
        </FormModal>
      )}
    </Form>
  );
};

AdvanceConnectionPhaseForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    company: PropTypes.arrayOf(PropTypes.string).isRequired,
    buy: PropTypes.arrayOf(PropTypes.string),
    sell: PropTypes.arrayOf(PropTypes.string),
    tradeId: PropTypes.string,
    phase: PropTypes.string,
  }).isRequired,
};

export default AdvanceConnectionPhaseForm;
