import axios from "axios";

import { StatusCodes, whitelistedRoute } from "../constants";
import { isAuthenticated, logout } from "../authenticate";

import BaseMiddleware from "./base";

/**
 * Silently authenticates attempts to authenticate a user on an unauthenticated request
 */
export default class AuthenticationMiddleware extends BaseMiddleware {
  processRequest(config) {
    if (!whitelistedRoute(config.url) && !isAuthenticated()) {
      throw new axios.Cancel("Not authenticated");
    }
    return config;
  }
  processResponseError(error) {
    if (
      error.response &&
      error.response.status === StatusCodes.HTTP_401_UNAUTHORIZED &&
      isAuthenticated()
    ) {
      logout(false);
      window.google.accounts.id.prompt();
    }
    return Promise.reject(error);
  }
}
