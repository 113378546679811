import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { FormModal, ModalStyles, TabList, useCurrentTab } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { DataTypes } from "api/constants";
import LoadingSpinner from "utils/LoadingSpinner";

import GeneralInvoicingFieldSet from "./GeneralInvoicingFieldSet";
import FeeAgreementInvoicingFieldSet from "./FeeAgreementInvoicingFieldSet";
import ContactsInvoicingFieldSet from "./ContactsInvoicingFieldSet";

const QUERY_PARAM = "edit-tab";
const TAB_LIST = [{ label: "General" }, { label: "Default Fee Agreement" }, { label: "Contacts" }];

const StyledTabListContainer = styled.div`
  margin-bottom: 16px;
`;

/**
 * A form to edit client invoicing details
 *
 * @returns {Element}
 */
const EditClientInvoicingForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_PROFILES.detail(props.source.apiId));

  // Manage tabs
  // Note that because tabs are baked into routing this gets a bit complicated. Instead of managing
  // current tab and modal state separately, deduped into using the tab logic for both
  const currentTab = useCurrentTab(QUERY_PARAM);

  const [, setSearchParams] = useSearchParams();
  const onClose = () => {
    setSearchParams({ [QUERY_PARAM]: "", tab: "Settings" });
  };

  const wrappedOnSubmit = (values, ...params) => {
    values.contacts.map(contact => {
      values.defaultAttn.includes(contact.apiId)
        ? (contact.useInAttn = true)
        : (contact.useInAttn = false);
      values.defaultCc.includes(contact.apiId)
        ? (contact.useInCc = true)
        : (contact.useInCc = false);
    });
    onSubmit(values, ...params).then(() => {
      onClose();
    });
  };

  const initialValues = useMemo(
    () => ({
      ...props.sourceProfile,
      apiType: DataTypes.CLIENT_PROFILES,
      apiId: props.source.apiId,
      contacts: props.contacts,
      defaultAttn: props.contacts
        .filter(contact => contact.useInAttn)
        .map(contact => contact.apiId),
      defaultCc: props.contacts.filter(contact => contact.useInCc).map(contact => contact.apiId),
    }),
    [props.sourceProfile, props.source.apiId, props.contacts]
  );

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit Invoicing Settings"
          modalStyle={ModalStyles.SCROLLABLE}
        >
          {submitting && <LoadingSpinner />}
          <StyledTabListContainer>
            <TabList tabs={TAB_LIST} paramName={QUERY_PARAM} />
          </StyledTabListContainer>
          {currentTab == "General" && <GeneralInvoicingFieldSet />}
          {currentTab == "Default Fee Agreement" && <FeeAgreementInvoicingFieldSet />}
          {currentTab == "Contacts" && <ContactsInvoicingFieldSet contacts={props.contacts} />}
        </FormModal>
      )}
    </Form>
  );
};

EditClientInvoicingForm.propTypes = {
  contacts: PropTypes.arrayOf({
    useInAttn: PropTypes.bool,
    useInCc: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  sourceProfile: PropTypes.shape({
    partySuffix: PropTypes.string,
    defaultDueDate: PropTypes.string,
  }).isRequired,
};

export default EditClientInvoicingForm;
