function pickBy(obj, fn = Boolean) {
  const picked = {};
  for (const [key, val] of Object.entries(obj)) {
    if (fn(val, key)) {
      picked[key] = val;
    }
  }
  return picked;
}

export default pickBy;
