import React, { useState } from "react";
import PropTypes from "prop-types";

import { IconButton, UploadIcon } from "yuka";

import { SPVClassDocumentsForm } from "forms/DocumentsForm";

/**
 * Allows users to upload a new version of the document
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const UploadCell = ({ value: document }) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [allowUpload, setAllowUpload] = useState(false);
  const uploadAction = () => {
    setAllowUpload(true);
    setEditModalOpen(true);
  };
  const closeModal = () => {
    setAllowUpload(false);
    setEditModalOpen(false);
  };

  return (
    <div>
      {isEditModalOpen && (
        <SPVClassDocumentsForm
          existingDocument={document}
          allowUpload={allowUpload}
          closeModal={closeModal}
        />
      )}
      <IconButton onClick={uploadAction} icon={UploadIcon} disabled={document.isFromParent} />
    </div>
  );
};

UploadCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default UploadCell;
