import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { IconButton, PencilIcon } from "yuka";

import { getRelatedObj, useFetch, QUERY_KEYS } from "api";

import EditClientContactsForm from "forms/EditClientContactsForm";

const StyledCell = styled.div`
  display: flex;
`;

/**
 * Renders a dropdown for the contacts table
 * @returns {JSX.Element}
 */

const DropdownCell = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const { id } = useParams();
  const queryParams = {
    include: "source,contacts",
  };
  const { isError, isLoading, data, relationships } = useFetch(
    QUERY_KEYS.CLIENT_PROFILES.detail(id, [], queryParams)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  const source = getRelatedObj(data.source, relationships);
  const contacts = data.contacts.map(contact => getRelatedObj(contact, relationships));

  const openModal = () => {
    setEditModalOpen(true);
  };
  const closeModal = () => {
    setEditModalOpen(false);
  };

  return (
    <StyledCell>
      {isEditModalOpen && (
        <EditClientContactsForm
          sourceProfile={data}
          source={source}
          contacts={contacts}
          onClose={closeModal}
        />
      )}
      <IconButton onClick={openModal} icon={PencilIcon} />
    </StyledCell>
  );
};

DropdownCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DropdownCell;
