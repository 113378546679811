import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Card, List } from "yuka";

import { getRelatedObj } from "api";
import { ROLE_BROKER, ROLE_CLIENT } from "utils/constants";

import { RelationshipsContext } from "..";
import SourceListItems from "./SourceListItems";

/**
 * Top level of the link particpant documents card
 */
const LinkParticipantDocumentsCard = ({ connection }) => {
  const relationships = useContext(RelationshipsContext);
  const buy = getRelatedObj(connection.buy, relationships);
  const sell = getRelatedObj(connection.sell, relationships);

  const buysideBrokers = buy.sources
    .map(source => getRelatedObj(source, relationships))
    .filter(source => source.role === ROLE_BROKER);
  const buysideClients = buy.sources
    .map(source => getRelatedObj(source, relationships))
    .filter(source => source.role === ROLE_CLIENT);
  const sellsideBrokers = sell.sources
    .map(source => getRelatedObj(source, relationships))
    .filter(source => source.role === ROLE_BROKER);
  const sellsideClients = sell.sources
    .map(source => getRelatedObj(source, relationships))
    .filter(source => source.role === ROLE_CLIENT);

  return (
    <Card
      title="Link Participant Documents"
      tagline="Set which client profiles to import their documents into the connection profile"
    >
      <List>
        {buysideBrokers.map(broker => (
          <SourceListItems
            key={broker.apiId}
            connection={connection}
            source={broker}
            participantType="Buyside Broker"
          />
        ))}
        {buysideClients.map(client => (
          <SourceListItems
            key={client.apiId}
            connection={connection}
            source={client}
            participantType="Buyside Client"
          />
        ))}
        {sellsideBrokers.map(broker => (
          <SourceListItems
            key={broker.apiId}
            connection={connection}
            source={broker}
            participantType="Sellside Broker"
          />
        ))}
        {sellsideClients.map(client => (
          <SourceListItems
            key={client.apiId}
            connection={connection}
            source={client}
            participantType="Sellside Client"
          />
        ))}
      </List>
    </Card>
  );
};

LinkParticipantDocumentsCard.propTypes = {
  connection: PropTypes.shape({
    buy: PropTypes.arrayOf(PropTypes.string),
    sell: PropTypes.arrayOf(PropTypes.string),
    tradeId: PropTypes.string,
    dropboxLink: PropTypes.string,
  }).isRequired,
};

export default LinkParticipantDocumentsCard;
