import { GlobalNavigation, NavigationCategoryHeader } from "yuka";

import ZXLogo from "utils/ZXLogo";
import OneTapPrompt from "templates/OneTapPrompt";

import NavLink from "./NavLink";

const Navigation = () => {
  let userMetadata = JSON.parse(localStorage.getItem("userMetadata"));
  return (
    <GlobalNavigation logo={<ZXLogo />}>
      <NavigationCategoryHeader>Connections</NavigationCategoryHeader>
      <NavLink to="/connections/live/" label="Live" mpHeader="Connections" />
      <NavLink to="/connections/settled/" label="Settled" mpHeader="Connections" />
      <NavLink to="/connections/closed/" label="Closed" mpHeader="Connections" />
      <NavLink to="/connections/disconnected/" label="Disconnected" mpHeader="Connections" />
      <NavLink to="/connections/all/" label="All Connections" />
      <NavigationCategoryHeader>Other</NavigationCategoryHeader>
      <NavLink to="/clients/" label="Clients" />
      {userMetadata?.canViewAllConnections && <NavLink to="/tasks/" label="Task List" />}
      {userMetadata?.canViewAnySpv && <NavLink to="/spvs/" label="SPVs" alert="New" />}
      <OneTapPrompt />
    </GlobalNavigation>
  );
};

export default Navigation;
