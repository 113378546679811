import styled from "styled-components";
import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Card,
  CardStyles,
  ActionChip,
  EyeIcon,
  EyeOffIcon,
  UploadIcon,
  ColorPalette,
  Table,
} from "yuka";
import { useInfiniteFetch, useWrite, DataTypes, QUERY_KEYS } from "api";
import { ClientDocumentsForm } from "forms/DocumentsForm";
import BulkUploadDocumentsForm from "forms/BulkUploadDocumentsForm";
import useFileDragAndDrop from "utils/hooks/form/useFileDragAndDrop";
import { useDispatch, ACTIONS } from "utils/StateProvider";

import { useDocumentsTableConfig, columns } from "./documentsTableConfig";

import { INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES } from "./constants";

const StyledContainer = styled.div`
  height: 100%;
  min-height: 0;
`;

const StyledActions = styled.div`
  padding: 0 16px;
`;

const StyledDropzone = styled.div`
  height: 100%;
  display: flex;
  border: 1px dashed ${ColorPalette.faintWhite};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const DocumentsTab = ({ sourceProfile, documents: initialDocuments }) => {
  const { id: sourceId } = useParams();
  const [includeArchived, setIncludeArchived] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const { sortState, onSort } = useDocumentsTableConfig(includeArchived);
  const sortQueryParam = useMemo(() => {
    if (sortState.length) {
      return sortState.map(({ desc, id }) => (desc ? `-${id}` : id)).join(",");
    }
    return undefined;
  }, [sortState]);

  const {
    data: documents,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteFetch(
    QUERY_KEYS.CLIENT_DOCUMENTS.list(sourceId, {
      "filter[isArchived]": includeArchived ? undefined : false,
      ordering: sortQueryParam,
    }),
    {
      initialData: initialDocuments,
    }
  );

  const dispatch = useDispatch();
  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_DOCUMENTS.list(sourceId), { silent: true });
  const [totalFiles, setTotalFiles] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);

  const handleUpload = files => {
    setTotalFiles(files.length);
    setCompletedFiles(0);
    // Spread files to get an array rather than FileList
    return Promise.all(
      [...files].map(file =>
        onSubmit({ apiType: DataTypes.EXPIRABLE_DOCUMENT, file }).then(() => {
          setCompletedFiles(completed => completed + 1);
        })
      )
    );
  };

  useEffect(() => {
    if (completedFiles === totalFiles && totalFiles > 0) {
      dispatch({
        type: ACTIONS.addToast,
        message: "Upload Complete",
      });
      setTotalFiles(0);
    }
  }, [completedFiles, totalFiles, setTotalFiles, dispatch]);

  const [containerRef, fileInputRef, isDragging] = useFileDragAndDrop({
    multiple: true,
    onChange: handleUpload,
  });

  const pinnedDocuments = useMemo(
    () => (documents ? documents.filter(document => document.isPinned) : []),
    [documents]
  );

  const unpinnedDocuments = useMemo(
    () => (documents ? documents.filter(document => !document.isPinned) : []),
    [documents]
  );

  const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);

  return (
    <StyledCard title="Documents" cardStyle={CardStyles.SECTIONED}>
      <StyledActions>
        {includeArchived ? (
          <ActionChip
            onClick={() => setIncludeArchived(prev => !prev)}
            leadingIcon={EyeIcon}
            text="Hide archived documents"
          />
        ) : (
          <ActionChip
            onClick={() => setIncludeArchived(prev => !prev)}
            leadingIcon={EyeOffIcon}
            text="Show archived documents"
          />
        )}
        <ActionChip onClick={openForm} leadingIcon={UploadIcon} text="Upload new client document" />
        <BulkUploadDocumentsForm fileInputRef={fileInputRef} />
      </StyledActions>
      {formOpen && (
        <ClientDocumentsForm
          closeModal={closeForm}
          isClient={[INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES].includes(
            sourceProfile.sourceType
          )}
        />
      )}
      <StyledContainer ref={containerRef} $dragging={isDragging}>
        {isDragging ? (
          <StyledDropzone>Drop files here to upload</StyledDropzone>
        ) : (
          <>
            {pinnedDocuments.length > 0 && (
              <Table
                usePercentageColumnWidths
                manualSortBy
                emptyTablePlaceholder="No client documents"
                paginationFunc={fetchNextPage}
                isPaginationLoading={isFetchingNextPage}
                isLoading={isLoading}
                sortState={sortState}
                onSort={onSort}
                data={pinnedDocuments || []}
                columns={columns}
              />
            )}
            {unpinnedDocuments.length > 0 && (
              <Table
                usePercentageColumnWidths
                manualSortBy
                emptyTablePlaceholder="No client documents"
                paginationFunc={fetchNextPage}
                isPaginationLoading={isFetchingNextPage}
                isLoading={isLoading}
                sortState={sortState}
                onSort={onSort}
                data={unpinnedDocuments || []}
                columns={columns}
              />
            )}
          </>
        )}
      </StyledContainer>
    </StyledCard>
  );
};

DocumentsTab.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string.isRequired,
    })
  ),
  sourceProfile: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    kycStatus: PropTypes.string.isRequired,
    sourceType: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentsTab;
