import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { ModalStyles, FormModal } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";

import { Checkbox, TextArea } from "forms/FormComponents";

import DisconnectFieldSet from "./DisconnectFieldSet";

/**
 * Modal for disconnecting a connection.
 *
 * @param {object} connection
 * @returns {React.Element}
 */
const DisconnectForm = ({ connection, onClose, disconnectSource, disconnectReason }) => {
  const { onSubmit } = useWrite(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId, ["disconnect"]));
  const wrappedOnSubmit = (values, ...params) =>
    onSubmit(values, ...params).then(() => {
      mpTrack("disconnect connection");
      onClose();
    });
  return (
    <Form
      onSubmit={wrappedOnSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={{
        ...connection,
        disconnectSource,
        disconnectReason,
      }}
    >
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Disconnect"
          title="Disconnect this Connection"
          modalStyle={ModalStyles.SCROLLABLE}
          requireDirtyForSubmit={!(disconnectSource && disconnectReason)}
        >
          {submitting && <LoadingSpinner />}
          <p>Which ticket(s) should go back to the market?</p>
          <Checkbox name="sendBuyToMarket" title="Bid Ticket" />
          <Checkbox name="sendSellToMarket" title="Offer Ticket" />
          <p>Where did this connection break down?</p>
          <DisconnectFieldSet />
          <TextArea
            label="Additional Notes (Optional)"
            name="disconnectNotes"
            placeholder="Add a note to provide context or detail."
          />
        </FormModal>
      )}
    </Form>
  );
};

DisconnectForm.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  disconnectSource: PropTypes.string,
  disconnectReason: PropTypes.string,
};

DisconnectForm.defaultProps = {
  disconnectSource: null,
  disconnectReason: null,
};

export default DisconnectForm;
