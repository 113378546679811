/* eslint-disable no-undefined, no-magic-numbers */
import _ from "lodash";

const MAX_DECIMAL_PLACES = 6;

const required = value => (value || _.isNumber(value) ? undefined : "Required");

const requiredArray = value => (_.isArray(value) && !_.isEmpty(value) ? undefined : "Required");

const requiredBoolean = value => (_.isUndefined(value) ? "Required" : undefined);

const requiredObject = value => (_.isObject(value) && !_.isEmpty(value) ? undefined : "Required");

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const maxValue = max => value =>
  (value || _.isNumber(value)) && value > max ? `Must be at most ${max}` : undefined;

// eslint-disable-next-line no-loss-of-precision
const maxValueTicket = maxValue(9999999999999.9999);

const minValue = min => value =>
  (value || _.isNumber(value)) && value < min ? `Must be at least ${min}` : undefined;

const aboveValue = min => value =>
  (value || _.isNumber(value)) && value <= min ? `Must be more than ${min}` : undefined;

const afterToday = value =>
  value && new Date(value) <= new Date()
    ? "Must be a date after today in MM-DD-YY format"
    : undefined;

const beforeToday = value =>
  value && new Date(value) >= new Date()
    ? "Must be a date before today in MM-DD-YY format"
    : undefined;

/*
 * The TLD is long, but it can in theory be even longer. We may continue to tweak this
 * regex to be more accurate as we encounter more edge cases
 */
const isEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value)
    ? "Please enter a valid email address"
    : undefined;

const maxLength20 = maxLength(20);
const maxLength30 = maxLength(30);
const maxLength50 = maxLength(50);
const maxLength80 = maxLength(80);
const maxLength100 = maxLength(100);
const maxLength150 = maxLength(150);
const maxLength200 = maxLength(200);
const maxLength254 = maxLength(254);

const maxValuePercent = maxValue(100);

const isNotNegative = minValue(0);
const isPositive = aboveValue(0);

const isInteger = value =>
  (value && _.isString(value) ? value.split(".").length > 1 : value && !_.isInteger(value))
    ? "Value must be a valid integer"
    : undefined;

const isValidDecimal = value =>
  value && _.get(value.toString().split("."), "1", "").length > MAX_DECIMAL_PLACES
    ? `Value must have at most ${MAX_DECIMAL_PLACES} decimal places`
    : undefined;

export {
  aboveValue,
  afterToday,
  beforeToday,
  isEmail,
  isInteger,
  isNotNegative,
  isPositive,
  isValidDecimal,
  MAX_DECIMAL_PLACES,
  maxLength,
  maxLength20,
  maxLength30,
  maxLength50,
  maxLength80,
  maxLength100,
  maxLength150,
  maxLength200,
  maxLength254,
  maxValue,
  maxValuePercent,
  maxValueTicket,
  minValue,
  required,
  requiredArray,
  requiredBoolean,
  requiredObject,
};
