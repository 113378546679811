import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";
import { Button, ButtonStyles } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { Input, InputRow, Select } from "forms/FormComponents";
import { required } from "utils/form/inputValidations";
import { mpTrack } from "utils/mixpanel";

import { STATUS_TYPE_OPTIONS } from "./constants";

const StyledInputRow = styled(InputRow)`
  margin-bottom: 0;
`;

/**
 * A basic form to create/edit a task on a Match/Connection
 *
 * @returns {Element}
 */
const EditStatusUpdateForm = props => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.STATUS_UPDATES.detail(props.tradeId, props.statusUpdate.apiId)
  );
  const wrappedOnSubmit = (values, ...params) => {
    return onSubmit(values, ...params).then(() => {
      mpTrack("edit status update", {
        priority: values.statusType,
      });
      props.onClose();
    });
  };
  const cancel = () => {
    props.onClose();
  };
  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        ...props.statusUpdate,
      }}
    >
      {({ valid, submitting, dirty, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <StyledInputRow>
            <Select
              name="statusType"
              placeholder="Select a status"
              options={STATUS_TYPE_OPTIONS}
              noSort
              width={160}
              validate={[required]}
            />
            <Input name="content" placeholder="Add status update" validate={[required]} />
            <Button onClick={cancel}>Cancel</Button>
            <Button
              buttonStyle={ButtonStyles.CTA}
              disabled={!dirty || !valid || submitting}
              onClick={handleSubmit}
            >
              Post
            </Button>
          </StyledInputRow>
        </form>
      )}
    </Form>
  );
};

EditStatusUpdateForm.propTypes = {
  statusUpdate: PropTypes.shape({
    apiId: PropTypes.string,
    content: PropTypes.string,
    statusType: PropTypes.number,
  }).isRequired,
  tradeId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditStatusUpdateForm;
