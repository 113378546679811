import { createContext, useReducer, useContext, useMemo } from "react";
import PropTypes from "prop-types";

const GlobalContext = createContext(null);

// Available actions in this state provider.
const [unmountForm, resetForm, updateForm] = ["REMOVE_FORM", "RESET_FORM", "UPDATE_FORM"];
const [addToast, removeToast] = ["ADD_TOAST", "REMOVE_TOAST"];
const FORM_ACTIONS = {
  unmount: form => ({ type: unmountForm, form }),
  reset: (form, initialValues = {}) => ({ type: resetForm, form, initialValues }),
  change: (form, field, value) => ({ type: updateForm, form, field, value }),
};

const ACTIONS = {
  SET_TABLE_SORT_STATE: "SET_TABLE_SORT_STATE",
  addToast,
  removeToast,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TABLE_SORT_STATE:
      return {
        ...state,
        tableSort: {
          ...state.tableSort,
          [action.name]: action.value,
        },
      };
    case resetForm:
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.form]: action.initialValues,
        },
      };
    case updateForm:
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.form]: {
            ...state.forms[action.form],
            [action.field]: action.value,
          },
        },
      };
    case unmountForm:
      delete state.forms[action.form];
      // Ensure reference updates for react render stuff
      return { ...state, forms: { ...state.forms } };
    case addToast:
      return {
        ...state,
        toasts: [...state.toasts, action.message],
      };
    case removeToast:
      state.toasts.shift();
      return { ...state, toasts: [...state.toasts] };
    // Falls through to default case and returns state;
    default:
      return state;
  }
};

const StateProvider = props => {
  const stateInitializer = initialState => ({
    forms: initialState,
    sources: initialState,
    tableSort: initialState,
    toasts: [],
  });

  // A global data store to be shared by all children.
  const [state, dispatch] = useReducer(reducer, {}, stateInitializer);

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSelector = fn => {
  const { state } = useContext(GlobalContext);

  // Don't include fn in dependencies so an arrow function can be provided
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const val = useMemo(() => fn(state), [state]);

  return val;
};

const useDispatch = () => {
  const { dispatch } = useContext(GlobalContext);
  return dispatch;
};

export { GlobalContext, ACTIONS, FORM_ACTIONS, useSelector, useDispatch };
export default StateProvider;
