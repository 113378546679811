import axios from "axios";
import { DateTime } from "luxon";

import { camelToSnake, isObject, get, mapValues, pickBy, some } from "utils";

import { DETAIL, DataTypes } from "./constants";

/**
 * Determines whether or not the provided data is for a file upload.
 * Checks if data contains any ``File`` objects.
 *
 * @param {object} data - The data we are sending to the server
 * @returns {boolean} True if the data is for a file upload, False otherwise
 */
const isFileUpload = data =>
  some(Object.values(data), value => {
    if (value instanceof File) {
      return true;
    }
    if (Array.isArray(value) || isObject(value)) {
      return isFileUpload(value);
    }
    return false;
  });

/**
 * Recursively append to form data values for an object with field names matching the
 * corresponding JSON nesting
 */
const constructNestedFormData = (formData, data, prefix) => {
  Object.entries(data).forEach(([key, value]) => {
    // Our form data parser doesn't coerce keys back to snake case right now. Do it for it for now.
    const formDataKey = camelToSnake(`${prefix}${key}`);
    if (Array.isArray(value)) {
      value.forEach(arrayValue => formData.append(formDataKey, arrayValue));
    } else if (value instanceof FileList) {
      // Unable to forEach a FileList
      for (let i = 0; i < value.length; i++) {
        formData.append(formDataKey, value[i]);
      }
    } else if (value instanceof Date) {
      formData.append(formDataKey, DateTime.fromJSDate(value).toISODate());
    } else if (isObject(value) && !(value instanceof File)) {
      constructNestedFormData(formData, value, `${prefix}${key}.`);
    } else if (value === null) {
      formData.append(formDataKey, "");
    } else {
      formData.append(formDataKey, value);
    }
  });
};

/**
 * File uploads need to use multipart/form-data requests in order to pass the file data.
 * Take the data and build form data from it and return it
 */
const constructFormData = data => {
  const formData = new FormData();
  constructNestedFormData(formData, data, "");
  return formData;
};

/**
 * JSON:API library doesn't accept writes that are mismatch between url kwarg and id,
 * but forces us to accept the pk on serializing to send to the client.
 *
 * Use this to include any workaround references necessary
 */
const typeIdFieldMap = {
  [DataTypes.CONNECTIONS]: "tradeId",
};

const _parseRelationship = obj => {
  const tidiedObj = _parseIdRelationship(obj);
  const { id, type, relationships, attributes } = _formatAsJsonApiObj(tidiedObj).data;
  return { id, type, ...mapValues(relationships, "data"), ...attributes };
};

// In fetch internals, we take related objects and convert them to [type, id] pairs.
// This converts those back into acceptable JSON:API data
const _parseIdRelationship = obj =>
  mapValues(obj, attr => {
    if (Array.isArray(attr) && attr.length === 2 && Object.values(DataTypes).includes(attr[0])) {
      // Single fk
      return {
        apiType: attr[0],
        apiId: attr[1],
      };
    }
    if (Array.isArray(attr)) {
      // Multiple
      return attr.map(attrVal => {
        if (
          Array.isArray(attrVal) &&
          attrVal.length === 2 &&
          Object.values(DataTypes).includes(attrVal[0])
        ) {
          return {
            apiType: attrVal[0],
            apiId: attrVal[1],
          };
        }
        return attrVal;
      });
    }
    return attr;
  });

const _formatAsJsonApiObj = obj => {
  const attributes = mapValues(
    pickBy(obj, value => {
      if (value instanceof FileList || value instanceof File) {
        return true;
      }
      if (Array.isArray(value)) {
        return !some(value, isObject);
      }
      return !isObject(value) || value instanceof Date;
    }),
    attr => {
      if (attr instanceof Date) {
        return DateTime.fromJSDate(attr).toISODate();
      }
      return attr;
    }
  );
  delete attributes.apiId;
  delete attributes.apiType;
  const rawRelationships = pickBy(obj, value => {
    if (value instanceof FileList || value instanceof File) {
      return false;
    }
    if (Array.isArray(value)) {
      return some(value, isObject);
    }
    return isObject(value) && !(value instanceof Date);
  });
  const relationships = mapValues(rawRelationships, value => ({
    data: Array.isArray(value)
      ? value.map(inst => _parseRelationship(inst))
      : _parseRelationship(value),
  }));

  const idField = get(typeIdFieldMap, obj.apiType) || "apiId";
  delete attributes[idField];

  const data = { id: obj[idField], type: obj.apiType, attributes, relationships };

  return { data };
};

const updateData = ({ key: [type, method, ...routeSegments], data, config }) => {
  const params = routeSegments.pop();
  let httpAction = method === DETAIL ? axios.patch : axios.post;
  if (config?.method && ["patch", "post"].includes(config.method)) {
    httpAction = axios[config.method];
  }
  return httpAction(`${type}${routeSegments.join("/")}${routeSegments.length ? "/" : ""}`, data, {
    params,
  }).then(response => response.data);
};

/**
 * Slight misnomer. If there is a file upload, we use form data in a flattened structure
 * instead of JSON in the JSON:API structure as that's the way our parser on the server can
 * handle multipart requests. File uploads require multipart rather than JSON to pass the binary
 */
const formatAsJsonApi = data => {
  if (!data) {
    return null;
  }
  const structuredData = _parseIdRelationship(data);
  if (isFileUpload(structuredData)) {
    // JSON:API only fields - we don't have the same validation and trust a request to an endpoint
    // using multipart intends to upload for the corresponding type, and id is also inferred from
    // url anyway on the server and ignored in serialization in this case
    if ("apiId" in structuredData) {
      delete structuredData.apiId;
    }
    if ("apiType" in structuredData) {
      delete structuredData.apiType;
    }
    return constructFormData(structuredData);
  }
  return _formatAsJsonApiObj(structuredData);
};

export { updateData, formatAsJsonApi };
