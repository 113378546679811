import { useMemo } from "react";
import styled from "styled-components";
import { FontColors } from "yuka";

import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";
import useTableSort from "utils/tables/useTableSort";

import DropdownCell from "./DropdownCell";

const TABLE_NAME = "CONTACTS";

const StyledSpan = styled.span`
  ${FontColors.theme50};
`;

const isHighlightedCell = ({ value: isHighlighted }) =>
  isHighlighted ? "Yes" : <StyledSpan>No</StyledSpan>;

const useContactsTableConfig = () => {
  const currentTableSort = useSelector(state => state?.tableSort[TABLE_NAME]);
  const dispatch = useDispatch();
  const onSort = useTableSort(ACTIONS, dispatch, TABLE_NAME);

  const sortState = useMemo(() => {
    return currentTableSort && currentTableSort.length ? currentTableSort : [];
  }, [currentTableSort]);

  return {
    sortState,
    onSort,
  };
};

const NameColumn = {
  id: "name",
  accessor: "name",
  width: 23,
  header: "Name",
  sortable: true,
  sticky: true,
};

const RoleColumn = {
  id: "position",
  accessor: "position",
  width: 18,
  header: "Role",
  sortable: true,
};

const PhoneColumn = {
  id: "phone",
  accessor: "phone",
  width: 20,
  header: "Phone",
  sortable: true,
};

const EmailColumn = {
  id: "email",
  accessor: "email",
  width: 30,
  header: "Email",
  sortable: true,
};

const HighlightColumn = {
  id: "isHighlighted",
  accessor: "isHighlighted",
  width: 26,
  header: "Highlight in Profile",
  sortable: true,
  cellRenderer: isHighlightedCell,
};

const DropdownColumn = {
  id: "dropdown",
  accessor: contact => contact,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: DropdownCell,
};

const columns = [NameColumn, RoleColumn, PhoneColumn, EmailColumn, HighlightColumn, DropdownColumn];

export { useContactsTableConfig, columns };
