import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { ColorPalette, IconButton, PinFilledIcon, PinIcon } from "yuka";

import { useWrite, DataTypes, QUERY_KEYS } from "api";

const StyledButton = styled.div`
  cursor: pointer;
`;

/**
 * Renders a pin icon for the documents table. Clicking the icon pins the document
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const PinCell = ({ value: document }) => {
  const { id: spvClassId } = useParams();
  const { onSubmit } = useWrite(QUERY_KEYS.SPV_CLASS_DOCUMENTS.detail(spvClassId, document.apiId), {
    silent: true,
  });

  const handlePin = () => {
    const values = {
      isPinned: !document.isPinned,
      apiType: DataTypes.EXPIRABLE_DOCUMENT,
      apiId: document.apiId,
    };
    return onSubmit(values);
  };

  if (document.isFromParent) {
    return <IconButton icon={PinIcon} disabled />;
  }

  return (
    <StyledButton role="button" onClick={handlePin}>
      {document.isPinned ? <PinFilledIcon color={ColorPalette.blue400} /> : <PinIcon />}
    </StyledButton>
  );
};

PinCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PinCell;
