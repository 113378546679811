import _ from "lodash";
import PropTypes from "prop-types";

import CreateConnectionTaskForm from "forms/CreateConnectionTaskForm";

import TaskItem from "./TaskItem";

const TaskSection = props => (
  <>
    {_.map(props.tasks, task => (
      <TaskItem key={task.apiId} task={task} />
    ))}
    <CreateConnectionTaskForm connection={props.connection} phase={props.phase} />
  </>
);

TaskSection.propTypes = {
  connection: PropTypes.shape({
    id: PropTypes.string,
    phase: PropTypes.string,
  }).isRequired,
  phase: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      connection: PropTypes.arrayOf(PropTypes.string),
      resolvedOn: PropTypes.string,
      phase: PropTypes.string,
    })
  ).isRequired,
};

export default TaskSection;
