import _ from "lodash";
import createDecorator from "final-form-calculate";

import { MAX_DECIMAL_PLACES } from "utils/form/inputValidations";

const calculator = () =>
  createDecorator(
    {
      field: "pricePerShare",
      updates: {
        totalValue: (pps, allValues) => {
          if (allValues.tradeType === "SPV") {
            return allValues.totalValue;
          }
          if (pps && allValues.shareQuantity) {
            return _.round(pps * allValues.shareQuantity, MAX_DECIMAL_PLACES);
          }
          return allValues.totalValue;
        },
      },
    },
    {
      field: "shareQuantity",
      updates: {
        totalValue: (shareQuantity, allValues) => {
          if (allValues.tradeType === "SPV") {
            return allValues.totalValue;
          }
          if (shareQuantity && allValues.pricePerShare) {
            return _.round(shareQuantity * allValues.pricePerShare, MAX_DECIMAL_PLACES);
          }
          return allValues.totalValue;
        },
      },
    }
  );

export default calculator;
