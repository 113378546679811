import { DataTypes } from "api";
import { mpTrack } from "utils/mixpanel";

import { SPV_TYPE_MASTER, SPV_TYPE_SERIES, SPV_TYPE_STANDALONE, SPV_TYPE_CLASS } from "./constants";

const getWrappedOnSubmit = (spvType, spvOnSubmit, spvClassOnSubmit, navigate) => {
  const getSPVClassCleanedValues = (name, spvId, underlyingFund) => ({
    name: name,
    apiType: DataTypes.SPV_CLASS,
    spv: {
      apiId: spvId,
      apiType: DataTypes.SPV,
    },
    underlyingFund: underlyingFund,
  });

  const getSPVCleanedValues = (name, spvType, company = null, parentId = null) => {
    const cleanedValues = {
      name: name,
      apiType: DataTypes.SPV,
      spvType: spvType,
    };
    if (company) {
      cleanedValues.company = company;
    }
    if (parentId) {
      cleanedValues.parent = {
        apiId: parentId,
        apiType: DataTypes.SPV,
      };
    }
    return cleanedValues;
  };

  const spvMasterOnSubmit = (values, ...params) => {
    const cleanedValues = getSPVCleanedValues(values.name, SPV_TYPE_MASTER);
    spvOnSubmit(cleanedValues, ...params).then(masterResponse => {
      mpTrack("create spv - master");
      const cleanedSeriesValues = getSPVCleanedValues(
        values.seriesName,
        SPV_TYPE_SERIES,
        values.company,
        masterResponse.data.id
      );
      spvOnSubmit(cleanedSeriesValues, ...params).then(seriesResponse => {
        if (values.className) {
          const cleanedClassValues = getSPVClassCleanedValues(
            values.className,
            seriesResponse.data.id,
            values.underlyingFund
          );
          spvClassOnSubmit(cleanedClassValues, ...params);
        }
      });
      navigate(`/spvs/${masterResponse.data.id}/`);
    });
  };

  const spvSeriesOnSubmit = (values, ...params) => {
    const cleanedValues = getSPVCleanedValues(
      values.name,
      SPV_TYPE_SERIES,
      values.company,
      values.parent
    );
    spvOnSubmit(cleanedValues, ...params).then(seriesResponse => {
      mpTrack("create spv - series");
      if (values.className) {
        const cleanedClassValues = getSPVClassCleanedValues(
          values.className,
          seriesResponse.data.id,
          values.underlyingFund
        );
        spvClassOnSubmit(cleanedClassValues, ...params);
      }
      navigate(`/spvs/${seriesResponse.data.id}/`);
    });
  };

  const spvStandaloneOnSubmit = (values, ...params) => {
    const cleanedValues = getSPVCleanedValues(values.name, SPV_TYPE_STANDALONE, values.company);
    spvOnSubmit(cleanedValues, ...params).then(standaloneResponse => {
      mpTrack("create spv - standalone");
      if (values.className) {
        const cleanedClassValues = getSPVClassCleanedValues(
          values.className,
          standaloneResponse.data.id,
          values.underlyingFund
        );
        spvClassOnSubmit(cleanedClassValues, ...params);
      }
      navigate(`/spvs/${standaloneResponse.data.id}/`);
    });
  };

  const spvClassWrappedOnSubmit = (values, ...params) => {
    const spvId = values.series ? values.series : values.standalone;
    const cleanedValues = getSPVClassCleanedValues(values.name, spvId, values.underlyingFund);
    spvClassOnSubmit(cleanedValues, ...params).then(classResponse => {
      mpTrack("create spv class profile");
      navigate(`/spvs/class/${classResponse.data.id}/`);
    });
  };

  switch (spvType) {
    case SPV_TYPE_MASTER:
      return spvMasterOnSubmit;
    case SPV_TYPE_SERIES:
      return spvSeriesOnSubmit;
    case SPV_TYPE_STANDALONE:
      return spvStandaloneOnSubmit;
    case SPV_TYPE_CLASS:
      return spvClassWrappedOnSubmit;
    default:
      // Should never reach default
      return null;
  }
};

export { getWrappedOnSubmit };
