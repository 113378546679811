import PropTypes from "prop-types";

/*
 * The primary ZX Logo in grayscale
 *
 * @param {Object} props
 * @returns {React.Element}
 */
const ZXLogo = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 26.6667C20.6971 26.6667 26.6667 20.6971 26.6667 13.3333C26.6667 5.96954 20.6971 0 13.3333 0C5.96954 0 0 5.96954 0 13.3333C0 20.6971 5.96954 26.6667 13.3333 26.6667Z"
      fill="white"
    />
    <path
      d="M66.6666 80C74.0304 80 79.9999 74.0305 79.9999 66.6667C79.9999 59.3029 74.0304 53.3334 66.6666 53.3334C59.3028 53.3334 53.3333 59.3029 53.3333 66.6667C53.3333 74.0305 59.3028 80 66.6666 80Z"
      fill="white"
    />
    <path
      d="M22.7666 76.1001L3.8999 57.2335L57.2332 3.90015L76.0999 22.7668L22.7666 76.1001Z"
      fill="#00E6F6"
    />
    <path
      d="M66.6666 26.6667C74.0304 26.6667 79.9999 20.6971 79.9999 13.3333C79.9999 5.96954 74.0304 0 66.6666 0C59.3028 0 53.3333 5.96954 53.3333 13.3333C53.3333 20.6971 59.3028 26.6667 66.6666 26.6667Z"
      fill="white"
    />
    <path
      d="M13.3333 80C20.6971 80 26.6667 74.0305 26.6667 66.6667C26.6667 59.3029 20.6971 53.3334 13.3333 53.3334C5.96954 53.3334 0 59.3029 0 66.6667C0 74.0305 5.96954 80 13.3333 80Z"
      fill="white"
    />
  </svg>
);

ZXLogo.displayName = "ZXLogo";

ZXLogo.propTypes = {
  size: PropTypes.number,
};

ZXLogo.defaultProps = {
  size: 20,
};

export default ZXLogo;
