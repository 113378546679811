import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import { FormModal } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";

import LoadingSpinner from "utils/LoadingSpinner";
import { Input, InputRow } from "forms/FormComponents";
import { isEmail, required } from "utils/form/inputValidations";

/**
 * A basic form to resend an onboarding request
 *
 * @returns {Element}
 */
const ResendOnboardingRequestForm = ({ latestOnboardingRequest, id, onClose }) => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.ONBOARDING_REQUEST.detail(latestOnboardingRequest?.apiId, ["resend"]),
    { message: "Onboarding request resent" }
  );

  const wrappedOnSubmit = (...params) => {
    onSubmit(...params).then(() => {
      queryClient.invalidateQueries(QUERY_KEYS.CLIENT_PROFILES.detail(id));
      onClose();
    });
  };

  const initialValues = React.useMemo(
    () => ({
      apiId: latestOnboardingRequest.apiId,
      apiType: latestOnboardingRequest.apiType,
      email: latestOnboardingRequest.email,
    }),
    [latestOnboardingRequest.apiId, latestOnboardingRequest.apiType, latestOnboardingRequest.email]
  );

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Submit"
          title="Resend Onboarding Request"
          requireDirtyForSubmit={false}
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <Input
              name="email"
              title="Client Email"
              placeholder="example@example.com"
              validate={[required, isEmail]}
            />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

ResendOnboardingRequestForm.propTypes = {
  latestOnboardingRequest: PropTypes.shape({
    apiId: PropTypes.string,
    apiType: PropTypes.string,
    email: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResendOnboardingRequestForm;
