import React from "react";
import PropTypes from "prop-types";

import ParticipantListItemHelper from "./ParticipantListItemHelper";

/**
 * Helper component to more easily display the list items for sources
 */
const SourceListItems = ({ connection, source, participantType }) => (
  <>
    {source.individual && (
      <ParticipantListItemHelper
        key={source.individual}
        connection={connection}
        participantType={participantType}
        name={source.individualName}
        sourceId={source.individual}
      />
    )}
    {source.entity && (
      <ParticipantListItemHelper
        key={source.entity}
        connection={connection}
        participantType={participantType}
        name={source.entityName}
        sourceId={source.entity}
      />
    )}
  </>
);

SourceListItems.propTypes = {
  connection: PropTypes.shape({
    buy: PropTypes.arrayOf(PropTypes.string),
    sell: PropTypes.arrayOf(PropTypes.string),
    tradeId: PropTypes.string,
    dropboxLink: PropTypes.string,
  }).isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
    individual: PropTypes.number,
    individualName: PropTypes.string,
    entity: PropTypes.number,
    entityName: PropTypes.string,
  }).isRequired,
  participantType: PropTypes.string.isRequired,
};

export default SourceListItems;
