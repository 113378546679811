import React from "react";
import { useForm } from "react-final-form";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  StandardWizardActions,
  Field,
  Fonts,
  FinalFormField,
  FinalFormArrayField,
  FormSection,
} from "yuka";

import MarketOperatorSelect from "forms/FormComponents/MarketOperatorSelect";
import { required, isEmail } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

const StyledFooterContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const InvestorFieldSet = ({ name }) => (
  <FormSection name={name}>
    <FinalFormField
      type="text"
      width={200}
      name="name"
      placeholder="E.g. Bob Bradley"
      validate={[required]}
    />
    <FinalFormField
      type="text"
      width={200}
      name="email"
      placeholder="example@example.com"
      validate={[required, isEmail]}
    />
    <MarketOperatorSelect width={200} name="marketOperator" placeholder="e.g. Dave Dunford" />
    <FinalFormField type="checkbox" name="needsKyc" label="Include KYC" />
    <FinalFormField type="checkbox" name="needsSignedNda" label="Include NDA" />
  </FormSection>
);

InvestorFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
};

const InvestorFieldArray = () => {
  const { change } = useForm();
  const invitesFromForm = useFormValue("invites", []);

  const toggleFieldForAll = (fieldName, value) => {
    const newInvites = [];
    invitesFromForm.map(invite => {
      if (!invite) {
        newInvites.push({ [fieldName]: value });
      } else {
        newInvites.push({ ...invite, [fieldName]: value });
      }
    });
    change("invites", newInvites);
  };

  return (
    <>
      <div>
        <FinalFormArrayField
          component={InvestorFieldSet}
          addText="Add another investor"
          name="invites"
        />
      </div>
      <StyledFooterContainer>
        <Fonts.Body1theme80>Total: {invitesFromForm.length} investor(s)</Fonts.Body1theme80>
        <Field
          type="checkbox"
          name="kycForAll"
          label="Include KYC for all"
          onChange={value => toggleFieldForAll("needsKyc", value)}
        />
        <Field
          type="checkbox"
          name="ndaForAll"
          label="Include NDA for all"
          onChange={value => toggleFieldForAll("needsSignedNda", value)}
        />
      </StyledFooterContainer>
      <StandardWizardActions />
    </>
  );
};

export default InvestorFieldArray;
