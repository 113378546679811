import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { fetchQuery, QUERY_KEYS } from "api";
import { Input, InputRow, RadioGroup, Select } from "forms/FormComponents";
import { required } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

import {
  SPV_TYPE_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_STANDALONE,
  SPV_TYPE_OPTIONS_WITHOUT_MASTER,
} from "./constants";

const SPVClassFieldSet = ({ initialValues }) => {
  const parentType = useFormValue("parentType", null);
  const masterName = useFormValue("masterName", null);
  const loadMaster = useCallback(
    name =>
      fetchQuery(
        QUERY_KEYS.SPV.list({ "filter[search]": name, "filter[spvType]": SPV_TYPE_MASTER })
      ),
    []
  );
  const loadSeries = useCallback(
    name =>
      fetchQuery(
        QUERY_KEYS.SPV.list({
          "filter[search]": name,
          "filter[spvType]": SPV_TYPE_SERIES,
          "filter[parentName]": masterName,
        })
      ).then(series => series.map(series => ({ value: series.apiId, label: series.name }))),
    [masterName]
  );
  const loadStandalone = useCallback(
    name =>
      fetchQuery(
        QUERY_KEYS.SPV.list({
          "filter[search]": name,
          "filter[spvType]": SPV_TYPE_STANDALONE,
        }).then(standalone =>
          standalone.map(standalone => ({ value: standalone.apiId, label: standalone.name }))
        )
      ),
    []
  );

  const defaultSeriesOptions = [];
  if (initialValues.series) {
    defaultSeriesOptions.push({ value: initialValues.series, label: initialValues.spvName });
  }

  const defaultStandaloneOptions = [];
  if (initialValues.standalone) {
    defaultStandaloneOptions.push({
      value: initialValues.standalone,
      label: initialValues.spvName,
    });
  }

  return (
    <>
      <InputRow>
        <RadioGroup
          label="What type of SPV?"
          items={SPV_TYPE_OPTIONS_WITHOUT_MASTER}
          name="parentType"
        />
      </InputRow>
      {parentType === SPV_TYPE_SERIES && (
        <InputRow>
          <Select
            name="masterName"
            title="Select Master"
            placeholder="Type Master SPV Name"
            loadOptions={loadMaster}
            optionLabelKey="name"
            optionValueKey="name"
          />
          <Select
            name="series"
            title="Select Series"
            placeholder="Type Series SPV Name"
            loadOptions={loadSeries}
            options={defaultSeriesOptions}
            validate={[required]}
          />
        </InputRow>
      )}
      {parentType === SPV_TYPE_STANDALONE && (
        <InputRow>
          <Select
            name="standalone"
            title="Select Standalone SPV"
            placeholder="Type Standalone SPV Name"
            loadOptions={loadStandalone}
            options={defaultStandaloneOptions}
            validate={[required]}
          />
        </InputRow>
      )}
      <InputRow>
        <Input name="name" title="Class Name" placeholder="e.g. Class A" validate={[required]} />
        <Input
          name="underlyingFund"
          title="Underlying Fund (optional)"
          placeholder="e.g. Sequoia Capital I"
        />
      </InputRow>
    </>
  );
};

SPVClassFieldSet.propTypes = {
  initialValues: PropTypes.shape({
    spvName: PropTypes.string,
    series: PropTypes.string,
    standalone: PropTypes.string,
  }),
};

SPVClassFieldSet.defaultProps = {
  initialValues: {},
};

export default SPVClassFieldSet;
