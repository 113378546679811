import React from "react";
import { ThemeProvider, useTheme } from "styled-components";

import defaultTheme from "./defaultTheme";
import { __internalsSurfaceContext as SurfaceContext } from "./Layout/internal/Surface";

const useYukaTheme = () => {
  const theme = useTheme();
  const computedTheme = React.useMemo(() => ({ ...defaultTheme, ...theme }), [theme]);
  return computedTheme;
};

// eslint-disable-next-line react/prop-types
const YukaThemeProvider = ({ theme = {}, children }) => {
  const parentTheme = useYukaTheme();
  const computedTheme = React.useMemo(
    () => ({ ...defaultTheme, ...parentTheme, ...theme }),
    [theme, parentTheme]
  );

  const provider = <ThemeProvider theme={computedTheme}>{children}</ThemeProvider>;
  // Intentionally only check if provided - do not want to insert providers unintentionally
  if (theme.surfaceLevel) {
    return <SurfaceContext.Provider value={theme.surfaceLevel}>{provider}</SurfaceContext.Provider>;
  }
  return provider;
};

export { YukaThemeProvider, useYukaTheme };
