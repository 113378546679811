import { useCallback } from "react";
import PropTypes from "prop-types";
import { FinalFormField } from "yuka";

import { fetchQuery, QUERY_KEYS } from "api";
import { Input, InputRow, Select } from "forms/FormComponents";
import { required, requiredObject } from "utils/form/inputValidations";

import { SPV_TYPE_MASTER } from "./constants";
import { loadCompanyOptions } from "./loadCompanyOptions";

const SeriesSPVFieldSet = ({ initialValues }) => {
  const loadParents = useCallback(
    name =>
      fetchQuery(
        QUERY_KEYS.SPV.list({ "filter[search]": name, "filter[spv_type]": SPV_TYPE_MASTER })
      ).then(parents => parents.map(parent => ({ value: parent.apiId, label: parent.name }))),
    []
  );

  const defaultParentOptions = [];
  if (initialValues.parent) {
    defaultParentOptions.push({ value: initialValues.parent, label: initialValues.parentName });
  }

  return (
    <>
      <InputRow>
        <Select
          name="parent"
          title="Select Master"
          placeholder="Type Master SPV Name"
          loadOptions={loadParents}
          options={defaultParentOptions}
        />
      </InputRow>
      <InputRow>
        <Input name="name" title="Series Name" placeholder="e.g. Series I" validate={[required]} />
        <FinalFormField
          type="autocomplete"
          loadOptions={loadCompanyOptions}
          name="company"
          placeholder="e.g. OpenAI"
          label="Portfolio Company"
          validate={[requiredObject]}
        />
      </InputRow>
      <InputRow>
        <Input name="className" title="Class Name" placeholder="e.g. Class A" />
        <Input name="underlyingFund" title="Underlying Fund" placeholder="e.g. Sequoia Capital I" />
      </InputRow>
    </>
  );
};

SeriesSPVFieldSet.propTypes = {
  initialValues: PropTypes.shape({
    parent: PropTypes.string,
    parentName: PropTypes.string,
  }),
};

SeriesSPVFieldSet.defaultProps = {
  initialValues: {},
};

export default SeriesSPVFieldSet;
