import PropTypes from "prop-types";
import { useContext, useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { Field } from "yuka";
import { uniqBy } from "lodash-es";

import { required } from "utils/form/inputValidations";
import { getRelatedObj } from "api";

import { RelationshipsContext } from "../../ExecutionApp/ConnectionProfileView";

const OTHER = "Other";
const ZX = "ZX";

const StyledNoticedByWrapper = styled.div`
  width: 100%;
`;

const NoticedByField = props => {
  const relationships = useContext(RelationshipsContext);

  const [other, setOther] = useState(props.value);
  const [selectValue, setSelectValue] = useState(props.value);
  const [isLoading, setIsLoading] = useState(true);

  const setOtherOnChange = e => {
    props.onChange?.(e.target.value);
    setOther(e.target.value);
  };

  const setSelectValueOnChange = value => {
    props.onChange?.(value);
    setSelectValue(value);
  };

  const sources = useMemo(() => {
    const _sources = [
      { individualName: ZX },
      ...props.sources.map(source => getRelatedObj(source, relationships)),
      { individualName: OTHER },
    ];
    return uniqBy(
      _sources.map(source => ({ name: source.individualName || source.entityName })),
      "name"
    );
  }, [relationships, props.sources]);

  useEffect(() => {
    setIsLoading(true);
    if (
      props.value &&
      selectValue !== OTHER &&
      !sources.find(source => source.name == selectValue)
    ) {
      setSelectValue(OTHER);
      setOther(selectValue);
    } else if (
      // TODO only set when it loses focus
      sources.find(source => source.name == other)
    ) {
      setOther("");
      setSelectValue(other);
    }
    setIsLoading(false);
  }, [props.value, other, selectValue, setOther, setSelectValue, sources]);

  return (
    <StyledNoticedByWrapper>
      {!isLoading && (
        <Field
          type="select"
          name="noticedBy"
          placeholder="E.g. ZX"
          noSort
          label="Noticed by"
          options={sources}
          optionLabelKey="name"
          optionValueKey="name"
          value={selectValue}
          onChange={setSelectValueOnChange}
          validate={[required]}
        />
      )}
      {selectValue === OTHER && !isLoading && (
        <Field
          type="text"
          name="noticedBy-other"
          placeholder="e.g. Sell side"
          autoComplete="off"
          value={other}
          onChange={setOtherOnChange}
        />
      )}
    </StyledNoticedByWrapper>
  );
};

NoticedByField.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.array).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default NoticedByField;
