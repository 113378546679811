import { useMemo } from "react";
import { useQuery } from "react-query";

import { fetchData, cleanJsonApiData, queryConfig } from "./fetchInternals";
import { formatAsJsonApi } from "./writeInternals";
import { isAuthenticated } from "./authenticate";

const useFetch = (queryKey, customQueryConfig) => {
  const computedQueryConfig = { ...queryConfig, ...customQueryConfig };

  if ("initialData" in computedQueryConfig) {
    // Imperfect remap of initial data the will be parsed out like a normal response
    computedQueryConfig.initialData = {
      data: computedQueryConfig.initialData.map(formatAsJsonApi),
    };
  }

  const queryInfo = useQuery(queryKey, fetchData, computedQueryConfig);

  const data = useMemo(() => {
    return cleanJsonApiData(queryInfo.data);
  }, [queryInfo.data]);

  const returnInfo = useMemo(
    () => ({
      ...queryInfo,
      isLoading: queryInfo.isLoading,
      isError: isAuthenticated() && queryInfo.isError,
      ...data,
    }),
    [queryInfo, data]
  );

  return returnInfo;
};

export default useFetch;
