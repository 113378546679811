import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { TENTH_SECOND, QUARTER_SECOND } from "./constants";

/**
 * A convenience helper to scroll an element to a point over a given time frame.
 *
 * @param {object} element - The actual DOM element being scrolled
 * @param {number} to - The scrollTop value after the animation
 * @param {number} duration - The time we want the scroll to take in milliseconds
 *                            If duration is 0, scroll without animation
 */
const animateScrollTo = (element, to, duration) => {
  const distance = to - element.scrollTop;
  const increment = 25;
  const adjustment = duration ? (distance * increment) / duration : distance;

  const animateScroll = () => {
    const prevTop = element.scrollTop;
    // eslint-disable-next-line no-param-reassign
    element.scrollTop += adjustment;
    if (element.scrollTop !== to && prevTop !== element.scrollTop) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

/**
 * @param {object} element - HTMLElement
 * @returns {number} distance from top
 */
const getDistanceFromTop = element => (element ? element.scrollTop : 0);

/**
 * @param {object} element - HTMLElement
 * @returns {number} distance from bottom
 */
const getDistanceFromBottom = element =>
  element ? element.scrollHeight - element.clientHeight - element.scrollTop : 0;

/**
 * Hook for scrollable elements.
 * Allows callbacks to be called on scroll up/down and returns convenience methods
 * for scrolling to the top/bottom of the provided `scrollRef`.
 *
 * @param {object} args
 * @param {object} args.scrollRef - Ref to the scrollable element
 * @param {Function} args.onScrollUp - Function to invoke when the user scrolls to the top
 *                              Param is distance from top in pixels
 * @param {Function} args.onScrollDown - Function to invoke when the user scrolls to the bottom
 *                                Param is distance from bottom in pixels
 * @returns {Array<*>}
 *   0: {Function} scrollToBottom Function that scrolls the scrollRef to the bottom
 *   1: {Function} scrollToTop Function that scrolls the scrollRef to the top
 *   2: {Number} distanceFromBottom Distance in px from bottom of scrollRef
 *   3: {Number} distanceFromTop Distance in px from top of scrollRef
 */
const useScrollable = ({ scrollRef, onScrollUp, onScrollDown }) => {
  const [lastScrollPosition, setLastScrollPosition] = useState(
    scrollRef.current ? scrollRef.current.scrollTop : 0
  );

  const _onScroll = () => {
    const newScrollPosition = scrollRef.current?.scrollTop || 0;
    const distanceFromTop = getDistanceFromTop(scrollRef.current);
    const distanceFromBottom = getDistanceFromBottom(scrollRef.current);

    // scrolling up
    if (newScrollPosition < lastScrollPosition) {
      onScrollUp(distanceFromTop);
    }

    // scrolling down
    if (newScrollPosition > lastScrollPosition) {
      onScrollDown(distanceFromBottom);
    }

    setLastScrollPosition(newScrollPosition);
  };
  const onScroll = _.throttle(_onScroll, TENTH_SECOND);

  // Mount: Add scroll event listeners to scrollRef
  // Unmount: Remove scroll event listeners from scrollRef
  useEffect(() => {
    if (!scrollRef.current) {
      return undefined;
    }
    const scrolledElement = scrollRef.current;

    scrolledElement.addEventListener("scroll", onScroll);
    return () => {
      scrolledElement.removeEventListener("scroll", onScroll);
    };
  });

  // Convenience methods for scrolling to top/bottom of scrollRef
  const scrollToBottom = useCallback(
    ({ animate }) => {
      if (!scrollRef.current) {
        return;
      }

      animateScrollTo(
        scrollRef.current,
        scrollRef.current.scrollHeight,
        animate ? QUARTER_SECOND : 0
      );
    },
    [scrollRef]
  );

  const scrollToTop = useCallback(
    ({ animate }) => {
      if (!scrollRef.current) {
        return;
      }

      animateScrollTo(scrollRef.current, 0, animate ? QUARTER_SECOND : 0);
    },
    [scrollRef]
  );

  const distanceFromTop = getDistanceFromTop(scrollRef.current);
  const distanceFromBottom = getDistanceFromBottom(scrollRef.current);

  return [scrollToBottom, scrollToTop, distanceFromBottom, distanceFromTop];
};

export default useScrollable;
