import {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  commissionDisplay,
  COMMISSION_TYPE_PRICE_PER_SHARE,
  COMMISSION_TYPE_PERCENT_TOTAL,
} from "utils/constants";

const isSolicitedOptions = [
  { label: "Solicited", value: true },
  { label: "Unsolicited", value: false },
];

const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const commissionOptions = [
  { label: "$ per share", value: COMMISSION_TYPE_PRICE_PER_SHARE },
  { label: "% of total", value: COMMISSION_TYPE_PERCENT_TOTAL },
];

const isContingentOnQuantityOptions = [
  { label: "AON (All or None)", value: true },
  { label: "Min Quantity", value: false },
];

const expirationOptions = [
  { label: "GTC (Good 'til Cancelled)", value: false },
  { label: "Expiration Date", value: true },
];

export {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  commissionDisplay,
  isSolicitedOptions,
  yesNoOptions,
  commissionOptions,
  isContingentOnQuantityOptions,
  expirationOptions,
};
