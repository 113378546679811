import { createContext, useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { Card, TabList, Button, ButtonStyles, useTabRoute } from "yuka";

import { QUERY_KEYS, useInfiniteFetch } from "api";
import {
  DIRECT,
  SPV,
  PHASE_EXECUTION,
  PHASE_COMPANY_DECISION,
  PHASE_DEAL_FILE_REVIEW,
  PHASE_FEE_COLLECTION_AND_PAYMENT,
  PHASE_INVOICING,
  PHASE_SETTLEMENT,
} from "utils/constants";
import TaskSection from "./TaskSection";

import AdvanceConnectionPhaseForm, { getNextPhase } from "forms/AdvanceConnectionPhaseForm";

const TaskRelationshipsContext = createContext(null);

const TaskRemindersCard = ({ connection }) => {
  const [showForm, setShowForm] = useState(false);

  const openForm = useCallback(() => setShowForm(true), [setShowForm]);
  const closeForm = useCallback(() => setShowForm(false), [setShowForm]);

  const nextPhase = getNextPhase(connection);

  const {
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data: tasks,
    relationships,
  } = useInfiniteFetch(QUERY_KEYS.TASKS.list({ "filter[trade_id]": connection.tradeId }));

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [connection.phase, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const tabs = useMemo(() => {
    const tabNames = [{ label: "Execution", value: PHASE_EXECUTION }];
    if (connection.tradeType === DIRECT) {
      tabNames.push({ label: "Company Decision", value: PHASE_COMPANY_DECISION });
    }
    if (connection.tradeType !== SPV) {
      tabNames.push({ label: "Settlement", value: PHASE_SETTLEMENT });
    }
    tabNames.push(
      { label: "Deal Review", value: PHASE_DEAL_FILE_REVIEW },
      { label: "Invoicing", value: PHASE_INVOICING },
      { label: "Fee & Payment", value: PHASE_FEE_COLLECTION_AND_PAYMENT }
    );

    return tabNames.map(({ label, value }) => ({
      count: tasks?.filter(task => task.phase === value).length ?? 0,
      label,
      value,
    }));
  }, [tasks, connection.tradeType]);

  const [activePhaseLabel, setActivePhaseLabel] = useTabRoute(
    tabs.map(tab => tab.label),
    "tasksFor"
  );

  useEffect(() => {
    const tab = tabs.find(tab => tab?.value === connection.phase);
    setActivePhaseLabel(tab?.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection.phase]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  const activePhase = tabs.find(tab => tab.label === activePhaseLabel)?.value;

  return (
    <Card
      title="Task Reminders"
      linkText={nextPhase ? `Advance to ${nextPhase}` : null}
      linkProps={{ onClick: openForm, as: Button, buttonStyle: ButtonStyles.CTA }}
    >
      <TaskRelationshipsContext.Provider value={relationships}>
        {showForm && <AdvanceConnectionPhaseForm onClose={closeForm} connection={connection} />}
        <TabList defaultTab={connection.phase} tabs={tabs} paramName="tasksFor" />
        {activePhase && (
          <TaskSection
            connection={connection}
            phase={activePhase}
            tasks={tasks.filter(task => task.phase === activePhase)}
          />
        )}
      </TaskRelationshipsContext.Provider>
    </Card>
  );
};

TaskRemindersCard.propTypes = {
  connection: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    phase: PropTypes.string,
    tradeId: PropTypes.string,
    tradeType: PropTypes.string,
  }).isRequired,
};

export default TaskRemindersCard;
export { TaskRelationshipsContext };
