import styled from "styled-components";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TabList, useCurrentTab, FormModal, ModalStyles } from "yuka";

import { getRelatedId, getRelatedObj, useWrite, DataTypes, QUERY_KEYS } from "api";
import { mpTrack } from "utils/mixpanel";
import LoadingSpinner from "utils/LoadingSpinner";
import { ROLE_BROKER, ROLE_CLIENT } from "utils/constants";
import calculator from "forms/withConnectionForm/calculateDecorator";

import { RelationshipsContext } from "../../ExecutionApp/ConnectionProfileView";

import {
  CONNECTION_TABS,
  DETAILS_TAB,
  EXECUTION_TAB,
  BUY_TAB,
  SELL_TAB,
  BUY_COMMISSION_TAB,
  SELL_COMMISSION_TAB,
  QUERY_PARAM,
  DIRECTION_BUY_CLIENT_TO_Z,
  DIRECTION_BUY_CLIENT_TO_BUY_BROKER,
  DIRECTION_BUY_BROKER_TO_Z,
  DIRECTION_Z_TO_BUY_BROKER,
  DIRECTION_SELL_CLIENT_TO_Z,
  DIRECTION_SELL_CLIENT_TO_SELL_BROKER,
  DIRECTION_SELL_BROKER_TO_Z,
  DIRECTION_Z_TO_SELL_BROKER,
} from "./constants";
import NegotiatedTermsFieldSet from "./NegotiatedTermsFieldSet";
import BuysideDetailsFieldSet from "./BuysideDetailsFieldSet";
import SellsideDetailsFieldSet from "./SellsideDetailsFieldSet";
import CommissionFieldSet from "./CommissionFieldSet";
import ExecutionFieldSet from "./ExecutionFieldSet";

const StyledTabList = styled.div`
  padding-bottom: 16px;
`;

const isBrokerSource = source => source.role === ROLE_BROKER;
const isClientSource = source => source.role === ROLE_CLIENT;

const cleanTicketValues = (ticketData, tradeId, isBuy) => {
  let cleaned = { ...ticketData };
  const commissions = ticketData.commission;
  const commissionDirections = isBuy
    ? {
        clientToZCommission: DIRECTION_BUY_CLIENT_TO_Z,
        clientToBrokerCommission: DIRECTION_BUY_CLIENT_TO_BUY_BROKER,
        brokerToZCommission: DIRECTION_BUY_BROKER_TO_Z,
        zToBrokerCommission: DIRECTION_Z_TO_BUY_BROKER,
      }
    : {
        clientToZCommission: DIRECTION_SELL_CLIENT_TO_Z,
        clientToBrokerCommission: DIRECTION_SELL_CLIENT_TO_SELL_BROKER,
        brokerToZCommission: DIRECTION_SELL_BROKER_TO_Z,
        zToBrokerCommission: DIRECTION_Z_TO_SELL_BROKER,
      };

  Object.keys(commissionDirections).map(direction => {
    const commission =
      commissions.find(
        commission => commission.commissionDirection === commissionDirections[direction]
      ) || null;
    // Commission already exists
    if (commission) {
      if (
        Number(commission.commissionAmount) !== ticketData[`${direction}Amount`] ||
        commission.commissionType !== ticketData[`${direction}Type`] ||
        commission.commissionAmountNumerator !== ticketData[`${direction}AmountNumerator`] ||
        commission.commissionAmountDenominator !== ticketData[`${direction}AmountDenominator`]
      ) {
        commission.commissionAmount = ticketData[`${direction}Amount`];
        commission.commissionType = ticketData[`${direction}Type`];
        commission.commissionAmountNumerator = ticketData[`${direction}AmountNumerator`];
        commission.commissionAmountDenominator = ticketData[`${direction}AmountDenominator`];
      }
    } else if (ticketData[`${direction}Amount`] || ticketData[`${direction}Type`]) {
      cleaned.commission = [
        ...cleaned.commission,
        {
          commissionAmount: ticketData[`${direction}Amount`],
          commissionAmountNumerator: ticketData[`${direction}AmountNumerator`],
          commissionAmountDenominator: ticketData[`${direction}AmountDenominator`],
          commissionType: ticketData[`${direction}Type`],
          commissionDirection: commissionDirections[direction],
          tradeId: tradeId,
        },
      ];
    }
  });

  delete cleaned.marketOperatorsUncommitted;
  delete cleaned.brokerParticipantsUncommitted;
  delete cleaned.clientParticipantsUncommitted;
  cleaned.sources = [...(cleaned.brokerParticipants || []), ...(cleaned.clientParticipants || [])];
  delete cleaned.brokerParticipants;
  delete cleaned.clientParticipants;
  // delete commission form values
  delete cleaned.clientToZCommissionId;
  delete cleaned.clientToZCommissionAmount;
  delete cleaned.clientToZCommissionType;
  delete cleaned.clientToBrokerCommissionId;
  delete cleaned.clientToBrokerCommissionAmount;
  delete cleaned.clientToBrokerCommissionType;
  delete cleaned.brokerToZCommissionId;
  delete cleaned.brokerToZCommissionAmount;
  delete cleaned.brokerToZCommissionAmountNumerator;
  delete cleaned.brokerToZCommissionAmountDenominator;
  delete cleaned.brokerToZCommissionType;
  delete cleaned.zToBrokerCommissionId;
  delete cleaned.zToBrokerCommissionAmount;
  delete cleaned.zToBrokerCommissionAmountNumerator;
  delete cleaned.zToBrokerCommissionAmountDenominator;
  delete cleaned.zToBrokerCommissionType;
  return cleaned;
};

/**
 * Renders the form to update details relevant to a connection.
 *
 * This renders in a modal with a collection of tabs for individual views.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const EditConnectionForm = props => {
  // Modal Close
  const [, setSearchParams] = useSearchParams();
  const onClose = () => {
    setSearchParams({ [QUERY_PARAM]: "" });
  };
  const navigate = useNavigate();

  const relationships = useContext(RelationshipsContext);
  const buy = getRelatedObj(props.connection.buy, relationships);
  const sell = getRelatedObj(props.connection.sell, relationships);

  const initialValues = React.useMemo(() => {
    const buyMos = buy.marketOperators.map(mo => getRelatedObj(mo, relationships));
    const sellMos = sell.marketOperators.map(mo => getRelatedObj(mo, relationships));
    const buySources = buy.sources.map(source => getRelatedObj(source, relationships));
    const sellSources = sell.sources.map(source => getRelatedObj(source, relationships));
    const buyCommission = buy.commission.map(commission =>
      getRelatedObj(commission, relationships)
    );
    const sellCommission = sell.commission.map(commission =>
      getRelatedObj(commission, relationships)
    );

    const buyerToZCommission =
      buyCommission.find(
        commission => commission.commissionDirection === DIRECTION_BUY_CLIENT_TO_Z
      ) || null;
    const buyerToBrokerCommission =
      buyCommission.find(
        commission => commission.commissionDirection === DIRECTION_BUY_CLIENT_TO_BUY_BROKER
      ) || null;
    const buyBrokerToZCommission =
      buyCommission.find(
        commission => commission.commissionDirection === DIRECTION_BUY_BROKER_TO_Z
      ) || null;
    const zToBuyBrokerCommission =
      buyCommission.find(
        commission => commission.commissionDirection === DIRECTION_Z_TO_BUY_BROKER
      ) || null;

    const sellerToZCommission =
      sellCommission.find(
        commission => commission.commissionDirection === DIRECTION_SELL_CLIENT_TO_Z
      ) || null;
    const sellerToBrokerCommission =
      sellCommission.find(
        commission => commission.commissionDirection === DIRECTION_SELL_CLIENT_TO_SELL_BROKER
      ) || null;
    const sellBrokerToZCommission =
      sellCommission.find(
        commission => commission.commissionDirection === DIRECTION_SELL_BROKER_TO_Z
      ) || null;
    const zToSellBrokerCommission =
      sellCommission.find(
        commission => commission.commissionDirection === DIRECTION_Z_TO_SELL_BROKER
      ) || null;

    const buyBrokerSources = buySources.filter(isBrokerSource);
    const buyClientSources = buySources.filter(isClientSource);
    const sellBrokerSources = sellSources.filter(isBrokerSource);
    const sellClientSources = sellSources.filter(isClientSource);

    return {
      ...props.connection,
      executionRep: getRelatedId(props.connection.executionRep),
      buy: {
        ...buy,
        marketOperators: buyMos,
        brokerParticipants: buyBrokerSources,
        clientParticipants: buyClientSources,
        commission: buyCommission,
        clientToZCommissionId: buyerToZCommission?.apiId,
        clientToZCommissionAmount: buyerToZCommission?.commissionAmount,
        clientToZCommissionType: buyerToZCommission?.commissionType,
        clientToBrokerCommissionId: buyerToBrokerCommission?.apiId,
        clientToBrokerCommissionAmount: buyerToBrokerCommission?.commissionAmount,
        clientToBrokerCommissionType: buyerToBrokerCommission?.commissionType,
        brokerToZCommissionId: buyBrokerToZCommission?.apiId,
        brokerToZCommissionAmount: buyBrokerToZCommission?.commissionAmount,
        brokerToZCommissionAmountNumerator: buyBrokerToZCommission?.commissionAmountNumerator,
        brokerToZCommissionAmountDenominator: buyBrokerToZCommission?.commissionAmountDenominator,
        brokerToZCommissionType: buyBrokerToZCommission?.commissionType,
        zToBrokerCommissionId: zToBuyBrokerCommission?.apiId,
        zToBrokerCommissionAmount: zToBuyBrokerCommission?.commissionAmount,
        zToBrokerCommissionAmountNumerator: zToBuyBrokerCommission?.commissionAmountNumerator,
        zToBrokerCommissionAmountDenominator: zToBuyBrokerCommission?.commissionAmountDenominator,
        zToBrokerCommissionType: zToBuyBrokerCommission?.commissionType,
      },
      sell: {
        ...sell,
        marketOperators: sellMos,
        brokerParticipants: sellBrokerSources,
        clientParticipants: sellClientSources,
        commission: sellCommission,
        clientToZCommissionId: sellerToZCommission?.apiId,
        clientToZCommissionAmount: sellerToZCommission?.commissionAmount,
        clientToZCommissionType: sellerToZCommission?.commissionType,
        clientToBrokerCommissionId: sellerToBrokerCommission?.apiId,
        clientToBrokerCommissionAmount: sellerToBrokerCommission?.commissionAmount,
        clientToBrokerCommissionType: sellerToBrokerCommission?.commissionType,
        brokerToZCommissionId: sellBrokerToZCommission?.apiId,
        brokerToZCommissionAmount: sellBrokerToZCommission?.commissionAmount,
        brokerToZCommissionAmountNumerator: sellBrokerToZCommission?.commissionAmountNumerator,
        brokerToZCommissionAmountDenominator: sellBrokerToZCommission?.commissionAmountDenominator,
        brokerToZCommissionType: sellBrokerToZCommission?.commissionType,
        zToBrokerCommissionId: zToSellBrokerCommission?.apiId,
        zToBrokerCommissionAmount: zToSellBrokerCommission?.commissionAmount,
        zToBrokerCommissionAmountNumerator: zToSellBrokerCommission?.commissionAmountNumerator,
        zToBrokerCommissionAmountDenominator: zToSellBrokerCommission?.commissionAmountDenominator,
        zToBrokerCommissionType: zToSellBrokerCommission?.commissionType,
      },
    };
  }, [props.connection, buy, sell, relationships]);

  // Manage tabs
  // Note that because tabs are baked into routing this gets a bit complicated. Instead of managing
  // current tab and modal state separately, deduped into using the tab logic for both
  const currentTab = useCurrentTab(QUERY_PARAM);

  // Final form doesn't update decorators when the reference changes. Memoize it on initial load
  // to reduce console pollution
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const decorators = React.useMemo(() => [calculator(props)], []);

  const { onSubmit } = useWrite(QUERY_KEYS.CONNECTIONS.detail(props.connection.tradeId));

  const wrappedOnSubmit = (values, ...params) => {
    const cleanedValues = {
      ...values,
      buy: cleanTicketValues(values.buy, values.tradeId, true),
      sell: cleanTicketValues(values.sell, values.tradeId, false),
      executionRep: values.executionRep
        ? { apiType: DataTypes.EXECUTION_REP, apiId: values.executionRep }
        : null,
    };
    delete cleanedValues.company;
    delete cleanedValues.notes;
    return onSubmit(cleanedValues, ...params)
      .then(response => {
        mpTrack("edit connection");
        if (!response.data.attributes.permissions.read) {
          navigate("/connections/");
        } else {
          onClose();
        }
      })
      .catch(() => {
        onClose();
      });
  };

  return (
    <Form
      onSubmit={wrappedOnSubmit}
      decorators={decorators}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit Trade Details"
          modalStyle={ModalStyles.SCROLLABLE}
        >
          {submitting && <LoadingSpinner />}
          <StyledTabList>
            <TabList tabs={CONNECTION_TABS} paramName={QUERY_PARAM} />
          </StyledTabList>
          {currentTab === DETAILS_TAB && <NegotiatedTermsFieldSet connection={props.connection} />}
          {currentTab === EXECUTION_TAB && <ExecutionFieldSet buy={buy} sell={sell} />}
          {currentTab === BUY_TAB && <BuysideDetailsFieldSet ticket={buy} />}
          {currentTab === SELL_TAB && <SellsideDetailsFieldSet ticket={sell} />}
          {currentTab === BUY_COMMISSION_TAB && (
            <CommissionFieldSet connection={props.connection} isBuy />
          )}
          {currentTab === SELL_COMMISSION_TAB && (
            <CommissionFieldSet connection={props.connection} isBuy={false} />
          )}
        </FormModal>
      )}
    </Form>
  );
};

EditConnectionForm.propTypes = {
  connection: PropTypes.shape({
    executionRep: PropTypes.arrayOf(PropTypes.string),
    company: PropTypes.arrayOf(PropTypes.string).isRequired,
    buy: PropTypes.arrayOf(PropTypes.string),
    sell: PropTypes.arrayOf(PropTypes.string),
    tradeId: PropTypes.string,
  }).isRequired,
};

export default EditConnectionForm;
