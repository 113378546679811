import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { ListItem, ListStyles, HyperLink } from "yuka";

import { useDelete } from "api";
import NoteContentForm from "forms/NoteContentForm";
import { newlineStringFormat } from "utils/displayFormatUtils";

/**
 * Renders a single note element.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const NoteItem = ({ apiId, note, queryKey }) => {
  const { onSubmit } = useDelete(queryKey.detail(apiId, note.apiId));
  const [isEditNoteModalOpen, setEditNoteModalOpen] = React.useState(false);
  const editAction = () => setEditNoteModalOpen(true);
  const deleteAction = () => {
    onSubmit();
  };
  return (
    <ListItem
      text={newlineStringFormat(note.content)}
      listStyle={ListStyles.TWO_LINE}
      subtext={
        <>
          {note.authorFullName} |{" "}
          {DateTime.fromISO(note.createdOn).toLocaleString(DateTime.DATE_SHORT)} | &nbsp;
          <HyperLink onClick={editAction}>Edit</HyperLink>
          <span>&nbsp; | &nbsp;</span>
          <HyperLink onClick={deleteAction}>Delete</HyperLink>
        </>
      }
      trailingContent={
        isEditNoteModalOpen ? (
          <NoteContentForm
            queryKey={queryKey}
            note={note}
            apiId={apiId}
            onClose={() => setEditNoteModalOpen(false)}
            isEdit
          />
        ) : null
      }
    />
  );
};

NoteItem.propTypes = {
  apiId: PropTypes.string.isRequired,
  note: PropTypes.shape({
    apiId: PropTypes.string,
    authorFullName: PropTypes.string,
    content: PropTypes.string,
    createdOn: PropTypes.string,
  }).isRequired,
  queryKey: PropTypes.shape({
    detail: PropTypes.func,
  }).isRequired,
};

export default NoteItem;
