import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, List, ListItem, ListStyles } from "yuka";
import styled from "styled-components";

import EditSPVDetailsForm from "forms/EditSPVDetailsForm";

import { SPV_TYPE_SERIES, SPV_TYPE_STANDALONE } from "./constants";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const SettingsCard = ({ company, spv }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  return (
    <StyledCard
      cardStyle={CardStyles.SECTIONED}
      title="Settings"
      linkText="Edit"
      linkProps={{ onClick: () => setIsEditModalOpen(true) }}
    >
      <List divider>
        {[SPV_TYPE_SERIES, SPV_TYPE_STANDALONE].includes(spv.spvType) && (
          <ListItem listStyle={ListStyles.TWO_LINE} text={spv.companyName} subtext="Represents" />
        )}
        <ListItem listStyle={ListStyles.TWO_LINE} text={spv.name} subtext="SPV Name" />
        <ListItem listStyle={ListStyles.TWO_LINE} text={spv.spvType} subtext="SPV Type" />
      </List>
      {isEditModalOpen && (
        <EditSPVDetailsForm company={company} spv={spv} onClose={() => setIsEditModalOpen(false)} />
      )}
    </StyledCard>
  );
};

SettingsCard.propTypes = {
  company: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  spv: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
    spvType: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
};

export default SettingsCard;
