import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";

import { Input } from "forms/FormComponents";

const StyledInput = styled(Input)`
  display: none;
`;

/**
 * An all-purpose form for managing documents
 */
const BulkUploadDocumentsForm = ({ fileInputRef }) => (
  <Form onSubmit={() => {}}>
    {() => (
      <form>
        <StyledInput type="file" name="file" ref={fileInputRef} />
      </form>
    )}
  </Form>
);

BulkUploadDocumentsForm.propTypes = {
  fileInputRef: PropTypes.object.isRequired,
};

export default BulkUploadDocumentsForm;
