import { useMutation } from "react-query";

import { updateData, formatAsJsonApi } from "./writeInternals";
import { cleanJsonApiData } from "./fetchInternals";
import { queryClient } from "./queryClient";
import { LIST, DETAIL } from "./constants";

import { useDispatch, ACTIONS } from "utils/StateProvider";

const defaultErrorMessage = "Validation failed, please check your information and try again";

const useWrite = (queryKey, config) => {
  const dispatch = useDispatch();
  const mutation = useMutation(updateData, {
    mutationKey: queryKey,
    onSuccess: data => {
      /* params passed to onSuccess:
       *   data - response from `updateData`
       *   params - params object passed to `mutate`
       *   context - any resolved values from `onMutate` if we define it.
       */
      queryClient.invalidateQueries([queryKey[0], LIST]);
      queryClient.invalidateQueries(queryKey.slice(0, 3));
      if (queryKey.length >= 2 && queryKey[1] === DETAIL) {
        queryClient.setQueryData(queryKey.slice(0, 3), data);
        queryClient.setQueryData(queryKey, data);
      }

      const { data: cleanedData, relationships } = cleanJsonApiData(data);

      if (!config?.silent) {
        if (config?.message) {
          dispatch({ type: ACTIONS.addToast, message: config.message });
        } else {
          dispatch({ type: ACTIONS.addToast, message: "Successfully updated" });
        }
      }

      return Promise.resolve({ data: cleanedData, relationships });
    },
    onError: e => {
      const err = e?.response?.data?.errors?.[0];

      dispatch({
        type: ACTIONS.addToast,
        message: err && err?.source?.pointer.startsWith("/data") ? err.detail : defaultErrorMessage,
      });
    },
  });

  const onSubmit = data => {
    const jsonApiData = formatAsJsonApi(data);
    return mutation.mutateAsync({ key: queryKey, data: jsonApiData, config });
  };

  return {
    onSubmit,
    isIdle: mutation.isIdle,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    reset: mutation.reset,
  };
};

export default useWrite;
