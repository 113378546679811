import {
  DIRECTION_BUY_CLIENT_TO_Z,
  DIRECTION_BUY_CLIENT_TO_BUY_BROKER,
  DIRECTION_BUY_BROKER_TO_Z,
  DIRECTION_Z_TO_BUY_BROKER,
  DIRECTION_SELL_CLIENT_TO_Z,
  DIRECTION_SELL_CLIENT_TO_SELL_BROKER,
  DIRECTION_SELL_BROKER_TO_Z,
  DIRECTION_Z_TO_SELL_BROKER,
} from "utils/constants";

const DETAILS_TAB = "Negotiated Terms";
const EXECUTION_TAB = "Execution Info";
const BUY_TAB = "Buyside Info";
const SELL_TAB = "Sellside Info";
const BUY_COMMISSION_TAB = "Buy Commission";
const SELL_COMMISSION_TAB = "Sell Commission";
const QUERY_PARAM = "edit-tab";

const CONNECTION_TAB_NAMES = [
  DETAILS_TAB,
  EXECUTION_TAB,
  BUY_TAB,
  SELL_TAB,
  BUY_COMMISSION_TAB,
  SELL_COMMISSION_TAB,
];

const CONNECTION_TABS = CONNECTION_TAB_NAMES.map(label => ({ label }));

export {
  CONNECTION_TABS,
  DETAILS_TAB,
  EXECUTION_TAB,
  BUY_TAB,
  SELL_TAB,
  BUY_COMMISSION_TAB,
  SELL_COMMISSION_TAB,
  QUERY_PARAM,
  DIRECTION_BUY_CLIENT_TO_Z,
  DIRECTION_BUY_CLIENT_TO_BUY_BROKER,
  DIRECTION_BUY_BROKER_TO_Z,
  DIRECTION_Z_TO_BUY_BROKER,
  DIRECTION_SELL_CLIENT_TO_Z,
  DIRECTION_SELL_CLIENT_TO_SELL_BROKER,
  DIRECTION_SELL_BROKER_TO_Z,
  DIRECTION_Z_TO_SELL_BROKER,
};
