import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";
import { InputRow, Button, FinalFormField } from "yuka";

import { useWrite, QUERY_KEYS, DataTypes } from "api";

import { required } from "utils/form/inputValidations";

const StyledForm = styled.form`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

/**
 * A basic form to create a task on a Connection
 *
 * @returns {Element}
 */
const CreateClientNoteForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_NOTES.list(props.sourceId));
  const wrappedOnSubmit = (values, form) =>
    values.content
      ? onSubmit(values).then(() => {
          form.restart();
        })
      : null;
  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        apiType: DataTypes.CLIENT_NOTES,
      }}
    >
      {({ handleSubmit, form, valid, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          <InputRow>
            <FinalFormField
              type="textarea"
              name="content"
              placeholder="Enter your note here..."
              validate={[required]}
            />
          </InputRow>
          <Button onClick={form.submit} disabled={!valid || submitting}>
            Add note
          </Button>
        </StyledForm>
      )}
    </Form>
  );
};

CreateClientNoteForm.propTypes = {
  sourceId: PropTypes.string.isRequired,
};

export default CreateClientNoteForm;
