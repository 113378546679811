import { useContext } from "react";
import PropTypes from "prop-types";
import { getRelatedObj } from "api";

import { RelationshipsContext } from "./index";

const SPVInviteMarketOperatorCell = ({ value: spvInvite }) => {
  const { spvInviteRelationships } = useContext(RelationshipsContext);
  const marketOperator = getRelatedObj(spvInvite, spvInviteRelationships);
  return marketOperator?.name;
};

SPVInviteMarketOperatorCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

const OnboardingRequestMarketOperatorCell = ({ value: onboardingRequest }) => {
  const { spvInviteRelationships, onboardingRequestRelationships } =
    useContext(RelationshipsContext);
  const spvInvite = getRelatedObj(onboardingRequest.spvInvite, onboardingRequestRelationships);
  const marketOperator = getRelatedObj(spvInvite?.marketOperator, spvInviteRelationships);
  return marketOperator?.name;
};

OnboardingRequestMarketOperatorCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

export { SPVInviteMarketOperatorCell, OnboardingRequestMarketOperatorCell };
