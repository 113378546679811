import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ColorPalette, Fonts, HyperLink, LinkTypes, StyledCaption2, StyledHeadline1 } from "yuka";

import { percentFormat, shortMoneyFormat } from "utils/displayFormatUtils";

const StyledHeader = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${ColorPalette.white15};
  flex-direction: column;
`;

const StyledHeaderFirstRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  > :first-child {
    margin-right: 4px;
  }
`;

const SPVClassProfileHeader = ({ spvClass, spv }) => (
  <>
    <StyledCaption2>
      <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
        SPVs
      </HyperLink>
      {spv.parentId && (
        <>
          <span> / </span>
          <HyperLink url={`/spvs/${spv.parentId}/`} linkType={LinkTypes.LOCAL_LINK}>
            {spv.parentName}
          </HyperLink>
        </>
      )}
      <span> / </span>
      <HyperLink url={`/spvs/${spv.apiId}/`} linkType={LinkTypes.LOCAL_LINK}>
        {spv.name}
      </HyperLink>
      <span> / {spvClass.name}</span>
    </StyledCaption2>
    <StyledHeader>
      <StyledHeaderFirstRow>
        <StyledHeadline1>{spvClass.name}</StyledHeadline1>
      </StyledHeaderFirstRow>
      <span>
        <StyledCaption2>
          {" Represents "}
          <Fonts.Caption2theme80>{spv.companyName}</Fonts.Caption2theme80>
          {" | Share Class "}
          <Fonts.Caption2theme80>{spvClass.securityType || "--"}</Fonts.Caption2theme80>
          {" | PPS "}
          <Fonts.Caption2theme80>
            {shortMoneyFormat(spvClass.pricePerShare) || "--"}
          </Fonts.Caption2theme80>
          {" | Placement Fee "}
          <Fonts.Caption2theme80>
            {percentFormat(spvClass.placementFee) || "--"}
          </Fonts.Caption2theme80>
          {" | Management Fee "}
          <Fonts.Caption2theme80>
            {percentFormat(spvClass.managementFee) || "--"}
          </Fonts.Caption2theme80>
          {" | Carried Interest "}
          <Fonts.Caption2theme80>
            {percentFormat(spvClass.carriedInterest) || "--"}
          </Fonts.Caption2theme80>
        </StyledCaption2>
      </span>
    </StyledHeader>
  </>
);

SPVClassProfileHeader.propTypes = {
  spvClass: PropTypes.shape({
    companyName: PropTypes.string,
    name: PropTypes.string.isRequired,
    spvName: PropTypes.string,
    securityType: PropTypes.string,
    pricePerShare: PropTypes.string,
    placementFee: PropTypes.string,
    managementFee: PropTypes.string,
    carriedInterest: PropTypes.string,
  }).isRequired,
  spv: PropTypes.shape({
    apiId: PropTypes.string,
    companyName: PropTypes.string,
    name: PropTypes.string,
    parentId: PropTypes.string,
    parentName: PropTypes.string,
  }),
};

export default SPVClassProfileHeader;
