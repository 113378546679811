import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import getLinkProps, { LinkTypes } from "../Button/getLinkProps";
import { PrimaryColorPalette } from "../StylingConstants";
import * as AllIcons from "../Icons";
import { body1, FontColors, caption2 } from "../Typography";

const StyledText = styled.span`
  ${body1}
  flex-grow: 1;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSubtext = styled.span`
  ${caption2}
  ${FontColors.theme50}
  margin-top: 4px;
  flex-grow: 1;
  ${props =>
    props.$wrap
      ? `
      white-space: wrap;
    `
      : `
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

const StyledAfterText = styled.div`
  margin-left: 24px;
  line-height: 1;
`;

const StyledSingleLineBeforeText = styled.div`
  line-height: 1;
  margin-right: 16px;
  max-width: 24px;
  max-height: 24px;
`;

const StyledMultiLineBeforeText = styled.div`
  line-height: 1;
  margin-right: 24px;
  max-width: 32px;
  max-height: 32px;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.$wrap
      ? `
    margin: 12px 0;
  `
      : `
    margin: 14px 0;
  `}
  flex-grow: 1;
  min-width: 0;
  width: 100%;
`;

const StyledListItem = styled.li`
  // normalizing buttons
  background: transparent;
  outline: none;
  border: none;
  color: inherit;
  text-align: left;
  letter-spacing: inherit;
  list-style: none;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  line-height: inherit;
  width: 100%;

  align-items: center;
  box-sizing: border-box;
  display: flex;
  min-width: 200px;
  min-height: 48px;
  padding: 0 16px;
  ${props =>
    props.onClick || props.href || props.to
      ? `
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
    &:active {
      background: rgba(255, 255, 255, 0.07);
    }
  `
      : ""}
`;

const SingleLineListItem = React.forwardRef((props, ref) => (
  <StyledListItem
    as={props.as}
    {...props.linkProps}
    ref={ref}
    className={props.className}
    onClick={props.onClick}
    role={props.role}
  >
    {(props.avatar || props.leadingIcon) && (
      <StyledSingleLineBeforeText>
        {props.avatar || <props.leadingIcon color={props.leadingIconColor} size={18} />}
      </StyledSingleLineBeforeText>
    )}
    <StyledText>{props.text}</StyledText>
    {props.trailingContent && <StyledAfterText>{props.trailingContent}</StyledAfterText>}
  </StyledListItem>
));

const TwoLineListItem = React.forwardRef((props, ref) => (
  <StyledListItem
    as={props.as}
    {...props.linkProps}
    ref={ref}
    className={props.className}
    onClick={props.onClick}
    role={props.role}
  >
    {(props.avatar || props.leadingIcon) && (
      <StyledMultiLineBeforeText>
        {props.avatar || <props.leadingIcon color={props.leadingIconColor} size={18} />}
      </StyledMultiLineBeforeText>
    )}
    <StyledTextWrapper $wrap={false}>
      <StyledText>{props.text}</StyledText>
      <StyledSubtext $wrap={false}>{props.subtext}</StyledSubtext>
    </StyledTextWrapper>
    {props.trailingContent && <StyledAfterText>{props.trailingContent}</StyledAfterText>}
  </StyledListItem>
));

const ThreeLineListItem = React.forwardRef((props, ref) => (
  <StyledListItem
    as={props.as}
    {...props.linkProps}
    ref={ref}
    className={props.className}
    onClick={props.onClick}
    role={props.role}
  >
    {(props.avatar || props.leadingIcon) && (
      <StyledMultiLineBeforeText>
        {props.avatar || <props.leadingIcon color={props.leadingIconFill} size={18} />}
      </StyledMultiLineBeforeText>
    )}
    <StyledTextWrapper $wrap>
      <StyledText>{props.text}</StyledText>
      <StyledSubtext $wrap>{props.subtext}</StyledSubtext>
    </StyledTextWrapper>
    {props.trailingContent && <StyledAfterText>{props.trailingContent}</StyledAfterText>}
  </StyledListItem>
));

const ListStyles = {
  SINGLE_LINE: "SINGLE_LINE",
  TWO_LINE: "TWO_LINE",
  THREE_LINE: "THREE_LINE",
};

/**
 * A standard item in a list. Contains primary text and optionally includes:
 *
 * - leading icon or avatar
 * - subtext
 * - trailing list control or metadata
 * - onClick handler for an actionable list item
 *
 * NOTE: If using for navigation, use a `NavItem` instead. This includes the `active` state desired
 *
 * Migration from old ListItem props
 * icon => leadingIcon + leadingIconColor
 * thumbnail => avatar
 * children => trailingContent
 * nowrap => deprecated
 * active => deprecated
 */
const ListItem = React.forwardRef((props, ref) => {
  let StyledElement;
  switch (props.listStyle) {
    case ListStyles.SINGLE_LINE:
      StyledElement = SingleLineListItem;
      break;
    case ListStyles.TWO_LINE:
      StyledElement = TwoLineListItem;
      break;
    case ListStyles.THREE_LINE:
      StyledElement = ThreeLineListItem;
      break;
  }

  let [linkProps, linkAs] = getLinkProps(props.url, props.linkType);

  if (!props.url && !props.onClick) {
    linkAs = "li";
    // clear out link props as unneeded on a <li>
    linkProps = {};
  }

  if (props.children || props.thubnail || props.icon) {
    // eslint-disable-next-line no-console
    console.warn(
      "You are passing V1 props to a V2 ListItem. Trailing content and other details may be lost"
    );
  }

  return <StyledElement ref={ref} as={linkAs} {...props} linkProps={linkProps} />;
});

ListItem.propTypes = {
  listStyle: PropTypes.oneOf(Object.values(ListStyles)),
  text: PropTypes.node.isRequired,
  subtext: PropTypes.node,
  leadingIcon: PropTypes.oneOf(Object.values(AllIcons)),
  leadingIconColor: PropTypes.oneOf(Object.values(PrimaryColorPalette)),
  avatar: PropTypes.element,
  trailingContent: PropTypes.element,
  onClick: PropTypes.func,
  className: PropTypes.string,
  linkType: PropTypes.oneOf(Object.values(LinkTypes)),
  url: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.string,
};

ListItem.defaultProps = {
  listStyle: ListStyles.SINGLE_LINE,
  leadingIconColor: PrimaryColorPalette.white50,
  role: "listitem",
};

ListItem.displayName = "ListItem";

SingleLineListItem.propTypes = ListItem.propTypes;
TwoLineListItem.propTypes = ListItem.propTypes;
ThreeLineListItem.propTypes = ListItem.propTypes;

const WrappedListItem = styled(ListItem)``;

WrappedListItem.displayName = "StyledListItem";

export default WrappedListItem;
export { ListStyles, ListItem as DocsListItem };
