function partition(arr, fn = Boolean) {
  const yes = [];
  const no = [];

  arr.forEach(val => {
    if (fn(val)) {
      yes.push(val);
    } else {
      no.push(val);
    }
  });
  return [yes, no];
}

export default partition;
