import { createContext, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash-es";
import PropTypes from "prop-types";

import { CardStyles, Card, Table } from "yuka";

import { useInfiniteFetch, QUERY_KEYS, getRelatedObj, getRelatedId } from "api";
import Filter from "forms/Filter";
import { CONNECTIONS_FILTERS } from "forms/filterNames";
import { pickBy, isEqual, findKey } from "utils";
import { mpTrack, useMpSource } from "utils/mixpanel";
import { HALF_SECOND } from "utils/constants";
import useTableSort from "utils/tables/useTableSort";

import ConnectionsFilters from "./ConnectionsFilters";
import { getStatusFilterInitialValues, INITIAL_SORT_STATE_MAP, TAB_PHASE_MAP } from "./constants";

import { ACTIONS, GlobalContext } from "utils/StateProvider";

const RelationshipsContext = createContext(null);

const ConnectionsTableView = ({ name, columns }) => {
  const {
    state: { tableSort, forms },
    dispatch,
  } = useContext(GlobalContext);
  const source = useMpSource();
  const navigate = useNavigate();
  let [relevantPhases, phaseOptions] = TAB_PHASE_MAP[name] || [undefined, []];

  // Some click handlers that will be forwarded to the necessary cells.
  const clickTradeId = ({ value: tradeId }) =>
    navigator.clipboard
      .writeText(tradeId)
      .then(() => {
        dispatch({ type: ACTIONS.addToast, message: `Copied "${tradeId}" to clipboard.` });
      })
      .catch(() => {
        dispatch({ type: ACTIONS.addToast, message: "Failed to copy." });
      });

  const navigateToConnectionProfile = ({ source, row: { original: originalData }, event }) => {
    mpTrack("click into connection profile", {
      source: `${source} - ${name}`,
      tradeId: originalData.tradeId,
      company: getRelatedId(originalData.company),
    });
    const isCtrlKeyHeld = event.ctrlKey || event.metaKey;
    if (isCtrlKeyHeld) {
      const url = `${originalData.tradeId}/`;
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      navigate(`${originalData.tradeId}/`);
    }
  };

  // Fire mixpanel events on filter values changes. Debounced to avoid spam-firing events that
  // are triggered on text input change. Requires the eslint-disable because `useCallback` doesn't
  // know about `debounce` and its dependencies.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeFilters = useCallback(
    debounce(
      (values, prevValues) => {
        const fieldToEventTypeMap = {
          search: "Company or Trade ID",
          source: "Source or User",
          phase: "Phases",
          assignedToMe: "My Connections",
        };
        const changedValue = findKey(pickBy(values, (val, key) => prevValues[key] !== val));
        if (changedValue || !isEqual(values, prevValues)) {
          mpTrack(`filter ${name} connections table`, {
            type: fieldToEventTypeMap[changedValue] || "Reset",
          });
        }
      },
      HALF_SECOND,
      { trailing: true }
    ),
    [name]
  );

  const onSort = useTableSort(ACTIONS, dispatch, name);
  const tableSortState = useMemo(() => {
    return tableSort[name] || [{ ...INITIAL_SORT_STATE_MAP[name], hideArrow: true }];
  }, [name, tableSort]);

  const tableSortQueryParam = useMemo(() => {
    if (tableSortState?.length) {
      return tableSortState[0].desc ? `-${tableSortState[0].id}` : tableSortState[0].id;
    }
    return undefined;
  }, [tableSortState]);
  // Remove false-y values from the form so they are converted to `undefined` in the queryParams below.
  const formValues = pickBy(forms[`${CONNECTIONS_FILTERS}_${name}`] || {});

  const queryParams = {
    sort: tableSortQueryParam,
    "page[size]": 50,
    "filter[assignedToMe]": formValues.assignedToMe,
    "filter[search]": formValues.search,
    "filter[source]": formValues.source,
    "filter[phase]": formValues.phase?.length > 0 ? formValues.phase : relevantPhases,
    "filter[status]": formValues.status || getStatusFilterInitialValues(name),
  };

  const { isFetching, isLoading, data, fetchNextPage, isFetchingNextPage, relationships } =
    useInfiniteFetch(QUERY_KEYS.CONNECTIONS.list({ ...queryParams }));

  // While waiting for a fetch, since it's slow, filter the previous results - only apply for the
  // search as it's by far the easiest
  const filteredData = useMemo(() => {
    const baseData = data || [];
    const tokens = formValues.search?.toLowerCase()?.split(" ") || [];

    return baseData.filter(connection => {
      let isValid = true;
      const company = getRelatedObj(connection.company, relationships);
      tokens.forEach(token => {
        if (
          connection.tradeId.toLowerCase().search(token) === -1 &&
          company.name.toLowerCase().search(token) === -1
        ) {
          isValid = false;
        }
      });
      return isValid;
    });
  }, [data, formValues.search, relationships]);

  const initialFilterValues = useMemo(
    () => ({
      phase: phaseOptions,
      status: getStatusFilterInitialValues(name),
      ...formValues,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [name]
  );

  return (
    <Card title={`${name} Connections`} cardStyle={CardStyles.SECTIONED}>
      <Filter
        name={`${CONNECTIONS_FILTERS}_${name}`}
        phaseOptions={phaseOptions}
        filterComponent={ConnectionsFilters}
        onChange={onChangeFilters}
        isLoading={isFetching}
        initialValues={initialFilterValues}
      />
      <RelationshipsContext.Provider value={relationships}>
        <Table
          manualSortBy
          sortState={tableSortState}
          onSort={onSort}
          paginationFunc={fetchNextPage}
          isPaginationLoading={isFetchingNextPage}
          isLoading={isLoading}
          data={filteredData}
          columns={columns}
          source={source}
          clickTradeId={clickTradeId}
          navigateToConnectionProfile={navigateToConnectionProfile}
        />
      </RelationshipsContext.Provider>
    </Card>
  );
};

ConnectionsTableView.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ConnectionsTableView;
export { RelationshipsContext };
