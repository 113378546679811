import { expandedMoneyFormat, percentFormat } from "utils/displayFormatUtils";
import {
  COMMISSION_TYPE_PERCENT_TOTAL,
  COMMISSION_TYPE_PERCENT_FEES,
  COMMISSION_TYPE_FRACTION_OF_FEES,
  COMMISSION_TYPE_PRICE_PER_SHARE,
  COMMISSION_TYPE_LUMP_SUM,
  COMMISSION_TYPE_NON_APPLICABLE,
  SPV,
} from "utils/constants";

const CommissionUtils = {
  /**
   * Display the formatted commission amount
   *
   * @param {object} connection
   * @returns {string}
   */
  commissionAmountDisplay: commission => {
    if (!commission) {
      return null;
    }
    const commissionAmount = Number(commission.commissionAmount);
    switch (commission.commissionType) {
      case COMMISSION_TYPE_PERCENT_TOTAL:
        return percentFormat(commissionAmount, 2);
      case COMMISSION_TYPE_PERCENT_FEES:
        return `${percentFormat(commissionAmount, 2)} of fees`;
      case COMMISSION_TYPE_FRACTION_OF_FEES:
        return `${commission.commissionAmountNumerator} / ${commission.commissionAmountDenominator} of fees`;
      case COMMISSION_TYPE_PRICE_PER_SHARE:
        return `${expandedMoneyFormat(commissionAmount, 2)}/ share`;
      case COMMISSION_TYPE_LUMP_SUM:
        return expandedMoneyFormat(commissionAmount, 2);
      case COMMISSION_TYPE_NON_APPLICABLE:
        return "N/A";
      default:
        return null;
    }
  },
  calculatedFees: (
    commissionAmountString,
    commissionType,
    connection,
    clientToZFee = 0,
    clientToBrokerFee = 0,
    commissionAmountNumerator = 0,
    commissionAmountDenominator = 1
  ) => {
    if (!commissionType) {
      return 0;
    }
    const commissionAmount = Number(commissionAmountString);
    switch (commissionType) {
      case COMMISSION_TYPE_PERCENT_TOTAL:
        return (commissionAmount * connection.totalValue) / 100;
      case COMMISSION_TYPE_PERCENT_FEES:
        return ((clientToZFee + clientToBrokerFee) * commissionAmount) / 100;
      case COMMISSION_TYPE_FRACTION_OF_FEES:
        return (
          ((clientToZFee + clientToBrokerFee) * commissionAmountNumerator) /
          commissionAmountDenominator
        );
      case COMMISSION_TYPE_PRICE_PER_SHARE:
        if (connection.tradeType === SPV) {
          // Need to calculate share quantity locally since SPVs don't have share quantity
          return commissionAmount * (connection.totalValue / connection.pricePerShare);
        }
        return commissionAmount * connection.shareQuantity;
      case COMMISSION_TYPE_LUMP_SUM:
        return commissionAmount;
      case COMMISSION_TYPE_NON_APPLICABLE:
        return 0;
      default:
        return 0;
    }
  },
  calculatedFeesDisplay: (
    commissionAmountString,
    commissionType,
    connection,
    clientToZFee = 0,
    clientToBrokerFee = 0,
    commissionAmountNumerator = 0,
    commissionAmountDenominator = 1
  ) => {
    if (!commissionType) {
      return "—";
    }
    const commissionAmount = Number(commissionAmountString);
    switch (commissionType) {
      case COMMISSION_TYPE_PERCENT_TOTAL:
      case COMMISSION_TYPE_PERCENT_FEES:
      case COMMISSION_TYPE_FRACTION_OF_FEES:
      case COMMISSION_TYPE_PRICE_PER_SHARE:
      case COMMISSION_TYPE_LUMP_SUM:
        return expandedMoneyFormat(
          CommissionUtils.calculatedFees(
            commissionAmount,
            commissionType,
            connection,
            clientToZFee,
            clientToBrokerFee,
            commissionAmountNumerator,
            commissionAmountDenominator
          ),
          0,
          2
        );
      case COMMISSION_TYPE_NON_APPLICABLE:
        return "N/A";
      default:
        return null;
    }
  },
  displayZNetCommission: (clientToZFee, brokerToZFee, zToBrokerFee) =>
    expandedMoneyFormat(clientToZFee + brokerToZFee - zToBrokerFee, 0, 2),
};

export default CommissionUtils;
