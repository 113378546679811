import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { StyledHeadline2 } from "yuka";

import { Input, InputRow } from "forms/FormComponents";
import MarketOperatorSelect from "forms/FormComponents/MarketOperatorSelect";
import { required } from "utils/form/inputValidations";

import { INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES } from "./constants";

const StyledContainer = styled.div`
  width: 400px;
`;

const StyledHeadline = styled(StyledHeadline2)`
  margin-bottom: 24px;
`;

const ClientInfo = ({ clientProfile }) => {
  let headerText = "";
  let nameTitle = "";
  let emailTitle = "";
  if (clientProfile.sourceType === INDIVIDUAL_SOURCES) {
    headerText = "Buyer or Seller Information";
    nameTitle = "Client Name";
    emailTitle = "Client Email";
  } else if (clientProfile.sourceType === ENTITY_SOURCES) {
    headerText = "Entity Information";
    nameTitle = "Entity Name";
    emailTitle = "Email";
  } else if (clientProfile.sourceType === TRUST_SOURCES) {
    headerText = "Trust Information";
    nameTitle = "Trust Name";
    emailTitle = "Email of Trust Representative";
  }
  return (
    <StyledContainer>
      <StyledHeadline>{headerText}</StyledHeadline>
      <InputRow>
        <Input name="name" title={nameTitle} placeholder="E.g. Bob Bradley" validate={[required]} />
      </InputRow>
      <InputRow>
        <Input
          name="email"
          title={emailTitle}
          placeholder="example@example.com"
          validate={[required]}
        />
      </InputRow>
      <InputRow>
        <MarketOperatorSelect
          label="Market Operator CC (optional)"
          name="zxRepresentatives"
          multiple
          placeholder="Reps to cc"
        />
      </InputRow>
    </StyledContainer>
  );
};

ClientInfo.propTypes = {
  clientProfile: PropTypes.shape({
    sourceType: PropTypes.string,
  }),
};

export default ClientInfo;
