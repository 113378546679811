import { useContext } from "react";
import styled, { css } from "styled-components";
import { Z_INDEXES } from "../StylingConstants";
import { body1, FontColors } from "../Typography";
import DefaultStyleConfiguration from "./utils/DefaultStyleConfiguration";
import { __internalsSurfaceContext as SurfaceContext } from "../Layout/internal/Surface";

const getThemeColorStyle = (accessor, props) => {
  // Allows users to specify custom styling on tableStyle elements as functions that consume
  // row props. NOTE: should only be used within a well-defined yuka theme context.
  const styles = props.theme?.tableStyles?.colors?.[accessor];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentSurface = useContext(SurfaceContext);

  if (typeof styles === "function") {
    return styles(props);
  }
  return styles || DefaultStyleConfiguration(currentSurface - 1).colors;
};

const StyledTableScrollableArea = styled.div`
  position: relative;
  flex-direction: column;
  max-height: 100%;
  ${props =>
    props.$usePercentageColumnWidths
      ? css`
          width: 100%;
          overflow-x: hidden;
        `
      : css`
          width: 100%;
          overflow: auto;
        `}
`;

const StyledTableHead = styled.div`
  display: inline-block;
  left: 0;
  position: sticky;
  top: 0;
  z-index: ${Z_INDEXES.zIndexVisible};
  ${props => (props.$width ? `width: ${props.$width}px;` : "")};
`;

const StyledHeaderContainer = styled.div`
  background: ${props => getThemeColorStyle("header", props)};
  color: ${props => getThemeColorStyle("headerText", props)};
  ${props => (props.$column.width ? `width: ${props.$column.width}px;` : "")}
  padding: 0;
  top: 0;
  ${props =>
    props.$column.sticky
      ? css`
          position: sticky;
          z-index: ${Z_INDEXES.zIndexVisible};
          left: ${props.$column.totalLeft}px;
        `
      : ""};
`;

const StyledTableBody = styled.div`
  ${props => (props.$width ? `width: ${props.$width}px;` : "")};
`;

const StyledRow = styled.div`
  display: flex;

  ${props =>
    props.$onRowClick
      ? css`
          &:hover > ${StyledTableCell} {
            /* 
             * Determine the hover colour to use dynamically, based on the style configuration in 
             * the theme object. If \`theme.tableStyles.colors.row\` is a function, invoke it to 
             * determine the color. Inspired by Tangelo's rowClassName behaviour. Applies background 
             * to child TableCells, which will self-apply if they have an onCellClick handler 
             * anyway. This way, we get the functionality of highlighting whole rows if the whole 
             * row is clickable, or just individual cells if they have individual handlers.
             */
            background: ${props => getThemeColorStyle("rowHover", props)};
            cursor: pointer;
          }
          &:active > ${StyledTableCell} {
            /* 
             * Similar to the hover interaction but with active coloring.
             */
            background: ${props => getThemeColorStyle("rowActive", props)};
          }
        `
      : ""}
`;

const StyledPlaceholderCell = styled.div`
  ${FontColors.theme30}
  display: flex;
  align-items: center;
  height: ${props => props.$height}px;
`;

const StyledTableCell = styled.div`
  ${body1}
  ${FontColors.theme80}
  flex-shrink: 0;
  flex-grow: 0;
  width: ${props => props.$column.width}px;
  padding: 0;
  ${props =>
    props.$column.sticky
      ? css`
          position: sticky;
          left: ${props.$column.totalLeft}px;
        `
      : css``}

  background: ${props => getThemeColorStyle("row", props)};
  /* 
  These hover/active styles are redundant if the whole row is clickable because of the identical 
  styling in the TableRow, however if only individual onCellClick handlers are defined, then this
  styling applies special colouring only to the hovered cell. 
  */
  ${props =>
    props.$column.onCellClick
      ? css`
          &:hover {
            background: ${props => getThemeColorStyle("rowHover", props)};
            cursor: pointer;
          }
          &:active {
            background: ${props => getThemeColorStyle("rowActive", props)};
            cursor: pointer;
          }
        `
      : css``}
`;

const StyledLoadingSpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 48px;
  left: 0;
  margin-top: 16px;
  position: sticky;
  ${props => (props.$width ? `width: ${props.$width}px;` : "")};
`;

const StyledTableNoValueText = styled.div`
  ${FontColors.theme30}
`;

export {
  getThemeColorStyle,
  StyledTableScrollableArea,
  StyledHeaderContainer,
  StyledTableHead,
  StyledTableBody,
  StyledRow,
  StyledPlaceholderCell,
  StyledTableCell,
  StyledLoadingSpinnerContainer,
  StyledTableNoValueText,
};
