import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useRadio } from "@react-aria/radio";
import { VisuallyHidden } from "@react-aria/visually-hidden";

import InlineValidatedField from "./internal/InlineValidatedField";

import { PrimaryColorPalette } from "../StylingConstants";

const StyledRadioButton = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;

  &:before {
    // this is the little dimple in the middle
    background: ${props =>
      props.$disabled ? PrimaryColorPalette.white15 : PrimaryColorPalette.black80};
    border-radius: 50%;
    content: "";
    display: ${props => (props.$checked ? "block" : "none")};
    height: 8px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 8px;
  }

  ${props => {
    if (props.$disabled) {
      return css`
        background: ${PrimaryColorPalette.white15};
        ${!props.$checked ? `border: 1px solid ${PrimaryColorPalette.white15};` : ""}
      `;
    }
    if (props.$checked) {
      return css`
        border-color: ${PrimaryColorPalette.blue400};
        background: ${PrimaryColorPalette.blue400};
      `;
    }
    return css`
      border: 1px solid ${PrimaryColorPalette.white50};
      &:hover {
        border-color: ${PrimaryColorPalette.white80};
      }
    `;
  }}
`;

const _RadioItem = props => {
  const ref = React.useRef(null);
  const { inputProps } = useRadio(
    {
      ...props,
      isDisabled: props.disabled,
      "aria-labelledby": props.id || props.name,
    },
    props.state,
    ref
  );
  return (
    <>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <StyledRadioButton $checked={inputProps.checked} $disabled={props.disabled} role="radio" />
    </>
  );
};

_RadioItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  state: PropTypes.object.isRequired,
};

/**
 * A simple component that renders a single radio button. For use in `RadioGroup` and not exposed
 * externally.
 *
 * @param {object} props
 * @returns {React.Component}
 */
const RadioItem = props => {
  // also remove whitespaces from the id
  const inputId = `${props.state.name}-${props.label}`.replace(/\s+/g, "_");

  return (
    <InlineValidatedField
      input={{
        type: "radio",
        ..._.omit(props, ["label", "helpText"]),
        name: props.state.name,
        id: inputId,
      }}
      label={props.label}
      helpText={props.helpText}
      wrapLabel={props.wrapLabel}
      component={_RadioItem}
      state={props.state}
    />
  );
};

RadioItem.propTypes = {
  state: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  wrapLabel: PropTypes.bool,
};

RadioItem.defaultProps = {
  helpText: "",
  disabled: false,
  wrapLabel: false,
};

export default RadioItem;
