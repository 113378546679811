import { CircleIcon, ColorPalette } from "yuka";

import {
  STATUS_TYPE_STANDARD,
  STATUS_TYPE_IN_PROGRESS,
  STATUS_TYPE_BLOCKING,
  STATUS_TYPE_STANDARD_LABEL,
  STATUS_TYPE_IN_PROGRESS_LABEL,
  STATUS_TYPE_BLOCKING_LABEL,
} from "utils/constants";

const STATUS_TYPE_OPTIONS = [
  {
    value: STATUS_TYPE_STANDARD,
    label: STATUS_TYPE_STANDARD_LABEL,
    icon: ({ size }) => <CircleIcon size={size} color={ColorPalette.blue500} />,
  },
  {
    value: STATUS_TYPE_IN_PROGRESS,
    label: STATUS_TYPE_IN_PROGRESS_LABEL,
    icon: ({ size }) => <CircleIcon size={size} color={ColorPalette.yellowLight} />,
  },
  {
    value: STATUS_TYPE_BLOCKING,
    label: STATUS_TYPE_BLOCKING_LABEL,
    icon: ({ size }) => <CircleIcon size={size} color={ColorPalette.redDark} />,
  },
];

export { STATUS_TYPE_STANDARD, STATUS_TYPE_IN_PROGRESS, STATUS_TYPE_BLOCKING, STATUS_TYPE_OPTIONS };
