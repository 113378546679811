import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { IconButton, useAnchoredMenu, DotsVerticalIcon } from "yuka";

import { fetchQueryResponse, useDelete, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import { SPVClassDocumentsForm } from "forms/DocumentsForm";
import { useDispatch, ACTIONS } from "utils/StateProvider";
import downloadFileResponse from "utils/downloadFileResponse";

import { STATUS_ARCHIVED } from "./constants";

const StyledCell = styled.div`
  display: flex;
`;

/**
 * Renders a dropdown for the spv class documents table
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const DropdownCell = ({ value: document }) => {
  const { id: spvId } = useParams();
  const { onSubmit: onDelete } = useDelete(
    QUERY_KEYS.SPV_CLASS_DOCUMENTS.detail(spvId, document.apiId),
    {
      silent: true,
    }
  );
  const dispatch = useDispatch();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const editAction = () => setEditModalOpen(true);
  const closeModal = () => {
    setEditModalOpen(false);
  };
  const handleDelete = () => {
    if (confirm(`Are you sure you archive this document?`)) {
      onDelete().then(() => {
        queryClient.invalidateQueries(QUERY_KEYS.SPV_CLASS_DOCUMENTS.detail(spvId, []));
        dispatch({
          type: ACTIONS.addToast,
          message: `Successfully Archived`,
        });
      });
    }
  };

  const menuItems = [];
  if (!document.isFromParent) {
    menuItems.push({ text: "Edit details", onClick: editAction });
    if (document.status !== STATUS_ARCHIVED) {
      menuItems.push({ text: "Archive", onClick: handleDelete });
    }
  }
  const download = document => {
    fetchQueryResponse(
      QUERY_KEYS.SPV_CLASS_DOCUMENTS.detail(spvId, document.apiId, ["download"])
    ).then(data => {
      const documentName =
        document.extension === document.name.split(".").at(-1)
          ? document.name
          : `${document.name}.${document.extension}`;
      downloadFileResponse(data, documentName);
    });
  };
  menuItems.push({ text: "Download", onClick: () => download(document) });

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({ menuItems });

  return (
    <StyledCell>
      {isEditModalOpen && (
        <SPVClassDocumentsForm existingDocument={document} closeModal={closeModal} />
      )}
      <IconButton icon={DotsVerticalIcon} ref={menuRef} onClick={toggleMenu} />
      {menu}
    </StyledCell>
  );
};

DropdownCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DropdownCell;
