import { debounce } from "lodash-es";
import { useMemo, useContext } from "react";

import { mpTrack } from "utils/mixpanel";
import { HALF_SECOND } from "utils/constants";
import { isEqual, findKey, pickBy } from "utils";
import { GlobalContext } from "utils/StateProvider";

import { CATEGORY_OPTIONS } from "../constants";

const fieldToEventTypeMap = {
  name: "File name",
  category: "Category",
};

const onChangeFilters = debounce(
  (values, prevValues) => {
    const changedValue = findKey(pickBy(values, (val, key) => prevValues[key] !== val));
    if (changedValue || !isEqual(values, prevValues)) {
      mpTrack("filter deal file table", {
        type: fieldToEventTypeMap[changedValue] || "Reset",
      });
    }
  },
  HALF_SECOND,
  { trailing: true }
);

const useDealFileFilterConfig = filterName => {
  const {
    state: { forms },
  } = useContext(GlobalContext);

  const formValues = pickBy(forms[filterName] || {});
  const queryParams = useMemo(
    () => ({
      name: formValues.name,
      category: formValues.category,
    }),
    [formValues]
  );

  return {
    queryParams,
    onChangeFilters,
    filterProps: {
      initialValues: {
        category: CATEGORY_OPTIONS,
      },
    },
  };
};

export default useDealFileFilterConfig;
