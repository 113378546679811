/**
 * Site-wide constants.
 */
import { BadgeStyles } from "yuka";

import { expandedMoneyFormat, percentFormat } from "utils/displayFormatUtils";

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const TENTH_SECOND = 100;
const QUARTER_SECOND = 250;
const HALF_SECOND = 500;

const PAGE_QUERY_PARAM = "page[number]";

// Connections
const DIRECT = "Direct";
const SPV = "SPV";
const FORWARD = "Forward";

const BUY = "Buy";
const SELL = "Sell";

const PHASE_CLOSED = "Closed";
const PHASE_EXECUTION = "Execution";
const PHASE_INVOICING = "Invoicing";
const PHASE_SETTLEMENT = "Settlement";
const PHASE_DISCONNECTED = "Disconnected";
const PHASE_COMPANY_DECISION = "Company Decision";
const PHASE_DEAL_FILE_REVIEW = "Deal File Review";
const PHASE_FEE_COLLECTION_AND_PAYMENT = "Fee Collection & Payment";

const EXECUTION_PHASE_BADGE_MAPPING = {
  [PHASE_EXECUTION]: BadgeStyles.LIGHT_BLUE,
  [PHASE_COMPANY_DECISION]: BadgeStyles.LIGHT_GRAY,
  [PHASE_SETTLEMENT]: BadgeStyles.WHITE,
  [PHASE_DEAL_FILE_REVIEW]: BadgeStyles.MEDIUM_GRAY,
  [PHASE_INVOICING]: BadgeStyles.BUY_GREEN,
  [PHASE_FEE_COLLECTION_AND_PAYMENT]: BadgeStyles.YELLOW,
  [PHASE_CLOSED]: BadgeStyles.DARK_GRAY,
  [PHASE_DISCONNECTED]: BadgeStyles.DARK_GRAY,
};

const COMPANY_DECISION_APPROVED = "Approved";
const COMPANY_DECISION_ASSIGNED_ROFR = "Assigned ROFR";
const COMPANY_DECISION_EXERCISED_ROFR = "Exercised ROFR";
const COMPANY_DECISION_BLOCKED = "Blocked";

// Sources
const ROLE_BROKER = "Broker Source";
const ROLE_CLIENT = "Client Source";

const SUBSCRIBER_SOURCES = "Subscriber";
const TRADING_GROUP_SOURCES = "Trading Group";
const NON_SUBSCRIBER_SOURCES = "Non-Subscriber";
const INDIVIDUAL_SOURCES = "Individual";
const ENTITY_SOURCES = "Entity";
const TRUST_SOURCES = "Trust";

const KYC_APPROVED = "Approved";
const KYC_EXPIRED = "Expired";
const KYC_INCOMPLETE = "Incomplete";
const KYC_REVIEW = "Review";

const FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE = "Standard Fee Share";
const FEE_AGREEMENT_TYPE_RATE_CARD = "Rate Card";
const FEE_AGREEMENT_TYPE_SUM_AND_SPLIT = "Sum and Split";
const FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_TRANSACTION = "% of total transaction";
const FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_FEES = "% of fees";
const FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES = "Fraction of fees";

const CATEGORY_CLIENT_INDIVIDUAL = "Client Individual";
const CATEGORY_CLIENT_ENTITY = "Client Entity";
const CATEGORY_CLIENT_ENTITY_MANAGER = "Client Entity Manager";
const CATEGORY_REP_INDIVIDUAL = "Registered Rep Individual";
const CATEGORY_REP_BRAND = "Trading Group";
const CATEGORY_REP_BROKER_DEALER = "Registered Rep Broker Dealer";

// Commissions
const DIRECTION_BUY_CLIENT_TO_Z = "Buy Client to Z";
const DIRECTION_BUY_CLIENT_TO_BUY_BROKER = "Buy Client to Buy Broker";
const DIRECTION_BUY_BROKER_TO_Z = "Buy Broker to Z";
const DIRECTION_Z_TO_BUY_BROKER = "Z to Buy Broker";
const DIRECTION_SELL_CLIENT_TO_Z = "Sell Client to Z";
const DIRECTION_SELL_CLIENT_TO_SELL_BROKER = "Sell Client to Sell Broker";
const DIRECTION_SELL_BROKER_TO_Z = "Sell Broker to Z";
const DIRECTION_Z_TO_SELL_BROKER = "Z to Sell Broker";

const COMMISSION_TYPE_PERCENT_TOTAL = "% of total";
const COMMISSION_TYPE_PERCENT_FEES = "% of fees";
const COMMISSION_TYPE_FRACTION_OF_FEES = "Fraction of fees";
const COMMISSION_TYPE_PRICE_PER_SHARE = "$/share";
const COMMISSION_TYPE_LUMP_SUM = "$";
const COMMISSION_TYPE_NON_APPLICABLE = "N/A";

// Status Updates
const STATUS_TYPE_STANDARD = 10;
const STATUS_TYPE_IN_PROGRESS = 20;
const STATUS_TYPE_BLOCKING = 30;

const STATUS_TYPE_STANDARD_LABEL = "On Track";
const STATUS_TYPE_IN_PROGRESS_LABEL = "Hurdle";
const STATUS_TYPE_BLOCKING_LABEL = "Blocked";
const STATUS_TYPE_NO_STATUS_LABEL = "No Status";

// Documents
const CATEGORY_UNSPECIFIED = "Import / Unspecified";
const CATEGORY_ONBOARDING = "Onboarding Document";
const CATEGORY_FEE_AGREEMENT = "Fee Agreement";
const CATEGORY_KYC = "KYC";
const CATEGORY_INVOICE_TEMPLATE = "Invoice";
const CATEGORY_WIRE_INSTRUCTIONS = "Wire Instructions";
const CATEGORY_SHARE_OWNERSHIP = "Share Ownership Document";
const CATEGORY_OTHER = "Other";

// Onboarding
const BUYER_AGREEMENT = "Buyer Agreement";
const SELLER_AGREEMENT = "Seller Agreement";
const BUYER_FIRM_ORDER = "Buyer Firm Order";
const SELLER_FIRM_ORDER = "Seller Firm Order";
const commissionDisplay = (commissionAmount, commissionType) => {
  if (commissionType === COMMISSION_TYPE_PRICE_PER_SHARE) {
    return `${expandedMoneyFormat(commissionAmount)} per share`;
  } else {
    return percentFormat(commissionAmount);
  }
};

// SPVs
const SPV_TYPE_MASTER = "Master";
const SPV_TYPE_SERIES = "Series";
const SPV_TYPE_STANDALONE = "Stand-alone";
const SPV_TYPE_CLASS = "Class";

// Task Alerts
const REMINDER_RECIPIENT_INDIVIDUAL = 10;
const REMINDER_RECIPIENT_TEAM = 20;

export {
  ONE_MINUTE,
  TENTH_SECOND,
  QUARTER_SECOND,
  HALF_SECOND,
  PAGE_QUERY_PARAM,
  DIRECT,
  SPV,
  FORWARD,
  BUY,
  SELL,
  PHASE_CLOSED,
  PHASE_EXECUTION,
  PHASE_INVOICING,
  PHASE_SETTLEMENT,
  PHASE_DISCONNECTED,
  PHASE_COMPANY_DECISION,
  PHASE_DEAL_FILE_REVIEW,
  PHASE_FEE_COLLECTION_AND_PAYMENT,
  EXECUTION_PHASE_BADGE_MAPPING,
  COMPANY_DECISION_BLOCKED,
  COMPANY_DECISION_EXERCISED_ROFR,
  COMPANY_DECISION_ASSIGNED_ROFR,
  COMPANY_DECISION_APPROVED,
  ROLE_BROKER,
  ROLE_CLIENT,
  SUBSCRIBER_SOURCES,
  TRADING_GROUP_SOURCES,
  NON_SUBSCRIBER_SOURCES,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
  FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE,
  FEE_AGREEMENT_TYPE_RATE_CARD,
  FEE_AGREEMENT_TYPE_SUM_AND_SPLIT,
  FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_TRANSACTION,
  FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_FEES,
  FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES,
  COMMISSION_TYPE_PERCENT_TOTAL,
  COMMISSION_TYPE_PERCENT_FEES,
  COMMISSION_TYPE_FRACTION_OF_FEES,
  COMMISSION_TYPE_PRICE_PER_SHARE,
  COMMISSION_TYPE_LUMP_SUM,
  COMMISSION_TYPE_NON_APPLICABLE,
  CATEGORY_CLIENT_INDIVIDUAL,
  CATEGORY_CLIENT_ENTITY,
  CATEGORY_CLIENT_ENTITY_MANAGER,
  CATEGORY_REP_INDIVIDUAL,
  CATEGORY_REP_BRAND,
  CATEGORY_REP_BROKER_DEALER,
  DIRECTION_BUY_CLIENT_TO_Z,
  DIRECTION_BUY_CLIENT_TO_BUY_BROKER,
  DIRECTION_BUY_BROKER_TO_Z,
  DIRECTION_Z_TO_BUY_BROKER,
  DIRECTION_SELL_CLIENT_TO_Z,
  DIRECTION_SELL_CLIENT_TO_SELL_BROKER,
  DIRECTION_SELL_BROKER_TO_Z,
  DIRECTION_Z_TO_SELL_BROKER,
  STATUS_TYPE_STANDARD,
  STATUS_TYPE_IN_PROGRESS,
  STATUS_TYPE_BLOCKING,
  STATUS_TYPE_STANDARD_LABEL,
  STATUS_TYPE_IN_PROGRESS_LABEL,
  STATUS_TYPE_BLOCKING_LABEL,
  STATUS_TYPE_NO_STATUS_LABEL,
  CATEGORY_UNSPECIFIED,
  CATEGORY_ONBOARDING,
  CATEGORY_FEE_AGREEMENT,
  CATEGORY_KYC,
  CATEGORY_INVOICE_TEMPLATE,
  CATEGORY_WIRE_INSTRUCTIONS,
  CATEGORY_SHARE_OWNERSHIP,
  CATEGORY_OTHER,
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  commissionDisplay,
  SPV_TYPE_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_STANDALONE,
  SPV_TYPE_CLASS,
  REMINDER_RECIPIENT_INDIVIDUAL,
  REMINDER_RECIPIENT_TEAM,
};
