/**
 *  React context for the current "source" to be passed to mixpanel events based on react hierarchy.
 *
 *  Default export - The context itself. Can access Context.Provider, Context.Consumer or assign
 *  to Component.contextType.
 *
 *  For functional components, use the `useMpSource` hook exported to retrieve the context value.
 *
 *  For use within an HOC that cannot or will not be refactored, wrap in `withMpSource` to inject
 *  the current value as a prop called `source`
 */
import React from "react";

const MixpanelSourceContext = React.createContext("Execution");
MixpanelSourceContext.displayName = "MixpanelSourceContext";

const useMpSource = () => {
  const source = React.useContext(MixpanelSourceContext);
  return source;
};

export default MixpanelSourceContext;
export { useMpSource };
