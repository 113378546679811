import React, { useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";

import { HyperLink, CircleIcon, ColorPalette, StyledHeadline2, body1, FontColors } from "yuka";

import { useWrite, QUERY_KEYS, DataTypes } from "api";
import EditStatusUpdateForm from "forms/EditStatusUpdateForm";
import { mpTrack } from "utils/mixpanel";
import {
  STATUS_TYPE_STANDARD,
  STATUS_TYPE_IN_PROGRESS,
  STATUS_TYPE_BLOCKING,
  STATUS_TYPE_STANDARD_LABEL,
  STATUS_TYPE_IN_PROGRESS_LABEL,
  STATUS_TYPE_BLOCKING_LABEL,
} from "utils/constants";

const StyledStatus = styled.div`
  ${body1}
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledFooter = styled.span`
  ${body1}
  ${FontColors.theme50}
`;

/**
 * Renders a single Status Update element.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const StatusUpdate = props => {
  const [editFormActive, setEditFormActive] = useState(null);
  const { onSubmit } = useWrite(
    QUERY_KEYS.STATUS_UPDATES.detail(props.connection.tradeId, props.statusUpdate.apiId)
  );
  const iconColor = () => {
    switch (props.statusUpdate.statusType) {
      case STATUS_TYPE_STANDARD:
        return ColorPalette.blue500;
      case STATUS_TYPE_IN_PROGRESS:
        return ColorPalette.yellowLight;
      case STATUS_TYPE_BLOCKING:
        return ColorPalette.redDark;
      default:
        // Should never happen in practice
        return ColorPalette.blue500;
    }
  };
  const statusText = () => {
    switch (props.statusUpdate.statusType) {
      case STATUS_TYPE_STANDARD:
        return STATUS_TYPE_STANDARD_LABEL;
      case STATUS_TYPE_IN_PROGRESS:
        return STATUS_TYPE_IN_PROGRESS_LABEL;
      case STATUS_TYPE_BLOCKING:
        return STATUS_TYPE_BLOCKING_LABEL;
      default:
        // Should never happen in practice
        return STATUS_TYPE_STANDARD_LABEL;
    }
  };
  const pinStatusUpdate = () => {
    const submitData = {
      apiType: DataTypes.STATUS_UPDATE,
      apiId: props.statusUpdate.apiId,
      isPinned: !props.statusUpdate.isPinned,
    };
    return onSubmit(submitData).then(() => {
      mpTrack("Pin Status Update", {
        phase: props.connection.phase,
        priority: props.statusUpdate.statusType,
      });
    });
  };
  const editTask = () => {
    setEditFormActive(true);
  };
  return editFormActive ? (
    <EditStatusUpdateForm
      tradeId={props.connection.tradeId}
      statusUpdate={props.statusUpdate}
      onClose={() => setEditFormActive(false)}
    />
  ) : (
    <>
      <StyledStatus>
        <CircleIcon color={iconColor()} size={16} />
        <span>{statusText()}</span>
      </StyledStatus>
      <StyledHeadline2>{props.statusUpdate.content}</StyledHeadline2>
      <StyledFooter>
        {props.statusUpdate.authorFullName} |{" "}
        {DateTime.fromISO(props.statusUpdate.updatedOn).toLocaleString(DateTime.DATE_MED)} |{" "}
        <HyperLink onClick={editTask}>Update Status</HyperLink>
        {" | "}
        <HyperLink onClick={pinStatusUpdate}>Delete</HyperLink>
      </StyledFooter>
    </>
  );
};

StatusUpdate.propTypes = {
  statusUpdate: PropTypes.shape({
    apiId: PropTypes.string,
    authorFullName: PropTypes.string,
    content: PropTypes.string,
    updatedOn: PropTypes.string,
    isPinned: PropTypes.bool,
    statusType: PropTypes.number,
  }).isRequired,
  connection: PropTypes.shape({
    phase: PropTypes.string,
    tradeId: PropTypes.string,
  }).isRequired,
};

export default StatusUpdate;
