import _ from "lodash";
import { useState, useCallback, useMemo, useEffect } from "react";

import { TENTH_SECOND } from "./constants";

const useScrollPosition = ref => {
  const [position, setPosition] = useState({
    top: 0,
    bottom: 0,
    left: 0,
  });

  const onScroll = useCallback(() => {
    if (ref.current) {
      const { top, bottom, left } = ref.current.getBoundingClientRect();
      setPosition({ top, bottom, left });
    }
  }, [ref]);

  const onScrollDebounce = useMemo(
    () => _.debounce(onScroll, TENTH_SECOND, { leading: true, trailing: true }),
    [onScroll]
  );

  useEffect(() => {
    window.addEventListener("scroll", onScrollDebounce, { passive: true, capture: true });
    window.addEventListener("resize", onScrollDebounce);
    onScrollDebounce();
    return () => {
      window.removeEventListener("scroll", onScrollDebounce);
      window.removeEventListener("resize", onScrollDebounce);
    };
  }, [onScrollDebounce]);

  return [position, onScrollDebounce];
};

export default useScrollPosition;
