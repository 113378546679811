import React, { useState } from "react";
import { Button, Card, CardStyles, PlusIcon, useAnchoredMenu } from "yuka";

import CreateSPVForm from "forms/CreateSPVForm";
import {
  SPV_TYPE_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_CLASS,
  SPV_TYPE_STANDALONE,
} from "utils/constants";

import SPVTable from "./SPVTable";

/**
 * Renders Card with tabs for SPV objects
 * @returns {JSX.Element}
 */
const SPVTableView = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [spvType, setSpvType] = useState("");
  const startCreateFlow = spvType => {
    setSpvType(spvType);
    setCreateModalOpen(true);
  };
  const closeModal = () => setCreateModalOpen(false);

  const menuItems = [
    { text: "Master SPV", onClick: () => startCreateFlow(SPV_TYPE_MASTER) },
    { text: "Series", onClick: () => startCreateFlow(SPV_TYPE_SERIES) },
    { text: "Class", onClick: () => startCreateFlow(SPV_TYPE_CLASS) },
    { text: "Standalone", onClick: () => startCreateFlow(SPV_TYPE_STANDALONE) },
  ];

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({ menuItems });

  // Create new SPV button
  const createNewSPV = (
    <>
      <Button leadingIcon={PlusIcon} onClick={toggleMenu} ref={menuRef}>
        Create new SPV
      </Button>
      {menu}
    </>
  );

  return (
    <Card cardStyle={CardStyles.SECTIONED} title="SPVs" headerTrailingContent={createNewSPV}>
      <SPVTable />
      {createModalOpen && <CreateSPVForm closeModal={closeModal} spvType={spvType} />}
    </Card>
  );
};

export default SPVTableView;
