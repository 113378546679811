import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { PrimaryColorPalette } from "../StylingConstants";
import { caption2 } from "../Typography";

import { XCircleIcon } from "../Icons";

const FILTER = "FILTER";
const ACTION = "ACTION";
const INPUT = "INPUT";

const ChipStyles = { FILTER, ACTION, INPUT };

const StyledFilterIcon = styled.div`
  height: 16px;
  ${props => {
    if (props.$disabled) {
      return css`
        path,
        polygon {
          fill: ${PrimaryColorPalette.white15};
        }
      `;
    }
    if (props.$selected) {
      return css`
        path,
        polygon {
          fill: ${PrimaryColorPalette.blue100};
        }
      `;
    }
    return css`
      path,
      polygon {
        fill: ${PrimaryColorPalette.white80};
      }
    `;
  }}
`;

const StyledActionIcon = styled.div`
  height: 16px;
  ${props =>
    props.$disabled
      ? css`
          path,
          polygon {
            fill: ${PrimaryColorPalette.white15};
          }
        `
      : css`
          path,
          polygon {
            fill: ${PrimaryColorPalette.white50};
          }
        `}
`;

const StyledCloseIcon = styled.div`
  height: 16px;
  svg {
    pointer-events: bounding-box;
    margin-right: -6px;
  }
  path {
    ${props =>
      props.$disabled
        ? `
      fill ${PrimaryColorPalette.white15};
    `
        : `
      fill ${PrimaryColorPalette.white30};
      &:hover {
        fill ${PrimaryColorPalette.white50};
      }
      &:active {
        fill ${PrimaryColorPalette.white80};
      }
    `}
  }
`;

const StyledChip = styled.button`
  & + & {
    margin-left: 12px;
  }
  align-items: center;
  background: transparent;
  border: 1px solid ${PrimaryColorPalette.white30};
  border-radius: 13px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  width: fit-content;
  height: 26px;
  gap: 8px;
  padding: 0 12px;
  ${caption2}
  line-height: 16px;

  ${props => {
    if (props.$disabled) {
      return css`
        cursor: default;
        color: ${PrimaryColorPalette.white30};
        border-color: ${PrimaryColorPalette.white15};
      `;
    }
    if (props.$selected) {
      const baseStyles = css`
        background: ${PrimaryColorPalette.white100};
        border-color: ${PrimaryColorPalette.white100};
        color: ${PrimaryColorPalette.blue100};
      `;
      return props.$hoverable
        ? css`
            ${baseStyles}
            &:hover {
              background: rgba(255, 255, 255, 0.95);
              border-color: rgba(255, 255, 255, 0.95);
            }
            &:active {
              background: rgba(255, 255, 255, 0.9);
              border-color: rgba(255, 255, 255, 0.9);
            }
          `
        : baseStyles;
    }
    if (props.$hoverable) {
      return css`
        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }
        &:active {
          background: rgba(255, 255, 255, 0.07);
        }
      `;
    }
  }}
`;

const StyledAvatarWrapper = styled.div`
  margin-left: -8px;
  height: 18px;
  width: 18px;
  overflow: hidden;
`;

const ICON_TYPES = {
  FILTER: StyledFilterIcon,
  ACTION: StyledActionIcon,
  INPUT: StyledCloseIcon,
};

/**
 * A chip is an element that appears similar to a button and to a badge. Notably however, chips
 * are smaller than buttons in size, and have actions on click, which badges do not.
 *
 * Chips help people enter information, make selections, filter content or trigger actions. Chips
 * can show multiple interactive elements together in the same area, such as a list of selectable
 * filters.
 *
 */
const Chip = React.forwardRef((props, ref) => {
  const StyledIcon = ICON_TYPES[props.chipStyle];
  // Do not enable the hover state for the chip as a whole if they are hovering
  // over the close icon
  const [isHoverClose, setIsHoverClose] = React.useState(false);
  return (
    <StyledChip
      ref={ref}
      onClick={props.onClick}
      $hoverable={!isHoverClose && props.onClick}
      $selected={props.selected}
      $disabled={props.disabled}
      className={props.className}
    >
      {props.leadingIcon && (
        <StyledIcon $selected={props.selected} $disabled={props.disabled}>
          <props.leadingIcon size={16} />
        </StyledIcon>
      )}
      {props.avatar && <StyledAvatarWrapper>{props.avatar}</StyledAvatarWrapper>}
      <span>{props.text}</span>
      {props.trailingIcon && (
        <StyledIcon
          onClick={props.onClose}
          onMouseEnter={
            props.onClose
              ? () => {
                  setIsHoverClose(true);
                }
              : null
          }
          onMouseLeave={
            props.onClose
              ? () => {
                  setIsHoverClose(false);
                }
              : null
          }
          role={props.onClose ? "button" : "presentation"}
          $selected={props.selected}
          $disabled={props.disabled}
        >
          <props.trailingIcon size={16} />
        </StyledIcon>
      )}
    </StyledChip>
  );
});

Chip.propTypes = {
  /* Filter Chip Only! Display a filter as selected. This state is to be managed by the implementing component */
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  chipStyle: PropTypes.oneOf(Object.values(ChipStyles)).isRequired,
  /* Action Chip Only! Icon component to render to the right of the chip text */
  trailingIcon: PropTypes.func,
  /* Action or Filter Chip Only! Icon component to render to the left of the chip text */
  leadingIcon: PropTypes.func,
  /* Input Chip Only! An element that may be display to the left of the chip text. Must be 18px square */
  avatar: PropTypes.node,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  /* Click handler for Chips. In a FilterChip, this should toggle the selected state */
  onClick: PropTypes.func,
  /* InputChip only: Event handler for clicking the close icon */
  onClose: PropTypes.func,
};

Chip.defaultProps = {
  selected: false,
  disabled: false,
};

const FilterChip = React.forwardRef((props, ref) => (
  <Chip
    {...props}
    ref={ref}
    chipStyle={ChipStyles.FILTER}
    trailingIcon={null}
    onClose={null}
    avatar={null}
  />
));
const ActionChip = React.forwardRef((props, ref) => (
  <Chip
    {...props}
    ref={ref}
    chipStyle={ChipStyles.ACTION}
    selected={false}
    onClose={null}
    avatar={null}
  />
));
const _InputChip = React.forwardRef((props, ref) => {
  const onClose = e => {
    e.stopPropagation();
    props.onClose();
  };
  return (
    <Chip
      {...props}
      ref={ref}
      onClose={onClose}
      chipStyle={ChipStyles.INPUT}
      trailingIcon={XCircleIcon}
      leadingIcon={null}
      selected={false}
    />
  );
});
_InputChip.propTypes = {
  text: PropTypes.string.isRequired,
  avatar: PropTypes.node,
  onClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
// Allow it to be used in styled components;
const InputChip = styled(_InputChip)``;

export { ChipStyles, FilterChip, ActionChip, InputChip };
export default Chip;
