import { DateCell, IconButtonCell, LinkExternalIcon } from "yuka";

import {
  SPVInviteMarketOperatorCell,
  OnboardingRequestMarketOperatorCell,
} from "./MarketOperatorCells";

const NameColumn = width => ({
  id: "name",
  accessor: "name",
  width,
  header: "Name",
  sortable: true,
});

const ExpandSourceProfileColumn = {
  id: "expandSourceProfileColumn",
  width: 5,
  header: "",
  align: "center",
  sortable: false,
  icon: LinkExternalIcon,
  onClick: ({ navigateToSourceProfile, ...props }) => navigateToSourceProfile(props),
  renderOverflow: true,
  cellRenderer: IconButtonCell,
};

const EmailColumn = {
  id: "email",
  accessor: "email",
  width: 25,
  header: "Email",
  sortable: true,
};

const OnboardingRequestMarketOperatorColumn = {
  id: "marketOperator",
  accessor: onboardingRequest => onboardingRequest,
  width: 25,
  header: "CC Market Operator",
  sortable: true,
  cellRenderer: OnboardingRequestMarketOperatorCell,
};

const SPVInviteMarketOperatorColumn = {
  id: "marketOperator",
  accessor: "marketOperator",
  width: 25,
  header: "CC Market Operator",
  sortable: true,
  cellRenderer: SPVInviteMarketOperatorCell,
};

const CompletedOnColumn = {
  id: "completedOn",
  accessor: "completedOn",
  width: 25,
  header: "Completed On",
  sortable: true,
  cellRenderer: DateCell,
};

const StartedOnColumn = {
  id: "createdOn",
  accessor: "createdOn",
  width: 25,
  header: "Started On",
  sortable: true,
  cellRenderer: DateCell,
};

const InviteSentOnColumn = {
  id: "sentOn",
  accessor: "sentOn",
  width: 25,
  header: "Invite Sent On",
  sortable: true,
  cellRenderer: DateCell,
};

const completedColumns = [
  NameColumn(20),
  ExpandSourceProfileColumn,
  EmailColumn,
  OnboardingRequestMarketOperatorColumn,
  CompletedOnColumn,
];
const pendingColumns = [
  NameColumn(25),
  EmailColumn,
  OnboardingRequestMarketOperatorColumn,
  StartedOnColumn,
];
const inviteSentColumns = [
  NameColumn(25),
  EmailColumn,
  SPVInviteMarketOperatorColumn,
  InviteSentOnColumn,
];

export { completedColumns, pendingColumns, inviteSentColumns };
