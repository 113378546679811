import PropTypes from "prop-types";
import styled from "styled-components";
import { ColorPalette } from "yuka";
import {
  COMPANY_DECISION_APPROVED,
  COMPANY_DECISION_ASSIGNED_ROFR,
  COMPANY_DECISION_BLOCKED,
  COMPANY_DECISION_EXERCISED_ROFR,
} from "utils/constants";

/**
 * Renders a description of the company decision, color coded appropriately.
 *
 * @param {object} prop
 * @returns {React.Element}
 */

const StyledApproved = styled.span`
  color: ${ColorPalette.greenNormal};
`;

const StyledBlocked = styled.span`
  color: ${ColorPalette.redDark};
`;

const StyledExercisedROFR = styled.span`
  color: ${ColorPalette.yellowLight};
`;

const StyledNoDecision = styled.span`
  color: ${ColorPalette.lightWhite};
`;

const CompanyDecisionCell = ({ value: companyDecision }) => {
  switch (companyDecision) {
    case COMPANY_DECISION_APPROVED:
      return <StyledApproved>Approved</StyledApproved>;
    case COMPANY_DECISION_EXERCISED_ROFR:
    case COMPANY_DECISION_ASSIGNED_ROFR:
      return <StyledExercisedROFR>{companyDecision}</StyledExercisedROFR>;
    case COMPANY_DECISION_BLOCKED:
      return <StyledBlocked>Blocked</StyledBlocked>;
    default:
      return <StyledNoDecision>No Decision/SPV</StyledNoDecision>;
  }
};

CompanyDecisionCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CompanyDecisionCell;
