import {
  SPV_TYPE_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_STANDALONE,
  SPV_TYPE_CLASS,
} from "utils/constants";

const SPV_TYPE_OPTIONS = [
  { value: SPV_TYPE_MASTER, label: "Master" },
  { value: SPV_TYPE_SERIES, label: "Series" },
  { value: SPV_TYPE_STANDALONE, label: "Stand-alone" },
];

const SPV_TYPE_OPTIONS_WITHOUT_MASTER = [
  { value: SPV_TYPE_STANDALONE, label: "Standalone SPV" },
  { value: SPV_TYPE_SERIES, label: "Series SPV" },
];

const getTitle = spvType => {
  switch (spvType) {
    case SPV_TYPE_MASTER:
      return "Add Master SPV Profile";
    case SPV_TYPE_SERIES:
      return "Add Series Profile";
    case SPV_TYPE_STANDALONE:
      return "Add Standalone SPV Profile";
    case SPV_TYPE_CLASS:
      return "Add Class Profile";
    default:
      // should never reach default
      return "SPV";
  }
};

export {
  SPV_TYPE_MASTER,
  SPV_TYPE_OPTIONS,
  SPV_TYPE_OPTIONS_WITHOUT_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_STANDALONE,
  SPV_TYPE_CLASS,
  getTitle,
};
