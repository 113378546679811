import React from "react";
import PropTypes from "prop-types";

import { IconButton, DotsHorizontalIcon, useAnchoredMenu } from "yuka";

import { useDelete, QUERY_KEYS } from "api";

/**
 * This component renders the task item edit menu
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const KYCChecklistTaskItemDropdown = props => {
  const { onSubmit } = useDelete(QUERY_KEYS.TASKS.detail(props.task.apiId));

  const deleteTask = () => {
    onSubmit();
  };

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({
    menuItems: [{ text: "Delete", onClick: deleteTask }],
  });

  return (
    <>
      <IconButton onClick={toggleMenu} icon={DotsHorizontalIcon} ref={menuRef} />
      {menu}
    </>
  );
};

KYCChecklistTaskItemDropdown.propTypes = {
  task: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    match: PropTypes.string,
  }).isRequired,
  editTask: PropTypes.func.isRequired,
  scheduleAlert: PropTypes.func.isRequired,
};

export default KYCChecklistTaskItemDropdown;
