import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { IconButton, UploadIcon } from "yuka";

import { useFetch, QUERY_KEYS } from "api";
import { ClientDocumentsForm } from "forms/DocumentsForm";

import { INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES } from "./constants";

/**
 * Allows users to upload a new version of the document
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const UploadCell = ({ value: document }) => {
  const { id: sourceId } = useParams();
  const { data: source } = useFetch(QUERY_KEYS.CLIENT_PROFILES.detail(sourceId));
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [allowUpload, setAllowUpload] = useState(false);
  const uploadAction = () => {
    setAllowUpload(true);
    setEditModalOpen(true);
  };
  const closeModal = () => {
    setAllowUpload(false);
    setEditModalOpen(false);
  };

  return (
    <div>
      {isEditModalOpen && (
        <ClientDocumentsForm
          existingDocument={document}
          allowUpload={allowUpload}
          closeModal={closeModal}
          isClient={[INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES].includes(
            source?.sourceType
          )}
        />
      )}
      <IconButton onClick={uploadAction} icon={UploadIcon} />
    </div>
  );
};

UploadCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default UploadCell;
