import { useMemo } from "react";
import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";
import {
  BadgeCell,
  CheckboxCell,
  CheckboxHeader,
  ColorPalette,
  IconCell,
  PinFilledIcon,
  PinIcon,
} from "yuka";
import useTableSort from "utils/tables/useTableSort";

const LINK_PINNED_PARTICIPANT_DOCS_TABLE_NAME = "LINK_PINNED_PARTICIPANT_DOCS";
const LINK_UNPINNED_PARTICIPANT_DOCS_TABLE_NAME = "LINK_UNPINNED_PARTICIPANT_DOCS";

const useLinkPinnedParticipantDocsTableConfig = () => {
  const currentTableSort = useSelector(
    state => state?.tableSort[LINK_PINNED_PARTICIPANT_DOCS_TABLE_NAME]
  );
  const dispatch = useDispatch();
  const onSort = useTableSort(ACTIONS, dispatch, LINK_PINNED_PARTICIPANT_DOCS_TABLE_NAME);

  const sortState = useMemo(
    () => (currentTableSort && currentTableSort.length ? currentTableSort : [{ id: "type" }]),
    [currentTableSort]
  );

  return {
    sortState,
    onSort,
  };
};

const useLinkUnpinnedParticipantDocsTableConfig = () => {
  const currentTableSort = useSelector(
    state => state?.tableSort[LINK_UNPINNED_PARTICIPANT_DOCS_TABLE_NAME]
  );
  const dispatch = useDispatch();
  const onSort = useTableSort(ACTIONS, dispatch, LINK_UNPINNED_PARTICIPANT_DOCS_TABLE_NAME);

  const sortState = useMemo(
    () => (currentTableSort && currentTableSort.length ? currentTableSort : [{ id: "type" }]),
    [currentTableSort]
  );

  return {
    sortState,
    onSort,
  };
};

const ParticipantNameColumn = headerText => ({
  id: "participantName",
  accessor: "participantName",
  width: 33,
  header: headerText,
  sortable: false,
});

const ProfileTypeColumn = {
  id: "profileType",
  accessor: "profileType",
  width: 33,
  header: "Profile Type",
  sortable: false,
};

const DocumentsColumn = {
  id: "documentCount",
  accessor: "documentCount",
  width: 33,
  header: "Documents",
  sortable: false,
};

const PinnedCheckboxColumn = {
  id: "pinnedDocumentSelected",
  accessor: "selected",
  header: "pinnedDocumentsSelected",
  width: 5,
  align: "center",
  cellRenderer: CheckboxCell,
  headerRenderer: CheckboxHeader,
};

const UnpinnedCheckboxColumn = {
  id: "unpinnedDocumentSelected",
  accessor: "selected",
  header: "unpinnedDocumentsSelected",
  width: 5,
  align: "center",
  cellRenderer: CheckboxCell,
  headerRenderer: CheckboxHeader,
};

const PinnedColumn = {
  id: "pinned",
  accessor: "pinned",
  width: 5,
  header: "",
  sortable: false,
  renderOverflow: true,
  getIcon: () => <PinFilledIcon color={ColorPalette.blue400} />,
  cellRenderer: IconCell,
};

const UnpinnedColumn = {
  id: "unpinned",
  accessor: "unpinned",
  width: 5,
  header: "",
  sortable: false,
  renderOverflow: true,
  getIcon: () => <PinIcon />,
  cellRenderer: IconCell,
};

const DocumentTypeColumn = {
  id: "type",
  accessor: "type",
  width: 20,
  header: "Document Type",
  sortable: true,
  cellRenderer: BadgeCell,
};

const CategoryColumn = {
  id: "category",
  accessor: "category",
  width: 20,
  header: "Category",
  sortable: false,
  cellRenderer: BadgeCell,
};

const DocumentNameColumn = {
  id: "name",
  accessor: "name",
  width: 50,
  header: "Document Name",
  sortable: false,
};

const headerTableColumns = participantType => [
  ParticipantNameColumn(participantType),
  ProfileTypeColumn,
  DocumentsColumn,
];

const pinnedDocumentTableColumns = [
  PinnedCheckboxColumn,
  PinnedColumn,
  DocumentTypeColumn,
  CategoryColumn,
  DocumentNameColumn,
];

const unpinnedDocumentTableColumns = [
  UnpinnedCheckboxColumn,
  UnpinnedColumn,
  DocumentTypeColumn,
  CategoryColumn,
  DocumentNameColumn,
];

export {
  useLinkPinnedParticipantDocsTableConfig,
  useLinkUnpinnedParticipantDocsTableConfig,
  pinnedDocumentTableColumns,
  unpinnedDocumentTableColumns,
  headerTableColumns,
};
