/**
 * The default theme configuration.
 *
 * By leveraging a default theme, we can enable highly configurable styles to be defined.
 */
import DefaultStyleConfiguration from "./Table/utils/DefaultStyleConfiguration";

export default {
  componentVersion: 1,
  useTangeloTable: true,
  tableStyles: DefaultStyleConfiguration,
  mode: "dark",
};
