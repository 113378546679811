import styled from "styled-components";

import addCommasToNum from "./addCommasToNum";
import convertNumToReadable from "./convertNumToReadable";
import roundDecimal from "./roundDecimal";
import convertTotalValueBucketed from "./convertTotalValueBucketed";

const StyledP = styled.p`
  min-height: 12px;
  margin-bottom: 0;
  white-space: normal;
`;

/**
 * Receives number and number of decimal places and returns a string
 * representing the percentage value
 * i.e (0.5) -> 50.00%
 * i.e (0.5, 3) -> 50.000%
 * Similar to ``get_percent`` in numberformat.py
 *
 * @param {?number} number
 * @param {number} decimalPlaces
 * @returns {string}
 */
const percentFormat = (number, decimalPlaces = 2) =>
  number === null ? "—" : `${roundDecimal(number, decimalPlaces)}%`;

/**
 * Receives number and returns formatted string with commas and rounding
 * i.e (4000000) -> 4,000,000
 * i.e (4000000.5, 2, 0) -> 4,000,000.50
 * i.e (4000000.1234, 0, 2) -> 4,000,000.12
 *
 * @param {?number} number
 * @param {number} minPrecision
 * @param {number} maxPrecision
 * @returns {string}
 */
// eslint-disable-next-line no-magic-numbers
const numberFormat = (number, minPrecision = 0, maxPrecision = 6) => {
  const formatted = `${addCommasToNum(roundDecimal(number, maxPrecision), minPrecision)}`;
  return number === null ? "—" : formatted;
};

/**
 * Receives number and returns formatted string with dollar sign, commas and rounding
 * i.e (4000000) -> $4,000,000
 * i.e (4000000.5, 2, 0) -> $4,000,000.50
 * i.e (4000000.1234, 0, 2) -> $4,000,000.12
 *
 * @param {?number} number
 * @param {number} minPrecision
 * @param {number} maxPrecision
 * @returns {string}
 */
// eslint-disable-next-line no-magic-numbers
const expandedMoneyFormat = (number, minPrecision = 0, maxPrecision = 6) => {
  const moneyFormat =
    number < 0
      ? `-$${addCommasToNum(roundDecimal(-number, maxPrecision), minPrecision)}`
      : `$${addCommasToNum(roundDecimal(number, maxPrecision), minPrecision)}`;
  return number === null ? "—" : moneyFormat;
};

/**
 * Receives number and returns formatted shorthand string, with suffix to represent size
 * i.e. (4000000) -> $4M
 * i.e. (4123450) -> $4.12M
 * i.e. (4123450, 3) -> $4.123M
 * Similar to ``convert_num_to_readable`` in numberformat.py
 *
 * @param {?number} number
 * @param {number} precision
 * @returns {string}
 */
const shortMoneyFormat = (number, precision = 2) =>
  number === null ? "—" : `$${convertNumToReadable(number, precision)}`;

/**
 * Receives boolean and returns "Yes" if true and "No" if false
 *
 * @param {boolean} bool
 * @returns {string}
 */
const booleanFormat = bool => (bool ? "Yes" : "No");

/**
 * Same as booleanFormat, but returns "-" if value is null
 *
 * @param {boolean} bool
 * @returns {string}
 */
const nullBooleanFormat = bool => {
  if (bool === true) {
    return "Yes";
  }
  if (bool === false) {
    return "No";
  }
  return "—";
};

/**
 * Convert a number into a bucketed number (e.g: 11000000 => $10mn - $25mn).
 * This has some similarities to ``convert_total_value_bucketed`` in numberformat.py
 *
 * @param {number} numRaw - the number to be converted
 * @param {number} precision - optional argument to specify number of decimal places
 * @returns {string} bucket value representation of num
 */
const bucketedMoneyFormat = (number, precision = 2) => convertTotalValueBucketed(number, precision);

/**
 * Convert a string with newline characters to a string
 *
 * @param {string} string - the string to be converted
 * @returns {string} - string with newlines shown
 */
const newlineStringFormat = string => {
  if (string) {
    return string.split("\n").map((str, i) => <StyledP key={`newlinestring${i}`}>{str}</StyledP>);
  }
  return string;
};

export {
  booleanFormat,
  nullBooleanFormat,
  percentFormat,
  numberFormat,
  shortMoneyFormat,
  expandedMoneyFormat,
  bucketedMoneyFormat,
  newlineStringFormat,
};
