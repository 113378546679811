import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import NavigationStateContext from "./NavigationStateContext";

import { Orientations, PrimaryColorPalette } from "../StylingConstants";
import HyperLink, { LinkStyles } from "../Button/HyperLink";
import { LinkTypes } from "../Button/getLinkProps";
import { body1, caption2, FontColors } from "../Typography";
import useTooltip from "../Tooltip/useTooltip";

const NavItemContent = styled(HyperLink).attrs({
  linkStyle: LinkStyles.UNSTYLED,
})`
  ${body1}
  ${FontColors.theme50}
  border-radius: 8px;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  &:active${props => (props.$selected ? ", &" : "")} {
    background: rgba(255, 255, 255, 0.07);
    ${FontColors.theme100}
    svg path {
      fill: ${PrimaryColorPalette.white100};
    }
  }
`;

const NavWrapper = styled.div`
  border-right: 1px solid
    ${props => (props.$selected ? PrimaryColorPalette.blue500 : "transparent")};
  box-sizing: border-box;
  display: flex;
  padding-left: 12px;
  padding-right: 11px;
  width: 100%;

  & + & {
    margin-top: 4px;
  }
  &:first-child {
    margin-top: 16px;
  }
`;

const StyledCounter = styled.span`
  ${caption2}
  ${props => props.$typography}
  background: ${props => props.$background};
  border-radius: 8px;
  height: 16px;
  line-height: 16px;
  padding: 0 6px;
  margin-left: auto;
`;

// Do not allow navigation icons to shrink when `children` is wide.
const FixedSizeIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 18px;
`;

const TruncatedChildren = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NavigationItem = React.forwardRef((props, ref) => {
  const isCollapsed = React.useContext(NavigationStateContext);
  const internalRef = React.useRef(null);
  const widthMeasuringRef = React.useRef(null);
  const [width, setWidth] = React.useState([0, 0]);

  // Automatically inject a tooltip holding the props.children if the children should be truncated.
  React.useEffect(() => {
    setWidth([widthMeasuringRef.current?.scrollWidth, widthMeasuringRef.current?.offsetWidth]);
  }, []);

  let tooltipContent = props.tooltip;
  if (!isCollapsed && !props.tooltip && width[0] > width[1]) {
    tooltipContent = props.children;
  }
  const tooltip = useTooltip(ref || internalRef, tooltipContent, {
    orientation: Orientations.HORIZONTAL,
  });

  return (
    <NavWrapper role="presentation" $selected={props.selected}>
      <NavItemContent
        role="menuitem"
        tabIndex="-1"
        id={props.id}
        ref={ref || internalRef}
        url={props.url}
        linkType={props.linkType}
        onClick={props.onClick}
        $selected={props.selected}
      >
        {props.leadingIcon && (
          <FixedSizeIconContainer>
            <props.leadingIcon color={PrimaryColorPalette.white50} size={18} />
          </FixedSizeIconContainer>
        )}
        {!isCollapsed && (
          <>
            <TruncatedChildren ref={widthMeasuringRef}>{props.children}</TruncatedChildren>
            {Boolean(props.counter) && (
              <StyledCounter $background={props.counterColor} $typography={props.counterTypography}>
                {props.counter}
              </StyledCounter>
            )}
          </>
        )}
        {tooltipContent && tooltip}
      </NavItemContent>
    </NavWrapper>
  );
});

NavigationItem.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.bool,
  url: PropTypes.string,
  linkType: PropTypes.oneOf(Object.values(LinkTypes)),
  onClick: PropTypes.func,
  leadingIcon: PropTypes.func,
  children: PropTypes.node.isRequired,
  counter: PropTypes.node,
  counterColor: PropTypes.oneOf(Object.values(PrimaryColorPalette)),
  counterTypography: PropTypes.oneOf(Object.values(FontColors)),
  tooltip: PropTypes.node,
};

NavigationItem.defaultProps = {
  url: "",
  selected: false,
  counter: 0,
  counterColor: PrimaryColorPalette.sell,
  counterTypography: PrimaryColorPalette.theme100,
};

NavigationItem.displayName = "NavigationItem";

export default NavigationItem;
