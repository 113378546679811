import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";
import { Button } from "yuka";

import { useWrite, QUERY_KEYS, DataTypes } from "api";

import { Input, InputRow, Select } from "forms/FormComponents";
import { mpTrack } from "utils/mixpanel";
import { required } from "utils/form/inputValidations";

import { STATUS_TYPE_STANDARD, STATUS_TYPE_OPTIONS } from "./constants";

const StyledInputRow = styled(InputRow)`
  margin-bottom: 0px;
`;

/**
 * A basic form to create a Status Update on a Connection
 *
 * @returns {Element}
 */
const CreateStatusUpdateForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.STATUS_UPDATES.list(props.connection.tradeId));
  const wrappedOnSubmit = (values, form) => {
    return onSubmit(values).then(() => {
      form.reset();
      mpTrack("Add Status Update", {
        phase: props.connection.phase,
        priority: values.statusType,
      });
    });
  };
  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        apiType: DataTypes.STATUS_UPDATE,
        statusType: STATUS_TYPE_STANDARD,
        isPinned: true,
      }}
    >
      {({ dirty, valid, submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <StyledInputRow>
            <Select
              name="statusType"
              placeholder="Select a status"
              options={STATUS_TYPE_OPTIONS}
              noSort
              validate={[required]}
              width={160}
            />
            <Input name="content" placeholder="Add new status update" validate={[required]} />
            <Button disabled={!dirty || !valid || submitting} onClick={handleSubmit}>
              Post
            </Button>
          </StyledInputRow>
        </form>
      )}
    </Form>
  );
};

CreateStatusUpdateForm.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    phase: PropTypes.string,
  }).isRequired,
};

export default CreateStatusUpdateForm;
