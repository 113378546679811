import React from "react";
import { FinalFormField, StyledBody2 } from "yuka";

import { Input, InputRow } from "forms/FormComponents";
import { required, requiredObject } from "utils/form/inputValidations";

import { loadCompanyOptions } from "./loadCompanyOptions";

const StandaloneSPVFieldSet = () => (
  <>
    <InputRow>
      <Input name="name" title="Name" placeholder="e.g. ZPF Standalone" validate={[required]} />
    </InputRow>
    <InputRow>
      <FinalFormField
        type="autocomplete"
        loadOptions={loadCompanyOptions}
        name="company"
        placeholder="e.g. OpenAI"
        label="Portfolio Company"
        validate={[requiredObject]}
      />
    </InputRow>
    <InputRow>
      <StyledBody2>Add a corresponding Class *optional*</StyledBody2>
    </InputRow>
    <InputRow>
      <Input name="className" title="Class Name" placeholder="e.g. Class A" />
      <Input
        name="underlyingFund"
        title="Underlying Fund (optional)"
        placeholder="e.g. Sequoia Capital I"
      />
    </InputRow>
  </>
);

export default StandaloneSPVFieldSet;
