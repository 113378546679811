/**
 * Gets the last element of the given `array`. Implementation from lodash.
 * @param array
 * @returns {*|undefined}
 */
const last = array => {
  const length = array == null ? 0 : array.length;
  return length ? array[length - 1] : undefined;
};

export default last;
