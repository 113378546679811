import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Button,
  Card,
  CardStyles,
  ComponentGroup,
  ColorPalette,
  List,
  ListItem,
  ListStyles,
} from "yuka";

import { useFetch, useWrite, DataTypes, QUERY_KEYS } from "api";
import { Input } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";
import useFileDragAndDrop from "utils/hooks/form/useFileDragAndDrop";

import DocumentListItem from "./DocumentListItem";

const StyledInput = styled(Input)`
  display: none;
`;

const StyledDropzone = styled.div`
  height: 100%;
  display: flex;
  border: 1px dashed ${ColorPalette.faintWhite};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

const AttachmentsFieldSet = ({ excludedDocuments, onRemoveDocument, spvClassId }) => {
  const invites = useFormValue("invites", []);
  const documents = useFormValue("documents", []);

  const { onSubmit } = useWrite(QUERY_KEYS.SPV_CLASS_DOCUMENTS.list(spvClassId));

  const { data: spvClass } = useFetch(QUERY_KEYS.SPV_CLASSES.detail(spvClassId, [], {}));

  const handleUpload = files => {
    // Spread files to get an array rather than FileList
    return Promise.all(
      [...files].map(file => onSubmit({ apiType: DataTypes.EXPIRABLE_DOCUMENT, file }))
    );
  };

  const [containerRef, fileInputRef, isDragging] = useFileDragAndDrop({
    multiple: true,
    onChange: handleUpload,
  });

  return (
    <>
      <Card title="User will finish on platform & DocuSign" cardStyle={CardStyles.SECTIONED}>
        <List divider>
          <ListItem
            listStyle={ListStyles.TWO_LINE}
            text="Signature Pages"
            subtext="This is not an email attachment"
          />
          {invites.some(client => client.needsKyc) && (
            <ListItem
              listStyle={ListStyles.TWO_LINE}
              text="KYC Investor Questionnaire"
              subtext="This is not an email attachment"
            />
          )}
          {invites.some(client => client.needsSignedNda) && (
            <ListItem
              listStyle={ListStyles.TWO_LINE}
              text="Non-Disclosure Agreement"
              subtext="This is not an email attachment"
            />
          )}
        </List>
      </Card>
      <Card title="Email Attachments:" ref={containerRef} cardStyle={CardStyles.SECTIONED}>
        <StyledInput
          type="file"
          ref={fileInputRef}
          name="files"
          id="files"
          multiple
          onChange={handleUpload}
        />
        {isDragging ? (
          <StyledDropzone>Drop files here to upload</StyledDropzone>
        ) : (
          documents?.length > 0 && (
            <ComponentGroup>
              <List divider>
                {documents
                  .filter(doc => !excludedDocuments?.includes(doc.apiId))
                  .map(document => (
                    <DocumentListItem
                      key={document.name}
                      document={document}
                      onRemove={onRemoveDocument}
                      spvClass={spvClass}
                    />
                  ))}
              </List>
              <PaddedWrapper>
                <Button onClick={() => document.getElementById("files").click()}>
                  Upload additional documents
                </Button>
              </PaddedWrapper>
            </ComponentGroup>
          )
        )}
      </Card>
    </>
  );
};

AttachmentsFieldSet.propTypes = {
  excludedDocuments: PropTypes.array.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  spvClassId: PropTypes.string.isRequired,
};

export default AttachmentsFieldSet;
