import PropTypes from "prop-types";
import { ComponentGroup } from "yuka";

import { QUERY_KEYS } from "api";
import {
  TRANSFER_DEAL_FILE_FILTERS,
  BUYSIDE_DEAL_FILE_FILTERS,
  SELLSIDE_DEAL_FILE_FILTERS,
} from "forms/filterNames";

import DealFileTable from "./DealFileTable";

const DealFile = ({ connection }) => (
  <ComponentGroup>
    <DealFileTable
      connection={connection}
      documents={connection.transferDocuments}
      tableName="TRANSFER_DOCUMENTS"
      title="Transfer Documents"
      filterName={TRANSFER_DEAL_FILE_FILTERS}
      queryKeys={QUERY_KEYS.DEAL_FILE_TRANSFER_DOCUMENTS}
    />
    <DealFileTable
      connection={connection}
      documents={connection.buysideDocuments}
      tableName="BUYSIDE_DOCUMENTS"
      title="Buyside Documents"
      filterName={BUYSIDE_DEAL_FILE_FILTERS}
      queryKeys={QUERY_KEYS.DEAL_FILE_BUYSIDE_DOCUMENTS}
    />
    <DealFileTable
      connection={connection}
      documents={connection.sellsideDocuments}
      tableName="SELLSIDE_DOCUMENTS"
      title="Sellside Documents"
      filterName={SELLSIDE_DEAL_FILE_FILTERS}
      queryKeys={QUERY_KEYS.DEAL_FILE_SELLSIDE_DOCUMENTS}
    />
  </ComponentGroup>
);

DealFile.propTypes = {
  connection: PropTypes.shape({
    buysideDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    sellsideDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    transferDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    tradeId: PropTypes.string.isRequired,
  }).isRequired,
};

export default DealFile;
