import { useFormState } from "react-final-form";
import { Fonts, FinalFormField, InputRow, StandardWizardActions } from "yuka";

import LoadingSpinner from "utils/LoadingSpinner";
import { required } from "utils/form/inputValidations";

const EmailTemplateFieldSet = () => {
  const { submitting } = useFormState();
  return (
    <>
      <InputRow>
        <FinalFormField
          type="text"
          label="Subject"
          name="emailSubject"
          placeholder="E.g. Subscribe to ZPF Master Series I Class A"
          validate={[required]}
        />
      </InputRow>
      <Fonts.Headline1theme80>Dear [Investor Name],</Fonts.Headline1theme80>
      <FinalFormField
        type="textarea"
        label="Body"
        name="emailBody"
        placeholder="Add a note to provide context or detail."
      />
      <StandardWizardActions />
      {submitting && <LoadingSpinner />}
    </>
  );
};

export default EmailTemplateFieldSet;
