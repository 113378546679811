import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { DefinitionListHeader } from "yuka";

import { QUERY_PARAM } from "forms/EditConnectionForm";

const EditAction = props => {
  const [, setSearchParams] = useSearchParams();
  const onClick = () => {
    setSearchParams({ [QUERY_PARAM]: props.action });
  };
  return <DefinitionListHeader title={props.title} actionLink="Edit" linkProps={{ onClick }} />;
};

EditAction.propTypes = {
  title: PropTypes.node.isRequired,
  action: PropTypes.string.isRequired,
};

export default EditAction;
