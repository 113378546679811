import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";
import { AlarmClockIcon, Button, ButtonStyles, ColorPalette, PlusIcon } from "yuka";

import CancelOnboardingRequestForm from "forms/CancelOnboardingRequestForm";
import ResendOnboardingRequestForm from "forms/ResendOnboardingRequestForm";

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const StyledOnboardingRequestInfo = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const StyledOnboardingRequestInfoText = styled.span`
  margin: 0 8px;
`;

const RESEND_MODAL = "RESEND_MODAL";
const CANCEL_MODAL = "CANCEL_MODAL";

const OnboardingRequestInfo = ({ id, latestOnboardingRequest }) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState("");

  const startOnboardFlow = id => {
    navigate(`/clients/onboarding/${id}`);
  };

  if (latestOnboardingRequest?.completedOn) {
    return (
      <StyledOnboardingRequestInfo>
        <StyledOnboardingRequestInfoText>
          <span>
            Last Onboarding request completed on{" "}
            {DateTime.fromISO(latestOnboardingRequest?.completedOn).toLocaleString(
              DateTime.DATE_MED
            )}
          </span>
        </StyledOnboardingRequestInfoText>
        <StyledButton
          buttonStyle={ButtonStyles.CTA}
          leadingIcon={PlusIcon}
          onClick={() => startOnboardFlow(id)}
        >
          Onboard Client
        </StyledButton>
      </StyledOnboardingRequestInfo>
    );
  }

  return latestOnboardingRequest ? (
    <StyledOnboardingRequestInfo>
      {isModalOpen === CANCEL_MODAL && (
        <CancelOnboardingRequestForm
          latestOnboardingRequest={latestOnboardingRequest}
          id={id}
          onClose={() => setModalOpen("")}
        />
      )}
      {isModalOpen === RESEND_MODAL && (
        <ResendOnboardingRequestForm
          latestOnboardingRequest={latestOnboardingRequest}
          id={id}
          onClose={() => setModalOpen("")}
        />
      )}
      <AlarmClockIcon color={ColorPalette.alert} />
      <StyledOnboardingRequestInfoText>
        <span>
          Onboarding request sent to
          <br /> {latestOnboardingRequest.email} on{" "}
          {DateTime.fromISO(latestOnboardingRequest.sentOn).toLocaleString(DateTime.DATE_MED)}
        </span>
      </StyledOnboardingRequestInfoText>
      <Button onClick={() => setModalOpen(RESEND_MODAL)}>Resend</Button>
      <Button onClick={() => setModalOpen(CANCEL_MODAL)}>Cancel</Button>
    </StyledOnboardingRequestInfo>
  ) : (
    <StyledButton
      buttonStyle={ButtonStyles.CTA}
      leadingIcon={PlusIcon}
      onClick={() => startOnboardFlow(id)}
    >
      Onboard Client
    </StyledButton>
  );
};

OnboardingRequestInfo.propTypes = {
  id: PropTypes.string.isRequired,
  latestOnboardingRequest: PropTypes.shape({
    apiId: PropTypes.string,
    email: PropTypes.string,
    completedOn: PropTypes.string,
    sentOn: PropTypes.string,
  }),
};

export default OnboardingRequestInfo;
