import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, List, ListItem, ListStyles } from "yuka";
import styled from "styled-components";

import EditSPVClassDetailsForm from "forms/EditSPVClassForm";
import { percentFormat, shortMoneyFormat } from "utils/displayFormatUtils";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const SettingsCard = ({ spvClass, spv }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  return (
    <StyledCard
      cardStyle={CardStyles.SECTIONED}
      title="Settings"
      linkText="Edit"
      linkProps={{ onClick: () => setIsEditModalOpen(true) }}
    >
      <List divider>
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={spv.companyName}
          subtext="Portfolio Company"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={spvClass.underlyingFund}
          subtext="Underlying Fund"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={spvClass.securityType || "--"}
          subtext="Share Class"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={spvClass.pricePerShare ? shortMoneyFormat(spvClass.pricePerShare) : "--"}
          subtext="Price Per Share"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={spvClass.placementFee ? percentFormat(spvClass.placementFee) : "--"}
          subtext="Placement Fee"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={spvClass.managementFee ? percentFormat(spvClass.managementFee) : "--"}
          subtext="Management Fee"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={spvClass.carriedInterest ? percentFormat(spvClass.carriedInterest) : "--"}
          subtext="Carried Interest"
        />
      </List>
      {isEditModalOpen && (
        <EditSPVClassDetailsForm
          spvClass={spvClass}
          spv={spv}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </StyledCard>
  );
};

SettingsCard.propTypes = {
  spv: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
    spvType: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  spvClass: PropTypes.shape({
    securityType: PropTypes.string,
    underlyingFund: PropTypes.string,
    pricePerShare: PropTypes.number,
    placementFee: PropTypes.number,
    managementFee: PropTypes.number,
    carriedInterest: PropTypes.number,
  }).isRequired,
};

export default SettingsCard;
