import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import { FormModal } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { DataTypes } from "api/constants";

import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";
import { InputRow } from "forms/FormComponents";

import NoteContentField from "./NoteContentField";

/**
 * A basic form to add a note
 *
 * @returns {Element}
 */
const NoteContentForm = props => {
  const { onSubmit: create } = useWrite(props.queryKey.list(props.apiId));
  const { onSubmit: edit } = useWrite(props.queryKey.detail(props.apiId, props.note?.apiId));

  const onSubmit = props.isEdit ? edit : create;

  const wrappedOnSubmit = (...params) => {
    onSubmit(...params).then(() => {
      let mixpanelText = "";
      if (props.queryKey === QUERY_KEYS.NOTES) {
        mixpanelText = "connection note";
      } else if (props.queryKey === QUERY_KEYS.CLIENT_NOTES) {
        mixpanelText = "client note";
      } else if (props.queryKey === QUERY_KEYS.SPV_NOTES) {
        mixpanelText = "spv note";
      } else if (props.queryKey === QUERY_KEYS.SPV_CLASS_NOTES) {
        mixpanelText = "spv class note";
      }
      props.isEdit ? mpTrack(`edit ${mixpanelText}`) : mpTrack(`add ${mixpanelText}`);
      props.onClose();
    });
  };

  const headerText = props.isEdit ? "Edit Note" : "Add Note";

  const initialValues = React.useMemo(() => {
    let apiType = "";
    if (props.queryKey === QUERY_KEYS.NOTES) {
      apiType = DataTypes.NOTE;
    } else if (props.queryKey === QUERY_KEYS.CLIENT_NOTES) {
      apiType = DataTypes.CLIENT_NOTES;
    } else if ([QUERY_KEYS.SPV_NOTES, QUERY_KEYS.SPV_CLASS_NOTES].includes(props.queryKey)) {
      apiType = DataTypes.SPV_NOTE;
    }
    let initialValues = {
      ...props.note,
      apiType: apiType,
    };
    return initialValues;
  }, [props.queryKey, props.note]);

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={props.onClose}
          onSubmit={handleSubmit}
          submitText="Submit"
          title={headerText}
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <NoteContentField title={headerText} />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

NoteContentForm.propTypes = {
  queryKey: PropTypes.shape({
    list: PropTypes.func,
    detail: PropTypes.func,
  }),
  note: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  apiId: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

NoteContentForm.defaultProps = {
  isEdit: false,
};

export default NoteContentForm;
