import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { IconButton, useAnchoredMenu, DotsVerticalIcon } from "yuka";

import { fetchQueryResponse, useFetch, useDelete, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import { ClientDocumentsForm } from "forms/DocumentsForm";
import { useDispatch, ACTIONS } from "utils/StateProvider";
import downloadFileResponse from "utils/downloadFileResponse";

import { STATUS_ARCHIVED, INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES } from "./constants";

const StyledCell = styled.div`
  display: flex;
`;

/**
 * Renders a dropdown for the documents table
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const DropdownCell = ({ value: document }) => {
  const { id: sourceId } = useParams();
  const { onSubmit: onDelete } = useDelete(
    QUERY_KEYS.CLIENT_DOCUMENTS.detail(sourceId, document.apiId),
    { silent: true }
  );
  const { data: source } = useFetch(QUERY_KEYS.CLIENT_PROFILES.detail(sourceId));
  const dispatch = useDispatch();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const editAction = () => setEditModalOpen(true);
  const closeModal = () => {
    setEditModalOpen(false);
  };
  const handleDelete = () => {
    if (confirm(`Are you sure you archive this document?`)) {
      onDelete().then(() => {
        queryClient.invalidateQueries(QUERY_KEYS.CLIENT_PROFILES.detail(sourceId, []));
        dispatch({
          type: ACTIONS.addToast,
          message: `Successfully Archived`,
        });
      });
    }
  };

  const menuItems = [{ text: "Edit details", onClick: editAction }];
  if (document.status !== STATUS_ARCHIVED) {
    menuItems.push({ text: "Archive", onClick: handleDelete });
  }
  const download = document => {
    fetchQueryResponse(
      QUERY_KEYS.CLIENT_DOCUMENTS.detail(sourceId, document.apiId, ["download"])
    ).then(data => {
      const documentName =
        document.extension === document.name.split(".").at(-1)
          ? document.name
          : `${document.name}.${document.extension}`;
      downloadFileResponse(data, documentName);
    });
  };
  menuItems.push({ text: "Download", onClick: () => download(document) });

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({ menuItems });

  return (
    <StyledCell>
      {isEditModalOpen && (
        <ClientDocumentsForm
          existingDocument={document}
          closeModal={closeModal}
          isClient={[INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES].includes(
            source?.sourceType
          )}
        />
      )}
      <IconButton icon={DotsVerticalIcon} ref={menuRef} onClick={toggleMenu} />
      {menu}
    </StyledCell>
  );
};

DropdownCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DropdownCell;
