import PropTypes from "prop-types";
import { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { Field } from "yuka";

import { useFetch, QUERY_KEYS } from "api";

const OTHER_SECURITY = "Other";

const StyledSecurityWrapper = styled.div`
  width: 100%;
`;

const sortFundingRounds = (fundingRoundA, fundingRoundB) => {
  return (fundingRoundB.order || 0) - (fundingRoundA.order || 0);
};

const SecurityField = props => {
  const [other, setOther] = useState(props.value);
  const [selectValue, setSelectValue] = useState(props.value);
  const [isLoading, setIsLoading] = useState(true);

  const setOtherOnChange = e => {
    props.onChange?.(e.target.value);
    setOther(e.target.value);
  };

  const setSelectValueOnChange = value => {
    props.onChange?.(value);
    setSelectValue(value);
  };

  const { isSuccess, data } = useFetch(QUERY_KEYS.FUNDING_ROUNDS.list(props.companyId), {
    enabled: Boolean(props.companyId),
  });

  const fundingRounds = useMemo(() => {
    let options = isSuccess ? data.sort(sortFundingRounds) || [] : [];
    options.push({ security: OTHER_SECURITY });
    return options;
  }, [isSuccess, data]);

  useEffect(() => {
    setIsLoading(true);
    if (
      selectValue !== OTHER_SECURITY &&
      !fundingRounds.find(fundingRound => fundingRound.security == selectValue)
    ) {
      setOther(selectValue);
      setSelectValue(OTHER_SECURITY);
    } else if (
      // TODO only set when it loses focus
      fundingRounds.find(fundingRound => fundingRound.security == other)
    ) {
      setOther("");
      setSelectValue(other);
    }
    setIsLoading(false);
  }, [other, selectValue, setOther, setSelectValue, fundingRounds]);

  return (
    <StyledSecurityWrapper>
      {!isLoading && (
        <Field
          type="select"
          name="security"
          placeholder="E.g. Common"
          noSort
          label="Security"
          options={fundingRounds}
          optionLabelKey="security"
          optionValueKey="security"
          value={selectValue}
          onChange={setSelectValueOnChange}
        />
      )}
      {selectValue === OTHER_SECURITY && !isLoading && (
        <Field
          type="text"
          name="security-other"
          placeholder="E.g. Common"
          autoComplete="off"
          value={other}
          onChange={setOtherOnChange}
        />
      )}
    </StyledSecurityWrapper>
  );
};

SecurityField.propTypes = {
  companyId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default SecurityField;
