/**
 * Feature Flags
 */
const FEATURE_FLAG_SPV_ONBOARDING = () => {
  switch (window.location.hostname) {
    case "localhost":
      return true;
    case "execution.qa.zanbato.com":
      return true;
    case "execution.branch-xe-1.zanbato.com":
      return true;
    case "execution.staging.zanbato.com":
      return false;
    case "execution.zanbato.com":
      return false;
    default:
      return false;
  }
};

const FEATURE_FLAG_YAC_ONBOARDING = () => {
  switch (window.location.hostname) {
    case "localhost":
      return true;
    case "execution.qa.zanbato.com":
      return true;
    case "execution.branch-xe-1.zanbato.com":
      return true;
    case "execution.staging.zanbato.com":
      return true;
    case "execution.zanbato.com":
      return true;
    default:
      return true;
  }
};

export { FEATURE_FLAG_SPV_ONBOARDING, FEATURE_FLAG_YAC_ONBOARDING };
