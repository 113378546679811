import { REMINDER_RECIPIENT_INDIVIDUAL, REMINDER_RECIPIENT_TEAM } from "utils/constants";

const REMINDER_RECIPIENT_INDIVIDUAL_LABEL = "Me";
const REMINDER_RECIPIENT_TEAM_LABEL = "The entire execution services team";

const REMINDER_RECIPIENT_ITEMS = [
  {
    label: REMINDER_RECIPIENT_INDIVIDUAL_LABEL,
    value: REMINDER_RECIPIENT_INDIVIDUAL,
  },
  {
    label: REMINDER_RECIPIENT_TEAM_LABEL,
    value: REMINDER_RECIPIENT_TEAM,
  },
];

export { REMINDER_RECIPIENT_ITEMS };
