import React from "react";

import { Input, InputRow } from "forms/FormComponents";

/**
 * Field set for general invoicing settings
 *
 * @returns {Element}
 */
const GeneralInvoicingFieldSet = () => (
  <>
    <InputRow>
      <Input name="partySuffix" title="Party Suffix" placeholder="e.g. ZAN" />
    </InputRow>
    <InputRow>
      <Input name="defaultDueDate" title="Default Due Date" placeholder="e.g. 2 business days" />
    </InputRow>
  </>
);

export default GeneralInvoicingFieldSet;
