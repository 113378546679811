import _ from "lodash";

const THOUSAND = "k";
const MILLION = "m";
const BILLION = "b";

/**
 * Converts a number in comma delimited form as a string to a number
 *
 * @param {string} value
 * @returns {number}
 */
function convertDelimitedStringToNum(value) {
  if (_.isNumber(value)) {
    // If it's a number already, return the value
    return value;
  }

  if (!value) {
    // this behavior is equivalent to being passed in an empty string
    return "";
  }
  let totalValueParsed = value.replace(/[$%, ]+/g, "").match(/[0-9.-]+|[a-zA-Z]+/g);
  totalValueParsed = _.dropWhile(totalValueParsed, segment => _.isNaN(_.toNumber(segment)));
  if (totalValueParsed == null || _.isEmpty(totalValueParsed)) {
    return "";
  }
  if (totalValueParsed.length === 1) {
    // no modifiers at the end
    return Number(totalValueParsed[0]);
  }
  const scale = { [THOUSAND]: 1000, [MILLION]: 1000000, [BILLION]: 1000000000 };
  const modifier = totalValueParsed[1][0].toLowerCase(); // get modifier letter (k, m, b etc)
  const amplifier = scale[modifier] || 1;
  return Number(totalValueParsed[0]) * amplifier;
}

/**
 * Strips characters from an input value. Common use case is to remove the $ or % sign to get a raw
 * number
 *
 * @param {string} value
 * @param {string} symbols
 *
 * @returns {string} value with all instances of characters in symbols removed
 */
function stripSymbols(value, symbols) {
  let tempValue = value;
  _.forEach(symbols, char => {
    tempValue = _.replace(tempValue, char, "");
  });
  return tempValue;
}

export { convertDelimitedStringToNum, stripSymbols, THOUSAND, MILLION, BILLION };
