import PropTypes from "prop-types";
import styled from "styled-components";

import TradeDetailsCard from "./TradeDetailsCard";
import ConnectionNotesCard from "./ConnectionNotesCard";
import TaskRemindersCard from "./TaskRemindersCard";
import StatusUpdateCard from "./StatusUpdateCard";

const StyledOverviewTab = styled.div`
  padding: 16px 0;
  align-items: flex-start;
  display: grid;
  grid-template-columns: minmax(360px, 1fr) 2fr;
  grid-gap: 16px;
  min-height: 0;
  overflow-y: auto;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  min-width: 0;
`;

const OverviewTab = ({ connection }) => (
  <StyledOverviewTab>
    <StyledColumn>
      <TradeDetailsCard connection={connection} />
    </StyledColumn>
    <StyledColumn>
      <StatusUpdateCard connection={connection} />
      <TaskRemindersCard connection={connection} />
      <ConnectionNotesCard connection={connection} />
    </StyledColumn>
  </StyledOverviewTab>
);

OverviewTab.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
};

export default OverviewTab;
