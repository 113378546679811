import PropTypes from "prop-types";
import { useMatch } from "react-router-dom";
import { NavigationItem, FontColors, ColorPalette, LinkTypes } from "yuka";

import { mpTrack } from "utils/mixpanel";

const NavLink = ({ to, label, mpHeader, alert }) => {
  const match = useMatch(`${to}*`);

  const onClick = () => {
    const page = mpHeader ? `${label} ${mpHeader}` : label;
    mpTrack("navigate global menu", { page });
  };

  return (
    <NavigationItem
      url={to}
      linkType={LinkTypes.LOCAL_LINK}
      onClick={onClick}
      selected={match !== null}
      counter={alert || 0}
      counterColor={ColorPalette.white15}
      counterTypography={FontColors.theme30}
    >
      {label}
    </NavigationItem>
  );
};

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mpHeader: PropTypes.string,
  alert: PropTypes.string,
};

NavLink.defaultProps = {
  mpHeader: "",
};

export default NavLink;
