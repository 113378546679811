import { debounce } from "lodash-es";
import { useMemo, useContext } from "react";

import { mpTrack } from "utils/mixpanel";
import { HALF_SECOND, SPV_TYPE_SERIES, SPV_TYPE_STANDALONE } from "utils/constants";
import { isEqual, findKey, pickBy } from "utils";
import { SPV_TABLE_FILTERS } from "forms/filterNames";
import { GlobalContext } from "utils/StateProvider";

const initialSPVOptions = [SPV_TYPE_SERIES, SPV_TYPE_STANDALONE];

const fieldToEventTypeMap = {
  name: "Name",
  spvType: "Type",
  parentName: "Master Name",
};

const onChangeFilters = debounce(
  (values, prevValues) => {
    const changedValue = findKey(pickBy(values, (val, key) => prevValues[key] !== val));
    if (changedValue || !isEqual(values, prevValues)) {
      mpTrack("filter spv table", {
        type: fieldToEventTypeMap[changedValue] || "Reset",
      });
    }
  },
  HALF_SECOND,
  { trailing: true }
);

const useSPVTableFilterConfig = () => {
  const {
    state: { forms },
  } = useContext(GlobalContext);

  const formValues = pickBy(forms[SPV_TABLE_FILTERS] || {});
  const queryParams = useMemo(
    () => ({
      "filter[search]": formValues.name,
      "filter[spvType]": formValues.spvType,
      "filter[parentName]": formValues.parentName,
      "filter[companyName]": formValues.companyName,
    }),
    [formValues]
  );

  return {
    queryParams,
    onChangeFilters,
    filterProps: {
      initialValues: {
        spvType: initialSPVOptions,
      },
    },
  };
};

export default useSPVTableFilterConfig;
