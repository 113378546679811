import { Form } from "react-final-form";
import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import { FormModal } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import LoadingSpinner from "utils/LoadingSpinner";
import { InputRow, DatePicker } from "forms/FormComponents";
import { beforeToday } from "utils/form/inputValidations";

const EditConnectionDatesForm = ({ connection, onClose, buy, sell }) => {
  const { onSubmit } = useWrite(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId));

  const initialValues = useMemo(
    () => ({
      ...connection,
      buy: { ...buy },
      sell: { ...sell },
    }),
    [connection, buy, sell]
  );

  const wrappedOnSubmit = useCallback(
    (values, ...params) => {
      const cleanedValues = { ...values };
      const DATETIME_FIELDS = ["companyNoticedOn", "inSettlementOn"];

      Object.keys(cleanedValues).map(value => {
        if (DATETIME_FIELDS.includes(value)) {
          if (cleanedValues[value] !== null && typeof cleanedValues[value] !== "string") {
            cleanedValues[value] = DateTime.fromJSDate(cleanedValues[value]).toISO();
          }
        }
      });
      return onSubmit(cleanedValues, ...params).then(() => {
        onClose();
      });
    },
    [onClose, onSubmit]
  );

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit Key Dates"
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <DatePicker
              name="companyNoticedOn"
              title="Noticed On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
          <InputRow>
            <DatePicker
              name="inSettlementOn"
              title="In Settlement On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
          <InputRow>
            <DatePicker
              name="buy.dealFileReviewedOn"
              title="Buyer Deal File Reviewed On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
          <InputRow>
            <DatePicker
              name="sell.dealFileReviewedOn"
              title="Seller Deal File Reviewed On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
          <InputRow>
            <DatePicker
              name="buy.invoicedOn"
              title="Buyer Invoiced On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
          <InputRow>
            <DatePicker
              name="sell.invoicedOn"
              title="Seller Invoiced On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
          <InputRow>
            <DatePicker
              name="buy.paidOn"
              title="Buyer Fee Collection and Payment On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
          <InputRow>
            <DatePicker
              name="sell.paidOn"
              title="Seller Fee Collection and Payment On"
              validate={[beforeToday]}
              maxDate={new Date()}
              placeholder="MM/DD/YY"
            />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

EditConnectionDatesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
  buy: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  sell: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default EditConnectionDatesForm;
