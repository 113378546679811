/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";

import { QUERY_KEYS, useWrite, DataTypes } from "api";
import { ColorPalette } from "yuka";
import { UnboundCheckbox } from "forms/FormComponents";

import KYCChecklistTaskItemDropdown from "./KYCChecklistTaskItemDropdown";

const StyledKYCChecklistTaskItem = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${ColorPalette.white05};
  margin-top: 12px;
  border-radius: 4px;
  padding: 8px 12px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

const StyledCheckbox = styled.div`
  margin-top: 6px;
`;

const StyledFlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const KYCChecklistTaskItem = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.TASKS.detail(props.task.apiId));

  const toggleTaskComplete = task => {
    const submitData = {
      apiType: DataTypes.TASK,
      apiId: props.task.apiId,
      resolvedOn: task.resolvedOn ? null : DateTime.fromJSDate(new Date()).toISO(),
    };
    onSubmit(submitData);
  };

  return (
    <StyledKYCChecklistTaskItem>
      <StyledCheckbox>
        <UnboundCheckbox
          checked={props.task.resolvedOn !== null}
          name={`resolvedOn-${props.task.apiId}`}
          label={props.task.text}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={() => {
            toggleTaskComplete(props.task);
          }}
        />
      </StyledCheckbox>
      <StyledFlexBox>
        <KYCChecklistTaskItemDropdown task={props.task} />
      </StyledFlexBox>
    </StyledKYCChecklistTaskItem>
  );
};

KYCChecklistTaskItem.propTypes = {
  task: PropTypes.shape({
    apiId: PropTypes.string,
    text: PropTypes.string,
    resolvedOn: PropTypes.string,
  }).isRequired,
};

export default KYCChecklistTaskItem;
