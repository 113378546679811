import axios from "axios";

import { keyBy, groupBy, mapValues } from "utils";
import { PAGE_QUERY_PARAM } from "utils/constants";

import { isAuthenticated } from "./authenticate";

const _parseJsonApiObj = obj => {
  const attributes = obj.attributes;
  const relationships = mapValues(obj.relationships, rel => {
    // Check if rel.data is an array of objects.
    if (rel.data) {
      if (Array.isArray(rel.data)) {
        return rel.data.map(datum => [datum.type, datum.id]);
      }
      // Not an array.
      return [rel.data.type, rel.data.id];
    }
    return null;
  });
  return { apiId: obj.id, apiType: obj.type, ...attributes, ...relationships };
};

const fetchData = ({ queryKey: [type, , ...routeSegments] }) => {
  const params = routeSegments.pop();
  return axios
    .get(`${type}${routeSegments.join("/")}${routeSegments.length ? "/" : ""}`, { params })
    .then(response => response.data);
};

const fetchResponse = ({ queryKey: [type, , ...routeSegments] }) => {
  const params = routeSegments.pop();
  return axios
    .get(`${type}${routeSegments.join("/")}${routeSegments.length ? "/" : ""}`, {
      params,
      responseType: "blob",
    })
    .then(response => response.data);
};

const fetchResponseWithFilename = ({ queryKey: [type, , ...routeSegments] }) => {
  const params = routeSegments.pop();
  return axios
    .get(`${type}${routeSegments.join("/")}${routeSegments.length ? "/" : ""}`, {
      params,
      responseType: "blob",
    })
    .then(response => {
      const contentDispositionHeaderString = response.headers["content-disposition"];
      const filename = contentDispositionHeaderString.match(/filename="([^"]+)"/)[1];
      return { data: response.data, filename };
    });
};

const fetchPagedData = ({ pageParam = 1, queryKey: [type, key, ...routeSegments] }) => {
  const params = routeSegments.pop();
  params[PAGE_QUERY_PARAM] = pageParam;
  routeSegments.push(params);
  return fetchData({ queryKey: [type, key, ...routeSegments] });
};

const cleanJsonApiData = data => {
  if (data) {
    // Handle the primary objects being passed down. Flatten structure
    let mainData;
    if (Array.isArray(data.data)) {
      mainData = data.data.map(inst => _parseJsonApiObj(inst));
    } else {
      mainData = _parseJsonApiObj(data.data);
    }
    // Handle the included objects. Returns an object shaped like
    // { [TYPE]: { [ID]: obj1, [ID2]: obj2 }, [TYPE2]: { [ID]: obj } }
    // Relationships get parsed from the api in a way you can use a get helper to access this
    // without any additional work
    let parsedIncluded = {};
    if (data.included) {
      const grouped = groupBy(
        data.included.map(inst => _parseJsonApiObj(inst)),
        "apiType"
      );
      parsedIncluded = mapValues(grouped, group => keyBy(group, "apiId"));
    }
    const parsedPaginationInfo = {
      ...data.links,
      ...data.meta?.pagination,
    };

    return {
      data: mainData,
      relationships: parsedIncluded,
      page: parsedPaginationInfo,
    };
  }
  // 204 is an example of something that would hit this result
  return {
    data: null,
    relationships: {},
    page: {},
  };
};

const queryConfig = {
  refetchOnWindowFocus: false,
  retry: (failureCount, error) => {
    if (error instanceof axios.Cancel && error.message == "Not authenticated") {
      return isAuthenticated();
    }
    return failureCount < 2;
  },
  retryDelay: 100,
};

export {
  fetchData,
  fetchResponse,
  fetchResponseWithFilename,
  fetchPagedData,
  cleanJsonApiData,
  queryConfig,
};
