import { expandedMoneyFormat, shortMoneyFormat } from "utils/displayFormatUtils";

import CompanyDecisionCell from "./CompanyDecisionCell";
import CompanyCell from "./CompanyCell";
import MarketOperatorCell from "./MarketOperatorCell";
import BrokerCell from "./BrokerCell";
import ClientCell from "./ClientCell";
import { DateCell, BadgeCell, NumberCell, IconButtonCell, LinkExternalIcon } from "yuka";

import { EXECUTION_PHASE_BADGE_MAPPING } from "utils/constants";
import StatusCell from "./StatusCell";

const StatusColumn = {
  id: "tradeStatus",
  accessor: connection => connection,
  width: 40,
  sticky: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: StatusCell,
};

const TradeIdColumn = {
  id: "tradeId",
  accessor: "tradeId",
  width: 160,
  sticky: true,
  header: "Trade ID",
  sortable: true,
  tooltip: () => "Click to copy",
  onCellClick: ({ clickTradeId, ...props }) => clickTradeId(props),
};

const CompanyColumn = {
  id: "company.name",
  accessor: connection => connection,
  width: 200,
  sticky: true,
  header: "Company",
  sortable: true,
  cellRenderer: CompanyCell,
};

const ExpandConnectionColumn = {
  id: "expandConnectionColumn",
  width: 40,
  sticky: true,
  header: "",
  align: "center",
  sortable: false,
  icon: LinkExternalIcon,
  onClick: ({ navigateToConnectionProfile, ...props }) => navigateToConnectionProfile(props),
  renderOverflow: true,
  cellRenderer: IconButtonCell,
};

const PriceColumn = (header = "Price") => ({
  id: "pricePerShare",
  accessor: "pricePerShare",
  width: 160,
  header,
  sortable: true,
  formatter: value => shortMoneyFormat(value, 2),
  cellRenderer: NumberCell,
});

const QuantityColumn = (header = "Quantity") => ({
  id: "shareQuantity",
  accessor: "shareQuantity",
  width: 160,
  header,
  sortable: true,
  precision: 0,
  cellRenderer: NumberCell,
});

const ValueColumn = (header = "Value") => ({
  id: "totalValue",
  accessor: "totalValue",
  width: 160,
  header,
  sortable: true,
  formatter: expandedMoneyFormat,
  cellRenderer: NumberCell,
});

const SecurityColumn = {
  id: "security",
  accessor: "security",
  width: 160,
  header: "Security",
};

const BuysideMOColumn = {
  id: "buysideMO",
  accessor: connection => connection.buy,
  width: 200,
  header: "Buyside MO",
  cellRenderer: MarketOperatorCell,
};

const BuysideBrokerColumn = {
  id: "buysideBroker",
  accessor: connection => connection.buy,
  width: 200,
  header: "Buyside Broker",
  cellRenderer: BrokerCell,
};

const BuyerColumn = {
  id: "buyer",
  accessor: connection => connection.buy,
  width: 200,
  header: "Buyer",
  cellRenderer: ClientCell,
};

const SellsideMOColumn = {
  id: "sellsideMO",
  accessor: connection => connection.sell,
  width: 200,
  header: "Sellside MO",
  cellRenderer: MarketOperatorCell,
};

const SellsideBrokerColumn = {
  id: "sellsideBroker",
  accessor: connection => connection.sell,
  width: 200,
  header: "Sellside Broker",
  cellRenderer: BrokerCell,
};

const SellerColumn = {
  id: "seller",
  accessor: connection => connection.sell,
  width: 200,
  header: "Seller",
  cellRenderer: ClientCell,
};

const PhaseColumn = {
  accessor: "phase",
  width: 208,
  header: "Phase",
  cellRenderer: BadgeCell,
  badgeMapping: EXECUTION_PHASE_BADGE_MAPPING,
};

const ExecutionRepColumn = {
  id: "executionRep",
  accessor: "executionRepName",
  width: 220,
  header: "Execution Rep",
};

const LastUpdatedColumn = {
  id: "lastEditedOn",
  accessor: "lastEditedOn",
  width: 140,
  header: "Last Updated",
  sortable: true,
  cellRenderer: DateCell,
};

const CompanyDecisionColumn = {
  accessor: "companyDecision",
  width: 208,
  header: "Company Decision",
  cellRenderer: CompanyDecisionCell,
};

const SettledOnColumn = {
  id: "settledOn",
  accessor: "settledOn",
  width: 136,
  header: "Settled On",
  sortable: true,
  cellRenderer: DateCell,
};

const DisconnectReasonColumn = {
  accessor: "disconnectReason",
  width: 300,
  header: "Disconnect Reason",
};

const DisconnectedOnColumn = {
  id: "disconnectedOn",
  accessor: "disconnectedOn",
  width: 136,
  header: "Disconnected On",
  sortable: true,
  cellRenderer: DateCell,
};

const AllConnectionsColumns = [
  StatusColumn,
  TradeIdColumn,
  CompanyColumn,
  ExpandConnectionColumn,
  PriceColumn(),
  QuantityColumn(),
  ValueColumn(),
  SecurityColumn,
  PhaseColumn,
  LastUpdatedColumn,
  BuysideMOColumn,
  BuysideBrokerColumn,
  BuyerColumn,
  SellsideMOColumn,
  SellsideBrokerColumn,
  SellerColumn,
  ExecutionRepColumn,
];

const ClosedConnectionsColumns = [
  StatusColumn,
  TradeIdColumn,
  CompanyColumn,
  ExpandConnectionColumn,
  PriceColumn("Closing Price"),
  QuantityColumn("Closing Quantity"),
  ValueColumn("Closing Value"),
  SecurityColumn,
  BuysideMOColumn,
  BuysideBrokerColumn,
  BuyerColumn,
  SellsideMOColumn,
  SellsideBrokerColumn,
  SellerColumn,
  ExecutionRepColumn,
  CompanyDecisionColumn,
  SettledOnColumn,
];

const DisconnectedConnectionsColumns = [
  StatusColumn,
  TradeIdColumn,
  CompanyColumn,
  ExpandConnectionColumn,
  PriceColumn(),
  QuantityColumn(),
  ValueColumn(),
  SecurityColumn,
  BuysideMOColumn,
  BuysideBrokerColumn,
  BuyerColumn,
  SellsideMOColumn,
  SellsideBrokerColumn,
  SellerColumn,
  ExecutionRepColumn,
  DisconnectReasonColumn,
  DisconnectedOnColumn,
];

const LiveConnectionsColumns = [
  StatusColumn,
  TradeIdColumn,
  CompanyColumn,
  ExpandConnectionColumn,
  PriceColumn(),
  QuantityColumn(),
  ValueColumn(),
  SecurityColumn,
  BuysideMOColumn,
  BuysideBrokerColumn,
  BuyerColumn,
  SellsideMOColumn,
  SellsideBrokerColumn,
  SellerColumn,
  ExecutionRepColumn,
  PhaseColumn,
  LastUpdatedColumn,
];

const SettledConnectionsColumns = [
  StatusColumn,
  TradeIdColumn,
  CompanyColumn,
  ExpandConnectionColumn,
  PriceColumn(),
  QuantityColumn(),
  ValueColumn(),
  SecurityColumn,
  BuysideMOColumn,
  BuysideBrokerColumn,
  BuyerColumn,
  SellsideMOColumn,
  SellsideBrokerColumn,
  SellerColumn,
  ExecutionRepColumn,
  PhaseColumn,
  LastUpdatedColumn,
];

const complianceConnectionsColumns = [
  TradeIdColumn,
  CompanyColumn,
  ExpandConnectionColumn,
  BuysideMOColumn,
  BuysideBrokerColumn,
  BuyerColumn,
  SellsideMOColumn,
  SellsideBrokerColumn,
  SellerColumn,
  ExecutionRepColumn,
];

export {
  AllConnectionsColumns,
  ClosedConnectionsColumns,
  DisconnectedConnectionsColumns,
  LiveConnectionsColumns,
  SettledConnectionsColumns,
  complianceConnectionsColumns,
};
