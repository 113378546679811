import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import { FinalFormField, FormModal, ModalStyles } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import SecurityField from "forms/fields/SecurityField";
import { DelimitedNumberInput, Input, InputRow } from "forms/FormComponents";
import { isNotNegative, isValidDecimal, required } from "utils/form/inputValidations";
import LoadingSpinner from "utils/LoadingSpinner";

/**
 * A form to edit spv class details
 *
 * @returns {Element}
 */
const EditSPVClassForm = ({ spvClass, spv, onClose }) => {
  const { onSubmit } = useWrite(QUERY_KEYS.SPV_CLASSES.detail(spvClass.apiId));

  const wrappedOnSubmit = (values, ...params) => {
    onSubmit(values, ...params).then(() => {
      onClose();
    });
  };

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={{ ...spvClass }}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit SPV Class Details"
          modalStyle={ModalStyles.SCROLLABLE}
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <Input
              name="name"
              title="Name"
              placeholder="Enter SPV Class name"
              validate={[required]}
            />
          </InputRow>
          <InputRow>
            <Input
              name="underlyingFund"
              title="Underlying Fund (Optional)"
              placeholder="e.g. Sequoia Capital I"
            />
          </InputRow>
          <InputRow>
            <FinalFormField
              component={SecurityField}
              type="custom-block"
              name="securityType"
              companyId={spv.companyId || undefined}
            />
          </InputRow>
          <InputRow>
            <DelimitedNumberInput
              title="Price per share / unit (USD) (Optional)"
              name="pricePerShare"
              placeholder="e.g. 10.00"
              validate={[isNotNegative, isValidDecimal]}
            />
          </InputRow>
          <InputRow>
            <DelimitedNumberInput
              title="% Placement Fee (Optional)"
              name="placementFee"
              placeholder="e.g. 5.00"
              validate={[isNotNegative, isValidDecimal]}
            />
          </InputRow>
          <InputRow>
            <DelimitedNumberInput
              title="% Management Fee (Optional)"
              name="managementFee"
              placeholder="e.g. 1.00"
              validate={[isNotNegative, isValidDecimal]}
            />
          </InputRow>
          <InputRow>
            <DelimitedNumberInput
              title="% Carried Interest (Optional)"
              name="carriedInterest"
              placeholder="e.g. 10.00"
              validate={[isNotNegative, isValidDecimal]}
            />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

EditSPVClassForm.propTypes = {
  spvClass: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  spv: PropTypes.shape({
    companyId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditSPVClassForm;
