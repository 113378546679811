import PropTypes from "prop-types";
import styled from "styled-components";

import { BUY, SELL } from "utils/constants";

import KYCChecklistCard from "./KYCChecklistCard";

const StyledKYCChecklistTab = styled.div`
  padding-top: 16px;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  min-height: 0;
  overflow-y: auto;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

const KYCChecklistTab = ({ connection }) => (
  <StyledKYCChecklistTab>
    <StyledColumn>
      <KYCChecklistCard connection={connection} type={BUY} />
    </StyledColumn>
    <StyledColumn>
      <KYCChecklistCard connection={connection} type={SELL} />
    </StyledColumn>
  </StyledKYCChecklistTab>
);

KYCChecklistTab.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
};

export default KYCChecklistTab;
