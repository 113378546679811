import { Form } from "react-final-form";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FormModal, Table } from "yuka";

import { useWrite, QUERY_KEYS, DataTypes } from "api";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";

import {
  headerTableColumns,
  pinnedDocumentTableColumns,
  unpinnedDocumentTableColumns,
  useLinkPinnedParticipantDocsTableConfig,
  useLinkUnpinnedParticipantDocsTableConfig,
} from "./config";

const StyledContainer = styled.div`
  width: 1000px;
`;

const LinkParticipantDocsForm = ({
  connection,
  documents,
  onClose,
  name,
  participantType,
  source,
}) => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.CONNECTIONS.detail(connection.tradeId, ["import_participant_documents"])
  );

  const { sortState: pinnedSortState, onSort: pinnedOnSort } =
    useLinkPinnedParticipantDocsTableConfig();
  const { sortState: unpinnedSortState, onSort: unpinnedOnSort } =
    useLinkUnpinnedParticipantDocsTableConfig();

  const headerTableData = useMemo(
    () => [
      {
        participantName: name,
        profileType: source.category,
        documentCount: documents.length,
      },
    ],
    [documents, name, source]
  );

  const [selectedPinnedDocuments, setSelectedPinnedDocuments] = useState([]);
  const [selectedUnpinnedDocuments, setSelectedUnpinnedDocuments] = useState([]);

  const wrappedOnSubmit = useCallback(
    (_, ...params) => {
      const selectedPinnedDocumentsData = selectedPinnedDocuments.map(document => ({
        apiId: document.apiId,
        apiType: DataTypes.EXPIRABLE_DOCUMENT,
      }));
      const selectedUnpinnedDocumentsData = selectedUnpinnedDocuments.map(document => ({
        apiId: document.apiId,
        apiType: DataTypes.EXPIRABLE_DOCUMENT,
      }));
      const documentsToLink = selectedPinnedDocumentsData.concat(selectedUnpinnedDocumentsData);
      const requestData = {
        apiId: connection.apiId,
        apiType: connection.apiType,
        tradeId: connection.tradeId,
        documents: documentsToLink,
      };
      onSubmit(requestData, ...params).then(() => {
        onClose();
        mpTrack("linked client documents to deal file", {
          "trade id": connection.tradeId,
          "participant name": name,
          "number of documents": documentsToLink.length,
        });
      });
    },
    [connection, name, onClose, onSubmit, selectedPinnedDocuments, selectedUnpinnedDocuments]
  );

  const pinnedDocuments = useMemo(
    () => documents.filter(document => document.isPinned),
    [documents]
  );

  const unpinnedDocuments = useMemo(
    () => documents.filter(document => !document.isPinned),
    [documents]
  );

  const pinnedOnRowSelectStateChange = useCallback(
    data => {
      let newSelectedDocuments = [];
      Object.keys(data).map(rowNumber => {
        newSelectedDocuments.push(pinnedDocuments[rowNumber]);
      });
      setSelectedPinnedDocuments(newSelectedDocuments);
      return;
    },
    [pinnedDocuments]
  );

  const unpinnedOnRowSelectStateChange = useCallback(
    data => {
      let newSelectedDocuments = [];
      Object.keys(data).map(rowNumber => {
        newSelectedDocuments.push(unpinnedDocuments[rowNumber]);
      });
      setSelectedUnpinnedDocuments(newSelectedDocuments);
      return;
    },
    [unpinnedDocuments]
  );

  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Confirm Import"
          title="Link Participant Documents"
          requireDirtyForSubmit={false}
        >
          {submitting && <LoadingSpinner />}
          <StyledContainer>
            <Table
              usePercentageColumnWidths
              data={headerTableData}
              columns={headerTableColumns(participantType)}
            />
            {pinnedDocuments.length > 0 && (
              <Table
                usePercentageColumnWidths
                data={pinnedDocuments}
                columns={pinnedDocumentTableColumns}
                onRowSelectStateChange={pinnedOnRowSelectStateChange}
                sortState={pinnedSortState}
                onSort={pinnedOnSort}
              />
            )}
            {unpinnedDocuments.length > 0 && (
              <Table
                usePercentageColumnWidths
                data={unpinnedDocuments}
                columns={unpinnedDocumentTableColumns}
                onRowSelectStateChange={unpinnedOnRowSelectStateChange}
                sortState={unpinnedSortState}
                onSort={unpinnedOnSort}
              />
            )}
          </StyledContainer>
        </FormModal>
      )}
    </Form>
  );
};

LinkParticipantDocsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    apiId: PropTypes.string,
    apiType: PropTypes.string,
    tradeId: PropTypes.string,
  }).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string.isRequired,
    })
  ),
  name: PropTypes.string.isRequired,
  participantType: PropTypes.string.isRequired,
  source: PropTypes.shape({
    category: PropTypes.string,
  }).isRequired,
};

export default LinkParticipantDocsForm;
