import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { AlertCircleIcon } from "../Icons";
import { PrimaryColorPalette } from "../StylingConstants";
import useWindowDimensions from "../utils/useWindowDimensions";

import { StyledTextArea, StyledInputWrapper } from "./internal/StyledComponents";

const StyledWrapper = styled(StyledInputWrapper)`
  height: unset;
`;

/**
 * A styled textarea element
 */
const TextArea = React.forwardRef((props, ref) => {
  // Use a provided ref if there is one, otherwise create one here since we need it locally.
  const backupRef = React.useRef(null);
  const _ref = ref || backupRef;

  // Listen for window changes for resize

  useWindowDimensions();

  const onChange = React.useCallback(
    e => {
      props.onChange?.(e);
      if (props.autoResize && _ref.current) {
        // resets scrollHeight in case there is no longer text
        _ref.current.style.height = "0";
        // @ for 1px border not captured correctly
        _ref.current.style.height = `${_ref.current.scrollHeight + 2}px`;
      }
    },
    [props.onChange, props.autoResize]
  );
  React.useEffect(() => {
    // handleUpdateSize sets the css height of the textarea to 0, and then sets it
    // to the scroll height. For this to work, the textarea MUST have a min-height
    // and a max-height css attribute specified, as that sets the upper and lower
    // bounds of the size of the textarea
    if (props.autoResize && _ref.current) {
      // resets scrollHeight in case there is no longer text
      _ref.current.style.height = "0";
      // @ for 1px border not captured correctly
      _ref.current.style.height = `${_ref.current.scrollHeight + 2}px`;
    }
  });

  const focusChild = () => {
    _ref.current?.focus();
  };

  return (
    <StyledWrapper
      $disabled={props.disabled}
      $error={Boolean(props.showError)}
      onClick={focusChild}
    >
      <StyledTextArea ref={_ref} {...props} onChange={onChange} $height={props.height} />
      {props.showError && <AlertCircleIcon color={PrimaryColorPalette.sell} />}
    </StyledWrapper>
  );
});

TextArea.propTypes = {
  autoResize: PropTypes.bool,
  // if the trailing text is used as a character counter, the implementation should change
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.string,
};

TextArea.defaultProps = {
  disabled: false,
  autoResize: false,
  showError: false,
};

TextArea.displayName = "TextArea";

export default TextArea;
