import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  useTooltip,
  Button,
  IconButton,
  IconButtonStyles,
  LockIcon,
  LockUnlockedIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  DownloadIcon,
  useAnchoredMenu,
} from "yuka";

import { fetchFullQueryResponse, QUERY_KEYS, useWrite } from "api";
import { queryClient } from "api/queryClient";
import downloadFileResponse from "utils/downloadFileResponse";
import AdvanceConnectionPhaseForm from "forms/AdvanceConnectionPhaseForm";
import { PHASE_DEAL_FILE_REVIEW } from "utils/constants";
import { canDownloadInvoice } from "utils/connections";

const StyledSpan = styled.span`
  text-wrap: nowrap;
`;

const ConnectionActions = ({ connection }) => {
  const [showForm, setShowForm] = React.useState(false);

  const { onSubmit } = useWrite(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId));

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({
    menuItems: [
      {
        text: "Buyside Invoice",
        onClick: () => downloadBuysideInvoice(true),
        trailingContent: <DownloadIcon />,
      },
      {
        text: "Sellside Invoice",
        onClick: () => downloadBuysideInvoice(false),
        trailingContent: <DownloadIcon />,
      },
    ],
  });
  const downloadBuysideInvoice = isBuyside => {
    fetchFullQueryResponse(
      QUERY_KEYS.CONNECTIONS.detail(connection.tradeId, ["download_invoice"], {
        side: isBuyside ? "buyside" : "sellside",
      })
    ).then(({ data, filename }) => {
      downloadFileResponse(data, filename);
      queryClient.invalidateQueries(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId));
    });
  };

  const toggleDealFileLock = () => {
    if (
      confirm(
        `Are you sure you want to ${
          connection.isDealFileLocked ? "unlock" : "lock"
        } this deal file?`
      )
    ) {
      onSubmit({
        tradeId: connection.tradeId,
        apiType: connection.apiType,
        isDealFileLocked: !connection.isDealFileLocked,
      });
    }
  };

  const lockRef = React.useRef(null);
  const tooltip = useTooltip(
    lockRef,
    <StyledSpan>
      Deal file is currently {connection.isDealFileLocked ? "locked" : "unlocked"}
    </StyledSpan>
  );

  const approveDealFile = () => setShowForm(true);
  const closeForm = () => setShowForm(false);

  return (
    <>
      {connection.phase === PHASE_DEAL_FILE_REVIEW && (
        <Button ref={menuRef} onClick={approveDealFile}>
          Approve Deal File and Advance to Invoicing
        </Button>
      )}
      {canDownloadInvoice(connection) && (
        <Button
          ref={menuRef}
          onClick={toggleMenu}
          trailingIcon={menu ? ChevronUpIcon : ChevronDownIcon}
        >
          Download Invoice
        </Button>
      )}
      <IconButton
        ref={lockRef}
        buttonStyle={IconButtonStyles.OUTLINE}
        onClick={toggleDealFileLock}
        icon={connection.isDealFileLocked ? LockIcon : LockUnlockedIcon}
      />
      {menu}
      {tooltip}
      {showForm && <AdvanceConnectionPhaseForm onClose={closeForm} connection={connection} />}
    </>
  );
};

ConnectionActions.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    phase: PropTypes.string,
    isDealFileLocked: PropTypes.bool,
    apiType: PropTypes.string,
  }).isRequired,
};

export default ConnectionActions;
