function groupBy(collection, groupFn) {
  let grouped = {};
  const useArray = Array.isArray(collection);
  for (const [key, value] of Object.entries(collection)) {
    let groupKey;
    if (typeof groupFn === "string") {
      groupKey = value[groupFn];
    } else {
      groupKey = groupFn(value);
    }
    if (useArray) {
      if (groupKey in grouped) {
        grouped[groupKey].push(value);
      } else {
        grouped[groupKey] = [value];
      }
    } else {
      if (groupKey in grouped) {
        grouped[groupKey][key] = value;
      } else {
        grouped[groupKey] = { [key]: value };
      }
    }
  }
  return grouped;
}

export default groupBy;
