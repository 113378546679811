import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-final-form";
import styled from "styled-components";

import { body1, FontColors, ColorPalette, PaperclipIcon } from "yuka";

import { required } from "utils/form/inputValidations";
import { Input, InputRow } from "forms/FormComponents";
import useFileDragAndDrop from "utils/hooks/form/useFileDragAndDrop";

import DocumentDetailsFieldSet from "./DocumentDetailsFieldSet";

const StyledInputRow = styled(InputRow)`
  border-bottom: 1px solid ${ColorPalette.white30};
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const StyledInput = styled(Input)`
  ${props =>
    props.$hidden
      ? `
    display: none;
  `
      : ""}
`;

const StyledDropText = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${ColorPalette.white50};
  width: 100%;
  box-sizing: border-box;
`;

const StyledSubtext = styled.div`
  ${body1}
  ${FontColors.theme50}
  margin-bottom: 16px;
`;

const StyledCurrentDocumentSpan = styled.span`
  ${FontColors.theme50}
  margin-right: 28px;
`;

/**
 * Renders the fields for the create new document and upload new document form
 */
const DocumentUploadFieldSet = ({ allowUpload, existingDocument, documentTypeOptions }) => {
  const { change } = useForm();

  const handleUpload = files => {
    change("file", files[0]);
  };

  const [containerRef, fileInputRef, isDragging] = useFileDragAndDrop({
    multiple: false,
    onChange: handleUpload,
  });

  const selectableFile =
    !existingDocument || allowUpload ? (
      <>
        {isDragging && <StyledDropText>Drop your file here</StyledDropText>}
        <StyledInput
          $hidden={isDragging}
          type="file"
          ref={fileInputRef}
          name="file"
          title={existingDocument ? "New Document" : "Selected File"}
          validate={[required]}
        />
      </>
    ) : null;

  const existingFile = existingDocument && (
    <div>
      {allowUpload && <StyledCurrentDocumentSpan>Current Document</StyledCurrentDocumentSpan>}
      <PaperclipIcon />
      <span>&nbsp;{existingDocument.name}</span>
    </div>
  );
  return (
    <div ref={containerRef}>
      {allowUpload ? (
        <>
          {existingDocument && (
            <StyledSubtext>
              Uploading a new document will automatically archive the existing document. The
              original document can still be accessed. The document will be updated in all places it
              has been synced to across the site (Except within approved deal files).
            </StyledSubtext>
          )}
          <InputRow>{existingFile}</InputRow>
          <InputRow>{selectableFile}</InputRow>
          <DocumentDetailsFieldSet documentTypeOptions={documentTypeOptions} />
        </>
      ) : (
        <>
          <StyledInputRow>
            {existingFile}
            {selectableFile}
          </StyledInputRow>
          <DocumentDetailsFieldSet documentTypeOptions={documentTypeOptions} />
        </>
      )}
    </div>
  );
};

DocumentUploadFieldSet.propTypes = {
  allowUpload: PropTypes.bool.isRequired,
  existingDocument: PropTypes.shape({
    name: PropTypes.string,
  }),
  documentTypeOptions: PropTypes.string.isRequired,
};

export default DocumentUploadFieldSet;
