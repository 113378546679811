import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button, Card, CardStyles, PlusIcon, Table } from "yuka";

import { QUERY_KEYS, useFetch } from "api";
import CreateSPVForm from "forms/CreateSPVForm";

import { SPV_TYPE_MASTER, SPV_TYPE_SERIES, SPV_TYPE_CLASS, getInitialValues } from "../constants";
import { getColumns, useFundsTableConfig } from "./fundsTableConfig";

const StyledContainer = styled.div`
  height: 100%;
  min-height: 0;
`;

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

/**
 * Table of child spv or spv class profiles of a given spv.
 * @returns {JSX.Element}
 */
const FundsCard = ({ spv }) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const queryKey =
    spv.spvType === SPV_TYPE_MASTER
      ? QUERY_KEYS.SPV.list({ "filter[parent]": spv.apiId })
      : QUERY_KEYS.SPV_CLASSES.list({ "filter[spv]": spv.apiId });
  const { isError, isLoading, data } = useFetch(queryKey);

  const { sortState, onSort, getOnRowClick } = useFundsTableConfig();

  const onRowClick = getOnRowClick(spv);

  const columns = getColumns(spv.spvType);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  // Create new SPV button
  const createNewChild = (
    <Button leadingIcon={PlusIcon} onClick={() => setCreateModalOpen(true)}>
      {`New ${spv.spvType === SPV_TYPE_MASTER ? "Series" : "Class"}`}
    </Button>
  );

  const initialValues = getInitialValues(spv);

  return (
    <StyledCard
      title={spv.spvType === SPV_TYPE_MASTER ? "Series" : "Classes"}
      cardStyle={CardStyles.SECTIONED}
      headerTrailingContent={createNewChild}
    >
      <StyledContainer>
        <Table
          usePercentageColumnWidths
          emptyTablePlaceholder="No funds"
          sortState={sortState}
          onSort={onSort}
          onRowClick={onRowClick}
          data={data || []}
          columns={columns}
        />
        {createModalOpen && (
          <CreateSPVForm
            closeModal={() => setCreateModalOpen(false)}
            initialValues={initialValues}
            spvType={spv.spvType === SPV_TYPE_MASTER ? SPV_TYPE_SERIES : SPV_TYPE_CLASS}
          />
        )}
      </StyledContainer>
    </StyledCard>
  );
};

FundsCard.propTypes = {
  spv: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
    spvType: PropTypes.string,
  }).isRequired,
};

export default FundsCard;
