import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useForm, useFormState } from "react-final-form";
import { InputRow, FinalFormField, Button, DownloadIcon, RefreshIcon } from "yuka";

import { fetchQuery } from "api";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";
import { Input } from "forms/FormComponents";

import { CATEGORY_OPTIONS } from "../constants";

const StyledWrapper = styled(InputRow)`
  margin-bottom: 0;
`;

const StyledLoadingSpinner = styled.div`
  margin: auto 0 auto auto;
`;

const DealFileFilter = ({ connection, isLoading, selectedDocuments, title, queryKeys }) => {
  const { reset } = useForm();
  const { dirty } = useFormState();
  const [isDownloading, setIsDownloading] = useState(false);

  const bulkDownload = () => {
    setIsDownloading(true);
    let document_ids = [];
    selectedDocuments.map(doc => document_ids.push(doc.apiId));
    fetchQuery(
      queryKeys.list(connection.tradeId, { document_ids: document_ids }, ["download_zip_file"]),
      { rawData: true }
    ).then(queryData => {
      var element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;base64," + queryData);
      element.setAttribute("download", `${connection.tradeId} ${title}.zip`);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      setIsDownloading(false);
      mpTrack("bulk download of selected deal file documents", { "trade id": connection.tradeId });
    });
  };

  return (
    <StyledWrapper>
      <Input name="name" placeholder="File name" />
      <FinalFormField
        type="checkbox-dropdown"
        name="category"
        placeholder="All tags"
        options={CATEGORY_OPTIONS.map(value => ({ label: value, value }))}
      />
      <Button disabled={!dirty} leadingIcon={RefreshIcon} onClick={() => reset()}>
        Reset Filters
      </Button>
      {selectedDocuments.length > 0 && (
        <Button leadingIcon={DownloadIcon} onClick={bulkDownload}>
          Download selected
        </Button>
      )}
      {}
      {(isLoading || isDownloading) && (
        <StyledLoadingSpinner>
          <LoadingSpinner showContainer={false} />
        </StyledLoadingSpinner>
      )}
    </StyledWrapper>
  );
};

DealFileFilter.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  selectedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      file: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    list: PropTypes.func.isRequired,
  }).isRequired,
};

DealFileFilter.defaultProps = {
  isLoading: false,
};

export default DealFileFilter;
