import React, { useState } from "react";
import PropTypes from "prop-types";

import { IconButton, UploadIcon } from "yuka";

import { ConnectionDocumentsForm } from "forms/DocumentsForm";

/**
 * Allows users to upload a new version of the document
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const UploadCell = ({ connection, value: document, queryKeys }) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [allowUpload, setAllowUpload] = useState(false);
  const uploadAction = () => {
    setAllowUpload(true);
    setEditModalOpen(true);
  };
  const closeModal = () => {
    setAllowUpload(false);
    setEditModalOpen(false);
  };

  return (
    <div>
      {isEditModalOpen && (
        <ConnectionDocumentsForm
          queryKeys={queryKeys}
          existingDocument={document}
          allowUpload={allowUpload}
          closeModal={closeModal}
        />
      )}
      <IconButton onClick={uploadAction} icon={UploadIcon} disabled={connection.isDealFileLocked} />
    </div>
  );
};

UploadCell.propTypes = {
  value: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    detail: PropTypes.func.isRequired,
  }).isRequired,
  connection: PropTypes.shape({
    phase: PropTypes.string,
    isDealFileLocked: PropTypes.bool,
  }).isRequired,
};

export default UploadCell;
