import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FormModal } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import {
  SPV_TYPE_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_STANDALONE,
  SPV_TYPE_CLASS,
} from "utils/constants";
import LoadingSpinner from "utils/LoadingSpinner";

import { getTitle } from "./constants";
import { getWrappedOnSubmit } from "./onSubmit";
import MasterSPVFieldSet from "./MasterSPVFieldSet";
import SeriesSPVFieldSet from "./SeriesSPVFieldSet";
import SPVClassFieldSet from "./SPVClassFieldSet";
import StandaloneSPVFieldSet from "./StandaloneSPVFieldSet";

const StyledContainer = styled.div`
  width: 600px;
`;

const CreateSPVForm = ({ closeModal, initialValues, spvType }) => {
  const { onSubmit: spvOnSubmit } = useWrite(QUERY_KEYS.SPV.list());
  const { onSubmit: spvClassOnSubmit } = useWrite(QUERY_KEYS.SPV_CLASSES.list());
  const navigate = useNavigate();

  const wrappedOnSubmit = getWrappedOnSubmit(spvType, spvOnSubmit, spvClassOnSubmit, navigate);

  const title = getTitle(spvType);

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal onSubmit={handleSubmit} submitText="Create" onClose={closeModal} title={title}>
          {submitting && <LoadingSpinner />}
          <StyledContainer>
            {spvType === SPV_TYPE_MASTER && <MasterSPVFieldSet />}
            {spvType === SPV_TYPE_SERIES && <SeriesSPVFieldSet initialValues={initialValues} />}
            {spvType === SPV_TYPE_CLASS && <SPVClassFieldSet initialValues={initialValues} />}
            {spvType === SPV_TYPE_STANDALONE && <StandaloneSPVFieldSet />}
          </StyledContainer>
        </FormModal>
      )}
    </Form>
  );
};

CreateSPVForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    parent: PropTypes.string,
  }),
  spvType: PropTypes.string.isRequired,
};

export default CreateSPVForm;
