import {
  PHASE_CLOSED,
  PHASE_DISCONNECTED,
  STATUS_TYPE_STANDARD_LABEL,
  STATUS_TYPE_IN_PROGRESS_LABEL,
  STATUS_TYPE_BLOCKING_LABEL,
  STATUS_TYPE_NO_STATUS_LABEL,
} from "utils/constants";

import { TabNames, PHASES_LIVE, PHASES_SETTLED } from "../constants";

const TAB_PHASE_MAP = {
  [TabNames.live]: [PHASES_LIVE, PHASES_LIVE],
  [TabNames.settled]: [PHASES_SETTLED, PHASES_SETTLED],
  [TabNames.closed]: [[PHASE_CLOSED], []],
  [TabNames.disconnected]: [[PHASE_DISCONNECTED], []],
  [TabNames.all]: [
    undefined,
    [...PHASES_LIVE, ...PHASES_SETTLED, PHASE_CLOSED, PHASE_DISCONNECTED],
  ],
};

const INITIAL_SORT_STATE_MAP = {
  [TabNames.live]: { id: "tradeId", desc: false },
  [TabNames.settled]: { id: "tradeId", desc: false },
  [TabNames.closed]: { id: "tradeId", desc: true },
  [TabNames.disconnected]: { id: "disconnectedOn", desc: true },
  [TabNames.all]: { id: "tradeId", desc: false },
};

const STATUS_FILTER_OPTIONS = [
  STATUS_TYPE_STANDARD_LABEL,
  STATUS_TYPE_IN_PROGRESS_LABEL,
  STATUS_TYPE_BLOCKING_LABEL,
  STATUS_TYPE_NO_STATUS_LABEL,
];

const STATUS_FILTER_INITIAL_VALUES = [
  STATUS_TYPE_STANDARD_LABEL,
  STATUS_TYPE_IN_PROGRESS_LABEL,
  STATUS_TYPE_NO_STATUS_LABEL,
];

const getStatusFilterInitialValues = tabName => {
  switch (tabName) {
    case TabNames.live:
    case TabNames.settled:
    case TabNames.closed:
    case TabNames.disconnected:
      return STATUS_FILTER_INITIAL_VALUES;
    case TabNames.all:
      return STATUS_FILTER_OPTIONS;
    default:
      // Should never reach default
      return STATUS_FILTER_INITIAL_VALUES;
  }
};

export {
  TAB_PHASE_MAP,
  INITIAL_SORT_STATE_MAP,
  STATUS_FILTER_OPTIONS,
  getStatusFilterInitialValues,
};
