import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { ListItem, ListStyles, HyperLink } from "yuka";

import { useDelete, QUERY_KEYS } from "api";
import NoteContentForm from "forms/NoteContentForm";
import { newlineStringFormat } from "utils/displayFormatUtils";

/**
 * Renders a single connection note element.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const ConnectionNoteItem = props => {
  const { onSubmit } = useDelete(QUERY_KEYS.NOTES.detail(props.tradeId, props.note.apiId));
  const [isEditNoteModalOpen, setEditNoteModalOpen] = React.useState(false);
  const editAction = () => setEditNoteModalOpen(true);
  const deleteAction = () => {
    if (confirm(`Are you sure you want to delete this note?`)) {
      onSubmit();
    }
  };
  return (
    <ListItem
      text={newlineStringFormat(props.note.content)}
      listStyle={ListStyles.TWO_LINE}
      subtext={
        <>
          {props.note.authorFullName} |{" "}
          {DateTime.fromISO(props.note.createdOn).toLocaleString(DateTime.DATE_SHORT)} | &nbsp;
          <HyperLink onClick={editAction}>Edit</HyperLink>
          <span>&nbsp; | &nbsp;</span>
          <HyperLink onClick={deleteAction}>Delete</HyperLink>
        </>
      }
      trailingContent={
        isEditNoteModalOpen ? (
          <NoteContentForm
            queryKey={QUERY_KEYS.NOTES}
            note={props.note}
            apiId={props.tradeId}
            onClose={() => setEditNoteModalOpen(false)}
            isEdit
          />
        ) : null
      }
    />
  );
};

ConnectionNoteItem.propTypes = {
  note: PropTypes.shape({
    apiId: PropTypes.string,
    authorFullName: PropTypes.string,
    content: PropTypes.string,
    createdOn: PropTypes.string,
  }).isRequired,
  tradeId: PropTypes.string.isRequired,
};

export default ConnectionNoteItem;
