import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "react-final-form-arrays";
import { useForm } from "react-final-form";

import { StyledBody2 } from "yuka";

import { FormSection, FieldPrefixContext } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";
import { ROLE_BROKER, ROLE_CLIENT } from "utils/constants";
import { DataTypes } from "api";

import TicketParticipantField from "./TicketParticipantField";
import TicketParticipantPermissionField from "./TicketParticipantPermissionField";

const composeValidators = validators => value =>
  validators.reduce((error, validator) => error || validator(value), undefined); // eslint-disable-line no-undefined

const FieldArrayComponent = ({ fields, ...props }) => {
  const tempName = `${fields.name}Uncommitted`;
  const value = useFormValue(tempName);
  const { change } = useForm();

  const addValue = () => {
    fields.push({ ...value, role: props.role, apiType: DataTypes.SOURCES });
    change(tempName, {});
  };

  return (
    <React.Fragment>
      <FormSection name={tempName.split(".").pop()}>
        <TicketParticipantField add={addValue} {...props} />
      </FormSection>
      {fields.map((field, index) => (
        <FormSection key={field} name={field.toString().split(".").pop()}>
          <TicketParticipantPermissionField remove={() => fields.remove(index)} {...props} />
        </FormSection>
      ))}
    </React.Fragment>
  );
};

FieldArrayComponent.propTypes = {
  fields: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  role: PropTypes.oneOf([ROLE_BROKER, ROLE_CLIENT]).isRequired,
};

/**
 * `<TicketParticipantField />` wrapped in a redux-form `<FieldArray />`
 *
 * @param {object} props
 * @returns {Element}
 */
const TicketParticipantFieldArray = props => {
  const namePrefix = useContext(FieldPrefixContext);

  return (
    <React.Fragment>
      <StyledBody2>{props.sourceType}(s)</StyledBody2>
      <FieldArray
        {...props}
        validate={composeValidators(props.validate)}
        component={FieldArrayComponent}
        name={`${namePrefix}${props.name}`}
      />
    </React.Fragment>
  );
};

TicketParticipantFieldArray.propTypes = {
  sourceType: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func),
  name: PropTypes.string.isRequired,
};

TicketParticipantFieldArray.defaultProps = {
  validate: [],
};

export default TicketParticipantFieldArray;
