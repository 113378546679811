import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import { FinalFormField, FormModal, ModalStyles } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { Input, InputRow } from "forms/FormComponents";
import { SPV_TYPE_MASTER } from "utils/constants";
import { required } from "utils/form/inputValidations";
import LoadingSpinner from "utils/LoadingSpinner";

import { loadCompanyOptions } from "./loadCompanyOptions";

/**
 * A form to edit spv details
 *
 * @returns {Element}
 */
const EditSPVDetailsForm = ({ company, spv, onClose }) => {
  const { onSubmit } = useWrite(QUERY_KEYS.SPV.detail(spv.apiId));

  const wrappedOnSubmit = (values, ...params) => {
    onSubmit(values, ...params).then(() => {
      onClose();
    });
  };

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={{ ...spv, company }}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit SPV Details"
          modalStyle={ModalStyles.SCROLLABLE}
        >
          {submitting && <LoadingSpinner />}
          {spv.spvType !== SPV_TYPE_MASTER && (
            <InputRow>
              <FinalFormField
                type="autocomplete"
                loadOptions={loadCompanyOptions}
                name="company"
                placeholder="Issuing Company"
                label="Company"
                validate={[required]}
                options={[{ value: company, label: company.name }]}
              />
            </InputRow>
          )}
          <InputRow>
            <Input name="name" title="Name" placeholder="Enter SPV name" validate={[required]} />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

EditSPVDetailsForm.propTypes = {
  company: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  spv: PropTypes.shape({
    apiId: PropTypes.string,
    spvType: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditSPVDetailsForm;
