import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { getRelatedId } from "api";
import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";

import ZxRepCell from "ExecutionApp/ClientTableView/ZxRepCell";
import useTableSort from "utils/tables/useTableSort";

const TABLE_NAME = "TRADING_GROUPS";

const useTradingGroupsTableConfig = () => {
  const currentTableSort = useSelector(state => state?.tableSort[TABLE_NAME]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRowClick = useCallback(
    ({ row: { original: originalData } }) => {
      const sourceId = getRelatedId(originalData.source);
      if (sourceId) {
        navigate(`/clients/${sourceId}/`);
      }
    },
    [navigate]
  );

  const onSort = useTableSort(ACTIONS, dispatch, TABLE_NAME);
  const sortState = useMemo(() => {
    return currentTableSort && currentTableSort.length ? currentTableSort : [];
  }, [currentTableSort]);

  return {
    sortState,
    onRowClick,
    onSort,
  };
};

const NameColumn = {
  id: "name",
  accessor: "name",
  width: 75,
  header: "Name",
  sortable: true,
};

const ZxRepColumn = {
  id: "representative",
  accessor: sourceProfile => sourceProfile.zxRepresentatives,
  width: 25,
  header: "Assigned ZX Rep",
  cellRenderer: ZxRepCell,
};

const columns = [NameColumn, ZxRepColumn];

export { useTradingGroupsTableConfig, columns };
