import { Badge, BadgeStyles } from "yuka";
import PropTypes from "prop-types";

import {
  PHASE_CLOSED,
  PHASE_COMPANY_DECISION,
  PHASE_DEAL_FILE_REVIEW,
  PHASE_DISCONNECTED,
  PHASE_EXECUTION,
  PHASE_FEE_COLLECTION_AND_PAYMENT,
  PHASE_INVOICING,
  PHASE_SETTLEMENT,
} from "utils/constants";

const ConnectionPhaseBadge = ({ phase }) => {
  switch (phase) {
    case PHASE_EXECUTION:
      return <Badge badgeStyle={BadgeStyles.LIGHT_BLUE}>Execution</Badge>;
    case PHASE_COMPANY_DECISION:
      return <Badge badgeStyle={BadgeStyles.LIGHT_GRAY}>Company Decision</Badge>;
    case PHASE_SETTLEMENT:
      return <Badge badgeStyle={BadgeStyles.WHITE}>Settlement</Badge>;
    case PHASE_DEAL_FILE_REVIEW:
      return <Badge badgeStyle={BadgeStyles.MEDIUM_GRAY}>Deal File Review</Badge>;
    case PHASE_INVOICING:
      return <Badge badgeStyle={BadgeStyles.BUY_GREEN}>Invoicing</Badge>;
    case PHASE_FEE_COLLECTION_AND_PAYMENT:
      return <Badge badgeStyle={BadgeStyles.YELLOW}>Fee Collection & Payment</Badge>;
    case PHASE_CLOSED:
      return <Badge badgeStyle={BadgeStyles.DARK_GRAY}>Closed</Badge>;
    case PHASE_DISCONNECTED:
      return <Badge badgeStyle={BadgeStyles.DARK_GRAY}>Disconnected</Badge>;
    default:
      return phase; // default to no badge at all.
  }
};

ConnectionPhaseBadge.propTypes = {
  phase: PropTypes.string.isRequired,
};

export default ConnectionPhaseBadge;
