import React, { useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";

import { QUERY_KEYS, useWrite, DataTypes } from "api";
import { useTooltip, Field, Card, BellIcon, FontColors } from "yuka";
import EditTaskReminderForm from "forms/EditTaskReminderForm";

const StyledTaskItem = styled(Card)`
  margin-top: 12px;
  flex-direction: row;
  align-items: space-between;
  padding: 16px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 32px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const StyledLightSpan = styled.span`
  ${FontColors.theme50};
`;

const StyledBrightestSpan = styled.span`
  ${FontColors.theme100};
`;

const TaskItem = props => {
  const [editFormActive, setEditFormActive] = useState(false);
  const { onSubmit } = useWrite(QUERY_KEYS.TASKS.detail(props.task.apiId));

  const toggleTaskComplete = task => {
    const submitData = {
      apiType: DataTypes.TASK,
      apiId: props.task.apiId,
      resolvedOn: task.resolvedOn ? null : DateTime.fromJSDate(new Date()).toISO(),
    };
    onSubmit(submitData);
  };

  const editTask = () => {
    setEditFormActive(true);
  };

  const ref = React.useRef(null);
  const tooltip = useTooltip(
    ref,
    <>
      <StyledLightSpan>Reminder email set to go out on &nbsp;</StyledLightSpan>
      <StyledBrightestSpan>
        {DateTime.fromISO(props.task.sendReminderOn).toLocaleString(DateTime.DATE_SHORT)}
      </StyledBrightestSpan>
    </>
  );

  return (
    <>
      <StyledTaskItem onClick={editTask}>
        {!editFormActive && (
          <Field
            type="checkbox"
            checked={props.task.resolvedOn !== null}
            name={`resolvedOn-${props.task.apiId}`}
            label={props.task.text}
            onChange={() => {
              toggleTaskComplete(props.task);
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        )}
        <FlexBox>
          {props.task.sendReminderOn && (
            <>
              <BellIcon ref={ref} />
              {tooltip}
            </>
          )}
        </FlexBox>
      </StyledTaskItem>
      {editFormActive && (
        <EditTaskReminderForm onClose={() => setEditFormActive(false)} task={props.task} />
      )}
    </>
  );
};

TaskItem.propTypes = {
  task: PropTypes.shape({
    apiId: PropTypes.string,
    text: PropTypes.string,
    reminder_recipients: PropTypes.number,
    resolvedOn: PropTypes.string,
    sendReminderOn: PropTypes.string,
  }).isRequired,
};

export default TaskItem;
