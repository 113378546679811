import React from "react";

import { required } from "utils/form/inputValidations";

import { DISCONNECT_SOURCE_CHOICES } from "./constants";
import useDisconnectReasonOptions from "./useDisconnectReasonOptions";

import { RadioGroup, Select, InputRow } from "forms/FormComponents";

const DisconnectFieldSet = () => {
  const reasonOptions = useDisconnectReasonOptions();
  return (
    <>
      <InputRow>
        <RadioGroup
          items={DISCONNECT_SOURCE_CHOICES}
          name="disconnectSource"
          validate={[required]}
        />
      </InputRow>
      <InputRow>
        <Select
          name="disconnectReason"
          options={reasonOptions}
          placeholder="Select a reason for disconnecting"
          popup
          title="Reason"
          validate={[required]}
        />
      </InputRow>
    </>
  );
};

export default DisconnectFieldSet;
