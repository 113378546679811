import { isString } from "utils";

import roundDecimal from "./roundDecimal";

const ONE_THOUSAND = 1000;
const ONE_MILLION = 1000000;
const ONE_BILLION = 1000000000;
const ONE_TRILLION = 1000000000000;

/**
 * Convert a number into a readable string. this has some similarities to
 * ``convert_num_to_readable``
 *
 * @param {number|string} numRaw - the number to be converted
 * @param {number} decimalPlaces - optional argument
 * @returns {string} a readable string representation of num
 */
const convertNumToReadable = (numRaw, decimalPlaces = 2) => {
  const num = isString(numRaw) ? Number.parseFloat(numRaw) : numRaw;
  if (num === 0) {
    return "0";
  }

  if (!num || num === Number.POSITIVE_INFINITY || num === Number.NEGATIVE_INFINITY) {
    return "";
  }

  const abs = Math.abs(num);
  if (abs < ONE_THOUSAND) {
    return roundDecimal(num, decimalPlaces);
  }
  if (abs < ONE_MILLION) {
    return `${roundDecimal(num / ONE_THOUSAND, decimalPlaces)}K`;
  }
  if (abs < ONE_BILLION) {
    return `${roundDecimal(num / ONE_MILLION, decimalPlaces)}M`;
  }
  if (abs < ONE_TRILLION) {
    return `${roundDecimal(num / ONE_BILLION, decimalPlaces)}B`;
  }
  return `${roundDecimal(num / ONE_TRILLION, decimalPlaces)}T`;
};

export default convertNumToReadable;
