import React, { useContext } from "react";
import PropTypes from "prop-types";
import { InputRow, DatePicker } from "forms/FormComponents";
import { compact } from "utils";

import { getRelatedObj } from "api";
import { COMPANY_DECISION_APPROVED, COMMISSION_TYPE_NON_APPLICABLE } from "utils/constants";
import { beforeToday, required } from "utils/form/inputValidations";

import { RelationshipsContext } from "../../ExecutionApp/ConnectionProfileView";

const FeeCollectionAndPaymentFieldSet = ({ connection, buy, sell }) => {
  const relationships = useContext(RelationshipsContext);
  const buyCommission = buy.commission
    .map(commission => getRelatedObj(commission, relationships))
    .filter(commission => commission.commissionType != COMMISSION_TYPE_NON_APPLICABLE);
  const sellCommission = sell.commission
    .map(commission => getRelatedObj(commission, relationships))
    .filter(commission => commission.commissionType != COMMISSION_TYPE_NON_APPLICABLE);

  const showBuy = Boolean(
    Number(buy.commissionPerShare) || Number(buy.commissionPercent) || buyCommission.length
  );
  const showSell = Boolean(
    Number(sell.commissionPerShare) || Number(sell.commissionPercent) || sellCommission.length
  );
  const buyRequired = connection.companyDecision === COMPANY_DECISION_APPROVED;

  return (
    <>
      {showBuy && (
        <InputRow>
          <DatePicker
            maxDate={new Date()}
            name="buy.invoicedOn"
            placeholder="Buyer invoicing date"
            title="Buyer invoicing date"
            validate={compact([beforeToday, buyRequired && required])}
          />
        </InputRow>
      )}
      {showSell && (
        <InputRow>
          <DatePicker
            maxDate={new Date()}
            name="sell.invoicedOn"
            placeholder="Seller invoicing date"
            title="Seller invoicing date"
            validate={compact([beforeToday, required])}
          />
        </InputRow>
      )}
    </>
  );
};

FeeCollectionAndPaymentFieldSet.propTypes = {
  connection: PropTypes.shape({
    companyDecision: PropTypes.string,
  }).isRequired,
  buy: PropTypes.shape({
    commission: PropTypes.arrayOf(
      PropTypes.shape({
        commissionType: PropTypes.string,
      })
    ),
    commissionPerShare: PropTypes.string,
    commissionPercent: PropTypes.string,
  }).isRequired,
  sell: PropTypes.shape({
    commission: PropTypes.arrayOf(
      PropTypes.shape({
        commissionType: PropTypes.string,
      })
    ),
    commissionPerShare: PropTypes.string,
    commissionPercent: PropTypes.string,
  }).isRequired,
};

export default FeeCollectionAndPaymentFieldSet;
