import { FinalFormField } from "yuka";

import { fetchQuery, QUERY_KEYS } from "api";

const loadMoOptions = search =>
  fetchQuery(
    QUERY_KEYS.MARKET_OPERATORS.list({
      "filter[search]": search,
    })
  );

const MarketOperatorSelect = props => {
  return (
    <FinalFormField
      {...props}
      type="select"
      loadOptions={loadMoOptions}
      optionLabelKey="name"
      optionValueKey="apiId"
    />
  );
};

export default MarketOperatorSelect;
