import React from "react";
import PropTypes from "prop-types";
import { Fonts } from "yuka";

/**
 * Renders the type of the document, greyed out if the document is inherited from the parent
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const TypeCell = ({ value: type, row: { original: document } }) =>
  document.isFromParent ? <Fonts.Body1theme50>{type}</Fonts.Body1theme50> : type;

TypeCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      file: PropTypes.string.isRequired,
      isFromParent: PropTypes.bool.isRequired,
    }).isRequired,
  }),
};

export default TypeCell;
