import React, { forwardRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";

import { caption1, FontColors } from "../../Typography";

const Label = styled.label`
  ${caption1}
  ${props => (props.$error ? FontColors.sell : FontColors.theme50)}
  line-height: 18px;
  display: inline-block;
  order: -1;
`;

// Class name to retain inherited styles while migrating
const Wrapper = styled.div.attrs({ className: "flex-input" })`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${props =>
    props.$width
      ? `
    max-width: ${props.$width}px;
    width: ${props.$width}px;
  `
      : ""}
`;

const StyledFollowingText = styled.span`
  ${caption1}
  ${props => (props.$error ? FontColors.sell : FontColors.theme50)}
  line-height: 18px;
  display: flex;
  justify-content: space-between;
`;

const StyledTrailingNode = styled.span`
  display: block;
  justify-self: flex-end;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
`;

/**
 * Base component used to wrap an underlying field with a label and error text
 *
 * @param {object} props
 * @param {object} ref
 *
 * @returns {Element}
 */
const BlockValidatedField = forwardRef((props, ref) => {
  const subprops = _.omit(props, ["component", "className", "label", "input", "meta", "render"]);
  const Input = props.component;
  const showError = props.meta.touched && props.meta.invalid && !props.meta.active;
  const errorMessage = showError ? props.meta.error || props.meta.submitError : "";

  const helpText = errorMessage || props.helpText;

  return (
    <Wrapper $width={props.width} className={props.className}>
      <Input ref={ref} showError={showError} {...subprops} {...props.input} />
      {(helpText || props.trailingNode) && (
        <StyledFollowingText $error={Boolean(errorMessage)}>
          {<span>{helpText}</span>}
          {props.trailingNode && <StyledTrailingNode>{props.trailingNode}</StyledTrailingNode>}
        </StyledFollowingText>
      )}
      {props.label && (
        <Label $error={Boolean(errorMessage)} htmlFor={props.id || props.input.name}>
          {props.label}
        </Label>
      )}
    </Wrapper>
  );
});

BlockValidatedField.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  label: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    active: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
  }).isRequired,
  helpText: PropTypes.string,
  trailingNode: PropTypes.node,
  width: PropTypes.number,
};

BlockValidatedField.defaultProps = {
  errorMessage: "",
  label: "",
  className: "",
  input: null,
  helpText: "",
  trailingNode: null,
};

BlockValidatedField.displayName = "BlockValidatedField";

export default BlockValidatedField;
