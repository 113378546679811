import React from "react";
import PropTypes from "prop-types";
import { HyperLink, LinkStyles, LinkTypes } from "yuka";

/**
 * Renders the name of the file which the user can click to open the file in a new tab
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const PreviewCell = ({ value: name, row: { original: document } }) => (
  <HyperLink url={document.file} linkStyle={LinkStyles.UNSTYLED} linkType={LinkTypes.DOWNLOAD}>
    {name}
  </HyperLink>
);

PreviewCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      file: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default PreviewCell;
