import { useCallback } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";
import { FinalFormField } from "yuka";

import { Select, DatePicker, DelimitedNumberInput, InputRow } from "forms/FormComponents";

import { getRelatedId, fetchQuery, QUERY_KEYS } from "api";
import { compact } from "utils";
import {
  DIRECT,
  SPV,
  COMPANY_DECISION_ASSIGNED_ROFR,
  COMPANY_DECISION_EXERCISED_ROFR,
} from "utils/constants";
import {
  beforeToday,
  required,
  isPositive,
  isValidDecimal,
  isInteger,
  isNotNegative,
  maxValuePercent,
} from "utils/form/inputValidations";
import SecurityField from "forms/fields/SecurityField";

const StyledSelect = styled(Select)`
  // Aligns with other input that has title set
  margin-top: 19px;
`;

const DealFileReviewFieldSet = ({ connection }) => {
  const isDirect = connection.tradeType === DIRECT;
  const isSpv = connection.tradeType === SPV;

  const fetchTombstoneOptions = useCallback(
    search =>
      fetchQuery(
        QUERY_KEYS.SOURCES.list({
          "filter[isTombstoneAssignable]": true,
          "filter[search]": search,
        })
      ).then(sources =>
        sources.map(source => ({
          value: source.searchId,
          label: `${source.name} (${source.category || "Unknown"} #${source.searchId})`,
          ...source,
        }))
      ),
    []
  );

  return (
    <>
      <InputRow>
        <DatePicker
          maxDate={new Date()}
          name="settledOn"
          placeholder="Closing Date"
          validate={[beforeToday, required]}
        />
      </InputRow>
      <InputRow>
        <FinalFormField
          component={SecurityField}
          type="custom-block"
          name="security"
          validate={compact([isDirect && required])}
          companyId={getRelatedId(connection.company)}
        />
      </InputRow>
      <InputRow>
        <DelimitedNumberInput
          name="pricePerShare"
          validate={[required, isPositive, isValidDecimal]}
          placeholder="Price"
        />
        {!isSpv && (
          <DelimitedNumberInput
            name="shareQuantity"
            validate={[required, isPositive, isInteger]}
            placeholder="# of shares"
          />
        )}
      </InputRow>
      <InputRow>
        <DelimitedNumberInput
          name="totalValue"
          validate={[required, isPositive, isValidDecimal]}
          placeholder="Total value"
        />
      </InputRow>
      {isDirect &&
        (connection.companyDecision === COMPANY_DECISION_ASSIGNED_ROFR ||
          connection.companyDecision === COMPANY_DECISION_EXERCISED_ROFR) && (
          <InputRow>
            <DelimitedNumberInput
              name="rofrPercent"
              validate={[required, isNotNegative, maxValuePercent, isValidDecimal]}
              placeholder="ROFR %"
            />
          </InputRow>
        )}
      <InputRow>
        <Select
          title="Tombstones"
          name="tombstoneBuyers"
          multiple
          placeholder="Buy-side Trading Group (optional)"
          loadOptions={fetchTombstoneOptions}
          popup
        />
        <StyledSelect
          name="tombstoneSellers"
          multiple
          placeholder="Sell-side Trading Group (optional)"
          loadOptions={fetchTombstoneOptions}
          popup
        />
      </InputRow>
    </>
  );
};

DealFileReviewFieldSet.propTypes = {
  connection: PropTypes.shape({
    tradeType: PropTypes.string,
    company: PropTypes.array.isRequired,
    companyDecision: PropTypes.string,
  }).isRequired,
};

const cleanSubmitValues = values => {
  const DATETIME_FIELDS = ["settledOn"];
  Object.keys(values).map(field => {
    if (DATETIME_FIELDS.includes(field)) {
      if (values[field] !== null && typeof values[field] !== "string") {
        values[field] = DateTime.fromJSDate(values[field]).toISO();
      }
    }
  });
  if ("rofrPercent" in values) {
    values.rofrValue = (values.totalValue * values.rofrPercent).toFixed(6);
    delete values.rofrPercent;
  }
};

export default DealFileReviewFieldSet;
export { cleanSubmitValues };
