import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-final-form";
import { StandardWizardActions } from "yuka";

import { FrontendError, Loading } from "templates/ErrorPages";
import { DataTypes, QUERY_KEYS, getRelatedObj, useFetch, useWrite } from "api";
import { FinalFormWizard } from "xeYuka";

import ClientInfo from "./ClientInfo";
import Documents from "./Documents";
import DocumentDetails from "./DocumentDetails";
import OnboardingReviewAndSend from "./ReviewAndSend";

const RequestClientOnboardingView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryParams = {
    include: "source",
  };
  const {
    isError,
    isLoading,
    data: clientProfile,
    relationships,
  } = useFetch(QUERY_KEYS.CLIENT_PROFILES.detail(id, [], queryParams));
  const { onSubmit } = useWrite(QUERY_KEYS.ONBOARDING_REQUEST.list());
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <FrontendError />;
  }
  const steps = [
    {
      name: "Client Info",
      leftContent: (
        <>
          <ClientInfo clientProfile={clientProfile} />
          <StandardWizardActions />
        </>
      ),
    },
    {
      name: "Documents",
      leftContent: (
        <>
          <Documents />
          <StandardWizardActions />
        </>
      ),
    },
    {
      name: "Details",
      leftContent: (
        <>
          <DocumentDetails />
          <StandardWizardActions />
        </>
      ),
    },
    {
      name: "Review & Send",
      leftContent: (
        <>
          <OnboardingReviewAndSend />
          <StandardWizardActions />
        </>
      ),
    },
  ];
  const source = getRelatedObj(clientProfile.source, relationships);
  const wrappedOnSubmit = (values, ...params) => {
    const cleanedValues = {
      ...values,
      zxRepresentatives: values.zxRepresentatives?.map(id => ({
        apiId: id,
        apiType: DataTypes.MARKET_OPERATORS,
      })),
    };
    return onSubmit(cleanedValues, ...params).then(() => {
      navigate(-1);
    });
  };
  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        apiType: DataTypes.ONBOARDING_REQUEST,
        name: source.name,
        email: clientProfile.email,
        sourceProfile: clientProfile,
      }}
    >
      {() => (
        <FinalFormWizard
          submitText="Review and Send"
          title="Onboard Buyer or Seller"
          requireDirtyForSubmit={false}
          steps={steps}
        />
      )}
    </Form>
  );
};

export default RequestClientOnboardingView;
