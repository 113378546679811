import { PHASE_INVOICING, PHASE_DEAL_FILE_REVIEW } from "utils/constants";

import ConnectionPhaseBadge from "./ConnectionPhaseBadge";

/**
 * Deal file is frozen once we are past deal file review. This prevents removing or editing
 * existing deal file documents, but upload is still permitted for some edge cases
 */
const canDownloadInvoice = connection =>
  [PHASE_INVOICING, PHASE_DEAL_FILE_REVIEW].includes(connection.phase);

export { ConnectionPhaseBadge, canDownloadInvoice };
