import PropTypes from "prop-types";
import { Badge, BadgeStyles } from "yuka";

import {
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
} from "../../ExecutionApp/ClientProfileView/constants";

const KYCStatusBadge = ({ kycStatus }) => {
  switch (kycStatus) {
    case KYC_APPROVED:
      return <Badge badgeStyle={BadgeStyles.BUY_GREEN}>KYC {kycStatus}</Badge>;
    case KYC_EXPIRED:
      return <Badge badgeStyle={BadgeStyles.SELL_RED}>KYC {kycStatus}</Badge>;
    case KYC_INCOMPLETE:
      return <Badge badgeStyle={BadgeStyles.YELLOW}>KYC {kycStatus}</Badge>;
    case KYC_REVIEW:
      return <Badge badgeStyle={BadgeStyles.YELLOW}>KYC {kycStatus}</Badge>;
    default:
      return kycStatus;
  }
};

KYCStatusBadge.propTypes = {
  kycStatus: PropTypes.oneOf([KYC_APPROVED, KYC_EXPIRED, KYC_INCOMPLETE, KYC_REVIEW]).isRequired,
};

export default KYCStatusBadge;
