import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { DataTypes, useInfiniteFetch, useWrite, QUERY_KEYS } from "api";
import { FinalFormWizard } from "xeYuka";

import InvestorListForm from "./InvestorListForm";
import AttachmentsFieldSet from "./AttachmentsFieldSet";
import EmailTemplateFieldSet from "./EmailTemplateFieldSet";

const RequestSPVOnboardingView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // Track which documents are exclude to avoid adding them again when we refetch
  const [excludedDocuments, setExcludedDocuments] = useState([]);

  const { data: documents } = useInfiniteFetch(QUERY_KEYS.SPV_CLASS_DOCUMENTS.list(id));

  const { onSubmit } = useWrite(QUERY_KEYS.SPV_INVITE.list());

  const wrappedOnSubmit = (values, ...params) => {
    // Remove files from values since it's only used to upload documents
    if (values.files) {
      delete values.files;
    }
    const cleanedValues = {
      ...values,
      documents: values.documents.filter(doc => !excludedDocuments.includes(doc.apiId)),
      invites: values.invites.map(invite => {
        const marketOperator = invite.marketOperator?.apiId;
        return {
          ...invite,
          marketOperator: marketOperator
            ? { apiId: marketOperator, apiType: DataTypes.MARKET_OPERATORS }
            : undefined,
        };
      }),
    };
    return onSubmit(cleanedValues, ...params).then(() => {
      navigate(-1);
    });
  };

  const onRemoveDocument = doc => {
    setExcludedDocuments([...excludedDocuments, doc.apiId]);
  };

  const steps = [
    {
      name: "Select Receipients",
      leftContent: <InvestorListForm />,
    },
    {
      name: "Review and Send",
      leftContent: <EmailTemplateFieldSet />,
      rightContent: (
        <AttachmentsFieldSet
          excludedDocuments={excludedDocuments}
          onRemoveDocument={onRemoveDocument}
          spvClassId={id}
        />
      ),
    },
  ];

  return (
    <Form
      onSubmit={wrappedOnSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={{
        documents,
        spvClass: { apiId: id, apiType: DataTypes.SPV_CLASS },
        apiType: DataTypes.SPV_INVITE,
      }}
      keepDirtyOnReinitialize
    >
      {() => (
        <FinalFormWizard
          submitText="Review and Send"
          title="Onboard Investor to Zanbato Managed SPV"
          steps={steps}
          initialStep={1}
        />
      )}
    </Form>
  );
};

export default RequestSPVOnboardingView;
