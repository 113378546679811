import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { uniqWith } from "lodash-es";

import { isEqual } from "utils";
import { ROLE_CLIENT, ROLE_BROKER } from "utils/constants";

import TicketParticipantFieldArray from "./TicketParticipantFieldArray";

// check that every participant in the field array is unique by comparing length of
// field array to the length of field array with only unique participants
const noDuplicateSource = [
  value =>
    uniqWith(value, isEqual)?.length < value?.length
      ? "Duplicate participant"
      : // eslint-disable-next-line no-undefined
        undefined,
];

const StyledParticipantList = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const StyledBrokerWrapper = styled.div`
  // Aligns definition value so it matches the TicketParticipantPermissionField width
  margin-right: 34px;
`;

/**
 * renders two SourceCategorizationFieldSet, one for broker participants and one for
 * client participants along with some validation logic that ensures that there are no
 * duplicate sources
 * There is also an optional prop `required` that when false allows the fields to be valid
 * even if there is no participant. By default it is true.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const TicketParticipantFieldSet = props => (
  <>
    <StyledParticipantList>
      <TicketParticipantFieldArray
        name="brokerParticipants"
        sourceType={props.brokerSourceType}
        popup={props.popup}
        validate={noDuplicateSource}
        role={ROLE_BROKER}
      />
      <StyledBrokerWrapper>{props.children}</StyledBrokerWrapper>
    </StyledParticipantList>
    <StyledParticipantList>
      <TicketParticipantFieldArray
        name="clientParticipants"
        sourceType={props.clientSourceType}
        popup={props.popup}
        validate={noDuplicateSource}
        role={ROLE_CLIENT}
      />
    </StyledParticipantList>
  </>
);

TicketParticipantFieldSet.propTypes = {
  brokerSourceType: PropTypes.string.isRequired,
  clientSourceType: PropTypes.string.isRequired,
  popup: PropTypes.bool,
  children: PropTypes.node,
};

TicketParticipantFieldSet.defaultProps = {
  popup: true,
  children: null,
};

export default TicketParticipantFieldSet;
