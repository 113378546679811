import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  ColorPalette,
  FontColors,
  HyperLink,
  LinkTypes,
  StyledCaption2,
  StyledHeadline1,
  useAnchoredMenu,
} from "yuka";

import { QUERY_KEYS, useFetch, useWrite, DataTypes } from "api";
import { KYCStatusBadge } from "utils/clientProfiles";

import { KYC_OPTIONS } from "./constants";
import OnboardingRequestInfo from "./OnboardingRequestInfo";

const StyledHeader = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${ColorPalette.white15};
  flex-direction: column;
`;

const StyledHeaderFirstRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  > :first-child {
    margin-right: 4px;
  }
`;

const StyledBrightSpan = styled.span`
  ${FontColors.theme80};
`;

const ClientProfileHeader = ({
  source,
  clientProfile,
  id,
  latestOnboardingRequest,
  representatives,
}) => {
  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_PROFILES.detail(id));
  const updateKycStatus = useCallback(
    kycStatus =>
      onSubmit({
        apiType: DataTypes.CLIENT_PROFILES,
        apiId: id,
        kycStatus,
      }),
    [id, onSubmit]
  );

  const menuItems = useMemo(
    () =>
      KYC_OPTIONS.map(({ label, value }) => ({
        text: label,
        onClick: () => updateKycStatus(value),
      })),
    [updateKycStatus]
  );

  const [statusMenu, statusMenuRef, showStatusMenu] = useAnchoredMenu({ menuItems });

  const navigate = useNavigate();

  const {
    isError,
    isLoading,
    data: sourceData,
  } = useFetch(
    QUERY_KEYS.SOURCES.list({
      "filter[search_id]": clientProfile.parent ? clientProfile.parent : [],
    }),
    { enabled: !!clientProfile.parent }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  const parent = clientProfile.parent ? sourceData[0] : null;

  const kycBadge = clientProfile.kycStatus ? (
    <KYCStatusBadge kycStatus={clientProfile.kycStatus} />
  ) : null;

  return (
    <>
      <StyledCaption2>
        <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
          Clients
        </HyperLink>
        <span> / {source.name}</span>
      </StyledCaption2>
      <StyledHeader>
        <StyledHeaderFirstRow>
          <StyledHeadline1>{source.name}</StyledHeadline1>
          {kycBadge}
          <StyledCaption2>
            {" | "}
            <HyperLink onClick={showStatusMenu} ref={statusMenuRef}>
              Update Status
            </HyperLink>
            {statusMenu}
          </StyledCaption2>
          <OnboardingRequestInfo id={id} latestOnboardingRequest={latestOnboardingRequest} />
        </StyledHeaderFirstRow>
        <span>
          <StyledCaption2>
            <StyledBrightSpan>{clientProfile.sourceType}</StyledBrightSpan>
            {" | Phone: "}
            <StyledBrightSpan>{clientProfile.phone || "--"}</StyledBrightSpan>
            {" | Email: "}
            <StyledBrightSpan>{clientProfile.email || "--"}</StyledBrightSpan>
            {" | Parent Organization: "}
            {parent ? (
              <HyperLink onClick={() => navigate(`/clients/${parent.apiId}/`)}>
                {parent.name}
              </HyperLink>
            ) : (
              <StyledBrightSpan>None</StyledBrightSpan>
            )}
            {" | ZX Reps: "}
            <StyledBrightSpan>
              {representatives.length > 0
                ? representatives.map(rep => rep.name).join(", ")
                : "None"}
            </StyledBrightSpan>
          </StyledCaption2>
        </span>
      </StyledHeader>
    </>
  );
};

ClientProfileHeader.propTypes = {
  source: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  clientProfile: PropTypes.shape({
    kycStatus: PropTypes.string,
    sourceType: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    parent: PropTypes.number,
    lastOnboardingRequestSentOn: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  latestOnboardingRequest: PropTypes.shape({
    id: PropTypes.string,
    createdOn: PropTypes.string,
  }),
  representatives: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default ClientProfileHeader;
