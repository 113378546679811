import EditConnectionForm from "./component";

export {
  QUERY_PARAM,
  DETAILS_TAB,
  EXECUTION_TAB,
  BUY_TAB,
  SELL_TAB,
  BUY_COMMISSION_TAB,
  SELL_COMMISSION_TAB,
} from "./constants";
export default EditConnectionForm;
