import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import { FormModal, ModalStyles } from "yuka";

import { fetchQuery, useWrite, QUERY_KEYS } from "api";
import { DataTypes } from "api/constants";

import { Input, InputRow, Select } from "forms/FormComponents";
import LoadingSpinner from "utils/LoadingSpinner";
import { required } from "utils/form/inputValidations";

import { SOURCE_TYPE_OPTIONS } from "./constants";

/**
 * A form to edit client details
 *
 * @returns {Element}
 */
const EditClientDetailsForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_PROFILES.detail(props.source.apiId));

  const wrappedOnSubmit = (values, ...params) => {
    onSubmit(values, ...params).then(() => {
      props.onClose();
    });
  };

  const loadParents = React.useCallback(
    search =>
      fetchQuery(QUERY_KEYS.SOURCES.list({ "filter[search]": search })).then(sources =>
        sources.map(source => ({
          value: source.searchId,
          label: `${source.name} (${source.category || "Unknown"} #${source.searchId})`,
          ...source,
        }))
      ),
    []
  );

  const loadParent = React.useCallback(
    value =>
      fetchQuery(QUERY_KEYS.SOURCES.list({ "filter[searchId]": value })).then(
        sources =>
          sources.map(source => ({
            value: source.searchId,
            label: `${source.name} (${source.category || "Unknown"} #${source.searchId})`,
            ...source,
          }))[0]
      ),
    []
  );

  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        ...props.sourceProfile,
        apiType: DataTypes.CLIENT_PROFILES,
        apiId: props.source.apiId,
      }}
    >
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={props.onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit Client Details"
          modalStyle={ModalStyles.SCROLLABLE}
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <Select
              name="sourceType"
              options={SOURCE_TYPE_OPTIONS}
              placeholder="Select client type"
              popup
              title="Client Type"
              validate={[required]}
            />
          </InputRow>
          <InputRow>
            <Select
              name="parent"
              title="Source Parent (optional)"
              placeholder="Name of source parent"
              loadOptions={loadParents}
              loadOptionFromValue={loadParent}
              popup
            />
          </InputRow>
          <InputRow>
            <Input name="phone" title="Phone (optional)" placeholder="(555) 123-4567" />
          </InputRow>
          <InputRow>
            <Input name="email" title="Email (optional)" placeholder="e.g. name@company.com" />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

EditClientDetailsForm.propTypes = {
  sourceProfile: PropTypes.shape({
    apiId: PropTypes.string,
    sourceType: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditClientDetailsForm;
