import React from "react";
import PropTypes from "prop-types";

import { TextArea } from "forms/FormComponents";

const NoteContentField = props => (
  <TextArea name="content" placeholder="Add a note" title={props.title} />
);

NoteContentField.propTypes = {
  title: PropTypes.string.isRequired,
};

export default NoteContentField;
