/* eslint-disable */
/* global navigator */
/**
 * Ported from mixpanel-browser library.
 * Adapted to only port the necessary values
 */
import { isString } from "utils";

const userAgent = navigator.userAgent;

const stripEmptyProperties = p => {
  const ret = {};
  Object.entries(p).forEach(([k, v]) => {
    if (isString(v) && v.length > 0) {
      ret[k] = v;
    }
  });
  return ret;
};

/**
 * This function detects which browser is running this script.
 * The order of the checks are important since many user agents
 * include key words used in later checks.
 */
function _browser(user_agent, vendor, opera) {
  vendor = vendor || ""; // vendor is undefined for at least IE9
  if (!user_agent) {
    return "";
  } else if (opera || user_agent.includes(" OPR/")) {
    if (user_agent.includes("Mini")) {
      return "Opera Mini";
    }
    return "Opera";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return "BlackBerry";
  } else if (user_agent.includes("IEMobile") || user_agent.includes("WPDesktop")) {
    return "Internet Explorer Mobile";
  } else if (user_agent.includes("SamsungBrowser/")) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return "Samsung Internet";
  } else if (user_agent.includes("Edge") || user_agent.includes("Edg/")) {
    return "Microsoft Edge";
  } else if (user_agent.includes("FBIOS")) {
    return "Facebook Mobile";
  } else if (user_agent.includes("Chrome")) {
    return "Chrome";
  } else if (user_agent.includes("CriOS")) {
    return "Chrome iOS";
  } else if (user_agent.includes("UCWEB") || user_agent.includes("UCBrowser")) {
    return "UC Browser";
  } else if (user_agent.includes("FxiOS")) {
    return "Firefox iOS";
  } else if (includes(vendor, "Apple")) {
    if (user_agent.includes("Mobile")) {
      return "Mobile Safari";
    }
    return "Safari";
  } else if (user_agent.includes("Android")) {
    return "Android Mobile";
  } else if (user_agent.includes("Konqueror")) {
    return "Konqueror";
  } else if (user_agent.includes("Firefox")) {
    return "Firefox";
  } else if (user_agent.includes("MSIE") || user_agent.includes("Trident/")) {
    return "Internet Explorer";
  } else if (user_agent.includes("Gecko")) {
    return "Mozilla";
  } else {
    return "";
  }
}

/**
 * This function detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 */
function _browserVersion(userAgent, vendor, opera) {
  const browser = _browser(userAgent, vendor, opera);
  const versionRegexs = {
    "Internet Explorer Mobile": /rv:(\d+(\.\d+)?)/,
    "Microsoft Edge": /Edge?\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    "Chrome iOS": /CriOS\/(\d+(\.\d+)?)/,
    "UC Browser": /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    "Mobile Safari": /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    "Firefox iOS": /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    "Android Mobile": /android\s(\d+(\.\d+)?)/,
    "Samsung Internet": /SamsungBrowser\/(\d+(\.\d+)?)/,
    "Internet Explorer": /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  };
  var regex = versionRegexs[browser];
  if (regex === undefined) {
    return null;
  }
  var matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
}

function _os() {
  var a = userAgent;
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return "Windows Phone";
    }
    return "Windows";
  } else if (/(iPhone|iPad|iPod)/.test(a)) {
    return "iOS";
  } else if (/Android/.test(a)) {
    return "Android";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return "BlackBerry";
  } else if (/Mac/i.test(a)) {
    return "Mac OS X";
  } else if (/Linux/.test(a)) {
    return "Linux";
  } else if (/CrOS/.test(a)) {
    return "Chrome OS";
  } else {
    return "";
  }
}

function _device(user_agent) {
  if (/Windows Phone/i.test(user_agent) || /WPDesktop/.test(user_agent)) {
    return "Windows Phone";
  } else if (/iPad/.test(user_agent)) {
    return "iPad";
  } else if (/iPod/.test(user_agent)) {
    return "iPod Touch";
  } else if (/iPhone/.test(user_agent)) {
    return "iPhone";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return "BlackBerry";
  } else if (/Android/.test(user_agent)) {
    return "Android";
  } else {
    return "";
  }
}

const properties = () => ({
  ...stripEmptyProperties({
    $os: _os(),
    $browser: _browser(userAgent, navigator.vendor, window.opera),
    $referrer: document.referrer,
    $device: _device(userAgent),
  }),
  $current_url: window.location.href,
  $browser_version: _browserVersion(userAgent, navigator.vendor, window.opera),
  $screen_height: window.screen.height,
  $screen_width: window.screen.width,
  time: Date.now() / 1000, // epoch time in seconds
});

const peopleProperties = () => ({
  ...stripEmptyProperties({
    $os: _os(),
    $browser: _browser(userAgent, navigator.vendor, window.opera),
  }),
  $browser_version: _browserVersion(userAgent, navigator.vendor, window.opera),
});

export { properties, peopleProperties };
