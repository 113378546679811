import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Button, body1, FontColors } from "yuka";

import { DataTypes, useWrite, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";
import CreateConnectionTaskForm from "forms/CreateConnectionTaskForm";

import KYCChecklistTaskItem from "./KYCChecklistTaskItem";

const StyledPlaceholderText = styled.span`
  ${body1}
  ${FontColors.theme30}
`;

const StyledContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 48px 0;
`;

const KYCChecklistTaskSection = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { onSubmit } = useWrite(
    QUERY_KEYS.CONNECTIONS.detail(props.connection.tradeId, ["generate_kyc_checklist"])
  );
  const generateChecklistItems = phase => {
    if (confirm(`Are you sure you want to create a ${phase} checklist?`)) {
      const values = {
        apiType: DataTypes.CONNECTIONS,
        apiId: props.connection.apiId,
        tradeId: props.connection.tradeId,
        kycType: phase,
      };
      setIsLoading(true);
      onSubmit(values).then(() => {
        mpTrack("KYC Checklist Generated", { phase: phase });
        queryClient.invalidateQueries(
          QUERY_KEYS.TASKS.list({ "filter[trade_id]": props.connection.tradeId })
        );
        setIsLoading(false);
      });
    }
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {props.tasks.length ? (
        <>
          {props.tasks.map(task => (
            <KYCChecklistTaskItem key={task.apiId} task={task} />
          ))}
          <CreateConnectionTaskForm connection={props.connection} phase={props.phase} />
        </>
      ) : (
        <StyledContainer>
          <StyledPlaceholderText>No {props.phase} Checklist</StyledPlaceholderText>
          <Button onClick={() => generateChecklistItems(props.phase)}>Create</Button>
        </StyledContainer>
      )}
    </>
  );
};

KYCChecklistTaskSection.propTypes = {
  connection: PropTypes.shape({
    apiId: PropTypes.string,
    tradeId: PropTypes.string,
  }),
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      connection: PropTypes.arrayOf(PropTypes.string),
      phase: PropTypes.string,
    })
  ).isRequired,
  phase: PropTypes.string.isRequired,
};

export default KYCChecklistTaskSection;
