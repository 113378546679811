import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useFetch } from "api";
import NoteContentForm from "forms/NoteContentForm";
import { Button, Card, CardStyles, List } from "yuka";

import NoteItem from "./NoteItem";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

/**
 * Renders a generic notes card
 *
 * @param {object} props
 * @returns {React.Element}
 */
const NotesCard = ({ queryKey, apiId }) => {
  const [isAddNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const addNote = () => setAddNoteModalOpen(true);
  const { isLoading, isError, data: notes } = useFetch(queryKey.list(apiId));
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  return (
    <StyledCard
      cardStyle={CardStyles.TITLE}
      title="Notes"
      linkText="Add New"
      linkProps={{ onClick: addNote, as: Button }}
    >
      {isAddNoteModalOpen && (
        <NoteContentForm
          queryKey={queryKey}
          apiId={apiId}
          onClose={() => setAddNoteModalOpen(false)}
        />
      )}
      {notes.length > 0 && (
        <List divider>
          {notes.map(note => (
            <NoteItem key={note.apiId} note={note} apiId={apiId} queryKey={queryKey} />
          ))}
        </List>
      )}
    </StyledCard>
  );
};

NotesCard.propTypes = {
  queryKey: PropTypes.shape({
    list: PropTypes.func.isRequired,
  }).isRequired,
  apiId: PropTypes.string.isRequired,
};

export default NotesCard;
