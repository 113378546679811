import styled from "styled-components";

import { ToastMessage, Z_INDEXES } from "yuka";

import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";

const StyledToastContainer = styled.div`
  z-index: ${Z_INDEXES.zIndexAriaModalSelect};
  position: fixed;
  bottom: 16px;
  left: 16px;
  width: 240px;
`;

const ToastList = () => {
  const currentMessage = useSelector(state => state.toasts[0]);
  const dispatch = useDispatch();
  const deque = () => dispatch({ type: ACTIONS.removeToast });
  return (
    <StyledToastContainer>
      {currentMessage && (
        <ToastMessage key={currentMessage} title={currentMessage} onClose={deque} />
      )}
    </StyledToastContainer>
  );
};

export default ToastList;
