import React from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { Card, CardStyles, List, ListItem, ListStyles, useCurrentTab } from "yuka";
import styled from "styled-components";

import EditClientInvoicingForm from "forms/EditClientInvoicingForm";
import { ClientProfileUtils } from "utils/clientProfiles/ClientProfileUtils";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const QUERY_PARAM = "edit-tab";

const InvoiceSettingsCard = ({ contacts, source, sourceProfile }) => {
  const editTab = useCurrentTab(QUERY_PARAM);
  const [searchParams, setSearchParams] = useSearchParams();
  const edit = () => {
    const newParams = {};
    // Convert URLSearchParams to plain object
    for (const [key, value] of searchParams) {
      newParams[key] = value;
    }
    newParams[QUERY_PARAM] = "General";
    setSearchParams(newParams);
  };
  return (
    <StyledCard
      cardStyle={CardStyles.SECTIONED}
      title="Invoice Settings"
      linkText="Edit"
      linkProps={{ onClick: edit }}
    >
      <List divider>
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={sourceProfile.partySuffix || "--"}
          subtext="Party Suffix"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={sourceProfile.defaultDueDate || "--"}
          subtext="Default Due Date"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={ClientProfileUtils.feeAgreementDisplay(sourceProfile) || "--"}
          subtext="Default Fee Agreement"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={
            contacts
              .filter(contact => contact.useInAttn)
              .map(contact => contact.name)
              .join(", ") || "--"
          }
          subtext="Default ATTN"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={
            contacts
              .filter(contact => contact.useInCc)
              .map(contact => contact.name)
              .join(", ") || "--"
          }
          subtext="Default CC"
        />
      </List>
      {editTab && (
        <EditClientInvoicingForm
          contacts={contacts}
          sourceProfile={sourceProfile}
          source={source}
        />
      )}
    </StyledCard>
  );
};

InvoiceSettingsCard.propTypes = {
  contacts: PropTypes.arrayOf({
    useInAttn: PropTypes.bool,
    useInCc: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  sourceProfile: PropTypes.shape({
    partySuffix: PropTypes.string,
    defaultDueDate: PropTypes.string,
  }).isRequired,
};

export default InvoiceSettingsCard;
