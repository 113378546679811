import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { FormModal } from "yuka";

import { useWrite, DataTypes } from "api";
import LoadingSpinner from "utils/LoadingSpinner";

import DocumentUploadFieldSet from "./DocumentUploadFieldSet";

/**
 * An all-purpose form for managing documents
 */
const DocumentsForm = ({
  queryBase,
  queryParams,
  type,
  closeModal,
  existingDocument,
  allowUpload,
  documentTypeOptions,
}) => {
  const queryKey = existingDocument
    ? queryBase.detail(...queryParams, existingDocument.apiId)
    : queryBase.list(...queryParams);

  const { onSubmit } = useWrite(queryKey);

  const wrappedOnSubmit = values => {
    const cleanedValues = {
      ...values,
      apiType: DataTypes.EXPIRABLE_DOCUMENT,
    };
    return onSubmit(cleanedValues).then(() => {
      closeModal();
    });
  };

  let header;
  let submitText = "Upload";
  if (!existingDocument) {
    header = `Upload New ${type} Document`;
  } else if (allowUpload) {
    header = `Update ${type} Document`;
  } else {
    header = `Edit ${type} Document`;
    submitText = "Save";
  }

  const initialValues = useMemo(() => {
    let values = {
      ...existingDocument,
    };
    delete values.file;
    delete values.latestVersion;
    delete values.latestVersionOrSelf;
    delete values.category;
    delete values.updatedOn;
    return values;
  }, [existingDocument]);

  return (
    <Form
      onSubmit={wrappedOnSubmit}
      mutators={{ arrayMutators }}
      initialValues={{ ...initialValues }}
    >
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={closeModal}
          onSubmit={handleSubmit}
          submitText={submitText}
          title={header}
        >
          {submitting && <LoadingSpinner />}
          <DocumentUploadFieldSet
            allowUpload={allowUpload}
            existingDocument={existingDocument}
            documentTypeOptions={documentTypeOptions}
          />
        </FormModal>
      )}
    </Form>
  );
};

DocumentsForm.propTypes = {
  queryBase: PropTypes.objectOf(PropTypes.func).isRequired,
  queryParams: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  existingDocument: PropTypes.object,
  allowUpload: PropTypes.bool,
  documentTypeOptions: PropTypes.string.isRequired,
};

DocumentsForm.defaultProps = {
  allowUpload: false,
};

export default DocumentsForm;
