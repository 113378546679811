import { useFormState } from "react-final-form";

/**
 * Hook to fetch if the current form is dirty (if we are within a form context)
 * A little convoluted in order to prevent a crash when we need to check dirty in a component
 * that may not be within form context. Specifically designed for `Modal`
 *
 * @returns {boolean}
 */
const useIsDirty = () => {
  const { dirty: isDirty } = useFormState({ subscription: { dirty: true } });

  return isDirty;
};

export default useIsDirty;
