import React from "react";

import { DelimitedNumberInput, InputRow } from "forms/FormComponents";
import { required, maxValueTicket, isPositive, isValidDecimal } from "utils/form/inputValidations";

/**
 * Renders the appropriate pricing fields for a connection
 *
 * @returns {React.Element}
 */
const ConnectionSPVFieldSet = () => (
  <InputRow>
    <DelimitedNumberInput
      name="pricePerShare"
      placeholder="E.g. 10.50"
      validate={[required, maxValueTicket, isPositive, isValidDecimal]}
      title="Price Per Share (USD)"
    />
    <DelimitedNumberInput
      name="totalValue"
      placeholder="E.g. 5,000,000 or 5M"
      validate={[required, isPositive, isValidDecimal]}
      title="Subscription Amount (USD)"
    />
  </InputRow>
);

export default ConnectionSPVFieldSet;
