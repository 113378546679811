import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, List, ListItem, ListStyles } from "yuka";
import styled from "styled-components";

import EditClientZXRepsForm from "forms/EditClientZXRepsForm";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const ZXRepSettingsCard = ({ representatives, source, sourceProfile }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const edit = () => setIsEditModalOpen(true);
  return (
    <StyledCard
      cardStyle={CardStyles.SECTIONED}
      title="ZX Represenative Settings"
      linkText="Edit"
      linkProps={{ onClick: edit }}
    >
      <List divider>
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={representatives.map(rep => rep.name).join(", ") || "--"}
          subtext="ZX Represenative(s)"
        />
      </List>
      {isEditModalOpen && (
        <EditClientZXRepsForm
          sourceProfile={sourceProfile}
          source={source}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </StyledCard>
  );
};

ZXRepSettingsCard.propTypes = {
  representatives: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  sourceProfile: PropTypes.shape({
    sourceType: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    parent: PropTypes.string,
  }).isRequired,
};

export default ZXRepSettingsCard;
