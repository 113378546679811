import React from "react";
import styled from "styled-components";

import { ColorPalette, XCloseIcon } from "yuka";

import { Input, DelimitedNumberInput, InputRow } from "forms/FormComponents";
import {
  required,
  maxValueTicket,
  isPositive,
  isValidDecimal,
  isInteger,
} from "utils/form/inputValidations";

const StyledIconWrapper = styled.div`
  margin: 32px 8px 0;
`;

/**
 * Renders the appropriate pricing fields for a connection
 *
 * @returns {React.Element}
 */
const ConnectionValueFieldSet = () => (
  <>
    <InputRow>
      <DelimitedNumberInput
        name="pricePerShare"
        placeholder="E.g. 10.50"
        validate={[required, maxValueTicket, isPositive, isValidDecimal]}
        title="Price Per Share (USD)"
      />
      <StyledIconWrapper>
        <XCloseIcon size={12} color={ColorPalette.lightWhite} />
      </StyledIconWrapper>
      <DelimitedNumberInput
        name="shareQuantity"
        placeholder="E.g. 5,000,000 or 5M"
        validate={[required, maxValueTicket, isPositive, isInteger]}
        title="Quantity (# of shares)"
      />
    </InputRow>
    <Input type="hidden" name="totalValue" />
  </>
);

export default ConnectionValueFieldSet;
