import { BadgeCell, CheckboxCell, CheckboxHeader, DateCell } from "yuka";

import DropdownCell from "./DropdownCell";
import UploadCell from "./UploadCell";
import PreviewCell from "./PreviewCell";

const CheckboxColumn = tableName => ({
  id: `${tableName}_dealFileDocsSelected`,
  accessor: "selected",
  header: `${tableName}_dealFileDocsSelected`,
  width: 50,
  align: "center",
  cellRenderer: CheckboxCell,
  headerRenderer: CheckboxHeader,
  useFixedWidth: true,
});

const NameColumn = tableName => ({
  id: `${tableName}_name`,
  accessor: "name",
  width: 28,
  header: "Attachment Name",
  sortable: true,
  cellRenderer: PreviewCell,
  sticky: true,
});

const ClientSourceColumn = tableName => ({
  id: `${tableName}_sourceName`,
  accessor: "sourceName",
  width: 28,
  header: "Client Source",
  sortable: false,
});

const CategoryColumn = tableName => ({
  id: `${tableName}_category`,
  accessor: "category",
  width: 22,
  header: "Category",
  sortable: true,
  cellRenderer: BadgeCell,
});

const TypeColumn = tableName => ({
  id: `${tableName}_type`,
  accessor: "type",
  width: 22,
  header: "Type",
  sortable: true,
  emptyPlaceholder: "--",
});

const UploadDateColumn = tableName => ({
  id: `${tableName}_createdOn`,
  accessor: "createdOn",
  width: 17,
  header: "Upload Date",
  sortable: true,
  emptyPlaceholder: "--",
  cellRenderer: DateCell,
});

const UploadColumn = tableName => ({
  id: `${tableName}_upload`,
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: UploadCell,
});

const DropdownColumn = tableName => ({
  id: `${tableName}_dropdown`,
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: DropdownCell,
});

const columns = tableName => [
  CheckboxColumn(tableName),
  NameColumn(tableName),
  ClientSourceColumn(tableName),
  CategoryColumn(tableName),
  TypeColumn(tableName),
  UploadDateColumn(tableName),
  UploadColumn(tableName),
  DropdownColumn(tableName),
];

export { columns };
