import React from "react";
import PropTypes from "prop-types";

import { FormSection } from "forms/FormComponents";

import TicketParticipantFieldSet from "./TicketParticipantFieldSet";
import TicketMOsFieldSet from "./TicketMOsFieldSet";

const SellsideDetailsFieldSet = () => {
  return (
    <FormSection name="sell">
      <TicketParticipantFieldSet brokerSourceType="Sell-side Broker" clientSourceType="Seller" />
      <TicketMOsFieldSet />
    </FormSection>
  );
};

SellsideDetailsFieldSet.propTypes = {
  ticket: PropTypes.shape({
    commissionPercent: PropTypes.string,
  }).isRequired,
};

export default SellsideDetailsFieldSet;
