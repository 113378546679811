import React from "react";
import { Field, FinalFormField } from "yuka";

export {
  FormSection,
  FieldPrefixContext,
  FormModal,
  InputRow,
  FinalFormArrayField as FieldArray,
} from "yuka";

// eslint-disable-next-line react/prop-types
export const Input = React.forwardRef(({ title, handleChange, ...props }, ref) => (
  <FinalFormField label={title} onChange={handleChange} {...props} ref={ref} />
));

export const Checkbox = props => <Input type="checkbox" {...props} />;
export const Select = props => <Input type="select" {...props} />;
export const TextArea = props => <Input type="textarea" {...props} />;
export const RadioGroup = props => <Input type="radio" {...props} />;
export const DelimitedNumberInput = props => <Input type="number" {...props} />;
export const DatePicker = props => <Input type="date" {...props} />;
export const Switch = props => <Input type="switch" {...props} />;

export const UnboundCheckbox = props => <Field type="checkbox" {...props} />;
