import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";
import { useMpSource, mpTrack } from "utils/mixpanel";
import useTableSort from "utils/tables/useTableSort";

import { SPV_TYPE_MASTER } from "../constants";
import EconomicsCell from "./EconomicsCell";

const TABLE_NAME = "SPV_FUNDS";

const useFundsTableConfig = () => {
  const currentTableSort = useSelector(state => state?.tableSort[TABLE_NAME]);
  const dispatch = useDispatch();

  const onSort = useTableSort(ACTIONS, dispatch, TABLE_NAME);
  const sortState = useMemo(() => {
    return currentTableSort && currentTableSort.length ? currentTableSort : [];
  }, [currentTableSort]);

  const navigate = useNavigate();
  const mpSource = useMpSource();

  const masterOnRowClick = useCallback(
    ({ row: { original: originalData } }) => {
      mpTrack("click into spv profile", {
        mpSource,
        spv: originalData.name,
      });
      if (originalData.apiId) {
        navigate(`/spvs/${originalData.apiId}/`);
      }
    },
    [navigate, mpSource]
  );

  const classOnRowClick = useCallback(
    ({ row: { original: originalData } }) => {
      mpTrack("click into spv class profile", {
        mpSource,
        spv: originalData.name,
        type: originalData.spvType,
      });
      if (originalData.apiId) {
        navigate(`/spvs/class/${originalData.apiId}/`);
      }
    },
    [navigate, mpSource]
  );

  const getOnRowClick = spv => {
    return spv.spvType === SPV_TYPE_MASTER ? masterOnRowClick : classOnRowClick;
  };

  return {
    sortState,
    onSort,
    getOnRowClick,
  };
};

const NameColumn = width => ({
  id: "name",
  accessor: "name",
  width,
  header: "Name",
  sortable: true,
});

// SPV columns
const CompanyColumn = {
  id: "companyName",
  accessor: "companyName",
  width: 50,
  header: "Portfolio Company",
  sortable: true,
};

// SPV Class columns
const UnderlyingFundColumn = {
  id: "underlyingFund",
  accessor: "underlyingFund",
  width: 25,
  header: "Underlying Fund",
  sortable: true,
};

const NumberOfInvestorsColumn = {
  id: "numberOfInvestors",
  accessor: "numberOfInvestors",
  width: 25,
  header: "# of Investors",
  sortable: true,
};

const EconomicsColumn = {
  id: "economics",
  accessor: spvClass => spvClass,
  width: 25,
  header: "PF / MF / CI (%)",
  sortable: true,
  cellRenderer: EconomicsCell,
};

const spvColumns = [NameColumn(50), CompanyColumn];
const spvClassColumns = [
  NameColumn(25),
  UnderlyingFundColumn,
  NumberOfInvestorsColumn,
  EconomicsColumn,
];

const getColumns = spvType => {
  return spvType === SPV_TYPE_MASTER ? spvColumns : spvClassColumns;
};

export { getColumns, useFundsTableConfig };
