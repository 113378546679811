import PropTypes from "prop-types";

import { numberFormat } from "utils/displayFormatUtils";

/**
 * Renders the econmoics of a SPV class
 *
 * @param {object} prop
 * @returns {string}
 */
const EconomicsCell = ({ value: spvClass }) =>
  `${numberFormat(spvClass.placementFee)} / ${numberFormat(
    spvClass.managementFee
  )} / ${numberFormat(spvClass.carriedInterest)}`;

EconomicsCell.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    parentName: PropTypes.string,
  }).isRequired,
};

export default EconomicsCell;
