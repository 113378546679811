/**
 * Takes a FileResponse and download name and downloads the file naming it with the correct name
 */
const downloadFileResponse = (responseData, name) => {
  const blob = new Blob([responseData]);
  const url = window.URL.createObjectURL(blob);
  const link = window.document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  window.document.body.appendChild(link);
  link.click();
};

export default downloadFileResponse;
