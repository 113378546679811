import React from "react";
import PropTypes from "prop-types";

import { Definition } from "yuka";

import { EXECUTION_TAB } from "forms/EditConnectionForm";

import EditAction from "./EditAction";

/**
 * Present the execution representative for a connection
 *
 * @param {object} connection
 *
 * @returns {React.Element}
 */
const ExecutionInfo = ({ connection }) => (
  <>
    <EditAction title="Execution Info" action={EXECUTION_TAB} />
    <Definition label="Execution Rep" value={connection.executionRepName} />
    <Definition label="Noticed By" value={connection.noticedBy} />
  </>
);

ExecutionInfo.propTypes = {
  connection: PropTypes.shape({
    executionRepName: PropTypes.string,
    noticedBy: PropTypes.string,
  }).isRequired,
};

export default ExecutionInfo;
