import React from "react";
import styled from "styled-components";

import { ColorPalette, FontColors, StyledBody1 } from "yuka";

import { afterToday, required } from "utils/form/inputValidations";
import { DatePicker, InputRow, RadioGroup, Switch } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";

import { REMINDER_RECIPIENT_ITEMS } from "./constants";

const StyledSectionDivider = styled.div`
  ${({ $hasAlert }) => {
    if ($hasAlert) {
      return `
        position: relative;
        height: 2px;
        width: 400px;
        right: 16px;
        padding-top: 12px;
        border-top: 1px solid ${ColorPalette.minWhite}
      `;
    }
    return;
  }}
`;

const StyledLabelSpan = styled(StyledBody1)`
  width: 143px;
  ${FontColors.theme50};
`;

const AlertFieldSet = () => {
  const hasAlert = useFormValue("hasAlert", false);
  return (
    <>
      <StyledSectionDivider $hasAlert={hasAlert} />
      <InputRow>
        <StyledLabelSpan>Alert</StyledLabelSpan>
        <Switch
          name="hasAlert"
          title={hasAlert ? "Alert email on" : "No scheduled alert"}
          defaultChecked={hasAlert}
        />
      </InputRow>
      {hasAlert && (
        <>
          <InputRow>
            <DatePicker
              name="sendReminderOn"
              validate={[afterToday]}
              minDate={new Date()}
              placeholder="Remind me in ..."
              title="Alert Email Date"
            />
          </InputRow>
          <InputRow>
            <RadioGroup
              items={REMINDER_RECIPIENT_ITEMS}
              name="reminderRecipients"
              validate={[required]}
              title="Alert Recipients"
            />
          </InputRow>
        </>
      )}
    </>
  );
};

export default AlertFieldSet;
