/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import _ from "lodash";

import getLinkProps, { LinkTypes } from "./getLinkProps";

import { FontColors } from "../Typography";
import { ColorPalette } from "../StylingConstants";

const LinkStyles = {
  DEFAULT: "DEFAULT", // default link rules
  SUPPORT: "SUPPORT", // support link rules
  UNSTYLED: "UNSTYLED", // "link" that doesn't have actual elements for it.
  INVISIBLE: "INVISIBLE", // Inline with existing styles
};

const StyledHyperLink = styled.a`
  ${props => {
    if (props.$linkStyle == LinkStyles.DEFAULT) {
      return css`
        color: ${ColorPalette.blue400};
        &:hover {
          text-decoration: underline;
        }
      `;
    } else if (props.$linkStyle == LinkStyles.SUPPORT) {
      return css`
        ${FontColors.theme30}
        &:hover {
          text-decoration: underline;
        }
      `;
    } else if (props.$linkStyle == LinkStyles.INVISIBLE) {
      return css`
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      `;
    } else {
      // Don't actually want to render as a "link" but want the functionality
      return css`
        color: inherit;
      `;
    }
  }}

  text-decoration: none;
  cursor: pointer;
  // Assorted button styles to wipe and normalize
  background: none;
  letter-spacing: inherit;
  display: inline;
  border: none;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-transform: inherit;
`;

/**
 * A text link element with helpers to ensure attributes and navigation behaves as desired.
 *
 * Provides a backwards compatible interface with the legacy element with warnings. This can be
 * expected to be removed in a future release
 *
 * LinkStyles.UNSTYLED may be removed in a future release. This is a backdoor for non traditional
 * link experiences to be given standardized, accessible attributes and is not officially part of
 * the yuka style guide
 *
 * LinkTypes guideline:
 * - `BUTTON` - when no navigation is performed but need a text link visually
 * - `DOWNLOAD` - when the link is directly to a document to be downloaded
 * - `LOCAL_LINK` - the link navigates within a SPA
 * - `EXTERNAL_LINK` - the link navigates away from the environment, opens in a new tab
 * - `LINK` - a traditional `<a>` tag without any special behaviors
 */
const HyperLink = React.forwardRef((props, ref) => {
  let computedLinkStyle = props.linkStyle;

  // Backwards compatibility with legacy HyperLink props
  if (props.newTab || props.accent || props.download || props.href || props.to || props.active) {
    // eslint-disable-next-line no-console
    console.warn(
      "You are passing V1 props to a V2 HyperLink. Some actions may not act is expected"
    );
    if (!props.linkStyle) {
      computedLinkStyle = props.accent ? LinkStyles.DEFAULT : LinkStyles.SUPPORT;
    }
  } else {
    // Default V2 behavior
    // Default props
    if (!computedLinkStyle) {
      computedLinkStyle = LinkStyles.DEFAULT;
    }
  }

  const legacyProps = _.pick(props, [
    "newTab",
    "accent",
    "download",
    "href",
    "to",
    "active",
    "rel",
    "target",
  ]);
  const [computedLinkProps, renderAs] = getLinkProps(props.url, props.linkType, legacyProps);

  return (
    <StyledHyperLink
      {...computedLinkProps}
      id={props.id}
      className={props.className}
      onClick={props.onClick}
      as={renderAs}
      ref={ref}
      $linkStyle={computedLinkStyle}
      role={props.role}
    >
      {props.children}
    </StyledHyperLink>
  );
});

HyperLink.propTypes = {
  className: PropTypes.string,
  /** The appearance of the link, either Default or Support. */
  linkStyle: PropTypes.oneOf(Object.values(LinkStyles)),
  /** How the link should be treated for navigation and other best practices purposes */
  linkType: PropTypes.oneOf(Object.values(LinkTypes)),
  onClick: PropTypes.func,
  /** the href to use. May be renamed to href in the future if legacy compatibility is removed */
  url: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  /** Aria role */
  role: PropTypes.string,
};

HyperLink.displayName = "HyperLink";

export default HyperLink;
export { LinkStyles };
