function mapValues(obj, mapFn) {
  if (!obj) {
    return obj;
  }
  if (typeof mapFn === "string") {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value[mapFn]]));
  }

  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, mapFn(value)]));
}

export default mapValues;
