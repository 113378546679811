import React from "react";
import PropTypes from "prop-types";

import { IconButton, useAnchoredMenu, DotsVerticalIcon } from "yuka";

import { fetchQueryResponse, useDelete, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import downloadFileResponse from "utils/downloadFileResponse";
import { mpTrack } from "utils/mixpanel";
import { ConnectionDocumentsForm } from "forms/DocumentsForm";

/**
 * Renders a dropdown for the deal file table
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const DropdownCell = ({ connection, value: document, queryKeys }) => {
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const { onSubmit } = useDelete(queryKeys.detail(connection.tradeId, document.apiId), {
    message: "Successfully removed from Deal File",
  });
  const download = document => {
    fetchQueryResponse(queryKeys.detail(connection.tradeId, document.apiId, ["download"])).then(
      data => {
        const documentName = document.name.includes(".")
          ? document.name
          : `${document.name}.${document.extension}`;
        downloadFileResponse(data, documentName);
        mpTrack("download deal file documents");
      }
    );
  };
  const menuItems = [
    {
      text: "Edit Details",
      onClick: () => setEditModalOpen(true),
      disabled: connection.isDealFileLocked,
    },
    {
      text: "Download",
      onClick: () => download(document),
    },
    {
      text: "Remove",
      onClick: () =>
        onSubmit().then(() => {
          queryClient.invalidateQueries(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId));
        }),
      disabled: connection.isDealFileLocked,
    },
  ];
  const [menu, menuRef, toggleMenu] = useAnchoredMenu({ menuItems });
  return (
    <>
      <IconButton icon={DotsVerticalIcon} ref={menuRef} onClick={toggleMenu} />
      {menu}
      {isEditModalOpen && (
        <ConnectionDocumentsForm
          queryKeys={queryKeys}
          existingDocument={document}
          closeModal={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

DropdownCell.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    isDealFileLocked: PropTypes.bool,
  }).isRequired,
  value: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    detail: PropTypes.func.isRequired,
  }).isRequired,
};

export default DropdownCell;
