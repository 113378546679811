/**
 * Checks if `value` is classified as a `String` primitive or object. Implementation
 * inspired from the more complicated version in lodash.
 *
 * @param value
 * @returns {boolean}
 */
const isString = value => {
  return typeof value === "string";
};

export default isString;
