import React from "react";
import PropTypes from "prop-types";

import { QUERY_KEYS, useFetch } from "api";
import { Card, CardStyles } from "yuka";
import CreateStatusUpdateForm from "forms/CreateStatusUpdateForm";

import StatusUpdate from "./StatusUpdate";

const useFetchedStatusUpdates = tradeId => {
  return useFetch(QUERY_KEYS.STATUS_UPDATES.list(tradeId));
};

/**
 * Renders the Status Update Card for a connection
 *
 * @param {object} props
 * @returns {React.Element}
 */
const StatusUpdateCard = ({ connection }) => {
  const { isLoading, isError, data: statusUpdates } = useFetchedStatusUpdates(connection.tradeId);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }
  let pinnedStatusUpdate = null;
  // get first status update since there can only be one pinned status update for each connection
  if (statusUpdates.length > 0) {
    pinnedStatusUpdate = statusUpdates[0];
  }
  const title = pinnedStatusUpdate ? "" : "Status Update";
  return (
    <Card cardStyle={pinnedStatusUpdate ? CardStyles.VALUE : CardStyles.PADDED} title={title}>
      {!pinnedStatusUpdate && <CreateStatusUpdateForm connection={connection} />}
      {pinnedStatusUpdate && (
        <StatusUpdate
          key={pinnedStatusUpdate.apiId}
          statusUpdate={pinnedStatusUpdate}
          connection={connection}
        />
      )}
    </Card>
  );
};

StatusUpdateCard.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
};

export default StatusUpdateCard;
