/**
 * Contains a list of all names of filters used for `StateProvider` in order to hook into the global state.
 *
 * Allows components to modify behaviour based on filter values where the filter does not appear in the same
 * form context as the component.
 */

const CONNECTIONS_FILTERS = "CONNECTIONS_FILTERS";
const CLIENT_PROFILE_FILTERS = "CLIENT_PROFILE_FILTERS";
const BUYSIDE_DEAL_FILE_FILTERS = "BUYSIDE_DEAL_FILE_FILTERS";
const SELLSIDE_DEAL_FILE_FILTERS = "SELLSIDE_DEAL_FILE_FILTERS";
const TRANSFER_DEAL_FILE_FILTERS = "TRANSFER_DEAL_FILE_FILTERS";
const SPV_TABLE_FILTERS = "SPV_TABLE_FILTERS";
const SPV_CLASS_TABLE_FILTERS = "SPV_CLASS_TABLE_FILTERS";

export {
  CONNECTIONS_FILTERS,
  CLIENT_PROFILE_FILTERS,
  BUYSIDE_DEAL_FILE_FILTERS,
  SELLSIDE_DEAL_FILE_FILTERS,
  TRANSFER_DEAL_FILE_FILTERS,
  SPV_TABLE_FILTERS,
  SPV_CLASS_TABLE_FILTERS,
};
