import styled from "styled-components";
import { useCallback, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardStyles,
  Table,
  UploadIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "yuka";

import { getRelatedObj } from "api";
import { RelationshipsContext } from "ExecutionApp/ConnectionProfileView";
import Filter from "forms/Filter";
import { ConnectionDocumentsForm } from "forms/DocumentsForm";
import { compact } from "utils";

import { columns } from "./dealFileTableConfig";
import DealFileFilter, { useDealFileFilterConfig } from "./DealFileFilter";

const StyledCard = styled(Card)`
  width: 100%;
`;

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

const StyledTitle = styled.div`
  cursor: pointer;
`;
const StyledTitleSpan = styled.span`
  margin-left: 12px;
`;

/**
 * Table displaying all documents in the Deal File
 */
const DealFileTable = ({ connection, documents, filterName, tableName, title, queryKeys }) => {
  const {
    queryParams: filterQueryParams,
    onChangeFilters,
    filterProps,
  } = useDealFileFilterConfig(filterName);

  const [showTable, setShowTable] = useState(true);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const relationships = useContext(RelationshipsContext);
  const documentObjects = useMemo(
    () => compact(documents.map(document => getRelatedObj(document, relationships))),
    [documents, relationships]
  );

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const onRowSelectStateChange = useCallback(
    data => {
      let newSelectedDocuments = [];
      Object.keys(data).map(rowNumber => {
        newSelectedDocuments.push(documentObjects[rowNumber]);
      });
      setSelectedDocuments(newSelectedDocuments);
      return;
    },
    [documentObjects, setSelectedDocuments]
  );

  const filteredDocumentObjects = useMemo(() => {
    let filteredDocuments = documentObjects;
    if (filterQueryParams.name) {
      filteredDocuments = documentObjects.filter(document =>
        document.name.toLowerCase().includes(filterQueryParams.name.toLowerCase())
      );
    }
    if (filterQueryParams.category) {
      filteredDocuments = filteredDocuments.filter(document =>
        filterQueryParams.category.includes(document.category)
      );
    }
    return filteredDocuments.filter(doc => doc.status !== "Archived");
  }, [documentObjects, filterQueryParams.name, filterQueryParams.category]);

  const cardHeader = (
    <StyledTitle role="button" onClick={() => setShowTable(!showTable)}>
      {showTable ? <ChevronDownIcon /> : <ChevronRightIcon />}
      <StyledTitleSpan>{title}</StyledTitleSpan>
    </StyledTitle>
  );

  const headerTrailingContent = (
    <Button
      onClick={() => setShowUploadForm(true)}
      leadingIcon={UploadIcon}
      disabled={connection.isDealFileLocked}
    >
      Upload Document
    </Button>
  );

  return (
    <StyledCard
      cardStyle={CardStyles.SECTIONED}
      title={cardHeader}
      headerTrailingContent={headerTrailingContent}
    >
      {showTable && (
        <>
          <PaddedWrapper>
            <Filter
              name={filterName}
              filterComponent={DealFileFilter}
              onChange={onChangeFilters}
              {...filterProps}
              selectedDocuments={selectedDocuments}
              connection={connection}
              queryKeys={queryKeys}
              title={title}
            />
          </PaddedWrapper>
          <Table
            usePercentageColumnWidths
            emptyTablePlaceholder="No documents linked"
            data={filteredDocumentObjects || []}
            columns={columns(tableName)}
            connection={connection}
            onRowSelectStateChange={onRowSelectStateChange}
            queryKeys={queryKeys}
          />
        </>
      )}
      {showUploadForm && (
        <ConnectionDocumentsForm
          queryKeys={queryKeys}
          closeModal={() => setShowUploadForm(false)}
        />
      )}
    </StyledCard>
  );
};

DealFileTable.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string.isRequired,
    isDealFileLocked: PropTypes.bool,
  }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.array).isRequired,
  filterName: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    detail: PropTypes.func,
    list: PropTypes.func,
  }).isRequired,
};

export default DealFileTable;
