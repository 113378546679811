import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

import { Definition } from "yuka";

import { getRelatedObj } from "api";
import { BUY_COMMISSION_TAB, SELL_COMMISSION_TAB } from "forms/EditConnectionForm";

import {
  BUY,
  DIRECTION_BUY_CLIENT_TO_Z,
  DIRECTION_BUY_CLIENT_TO_BUY_BROKER,
  DIRECTION_BUY_BROKER_TO_Z,
  DIRECTION_Z_TO_BUY_BROKER,
  DIRECTION_SELL_CLIENT_TO_Z,
  DIRECTION_SELL_CLIENT_TO_SELL_BROKER,
  DIRECTION_SELL_BROKER_TO_Z,
  DIRECTION_Z_TO_SELL_BROKER,
} from "utils/constants";
import CommissionUtils from "utils/commissions/CommissionUtils";

import { RelationshipsContext } from "..";
import EditAction from "./EditAction";

/**
 * Ticket Commission fields
 *
 * @param {object} props
 * @returns {React.Element} TicketCommission
 */
const TicketCommission = ({ connection, ticket, type }) => {
  const relationships = useContext(RelationshipsContext);
  const commissions = ticket.commission.map(commission => getRelatedObj(commission, relationships));

  const clientToZCommission =
    commissions.find(
      commission =>
        commission.commissionDirection ===
        (type === BUY ? DIRECTION_BUY_CLIENT_TO_Z : DIRECTION_SELL_CLIENT_TO_Z)
    ) || 0;
  const clientToBrokerCommission =
    commissions.find(
      commission =>
        commission.commissionDirection ===
        (type === BUY ? DIRECTION_BUY_CLIENT_TO_BUY_BROKER : DIRECTION_SELL_CLIENT_TO_SELL_BROKER)
    ) || 0;
  const brokerToZCommission =
    commissions.find(
      commission =>
        commission.commissionDirection ===
        (type === BUY ? DIRECTION_BUY_BROKER_TO_Z : DIRECTION_SELL_BROKER_TO_Z)
    ) || 0;
  const zToBrokerCommission =
    commissions.find(
      commission =>
        commission.commissionDirection ===
        (type === BUY ? DIRECTION_Z_TO_BUY_BROKER : DIRECTION_Z_TO_SELL_BROKER)
    ) || 0;

  const client = `${type === BUY ? "Buy" : "Sell"}`;

  const clientToZFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        clientToZCommission?.commissionAmount,
        clientToZCommission?.commissionType,
        connection
      ),
    [clientToZCommission, connection]
  );

  const clientToBrokerFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        clientToBrokerCommission?.commissionAmount,
        clientToBrokerCommission?.commissionType,
        connection
      ),
    [clientToBrokerCommission, connection]
  );

  const brokerToZFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        brokerToZCommission?.commissionAmount,
        brokerToZCommission?.commissionType,
        connection,
        clientToZFee,
        clientToBrokerFee,
        brokerToZCommission?.commissionAmountNumerator,
        brokerToZCommission?.commissionAmountDenominator
      ),
    [brokerToZCommission, connection, clientToZFee, clientToBrokerFee]
  );

  const zToBrokerFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        zToBrokerCommission?.commissionAmount,
        zToBrokerCommission?.commissionType,
        connection,
        clientToZFee,
        clientToBrokerFee,
        zToBrokerCommission?.commissionAmountNumerator,
        zToBrokerCommission?.commissionAmountDenominator
      ),
    [zToBrokerCommission, connection, clientToZFee, clientToBrokerFee]
  );

  return (
    <>
      <EditAction
        title={type === BUY ? "Buyside Commission" : "Sellside Commission"}
        action={type === BUY ? BUY_COMMISSION_TAB : SELL_COMMISSION_TAB}
      />
      <Definition
        key={clientToZCommission?.id}
        label={`${client}er Fee to Z`}
        value={CommissionUtils.commissionAmountDisplay(clientToZCommission)}
      />
      <Definition
        key={clientToBrokerCommission?.id}
        label={`${client}er Fee to ${client}side Broker`}
        value={CommissionUtils.commissionAmountDisplay(clientToBrokerCommission)}
      />
      <Definition
        key={brokerToZCommission?.id}
        label={`${client}side Broker Fee to Z`}
        value={CommissionUtils.commissionAmountDisplay(brokerToZCommission)}
      />
      <Definition
        key={zToBrokerCommission?.id}
        label={`Z Fee to ${client}side Broker`}
        value={CommissionUtils.commissionAmountDisplay(zToBrokerCommission)}
      />
      <Definition
        key="zNetCommission"
        label="Z Net Commission"
        value={CommissionUtils.displayZNetCommission(clientToZFee, brokerToZFee, zToBrokerFee)}
      />
    </>
  );
};

TicketCommission.propTypes = {
  connection: PropTypes.shape({
    totalValue: PropTypes.string,
  }),
  ticket: PropTypes.shape({
    commission: PropTypes.arrayOf(PropTypes.array),
  }),
  type: PropTypes.string.isRequired,
};

export default TicketCommission;
