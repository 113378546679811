import { BadgeCell, IconButtonCell, LinkExternalIcon, NumberCell } from "yuka";

import { expandedMoneyFormat, shortMoneyFormat } from "utils/displayFormatUtils";

import CompanyCell from "./CompanyCell";
import { EXECUTION_PHASE_BADGE_MAPPING } from "utils/constants";

const TABLE_NAME = "CLIENT_PROFILE_CONNECTIONS";

const TradeIdColumn = {
  id: "tradeId",
  accessor: "tradeId",
  width: 160,
  sticky: true,
  header: "Trade ID",
  sortable: true,
  tooltip: () => "Click to copy",
  onCellClick: ({ clickTradeId, ...props }) => clickTradeId(props),
};

const CompanyColumn = {
  id: "company.name",
  accessor: connection => connection,
  width: 200,
  sticky: true,
  header: "Company",
  sortable: true,
  cellRenderer: CompanyCell,
};

const ExpandConnectionColumn = {
  id: "expandConnectionColumn",
  width: 40,
  sticky: true,
  header: "",
  align: "center",
  sortable: false,
  icon: LinkExternalIcon,
  onClick: ({ navigateToConnectionProfile, ...props }) => navigateToConnectionProfile(props),
  renderOverflow: true,
  cellRenderer: IconButtonCell,
};

const PriceColumn = {
  id: "pricePerShare",
  accessor: "pricePerShare",
  width: 120,
  header: "Price",
  sortable: true,
  formatter: value => shortMoneyFormat(value, 2),
  cellRenderer: NumberCell,
};

const QuantityColumn = {
  id: "shareQuantity",
  accessor: "shareQuantity",
  width: 160,
  header: "Quantity",
  sortable: true,
  precision: 0,
  cellRenderer: NumberCell,
};

const ValueColumn = {
  id: "totalValue",
  accessor: "totalValue",
  width: 160,
  header: "Value",
  sortable: true,
  formatter: expandedMoneyFormat,
  cellRenderer: NumberCell,
};

const PhaseColumn = {
  accessor: "phase",
  width: 187,
  header: "Phase",
  cellRenderer: BadgeCell,
  badgeMapping: EXECUTION_PHASE_BADGE_MAPPING,
};

const columns = [
  TradeIdColumn,
  CompanyColumn,
  ExpandConnectionColumn,
  PriceColumn,
  QuantityColumn,
  ValueColumn,
  PhaseColumn,
];

export { TABLE_NAME, columns };
