import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { IconButton, useAnchoredMenu, DotsVerticalIcon } from "yuka";

import { fetchQueryResponse, useDelete, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import { useDispatch, ACTIONS } from "utils/StateProvider";
import downloadFileResponse from "utils/downloadFileResponse";
import { ExpiringDocumentsForm } from "forms/DocumentsForm";

const StyledCell = styled.div`
  display: flex;
`;

/**
 * Renders a dropdown for the documents table
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const DropdownCell = ({ value: document }) => {
  const { onSubmit: onDelete } = useDelete(QUERY_KEYS.EXPIRING_DOCUMENTS.detail(document.apiId), {
    silent: true,
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const editAction = () => setEditModalOpen(true);
  const closeModal = () => {
    setEditModalOpen(false);
  };

  const dispatch = useDispatch();
  const handleDelete = () => {
    if (confirm(`Are you sure you archive this document?`)) {
      onDelete().then(() => {
        queryClient.invalidateQueries(QUERY_KEYS.EXPIRING_DOCUMENTS.list());
        dispatch({
          type: ACTIONS.addToast,
          message: `Successfully Archived`,
        });
      });
    }
  };

  const menuItems = [
    { text: "Edit details", onClick: editAction },
    { text: "Archive", onClick: handleDelete },
  ];
  const download = document => {
    fetchQueryResponse(QUERY_KEYS.EXPIRING_DOCUMENTS.list(document.apiId, ["download"])).then(
      data => {
        const documentName =
          document.extension === document.name.split(".").at(-1)
            ? document.name
            : `${document.name}.${document.extension}`;
        downloadFileResponse(data, documentName);
      }
    );
  };
  menuItems.push({ text: "Download", onClick: () => download(document) });

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({ menuItems });

  return (
    <StyledCell>
      {isEditModalOpen && (
        <ExpiringDocumentsForm existingDocument={document} closeModal={closeModal} />
      )}
      <IconButton icon={DotsVerticalIcon} ref={menuRef} onClick={toggleMenu} />
      {menu}
    </StyledCell>
  );
};

DropdownCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DropdownCell;
