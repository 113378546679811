import React, { useState } from "react";
import PropTypes from "prop-types";
import { Fonts, Card, CardStyles, HyperLink, LinkStyles } from "yuka";

import { useDispatch, ACTIONS } from "utils/StateProvider";
import AttachDropboxLinkForm from "forms/AttachDropboxLinkForm";

/**
 * Card that allows users to add, edit, and copy link to drop box
 */
const DropboxCard = ({ connection }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const handleClickDropboxLink = () => {
    // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(connection.dropboxLink);
        dispatch({
          type: ACTIONS.addToast,
          message: `Copied "${connection.dropboxLink}" to clipboard`,
        });
      }
    });
  };
  return (
    <Card
      cardStyle={CardStyles.PADDED}
      title={
        connection.dropboxLink &&
        !isEditing && (
          <HyperLink onClick={handleClickDropboxLink} linkStyle={LinkStyles.INVISIBLE}>
            Copy Dropbox Link
          </HyperLink>
        )
      }
      headerTrailingContent={
        connection.dropboxLink &&
        !isEditing && (
          <Fonts.Caption2theme50>
            <HyperLink onClick={() => setIsEditing(true)} linkStyle={LinkStyles.SUPPORT}>
              Edit Link
            </HyperLink>
          </Fonts.Caption2theme50>
        )
      }
    >
      {(!connection.dropboxLink || isEditing) && (
        <AttachDropboxLinkForm connection={connection} onClose={() => setIsEditing(false)} />
      )}
    </Card>
  );
};

DropboxCard.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    dropboxLink: PropTypes.string,
  }).isRequired,
};

export default DropboxCard;
