import React from "react";
import PropTypes from "prop-types";

import { AlertCircleIcon } from "../../Icons";
import { PrimaryColorPalette } from "../../StylingConstants";

import { StyledInputWrapper, SIZE_SMALL, SIZE_REGULAR } from "./StyledComponents";

/**
 * A wrapper around most input elements to include default icon behaviors and manage variable sizes
 *
 * NOTE: FileInput has it's own version with 2 minor adjustments for the focus action and cursor
 *
 */
const InputWrapper = props => {
  const TrailingIcon = props.showError ? AlertCircleIcon : props.trailingIcon;
  const defaultIconColor = props.disabled
    ? PrimaryColorPalette.white15
    : PrimaryColorPalette.white30;
  const focusChild = () => {
    const refCurrent = props.inputRef.current;
    // DatePicker ref is actually a class that has a ref on `input` attribute
    refCurrent?.input ? refCurrent.input.focus() : refCurrent?.focus();
  };
  const size = props.small ? SIZE_SMALL : SIZE_REGULAR;
  const iconSize = props.small ? 16 : 18;

  return (
    <StyledInputWrapper
      $disabled={props.disabled}
      $error={props.showError}
      $size={size}
      onClick={focusChild}
      className={props.className}
      style={props.style}
    >
      {props.leadingIcon && <props.leadingIcon size={iconSize} color={defaultIconColor} />}
      {props.children}
      {TrailingIcon && (
        <TrailingIcon
          size={iconSize}
          color={props.showError ? PrimaryColorPalette.sell : defaultIconColor}
        />
      )}
    </StyledInputWrapper>
  );
};

InputWrapper.propTypes = {
  showError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  trailingIcon: PropTypes.func,
  leadingIcon: PropTypes.func,
  /* ref of underlying input being wrapped */
  inputRef: PropTypes.object.isRequired,
  small: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default InputWrapper;
