import PropTypes from "prop-types";
import { useMemo } from "react";
import { DefinitionListHeader, FinalFormField } from "yuka";

import { compact, difference, intersection } from "utils";

import { useFetch, QUERY_KEYS } from "api";

const getOption = (source, side) => ({
  label: `${source.name} (${side})`,
  value: source.searchId,
});

const EmailRecipientsFieldSet = ({ buy, sell }) => {
  const { data: buyInterestedParties } = useFetch(
    QUERY_KEYS.SOURCES.list({ "filter[ticketInterestedParties]": buy.apiId })
  );
  const { data: sellInterestedParties } = useFetch(
    QUERY_KEYS.SOURCES.list({ "filter[ticketInterestedParties]": sell.apiId })
  );

  const emailRecipients = useMemo(() => {
    const bothSides = intersection(
      buyInterestedParties,
      sellInterestedParties,
      source => source.apiId
    );
    const buyOnly = difference(buyInterestedParties, sellInterestedParties, source => source.apiId);
    const sellOnly = difference(
      sellInterestedParties,
      buyInterestedParties,
      source => source.apiId
    );
    return compact([
      ...bothSides.map(source => getOption(source, "Buyside, Sellside")),
      ...buyOnly.map(source => getOption(source, "Buyside")),
      ...sellOnly.map(source => getOption(source, "Sellside")),
    ]);
  }, [buyInterestedParties, sellInterestedParties]);

  const recipientsInitial = useMemo(() => emailRecipients.map(opt => opt.value), [emailRecipients]);

  return (
    <>
      <p>
        An email notification will be sent to the following selected recipients. Unselect a
        recipient if you do not wish to send them the notification.
      </p>
      <DefinitionListHeader title="Participant Name" />
      <FinalFormField
        type="multiple-checkbox"
        options={emailRecipients}
        initialValue={recipientsInitial}
        name="emailRecipients"
      />
    </>
  );
};

EmailRecipientsFieldSet.propTypes = {
  buy: PropTypes.shape({ apiId: PropTypes.string }).isRequired,
  sell: PropTypes.shape({ apiId: PropTypes.string }).isRequired,
};

export default EmailRecipientsFieldSet;
