import React, { useRef, useEffect } from "react";

import useDynamicPosition from "../utils/useDynamicPosition";

import useIsPopupOpen from "./internal/useIsPopupOpen";
import Dropdown from "./internal/Dropdown";
import { CardStyles } from "./Card";

// Possible eventual upgrade - allow a default alignment to be specified

const THRESHOLD = 16;
const OVERHANG = 0;
const SPACING = -4;

/**
 * A hook that integrates an elevated surface to position relative to other elements
 *
 * This hook is intended to be used to anchor a flexible dropdown element to a referring element
 *
 * @param renderContents - a render prop for the contents of the dropdown
 * @param config - Config object for dropdown options.
 *   - defaultAbove - prefer above
 *   - orientation - position vertically overlapping an element or horizontally
 *   if enough space is available
 */
const useDropdown = (renderContents, config) => {
  const containerRef = useRef(null);
  const elementRef = useRef(null);
  const [isOpen, toggleIsOpen] = useIsPopupOpen(containerRef, elementRef);

  const [style, calculatePosition] = useDynamicPosition({
    containerRef,
    elementRef,
    THRESHOLD,
    OVERHANG,
    SPACING,
    defaultAbove: config?.defaultAbove ?? false,
    orientation: config?.orientation,
  });

  // Once it's visible, recalculate position - unable to listen for the ref being attached
  useEffect(() => {
    if (isOpen) {
      calculatePosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const dropdown = (
    <Dropdown style={style} ref={elementRef} cardStyle={config?.cardStyle || CardStyles.SECTIONED}>
      {renderContents({ isOpen, toggleIsOpen })}
    </Dropdown>
  );

  return [isOpen ? dropdown : null, containerRef, toggleIsOpen];
};

export default useDropdown;
