import { useCallback } from "react";

const useTableSort = (ACTIONS, dispatch, table) =>
  useCallback(
    newSort => {
      // For now we only support single column sorting.
      if (newSort) {
        dispatch({
          type: ACTIONS.SET_TABLE_SORT_STATE,
          name: table,
          value: [newSort],
        });
      } else {
        dispatch({
          type: ACTIONS.SET_TABLE_SORT_STATE,
          name: table,
          value: [],
        });
      }
    },
    [ACTIONS, dispatch, table]
  );

export default useTableSort;
