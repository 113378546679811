/**
 * A helper function mirroring the behavior of _.get
 *
 * @param {object} obj
 * @param {Array|string} pathArr
 *
 * @returns {any} value
 */
function get(obj, pathArr, ...paths) {
  let path = pathArr;
  if (typeof pathArr === "string") {
    path = pathArr.split(".");
  }
  if (!Array.isArray(path)) {
    return undefined; // eslint-disable-line no-undefined
  }
  if (path && paths) {
    path = [...path, ...paths];
  }
  let val = obj;
  for (const key of path) {
    if (!(typeof val === "object" && val != null)) {
      return undefined;
    }
    val = val[key];
  }
  return val;
}

export default get;
