import { queryClient } from "./queryClient";

import { cleanJsonApiData } from "./fetchInternals";

/**
 * A simplified alternative to useFetch when hooks are unavailable. Initially, we are just handling
 * just the objects that are available at the top level.
 *
 * This is to optimize for the primary use case of needing it to load options in selects
 *
 * @param {Array} queryKey
 * @param {any} id
 *
 * @returns {Promise}
 */
const getCachedById = (queryKeyFactory, id, idField = "apiId") => {
  const queryData = queryClient.getQueryData(queryKeyFactory.detail(id));
  if (queryData?.data) {
    return queryData.data;
  }

  const queriesData = queryClient.getQueriesData(queryKeyFactory.list());
  let _queryData = null;
  queriesData.forEach(([, queryData]) => {
    if (!_queryData && queryData?.data) {
      _queryData = cleanJsonApiData(queryData).data.find(el => el[idField] === id);
    }
  });
  return _queryData;
};

export default getCachedById;
