import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { HyperLink, ListItem, ListStyles } from "yuka";

import { useDelete, QUERY_KEYS } from "api";
import { newlineStringFormat } from "utils/displayFormatUtils";
import NoteContentForm from "forms/NoteContentForm";

const ClientNoteItem = ({ note, sourceId }) => {
  const { onSubmit: onDelete } = useDelete(QUERY_KEYS.CLIENT_NOTES.detail(sourceId, note.apiId));
  const [isEditNoteModalOpen, setEditNoteModalOpen] = React.useState(false);
  const editAction = () => setEditNoteModalOpen(true);
  return (
    <ListItem
      listStyle={ListStyles.TWO_LINE}
      trailingContent={
        isEditNoteModalOpen && (
          <NoteContentForm
            queryKey={QUERY_KEYS.CLIENT_NOTES}
            note={note}
            apiId={sourceId}
            onClose={() => setEditNoteModalOpen(false)}
            isEdit
          />
        )
      }
      text={newlineStringFormat(note.content)}
      subtext={
        <>
          {note.authorFullName} |{" "}
          {DateTime.fromISO(note.createdOn).toLocaleString(DateTime.DATE_SHORT)} | &nbsp;
          <HyperLink onClick={editAction}>Edit</HyperLink>
          <span>&nbsp; | &nbsp;</span>
          <HyperLink onClick={onDelete}>Delete</HyperLink>
        </>
      }
    />
  );
};

ClientNoteItem.propTypes = {
  note: PropTypes.shape({
    apiId: PropTypes.string,
    authorFullName: PropTypes.string,
    content: PropTypes.string,
    createdOn: PropTypes.string,
  }).isRequired,
  sourceId: PropTypes.string.isRequired,
};

export default ClientNoteItem;
