import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import GeneralSettingsCard from "./GeneralSettingsCard";
import InvoiceSettingsCard from "./InvoiceSettingsCard";
import ZXRepSettingsCard from "./ZXRepSettingsCard";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  gap: 24px;
  padding: 0px 8px;
`;

const SettingsTab = ({ contacts, representatives, source, sourceProfile }) => (
  <StyledContainer>
    <GeneralSettingsCard source={source} sourceProfile={sourceProfile} />
    <InvoiceSettingsCard contacts={contacts} source={source} sourceProfile={sourceProfile} />
    <ZXRepSettingsCard
      representatives={representatives}
      source={source}
      sourceProfile={sourceProfile}
    />
  </StyledContainer>
);

SettingsTab.propTypes = {
  contacts: PropTypes.shape({
    useInAttn: PropTypes.bool,
    useInCc: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  representatives: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  sourceProfile: PropTypes.shape({
    partySuffix: PropTypes.string,
    defaultDueDate: PropTypes.string,
  }).isRequired,
};

export default SettingsTab;
