import {
  PHASE_CLOSED,
  PHASE_EXECUTION,
  PHASE_INVOICING,
  PHASE_SETTLEMENT,
  PHASE_DISCONNECTED,
  PHASE_COMPANY_DECISION,
  PHASE_DEAL_FILE_REVIEW,
  PHASE_FEE_COLLECTION_AND_PAYMENT,
  DIRECT,
  SPV,
  FORWARD,
} from "utils/constants";

import CompanyDecisionFieldSet, {
  cleanSubmitValues as cleanCompanyDecisionSubmitValues,
} from "./CompanyDecisionFieldSet";
import SettlementFieldSet, { cleanSubmitValues } from "./SettlementFieldSet";
import DealFileReviewFieldSet, {
  cleanSubmitValues as cleanDealFileSubmitValues,
} from "./DealFileReviewFieldSet";
import InvoicingFieldSet from "./InvoicingFieldSet";
import FeeCollectionAndPaymentFieldSet from "./FeeCollectionAndPaymentFieldSet";
import CloseFieldSet, { cleanSubmitValues as cleanClosedSubmitValues } from "./CloseFieldSet";

const NEXT_PHASES_MAP_ALL = {
  [PHASE_COMPANY_DECISION]: PHASE_SETTLEMENT,
  [PHASE_SETTLEMENT]: PHASE_DEAL_FILE_REVIEW,
  [PHASE_DEAL_FILE_REVIEW]: PHASE_INVOICING,
  [PHASE_INVOICING]: PHASE_FEE_COLLECTION_AND_PAYMENT,
  [PHASE_FEE_COLLECTION_AND_PAYMENT]: PHASE_CLOSED,
  [PHASE_CLOSED]: null,
  [PHASE_DISCONNECTED]: null,
};

const NEXT_PHASES_MAP_DIRECT = {
  ...NEXT_PHASES_MAP_ALL,
  [PHASE_EXECUTION]: PHASE_COMPANY_DECISION,
};

const NEXT_PHASES_MAP_SPV = {
  ...NEXT_PHASES_MAP_ALL,
  [PHASE_EXECUTION]: PHASE_DEAL_FILE_REVIEW,
};

const NEXT_PHASES_MAP_FORWARD = {
  ...NEXT_PHASES_MAP_ALL,
  [PHASE_EXECUTION]: PHASE_SETTLEMENT,
};

const PHASE_FIELDSET_MAP = {
  [PHASE_EXECUTION]: null,
  [PHASE_COMPANY_DECISION]: {
    fieldSet: CompanyDecisionFieldSet,
    cleanSubmitValues: cleanCompanyDecisionSubmitValues,
    sendEmails: true,
  },
  [PHASE_SETTLEMENT]: { fieldSet: SettlementFieldSet, cleanSubmitValues, sendEmails: true },
  [PHASE_DEAL_FILE_REVIEW]: {
    fieldSet: DealFileReviewFieldSet,
    cleanSubmitValues: cleanDealFileSubmitValues,
    sendEmails: true,
  },
  [PHASE_INVOICING]: InvoicingFieldSet,
  [PHASE_FEE_COLLECTION_AND_PAYMENT]: FeeCollectionAndPaymentFieldSet,
  [PHASE_CLOSED]: {
    fieldSet: CloseFieldSet,
    cleanSubmitValues: cleanClosedSubmitValues,
  },
  [PHASE_DISCONNECTED]: null,
};

const getNextPhase = connection => {
  let nextPhase = null;
  switch (connection.tradeType) {
    case DIRECT:
      nextPhase = NEXT_PHASES_MAP_DIRECT[connection.phase];
      break;
    case SPV:
      nextPhase = NEXT_PHASES_MAP_SPV[connection.phase];
      break;
    case FORWARD:
      nextPhase = NEXT_PHASES_MAP_FORWARD[connection.phase];
      break;
  }
  return nextPhase;
};

const getFieldSetForPhase = connection => {
  const nextPhase = getNextPhase(connection);
  return PHASE_FIELDSET_MAP[nextPhase];
};

export default getFieldSetForPhase;
export { getNextPhase };
