import React from "react";
import PropTypes from "prop-types";

import { Definition, DefinitionListHeader } from "yuka";

/**
 * Show the details of why and when the connection was disconnected
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const DisconnectDetails = ({ connection }) => (
  <>
    <DefinitionListHeader title="Disconnect Details" />
    <Definition label="Blocked by" value={connection.disconnectSource} />
    <Definition label="Reason" value={connection.disconnectReason} />
    <Definition label="Notes" value={connection.disconnectNotes} />
  </>
);

DisconnectDetails.propTypes = {
  connection: PropTypes.shape({
    disconnectSource: PropTypes.string,
    disconnectReason: PropTypes.string,
    disconnectNotes: PropTypes.string,
  }).isRequired,
};

export default DisconnectDetails;
