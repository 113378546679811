import { debounce } from "lodash-es";
import { useForm, useFormState } from "react-final-form";
import { useMemo, useContext, useEffect, useRef } from "react";

import { pickBy, omit } from "utils";
import { QUARTER_SECOND } from "utils/constants";
import useDidMount from "utils/hooks/useDidMount";
import { FORM_ACTIONS, GlobalContext } from "utils/StateProvider";

/**
 * Hook that when added to the top level of forms opts that form into using global state to store its form values.
 *
 * Especially useful for filters whose values need to be used outside of the filter's form context.
 *
 * @param {string} formName - unique key identifier for the form the filter lives in.
 * @param {object} config
 *  - onChange: Custom handler to run when the form values are changed.
 */
const useFilter = (formName, { onChange = null, persist = true } = {}) => {
  const didMount = useDidMount();
  const {
    state: { forms },
    dispatch,
  } = useContext(GlobalContext);

  const dispatchChange = useMemo(
    () =>
      debounce(
        (field, value) => dispatch(FORM_ACTIONS.change(formName, field, value)),
        QUARTER_SECOND
      ),
    [dispatch, formName]
  );

  const { values, initialValues } = useFormState({
    subscription: { values: true, initialValues: true },
  });

  const { change } = useForm();

  const prevValues = useRef(initialValues || {});

  const stateValues = forms[formName] || {};

  // On form change, update the global state with the changed values
  useEffect(() => {
    const diffValues = pickBy(values, (value, field) => value !== prevValues.current[field]);
    Object.entries(diffValues).forEach(([field, value]) => {
      dispatchChange(field, value);
    });
    const removedValues = omit(prevValues.current, Object.keys(values));
    Object.entries(removedValues).forEach(([field]) => {
      dispatchChange(field, null);
    });
    if (didMount && onChange) {
      onChange(values, prevValues.current);
    }
    prevValues.current = values;
  }, [values, dispatchChange, onChange, didMount]);

  // On load or if the initial values are updated, reset the form to the updated values
  useEffect(() => {
    dispatch(FORM_ACTIONS.reset(formName, initialValues));
  }, [initialValues, dispatch, formName]);

  // On unmount, clean up redux state if we don't want it preserved
  useEffect(
    () => {
      // Load saved values into component state
      const diffValues = pickBy(stateValues, (value, field) => value !== prevValues.current[field]);
      Object.entries(diffValues).forEach(([field, value]) => {
        change(field, value);
      });

      return () => {
        if (!persist) {
          dispatch(FORM_ACTIONS.unmount(formName));
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formName]
  );
};

export default useFilter;
