import React from "react";
import PropTypes from "prop-types";

import { Definition } from "yuka";

import { DETAILS_TAB } from "forms/EditConnectionForm";
import { SPV } from "utils/constants";
import { expandedMoneyFormat, numberFormat } from "utils/displayFormatUtils";

import EditAction from "./EditAction";

/**
 * Present the details that have been agreed to by both parties in a connection
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const NegotiatedTerms = ({ connection }) => (
  <>
    <EditAction title="Negotiated Terms" action={DETAILS_TAB} />
    <Definition
      label={connection.tradeType === SPV ? "Subscription Amount" : "Total Value"}
      value={expandedMoneyFormat(connection.totalValue)}
    />
    <Definition
      label="Price"
      value={`${expandedMoneyFormat(connection.pricePerShare, 2)} / share`}
    />
    {connection.tradeType !== SPV && (
      <Definition label="Quantity (# of shares)" value={numberFormat(connection.shareQuantity)} />
    )}
    <Definition label="Security" value={connection.security} />
    <Definition label="Type" value={connection.tradeType} />
  </>
);

NegotiatedTerms.propTypes = {
  connection: PropTypes.shape({
    totalValue: PropTypes.string,
    pricePerShare: PropTypes.string,
    shareQuantity: PropTypes.number,
    security: PropTypes.string,
    spv: PropTypes.bool,
    tradeType: PropTypes.string,
  }).isRequired,
};

export default NegotiatedTerms;
