import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { LinkIcon } from "yuka";

import { useWrite, QUERY_KEYS } from "api";

import { Input } from "forms/FormComponents";
import { required } from "utils/form/inputValidations";
import LoadingSpinner from "utils/LoadingSpinner";

/**
 * A basic form to create and update the Dropbox link on a Connection
 *
 * @returns {Element}
 */
const AttachDropboxLinkForm = ({ connection, onClose }) => {
  const { onSubmit } = useWrite(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId));
  const wrappedOnSubmit = (values, form) => {
    return onSubmit(values).then(() => {
      form.reset();
      onClose();
    });
  };
  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        apiId: connection.apiId,
        apiType: connection.apiType,
        tradeId: connection.tradeId,
        dropboxLink: connection.dropboxLink,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Input
            title="Attach Dropbox Link"
            name="dropboxLink"
            placeholder="Paste link"
            validate={[required]}
            trailingIcon={LinkIcon}
          />
          {submitting && <LoadingSpinner />}
        </form>
      )}
    </Form>
  );
};

AttachDropboxLinkForm.propTypes = {
  connection: PropTypes.shape({
    apiId: PropTypes.string,
    apiType: PropTypes.string,
    tradeId: PropTypes.string,
    dropboxLink: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AttachDropboxLinkForm;
