import PropTypes from "prop-types";

import { Select, Checkbox, InputRow } from "forms/FormComponents";
import {
  FORWARD,
  COMPANY_DECISION_BLOCKED,
  COMPANY_DECISION_APPROVED,
  COMPANY_DECISION_ASSIGNED_ROFR,
  COMPANY_DECISION_EXERCISED_ROFR,
} from "utils/constants";

import { required } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

const options = [
  { label: COMPANY_DECISION_APPROVED, value: COMPANY_DECISION_APPROVED },
  { label: COMPANY_DECISION_ASSIGNED_ROFR, value: COMPANY_DECISION_ASSIGNED_ROFR },
  { label: COMPANY_DECISION_EXERCISED_ROFR, value: COMPANY_DECISION_EXERCISED_ROFR },
  { label: COMPANY_DECISION_BLOCKED, value: COMPANY_DECISION_BLOCKED },
];

const SettlementFieldSet = ({ connection }) => {
  const decision = useFormValue("companyDecision");

  const isBlocked = decision === COMPANY_DECISION_BLOCKED;

  return (
    <>
      {connection.tradeType !== FORWARD && (
        <InputRow>
          <Select
            name="companyDecision"
            placeholder="Indicate company decision"
            validate={[required]}
            options={options}
            noSort
            popup
          />
        </InputRow>
      )}
      {isBlocked && (
        <InputRow>
          <Checkbox title="Convert to a forward contract" name="convertToForward" />
        </InputRow>
      )}
    </>
  );
};

SettlementFieldSet.propTypes = {
  connection: PropTypes.shape({
    tradeType: PropTypes.string,
  }).isRequired,
};

const cleanSubmitValues = values => {
  if (values.convertToForward) {
    values.tradeType = FORWARD;
  }
  delete values.convertToForward;
};

export default SettlementFieldSet;
export { cleanSubmitValues };
