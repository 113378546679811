import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Checkbox, Input, InputRow, FieldArray } from "forms/FormComponents";

const StyledWrapper = styled.div`
  ${InputRow} {
    margin-bottom: 4px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 24px;
  margin-left: 12px;
`;

const ContactDetailFieldSet = ({ name }) => (
  <InputRow>
    <Input name={`${name}.name`} title="Name" placeholder="e.g. Brad Dala" />
    <Input name={`${name}.position`} title="Title (Optional)" placeholder="e.g. CEO" />
    <Input name={`${name}.phone`} title="Phone (Optional)" placeholder="e.g. (555) 123-4567" />
    <Input name={`${name}.email`} title="Email (Optional)" placeholder="e.g. bdala@company.com" />
    <StyledCheckbox name={`${name}.isHighlighted`} title="Highlight in Profile" />
  </InputRow>
);

ContactDetailFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
};

/**
 * `<ContactDetailFieldset />` wrapped in a redux-form `<FieldArray />`
 *
 * @param {object} props
 * @returns {React.Element}
 */
const ContactDetailFields = props => (
  <StyledWrapper>
    <FieldArray
      {...props}
      name={props.name}
      component={ContactDetailFieldSet}
      addText="Add another contact"
    />
  </StyledWrapper>
);

ContactDetailFields.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ContactDetailFields;
