import { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Definition, body1, XCloseIcon, IconButton } from "yuka";

import { FieldPrefixContext, Select } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";
import { getCachedById, QUERY_KEYS } from "api";

const ACCESS_CHOICES = ["No access", "Can view", "Can edit"];

const OPTIONS = ACCESS_CHOICES.map(str => ({ label: str, value: str }));

const StyledLabel = styled.span`
  ${body1}
  text-transform: none;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 8px;
  margin-bottom: 8px;
`;

const StyledRow = styled.div`
  align-items: center;
  display: flex;
`;

const StyledDefinition = styled(Definition)`
  flex-grow: 1;
`;

/**
 * Displays the individual @ entity text in a definition and includes a select dropdown to
 * adjust participant permissions if eligible.
 *
 * Eligibility checked in this order:
 * - existing participant in API
 * - users in state with source matching individual
 * - API fetch for user with source matching individual
 *
 * @param {object} props
 *
 * @returns {Element}
 */
const TicketParticipantPermissionField = props => {
  const name = useContext(FieldPrefixContext);
  // If existing source, this is computed for us
  const isAccessEditableApi = useFormValue(`${name}isAccessEditable`);
  const ticketAccess = useFormValue(`${name}ticketAccess`);
  const individual = useFormValue(`${name}individual`);
  const entity = useFormValue(`${name}entity`);
  const individualNameForm = useFormValue(`${name}individualName`);
  const entityNameForm = useFormValue(`${name}entityName`);

  // Default fetched from server directly, secondary from connection fetch (form values),
  // third from new source being added
  const { name: individualName, isIndividualAccessEditable } = getCachedById(
    QUERY_KEYS.SOURCES,
    individual,
    "searchId"
  ) || { name: individualNameForm || individual };
  const { name: entityName } = getCachedById(QUERY_KEYS.SOURCES, entity, "searchId") || {
    name: entityNameForm || entity,
  };

  let sourceText = `${individualName} @ ${entityName}`;
  if (!individualName) {
    sourceText = entityName;
  } else if (!entityName) {
    sourceText = individualName;
  }
  sourceText = <StyledLabel>{sourceText}</StyledLabel>;

  // Default to existing access level
  let accessSelect = ticketAccess || "No access";
  if (isAccessEditableApi || isIndividualAccessEditable) {
    accessSelect = (
      <Select
        name="ticketAccess"
        options={OPTIONS}
        placeholder="No access"
        popup
        noSort
        clearable={false}
        width={150}
      />
    );
  }

  return (
    <StyledRow>
      <StyledDefinition label={sourceText} value={accessSelect} />
      <StyledIconButton onClick={props.remove} icon={XCloseIcon} />
    </StyledRow>
  );
};

TicketParticipantPermissionField.propTypes = {
  remove: PropTypes.func.isRequired,
};

export default TicketParticipantPermissionField;
