import React from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { Form } from "react-final-form";
import styled from "styled-components";

import { InfoCircleIcon, ColorPalette, caption2, FontColors, FormModal } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { DataTypes } from "api/constants";

import { QUERY_PARAM } from "forms/EditConnectionForm";
import LoadingSpinner from "utils/LoadingSpinner";

import ConnectionTypeField from "./ConnectionTypeField";

const StyledWarning = styled.div`
  display: flex;

  > svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

const StyledParagraph = styled.p`
  ${caption2}
  ${FontColors.yellow}
`;

/**
 * A basic form to edit the connection type
 *
 * @returns {Element}
 */
const EditConnectionTypeForm = props => {
  const [, setSearchParams] = useSearchParams();
  const onClose = () => {
    setSearchParams({ [QUERY_PARAM]: "" });
  };

  const { onSubmit } = useWrite(QUERY_KEYS.CONNECTIONS.detail(props.connection.tradeId));

  const wrappedOnSubmit = (...params) => {
    onSubmit(...params).then(() => {
      onClose();
    });
  };

  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        apiType: DataTypes.CONNECTIONS,
        apiId: props.connection.apiId,
        tradeId: props.connection.tradeId,
        tradeType: props.connection.tradeType,
      }}
    >
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit Connection Type"
        >
          {submitting && <LoadingSpinner />}
          <ConnectionTypeField />
          <StyledWarning>
            <InfoCircleIcon color={ColorPalette.alert} size={16} />
            <StyledParagraph>
              Note that changing the connection type will reset this trade to the execution phase.
              This change cannot be undone.
            </StyledParagraph>
          </StyledWarning>
        </FormModal>
      )}
    </Form>
  );
};

EditConnectionTypeForm.propTypes = {
  connection: PropTypes.shape({
    apiId: PropTypes.string,
    tradeId: PropTypes.string,
    tradeType: PropTypes.string,
  }).isRequired,
};

export default EditConnectionTypeForm;
