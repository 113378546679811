import React from "react";
import PropTypes from "prop-types";
import { HyperLink, LinkStyles, LinkTypes } from "yuka";

/**
 * Renders the name of the spv prefixed with a link to the master spv
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const SPVNameCell = ({ value: spv }) => {
  return (
    <div>
      {spv.parentName && (
        <>
          <HyperLink
            url={`/spvs/${spv.parentId}/`}
            linkType={LinkTypes.LOCAL_LINK}
            linkStyle={LinkStyles.DEFAULT}
            onClick={e => e.stopPropagation()}
          >
            {spv.parentName}
          </HyperLink>
          {" - "}
        </>
      )}
      {spv.name}
    </div>
  );
};

SPVNameCell.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    parentName: PropTypes.string,
  }).isRequired,
};

export default SPVNameCell;
