import { debounce } from "lodash-es";
import { useMemo, useContext } from "react";

import { mpTrack } from "utils/mixpanel";
import { HALF_SECOND } from "utils/constants";
import { isEqual, findKey, pickBy } from "utils";
import { CLIENT_PROFILE_FILTERS } from "forms/filterNames";
import { GlobalContext } from "utils/StateProvider";

import { KYC_OPTIONS, TYPE_OPTIONS } from "../constants";

const fieldToEventTypeMap = {
  name: "Name",
  zxRepresentatives: "Assigned ZX Rep",
  sourceType: "Type",
  kycStatus: "KYC Status",
};

const onChangeFilters = debounce(
  (values, prevValues) => {
    const changedValue = findKey(pickBy(values, (val, key) => prevValues[key] !== val));
    if (changedValue || !isEqual(values, prevValues)) {
      mpTrack("filter clients table", {
        type: fieldToEventTypeMap[changedValue] || "Reset",
      });
    }
  },
  HALF_SECOND,
  { trailing: true }
);

const useClientFilterConfig = tab => {
  const {
    state: { forms },
  } = useContext(GlobalContext);

  const formValues = pickBy(forms[CLIENT_PROFILE_FILTERS] || {});
  const queryParams = useMemo(
    () => ({
      "filter[search]": formValues.name,
      "filter[zxRepresentatives]": formValues.representative,
      "filter[sourceType]": formValues.sourceType,
      "filter[kycStatus]": formValues.kycStatus,
    }),
    [formValues]
  );

  return {
    queryParams,
    onChangeFilters,
    filterProps: {
      typeOptions: TYPE_OPTIONS[tab],
      initialValues: {
        representative: formValues.representative,
        sourceType: TYPE_OPTIONS[tab],
        kycStatus: KYC_OPTIONS,
      },
    },
  };
};

export default useClientFilterConfig;
