import { Types, LIST, DETAIL } from "./constants";

// Inspired by https://tkdodo.eu/blog/effective-react-query-keys
const keyBuilderFactory = type => {
  if (typeof type === "function") {
    const keys = {
      all: params => [type(params)],
      allLists: params => [...keys.all(params), LIST],
      list: (params, queryParams, routeParams = []) => [
        ...keys.allLists(params),
        ...routeParams,
        queryParams || {},
      ],
      allDetails: params => [...keys.all(params), DETAIL],
      detail: (params, id, routeParams = [], queryParams = {}) => [
        ...keys.allDetails(params),
        id,
        ...routeParams,
        queryParams,
      ],
    };
    return keys;
  }
  const keys = {
    all: [type],
    allLists: () => [...keys.all, LIST],
    list: queryParams => [...keys.allLists(), queryParams || {}],
    allDetails: () => [...keys.all, DETAIL],
    detail: (id, routeParams = [], queryParams = {}) => [
      ...keys.allDetails(),
      id,
      ...routeParams,
      queryParams,
    ],
  };
  return keys;
};

const keyGens = {};
for (const [key, type] of Object.entries(Types)) {
  keyGens[key] = keyBuilderFactory(type);
}

export default keyGens;
