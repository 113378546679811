import React from "react";
import PropTypes from "prop-types";

import { QUERY_KEYS, useFetch } from "api";
import NoteContentForm from "forms/NoteContentForm";
import { Button, Card, CardStyles, List } from "yuka";

import ConnectionNoteItem from "./ConnectionNoteItem";

const useFetchedConnectionNotes = tradeId => {
  return useFetch(QUERY_KEYS.NOTES.list(tradeId));
};

/**
 * Renders the notes card for a connection
 *
 * @param {object} props
 * @returns {React.Element}
 */
const ConnectionNotesCard = ({ connection }) => {
  const [isAddNoteModalOpen, setAddNoteModalOpen] = React.useState(false);
  const addNote = () => setAddNoteModalOpen(true);
  const {
    isLoading,
    isError,
    data: connectionNotes,
  } = useFetchedConnectionNotes(connection.tradeId);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }
  return (
    <Card
      cardStyle={CardStyles.TITLE}
      title="Notes"
      linkText="Add New"
      linkProps={{ onClick: addNote, as: Button }}
    >
      {isAddNoteModalOpen && (
        <NoteContentForm
          queryKey={QUERY_KEYS.NOTES}
          apiId={connection.tradeId}
          onClose={() => setAddNoteModalOpen(false)}
        />
      )}
      {connectionNotes.length > 0 && (
        <List divider>
          {connectionNotes.map(note => (
            <ConnectionNoteItem key={note.apiId} note={note} tradeId={connection.tradeId} />
          ))}
        </List>
      )}
    </Card>
  );
};

ConnectionNotesCard.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
  }).isRequired,
};

export default ConnectionNotesCard;
