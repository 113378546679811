import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Card, useCurrentTab } from "yuka";

import { getRelatedObj } from "api";
import { BUY, SELL } from "utils/constants";

import { RelationshipsContext } from "..";

import EditConnectionForm, { QUERY_PARAM } from "forms/EditConnectionForm";
import EditConnectionTypeForm from "forms/EditConnectionTypeForm";

import NegotiatedTerms from "./NegotiatedTerms";
import ConnectionDates from "./ConnectionDates";
import ExecutionInfo from "./ExecutionInfo";
import TicketInfo from "./TicketInfo";
import TicketCommission from "./TicketCommission";
import DisconnectDetails from "./DisconnectDetails";

const TYPE_FORM_PARAM = "Type";

/**
 * Sidebar of Connection Profile that has details about the connection
 *
 * @param {object} connection
 *
 * @returns {React.Element}
 */
const TradeDetailsCard = ({ connection }) => {
  const editTab = useCurrentTab(QUERY_PARAM);
  const relationships = useContext(RelationshipsContext);
  const buy = getRelatedObj(connection.buy, relationships);
  const sell = getRelatedObj(connection.sell, relationships);
  return (
    <Card title="Trade Details">
      <NegotiatedTerms connection={connection} />
      <ConnectionDates connection={connection} />
      <ExecutionInfo connection={connection} />
      <TicketInfo ticket={buy} type={BUY} />
      <TicketCommission connection={connection} ticket={buy} type={BUY} />
      <TicketInfo ticket={sell} type={SELL} />
      <TicketCommission connection={connection} ticket={sell} type={SELL} />
      {connection.disconnectedOn && <DisconnectDetails connection={connection} />}
      {editTab && editTab !== TYPE_FORM_PARAM && <EditConnectionForm connection={connection} />}
      {editTab === TYPE_FORM_PARAM && <EditConnectionTypeForm connection={connection} />}
    </Card>
  );
};

TradeDetailsCard.propTypes = {
  connection: PropTypes.shape({
    buy: PropTypes.arrayOf(PropTypes.string),
    sell: PropTypes.arrayOf(PropTypes.string),
    disconnectedOn: PropTypes.string,
  }).isRequired,
};

export default TradeDetailsCard;
