import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";

import ConnectionsTableView from "./ConnectionsTableView";
import ConnectionProfileView from "./ConnectionProfileView";
import { MixpanelSourceContext } from "utils/mixpanel";

import { TabNames } from "./constants";

const ConnectionsBase = ({ name, columns }) => (
  <Routes>
    <Route
      index
      element={
        <MixpanelSourceContext.Provider value="connection list">
          <ConnectionsTableView name={name} columns={columns} />
        </MixpanelSourceContext.Provider>
      }
    />
    <Route path=":id/" element={<ConnectionProfileView name={name} />} />
  </Routes>
);

ConnectionsBase.propTypes = {
  name: PropTypes.oneOf(Object.values(TabNames)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ConnectionsBase;
