import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import { FormModal, FinalFormField } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import { InputRow } from "forms/FormComponents";
import LoadingSpinner from "utils/LoadingSpinner";
import { required } from "utils/form/inputValidations";

/**
 * A basic form to cancel an onboarding request
 *
 * @returns {Element}
 */
const CancelOnboardingRequestForm = ({ latestOnboardingRequest, id, onClose }) => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.ONBOARDING_REQUEST.detail(latestOnboardingRequest?.apiId, ["void"]),
    { message: "Onboarding request cancelled" }
  );

  const wrappedOnSubmit = (values, ...params) => {
    onSubmit(values, ...params).then(() => {
      queryClient.invalidateQueries(QUERY_KEYS.CLIENT_PROFILES.detail(id));
      onClose();
    });
  };

  const initialValues = React.useMemo(
    () => ({
      apiId: latestOnboardingRequest.apiId,
      apiType: latestOnboardingRequest.apiType,
    }),
    [latestOnboardingRequest.apiId, latestOnboardingRequest.apiType]
  );

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Submit"
          title="Cancel Onboarding Request"
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <FinalFormField
              type="textarea"
              name="canceledReason"
              placeholder="Enter your note to the client here..."
              validate={[required]}
            />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

CancelOnboardingRequestForm.propTypes = {
  latestOnboardingRequest: PropTypes.shape({
    apiId: PropTypes.string,
    apiType: PropTypes.string,
    email: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelOnboardingRequestForm;
