import React from "react";
import PropTypes from "prop-types";

import { FinalFormField } from "yuka";

import { useFetch, QUERY_KEYS } from "api";
import { InputRow, Select } from "forms/FormComponents";
import NoticedByField from "forms/fields/NoticedByField";

/**
 * Field set for execution representative and noticed by fields
 * @param {object} buy - Buy side form data
 * @param {object} sell - Sell side form data
 * @returns {React.Element}
 */
const ExecutionFieldSet = ({ buy, sell }) => {
  const { data: executionReps } = useFetch(QUERY_KEYS.EXECUTION_REPS.list());
  return (
    <>
      <InputRow>
        <Select
          title="Execution Representative"
          name="executionRep"
          options={executionReps || []}
          optionLabelKey="name"
          optionValueKey="apiId"
          popup
          placeholder="Execution representative's name"
        />
      </InputRow>
      <InputRow>
        <FinalFormField
          component={NoticedByField}
          type="custom-block"
          name="noticedBy"
          sources={[...buy.sources, ...sell.sources]}
        />
      </InputRow>
    </>
  );
};

ExecutionFieldSet.propTypes = {
  buy: PropTypes.shape({
    sources: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  sell: PropTypes.shape({
    sources: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ExecutionFieldSet;
