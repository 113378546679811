import PropTypes from "prop-types";
import { useContext } from "react";
import { ROLE_BROKER } from "utils/constants";
import { getRelatedObj } from "../../../api";
import { RelationshipsContext } from "../index";

const BrokerCell = ({ value: connectedTicketId }) => {
  const relationships = useContext(RelationshipsContext);
  const sources = getRelatedObj(connectedTicketId, relationships).sources;

  let brokerNames = [];

  if (sources) {
    const brokerSourceObjects = sources
      .map(source => getRelatedObj(source, relationships))
      .filter(source => source.role === ROLE_BROKER);
    if (brokerSourceObjects.length > 0) {
      if (brokerSourceObjects.length > 1) {
        for (let i = 0; i < brokerSourceObjects.length; i++) {
          if (brokerSourceObjects[i].entityName) {
            brokerNames.push(brokerSourceObjects[i].entityName);
          } else if (brokerSourceObjects[i].individualName) {
            brokerNames.push(brokerSourceObjects[i].individualName);
          }
        }
        return brokerNames.join(", ");
      }
      if (brokerSourceObjects[0].entityName) {
        brokerNames.push(brokerSourceObjects[0].entityName);
      } else if (brokerSourceObjects[0].individualName) {
        brokerNames.push(brokerSourceObjects[0].individualName);
      }
      return brokerNames.join(", ");
    }
  }
  if (brokerNames.length > 0) {
    return brokerNames.join(", ");
  }
  return "";
};

BrokerCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

export default BrokerCell;
