import { queryClient } from "./queryClient";

import { fetchData, cleanJsonApiData } from "./fetchInternals";

/**
 * A simplified alternative to useFetch when hooks are unavailable. Initially, we are just handling
 * just the objects that are available at the top level.
 *
 * This is to optimize for the primary use case of needing it to load options in selects
 *
 * @param {Array} queryKey
 *
 * @returns {Promise}
 */
const fetchQuery = (queryKey, { dataOnly = true, rawData = false } = {}) =>
  queryClient.fetchQuery(queryKey, fetchData).then(queryData => {
    if (rawData) {
      return queryData;
    }
    const cleanedInfo = cleanJsonApiData(queryData);
    if (!dataOnly) {
      return cleanedInfo;
    }
    return cleanedInfo?.data;
  });

export default fetchQuery;
