import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import { AlertCircleIcon } from "../Icons";
import { PrimaryColorPalette } from "../StylingConstants";

import {
  StyledInputWrapper,
  StyledInput,
  SIZE_SMALL,
  SIZE_REGULAR,
} from "./internal/StyledComponents";

const StyledFileInputWrapper = styled(StyledInputWrapper)`
  cursor: default;
`;

/**
 * A standard element for uploading either a single file, or multiple files.
 */
const FileInput = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  const TrailingIcon = props.showError ? AlertCircleIcon : props.trailingIcon;
  const defaultIconColor = props.disabled
    ? PrimaryColorPalette.white15
    : PrimaryColorPalette.white30;
  const onChange = e => {
    props.onChange(e.target.files);
  };
  const size = props.small ? SIZE_SMALL : SIZE_REGULAR;
  const iconSize = props.small ? 16 : 18;

  return (
    <StyledFileInputWrapper $disabled={props.disabled} $error={props.showError} $size={size}>
      {props.leadingIcon && <props.leadingIcon size={iconSize} color={defaultIconColor} />}
      <StyledInput
        ref={ref || internalRef}
        {..._.omit(props, [
          "leadingIcon",
          "trailingIcon",
          "showError",
          "className",
          "style",
          "value",
        ])}
        onChange={onChange}
        type="file"
      />
      {TrailingIcon && (
        <TrailingIcon
          size={iconSize}
          color={props.showError ? PrimaryColorPalette.sell : defaultIconColor}
        />
      )}
    </StyledFileInputWrapper>
  );
});

FileInput.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  leadingIcon: PropTypes.func,
  trailingIcon: PropTypes.func,
  showError: PropTypes.bool,
  small: PropTypes.bool,
};

FileInput.defaultProps = {
  placeholder: "",
  disabled: false,
  style: null,
  multiple: false,
  showError: false,
  small: false,
};

export default FileInput;
