import {
  SUBSCRIBER_SOURCES,
  TRADING_GROUP_SOURCES,
  NON_SUBSCRIBER_SOURCES,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  CATEGORY_CLIENT_INDIVIDUAL,
  CATEGORY_CLIENT_ENTITY,
  CATEGORY_CLIENT_ENTITY_MANAGER,
  CATEGORY_REP_INDIVIDUAL,
  CATEGORY_REP_BRAND,
  CATEGORY_REP_BROKER_DEALER,
} from "utils/constants";

const VALID_UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;

const USER_TYPE_BD = "Broker Dealer";
const USER_TYPE_CLIENT = "Buyer/Seller";

const USER_TYPE_OPTIONS = [
  { label: USER_TYPE_BD, value: USER_TYPE_BD },
  { label: USER_TYPE_CLIENT, value: USER_TYPE_CLIENT },
];

const SUBTYPE_OPTIONS = {
  [USER_TYPE_BD]: [
    { label: SUBSCRIBER_SOURCES, value: SUBSCRIBER_SOURCES },
    { label: TRADING_GROUP_SOURCES, value: TRADING_GROUP_SOURCES },
    { label: NON_SUBSCRIBER_SOURCES, value: NON_SUBSCRIBER_SOURCES },
  ],
  [USER_TYPE_CLIENT]: [
    { label: ENTITY_SOURCES, value: ENTITY_SOURCES },
    { label: INDIVIDUAL_SOURCES, value: INDIVIDUAL_SOURCES },
    { label: TRUST_SOURCES, value: TRUST_SOURCES },
  ],
};

const getSourceTypeFromCategory = category => {
  if (!category) {
    return null;
  }
  if (category === CATEGORY_CLIENT_INDIVIDUAL) {
    return INDIVIDUAL_SOURCES;
  } else if ([CATEGORY_CLIENT_ENTITY, CATEGORY_CLIENT_ENTITY_MANAGER].includes(category)) {
    return ENTITY_SOURCES;
  } else if (category === CATEGORY_REP_INDIVIDUAL) {
    return NON_SUBSCRIBER_SOURCES;
  } else if (category === CATEGORY_REP_BRAND) {
    return TRADING_GROUP_SOURCES;
  } else if (category === CATEGORY_REP_BROKER_DEALER) {
    return SUBSCRIBER_SOURCES;
  }
  return null;
};

export {
  getSourceTypeFromCategory,
  USER_TYPE_BD,
  USER_TYPE_CLIENT,
  VALID_UUID_REGEX,
  USER_TYPE_OPTIONS,
  SUBTYPE_OPTIONS,
};
