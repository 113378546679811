import TemplateActionsCell from "./TemplateActionsCell";
import { DateCell } from "yuka";

const TemplateNameColumn = {
  accessor: "name",
  width: 500,
  header: "Template",
};

const SizeColumn = {
  accessor: "file_size",
  width: 180,
  header: "Size",
};

const UploadDateColumn = {
  accessor: "created_on",
  width: 180,
  header: "Date Uploaded",
  emptyPlaceholder: "--",
  cellRenderer: DateCell,
};

const ActionsColumn = {
  id: "actions",
  width: 60,
  header: "Actions",
  accessor: template => template,
  cellRenderer: TemplateActionsCell,
};

const columns = [TemplateNameColumn, SizeColumn, UploadDateColumn, ActionsColumn];

export default columns;
