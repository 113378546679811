import { createContext, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { FloatingNavigation, NavigationItem, useTabRoute, ValueCard } from "yuka";

import { QUERY_KEYS, getRelatedObj, getRelatedId, useFetch } from "api";
import { MixpanelSourceContext, mpTrack } from "utils/mixpanel";
import { FrontendError, Loading } from "templates/ErrorPages";

import ConnectionsTab from "./ConnectionsTab";
import ContactsTab from "./ContactsTab";
import DocumentsTab from "./DocumentsTab";
import TradingGroupsTab from "./TradingGroupsTab";
import FundsTab from "./FundsTab";
import NotesCard from "./NotesCard";
import ClientProfileHeader from "./ClientProfileHeader";
import SettingsTab from "./SettingsTab";

import {
  DOCUMENTS,
  CONNECTIONS,
  TRADING_GROUPS,
  FUNDS,
  CONTACTS,
  NOTES,
  SETTINGS,
  ALL_TAB_NAMES,
  getTabsForProfile,
} from "./constants";

const RelationshipsContext = createContext(null);

const StyledClientProfile = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
  height: 100%;
  min-height: 0;
`;

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledValueCard = styled(ValueCard)`
  width: 224px;
`;

const ClientProfileView = () => {
  const [currentTab, setCurrentTab] = useTabRoute(ALL_TAB_NAMES, "tab");
  const { id } = useParams();

  const queryParams = {
    include: "source,contacts,zxRepresentatives,notes,currentDocuments,latestOnboardingRequest",
  };

  const { isError, isLoading, data, relationships } = useFetch(
    QUERY_KEYS.CLIENT_PROFILES.detail(id, [], queryParams)
  );

  const documents = useMemo(() => {
    return data?.currentDocuments.map(document => getRelatedObj(document, relationships));
  }, [data, relationships]);
  const contacts = useMemo(
    () => data?.contacts.map(contact => getRelatedObj(contact, relationships)),
    [data, relationships]
  );
  const representatives = useMemo(
    () =>
      data?.zxRepresentatives.map(representative => getRelatedObj(representative, relationships)),
    [data, relationships]
  );

  const mpTrackTabChange = useCallback(
    tab => {
      setCurrentTab(tab);
      mpTrack("toggle client profile tab", { tab });
    },
    [setCurrentTab]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <FrontendError />;
  }

  const source = getRelatedObj(data.source, relationships);
  const notes = data.notes.map(note => getRelatedObj(note, relationships));
  const latestOnboardingRequest = getRelatedObj(data.latestOnboardingRequest, relationships);

  const highlightedContacts = contacts.filter(contact => contact.isHighlighted);

  const TAB_NAMES = getTabsForProfile(data.sourceType);

  return (
    <MixpanelSourceContext.Provider value="client profile">
      <RelationshipsContext.Provider value={relationships}>
        <ClientProfileHeader
          source={source}
          clientProfile={data}
          id={id}
          latestOnboardingRequest={latestOnboardingRequest}
          representatives={representatives}
        />
        <StyledClientProfile>
          <StyledSideBar>
            <FloatingNavigation>
              {TAB_NAMES.map(tab => (
                <NavigationItem
                  key={tab}
                  onClick={() => mpTrackTabChange(tab)}
                  selected={tab === currentTab}
                >
                  {tab}
                </NavigationItem>
              ))}
            </FloatingNavigation>
            {highlightedContacts.map(contact => (
              <StyledValueCard
                key={contact.email}
                headline="Primary Contacts"
                value={contact.name}
                supportingText={contact.position}
                additionalText={contact.email}
              />
            ))}
          </StyledSideBar>
          {currentTab === DOCUMENTS && <DocumentsTab documents={documents} sourceProfile={data} />}
          {currentTab === CONNECTIONS && <ConnectionsTab source={source} />}
          {currentTab == TRADING_GROUPS && <TradingGroupsTab source={source} />}
          {currentTab == FUNDS && <FundsTab source={source} />}
          {currentTab === CONTACTS && (
            <ContactsTab sourceProfile={data} source={source} contacts={contacts} />
          )}
          {currentTab === NOTES && <NotesCard notes={notes} sourceId={getRelatedId(data.source)} />}
          {currentTab == SETTINGS && (
            <SettingsTab
              contacts={contacts}
              representatives={representatives}
              source={source}
              sourceProfile={data}
            />
          )}
        </StyledClientProfile>
      </RelationshipsContext.Provider>
    </MixpanelSourceContext.Provider>
  );
};

export default ClientProfileView;
