import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Field } from "react-final-form";

import { FieldPrefixContext } from "../Form/FormSection";
import TYPE_MAPPING, { DEFAULT_MAPPING } from "./fieldMapping";
import ChangeListener from "./internal/ChangeListener";

// When submitting forms that have cleared values, i.e. clearing the field 'assigned-rep', the
// 'assigned-rep' key does not appear by default in the submit data object because its value
// has become "undefined". We want `assigned-rep: undefined` to show up in our form submit data
// to indicate cleared values in update forms.
const identity = value => value;

// Historically redux-form passed a validate prop that was an array. react-final-form expects a
// function. This coerces the old structure to be valid while not breaking anything that doesn't
// require coercion.
const composeValidators = validators => {
  if (_.isFunction(validators)) {
    return validators;
  }
  return value => validators.reduce((error, validator) => error || validator(value), undefined); // eslint-disable-line no-undefined
};

/**
 * An HTML input (or other form field) connected to react final form
 */
const FinalFormField = React.forwardRef((props, ref) => {
  const prefix = React.useContext(FieldPrefixContext);
  const name = `${prefix}${props.name}`;
  const idProp = props.id || name;

  const [Component, Wrapper, additionalProps] = TYPE_MAPPING[props.type] || DEFAULT_MAPPING;

  const memoizedComponent = React.useCallback(
    memProps => <Wrapper ref={ref} {...memProps} component={Component || props.component} />,
    [Wrapper, Component, props.component, ref]
  );

  const field = (
    <Field
      {...props}
      {...additionalProps}
      name={name}
      component={memoizedComponent}
      id={idProp}
      validate={composeValidators(props.validate)}
    />
  );

  if (props.type === "hidden") {
    return field;
  }

  const onChange = props.handleChange || props.onChange; // eslint-disable-line react/prop-types

  return (
    <>
      {field}
      <ChangeListener name={name} onChange={onChange} />
    </>
  );
});

FinalFormField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  parse: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.arrayOf(PropTypes.func),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

FinalFormField.defaultProps = {
  // eslint-disable-next-line no-undefined
  id: undefined,
  label: null,
  required: false,
  disabled: false,
  type: "text",
  className: "",
  onChange: _.noop,
  parse: identity,
  validate: [],
};

FinalFormField.displayName = "FinalFormField";

export default FinalFormField;
