import PropTypes from "prop-types";
import { InputRow, DatePicker } from "forms/FormComponents";
import { compact } from "utils";
import { DIRECT, COMPANY_DECISION_APPROVED } from "utils/constants";

import { beforeToday, required } from "utils/form/inputValidations";

const InvoicingFieldSet = ({ connection }) => {
  const buyRequired =
    connection.tradeType !== DIRECT || connection.companyDecision === COMPANY_DECISION_APPROVED;

  return (
    <>
      <InputRow>
        <DatePicker
          maxDate={new Date()}
          name="buy.dealFileReviewedOn"
          placeholder="Buyer deal file reviewed date"
          title="Buyer deal file reviewed date"
          validate={compact([beforeToday, buyRequired && required])}
        />
      </InputRow>
      <InputRow>
        <DatePicker
          maxDate={new Date()}
          name="sell.dealFileReviewedOn"
          placeholder="Seller deal file reviewed date"
          title="Seller deal file reviewed date"
          validate={[beforeToday, required]}
        />
      </InputRow>
    </>
  );
};

InvoicingFieldSet.propTypes = {
  connection: PropTypes.shape({
    tradeType: PropTypes.string,
    companyDecision: PropTypes.string,
  }).isRequired,
};

export default InvoicingFieldSet;
