import { useState } from "react";
import styled from "styled-components";
import { Card, CardStyles, TabList, useCurrentTab, Button, PlusIcon, Table } from "yuka";

import { QUERY_KEYS, useInfiniteFetch } from "api";
import Filter from "forms/Filter";
import { CLIENT_PROFILE_FILTERS } from "forms/filterNames";
import AddClientForm from "forms/AddClientForm";

import { TABS } from "./constants";
import ClientFilter, { useClientFilterConfig } from "./ClientFilter";
import { columns, useClientTableConfig } from "./tableConfig";
import RelationshipsContext from "./relationshipContext";

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

const ClientTableView = () => {
  const currentTab = useCurrentTab();
  const [addModalOpen, setAddModalOpen] = useState(false);

  const startAddFlow = () => setAddModalOpen(true);
  const closeModal = () => setAddModalOpen(false);

  const {
    queryParams: filterQueryParams,
    onChangeFilters,
    filterProps,
  } = useClientFilterConfig(currentTab);
  const { onRowClick, sortState, sortQueryParam, onSort } = useClientTableConfig();

  const queryParams = {
    sort: sortQueryParam,
    "page[size]": 50,
    include: "source,zxRepresentatives",
    ...filterQueryParams,
  };

  const { isFetching, isLoading, data, fetchNextPage, isFetchingNextPage, relationships } =
    useInfiniteFetch(QUERY_KEYS.CLIENT_PROFILES.list({ ...queryParams }));

  const cardLink = (
    <Button leadingIcon={PlusIcon} onClick={startAddFlow}>
      Add New Profile
    </Button>
  );

  return (
    <Card
      cardStyle={CardStyles.SECTIONED}
      title="Clients"
      linkText={cardLink}
      linkProps={{ as: "div" }}
    >
      <PaddedWrapper>
        <TabList tabs={TABS} />
      </PaddedWrapper>
      <PaddedWrapper>
        <Filter
          name={CLIENT_PROFILE_FILTERS}
          filterComponent={ClientFilter}
          onChange={onChangeFilters}
          isLoading={isFetching}
          {...filterProps}
        />
      </PaddedWrapper>
      <RelationshipsContext.Provider value={relationships}>
        <Table
          usePercentageColumnWidths
          manualSortBy
          sortState={sortState}
          onSort={onSort}
          paginationFunc={fetchNextPage}
          isPaginationLoading={isFetchingNextPage}
          onRowClick={onRowClick}
          isLoading={isLoading}
          data={data || []}
          columns={columns}
        />
      </RelationshipsContext.Provider>
      {addModalOpen && <AddClientForm closeModal={closeModal} />}
    </Card>
  );
};

export default ClientTableView;
