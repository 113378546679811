import { useMemo } from "react";
import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";
import useTableSort from "utils/tables/useTableSort";
import { BadgeCell } from "yuka";
import DropdownCell from "./DropdownCell";
import PinCell from "./PinCell";
import PreviewCell from "./PreviewCell";
import TypeCell from "./TypeCell";
import UploadCell from "./UploadCell";

const TABLE_NAME = "SPV_CLASS_DOCUMENTS";

const useDocumentsTableConfig = includeArchived => {
  const currentTableSort = useSelector(state => state?.tableSort[TABLE_NAME]);
  const dispatch = useDispatch();
  const onSort = useTableSort(ACTIONS, dispatch, TABLE_NAME);

  const sortState = useMemo(() => {
    if (includeArchived) {
      return [{ id: "latestVersionOrSelf" }, { id: "updatedOn", desc: true }];
    }
    return currentTableSort && currentTableSort.length ? currentTableSort : [];
  }, [currentTableSort, includeArchived]);

  return {
    sortState,
    onSort,
  };
};

const UploadColumn = {
  id: "upload",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: UploadCell,
};
const DropdownColumn = {
  id: "dropdown",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: DropdownCell,
};

const PinColumn = {
  id: "pin",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: PinCell,
};

const NameColumn = {
  id: "name",
  accessor: "name",
  width: 28,
  header: "Document Name",
  sortable: true,
  sticky: true,
  cellRenderer: PreviewCell,
};

const TypeColumn = {
  id: "type",
  accessor: "documentType",
  width: 28,
  header: "Type",
  sortable: true,
  cellRenderer: TypeCell,
};

const StatusColumn = {
  id: "status",
  accessor: "status",
  width: 17,
  header: "Status",
  sortable: true,
  cellRenderer: BadgeCell,
};

const columns = [UploadColumn, DropdownColumn, PinColumn, NameColumn, TypeColumn, StatusColumn];

export { useDocumentsTableConfig, columns };
