/**
 * Base class for front-end middleware.
 * All of our middleware classes should extend this.
 */
export default class BaseMiddleware {
  constructor() {
    this.processRequest = this.processRequest.bind(this);
    this.processRequestError = this.processRequestError.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processResponseError = this.processResponseError.bind(this);
  }

  /**
   * Called on a successful request.
   *
   * See https://github.com/axios/axios#request-config for config options
   *
   * @param {object} config
   * @returns {object}
   */
  processRequest(config) {
    return config;
  }

  /**
   * Called on a rejected request.
   *
   * See https://github.com/axios/axios#handling-errors for error options
   *
   * @param {object} error
   * @returns {Promise}
   */
  processRequestError(error) {
    return Promise.reject(error);
  }

  /**
   * Called on a successful response.
   *
   * See https://github.com/axios/axios#response-schema for response options
   *
   * @param {object} response
   * @returns {object}
   */
  processResponse(response) {
    return response;
  }

  /**
   * Called on a rejected response.
   *
   * See https://github.com/axios/axios#handling-errors for error options
   *
   * @param {object} error
   * @returns {Promise}
   */
  processResponseError(error) {
    return Promise.reject(error);
  }
}
