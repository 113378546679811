const BUYSIDE_BROKER_DEALER_KYC = "Buyside Broker Dealer KYC";
const BUYSIDE_INDIVIDUAL_CLIENT_KYC = "Buyside Individual Client KYC";
const BUYSIDE_ENTITY_KYC = "Buyside Entity KYC";
const SELLSIDE_BROKER_DEALER_KYC = "Sellside Broker Dealer KYC";
const SELLSIDE_INDIVIDUAL_CLIENT_KYC = "Sellside Individual Client KYC";
const SELLSIDE_ENTITY_KYC = "Sellside Entity KYC";

export {
  BUYSIDE_BROKER_DEALER_KYC,
  BUYSIDE_INDIVIDUAL_CLIENT_KYC,
  BUYSIDE_ENTITY_KYC,
  SELLSIDE_BROKER_DEALER_KYC,
  SELLSIDE_INDIVIDUAL_CLIENT_KYC,
  SELLSIDE_ENTITY_KYC,
};
