import React from "react";
import { FinalFormField, StyledBody2 } from "yuka";

import { Input, InputRow } from "forms/FormComponents";
import { required, requiredObject } from "utils/form/inputValidations";

import { loadCompanyOptions } from "./loadCompanyOptions";

const MasterSPVFieldSet = () => (
  <>
    <InputRow>
      <Input name="name" title="Master Name" placeholder="e.g. ZPF Master" validate={[required]} />
    </InputRow>
    <InputRow>
      <StyledBody2>Add a corresponding Series and Class</StyledBody2>
    </InputRow>
    <InputRow>
      <Input
        name="seriesName"
        title="Series Name"
        placeholder="e.g. Series I"
        validate={[required]}
      />
      <FinalFormField
        type="autocomplete"
        loadOptions={loadCompanyOptions}
        name="company"
        placeholder="e.g. OpenAI"
        label="Portfolio Company"
        validate={[requiredObject]}
      />
    </InputRow>
    <InputRow>
      <Input name="className" title="Class Name" placeholder="e.g. Class A" />
      <Input
        name="underlyingFund"
        title="Underlying Fund (optional)"
        placeholder="e.g. Sequoia Capital I"
      />
    </InputRow>
  </>
);

export default MasterSPVFieldSet;
