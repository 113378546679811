import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { FormModal } from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { DataTypes } from "api/constants";
import LoadingSpinner from "utils/LoadingSpinner";

import ContactDetailFields from "./ContactDetailFields";

/**
 * A form to edit client contact info
 *
 * @returns {Element}
 */
const EditClientContactsForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_PROFILES.detail(props.source.apiId));
  const wrappedOnSubmit = (values, ...params) =>
    onSubmit(values, ...params).then(() => {
      props.onClose();
    });
  const initialValues = React.useMemo(
    () => ({
      ...props.sourceProfile,
      apiType: DataTypes.CLIENT_PROFILES,
      apiId: props.source.apiId,
      contacts: props.contacts,
    }),
    [props.sourceProfile, props.source.apiId, props.contacts]
  );
  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={props.onClose}
          onSubmit={handleSubmit}
          submitText="Save"
          title="Edit Client Details"
        >
          {submitting && <LoadingSpinner />}
          <ContactDetailFields name="contacts" />
        </FormModal>
      )}
    </Form>
  );
};

EditClientContactsForm.propTypes = {
  sourceProfile: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  contacts: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditClientContactsForm;
