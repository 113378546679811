import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import styled from "styled-components";
import { Button, IconButton, XCloseIcon, Definition, StyledBody2, StyledCaption2 } from "yuka";

import { DataTypes, getCachedById, QUERY_KEYS } from "api";
import { InputRow, FieldPrefixContext } from "forms/FormComponents";
import MarketOperatorSelect from "forms/FormComponents/MarketOperatorSelect";
import { requiredArray } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

const StyledInputRow = styled(InputRow)`
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;

  // Workaround to fix bottom aligned default alignment
  > .flex-input + .btn {
    margin-top: 0;
  }
`;

const StyledRow = styled.div`
  align-items: center;
  display: flex;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 8px;
  margin-bottom: 8px;
`;

const StyledDefinition = styled(Definition)`
  flex-grow: 1;
`;

const StyledHelpText = styled(StyledCaption2)`
  margin-top: -4px;
`;

const FieldArrayComponent = ({ fields }) => {
  const tempName = "marketOperatorsUncommitted";
  const prefix = React.useContext(FieldPrefixContext);
  const value = useFormValue(`${prefix}${tempName}`);
  const { change } = useForm();

  const addValue = () => {
    fields.push({
      ...value,
      apiType: DataTypes.MARKET_OPERATORS,
    });
    change(`${prefix}${tempName}`, "");
  };

  return (
    <>
      <StyledInputRow>
        <MarketOperatorSelect placeholder="Market operator's name" name={tempName + ".apiId"} />
        <Button disabled={!value} onClick={addValue}>
          Add
        </Button>
      </StyledInputRow>
      <StyledHelpText>Click add to save changes</StyledHelpText>
      {fields.map((field, index) => {
        const mo = fields.value[index];
        // Either full object from relationships, or lookup from newly added
        const name = mo?.name || getCachedById(QUERY_KEYS.MARKET_OPERATORS, mo.apiId)?.name;
        return (
          <StyledRow key={mo.apiId}>
            <StyledDefinition label={name} value="Can edit" />
            <StyledIconButton onClick={() => fields.remove(index)} icon={XCloseIcon} />
          </StyledRow>
        );
      })}
    </>
  );
};

FieldArrayComponent.propTypes = {
  fields: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

/**
 * Set of fields for updating the responsible parties on an ticket.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const TicketMOsFieldSet = () => {
  const namePrefix = React.useContext(FieldPrefixContext);
  return (
    <>
      <StyledBody2>Market Operator(s)</StyledBody2>
      <FieldArray
        validate={requiredArray}
        component={FieldArrayComponent}
        name={`${namePrefix}marketOperators`}
      />
    </>
  );
};

export default TicketMOsFieldSet;
