import isObject from "./isObject";

function isEqual(val1, val2) {
  if (isObject(val1) && isObject(val2)) {
    let equal = true;
    Object.entries(val1).forEach(([k, v]) => {
      if (!isEqual(v, val2[k])) {
        equal = false;
      }
    });
    return equal;
  }
  return val1 === val2;
}

export default isEqual;
