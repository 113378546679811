/* eslint-disable no-console */
// We bypass JSON:API formatting and other utils we have, just post directly
import axios from "axios";

import { properties, peopleProperties } from "./properties";

const InitMixpanel = () => {
  axios.post("mp-people/", peopleProperties());
};

const mpTrack = (eventName, data) => {
  const trackData = data || {};
  trackData["time since page load"] = (new Date() - window.mpTimePageLoad) / 100;
  trackData["event version"] = trackData["event version"] || 1;

  // dev logger to easily see what Mixpanel events we're triggering
  if (
    process.env.NODE_ENV !== "production" &&
    process.env.NODE_ENV !== "test" &&
    process.env.ENABLE_MIXPANEL_LOGGER === "on"
  ) {
    console.groupCollapsed(
      `%cMixpanel: ${eventName} @ ${new Date().toLocaleTimeString()}`,
      "background: #4f44e0; color: #e9e9e9"
    );
    console.log(trackData);
    console.groupEnd();
  }

  axios.post(
    "mp-track/",
    { event: eventName, ...properties(), ...trackData },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const mpIncrementPeople = eventName => {
  axios.post("mp-people-increment/", { event: eventName });
};

export { default as MixpanelSourceContext, useMpSource } from "./SourceContext";
export { InitMixpanel, mpTrack, mpIncrementPeople };
