import _ from "lodash";

/**
 * Match compare string by each part in the array of search strings (split by spaces).
 *
 * @param {string} searchString
 * @param {string} compareString
 * @returns {boolean}
 */
export default (searchString, compareString) => {
  const tokenizedSearch = _.toLower(searchString).trim().split(/\s+/);
  return _.every(tokenizedSearch, keyword => _.includes(_.toLower(compareString), keyword));
};
