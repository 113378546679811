import PropTypes from "prop-types";
import styled from "styled-components";
import { useForm, useFormState } from "react-final-form";
import { InputRow, Button, RefreshIcon } from "yuka";

import LoadingSpinner from "utils/LoadingSpinner";
import { Input } from "forms/FormComponents";

const StyledWrapper = styled(InputRow)`
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const StyledLoadingSpinner = styled.div`
  margin: auto 0 auto auto;
`;

const SPVTableFilter = ({ isLoading }) => {
  const { reset } = useForm();
  const { dirty } = useFormState();

  return (
    <StyledWrapper>
      <Input name="name" placeholder="Name" />
      <Input name="parentName" placeholder="Master Name" />
      <Input name="companyName" placeholder="Company" />
      <StyledButton disabled={!dirty} leadingIcon={RefreshIcon} onClick={() => reset()}>
        Reset Filters
      </StyledButton>
      {isLoading && (
        <StyledLoadingSpinner>
          <LoadingSpinner showContainer={false} />
        </StyledLoadingSpinner>
      )}
    </StyledWrapper>
  );
};

SPVTableFilter.propTypes = {
  isLoading: PropTypes.bool,
};

SPVTableFilter.defaultProps = {
  isLoading: false,
};

export default SPVTableFilter;
