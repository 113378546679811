import React, { createContext, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, LinkTypes, MailIcon, Table, TabList, useCurrentTab } from "yuka";

import { QUERY_KEYS, useFetch } from "api";

import { completedColumns, pendingColumns, inviteSentColumns } from "./columns";

const StyledCard = styled(Card)`
  width: 100%;
`;

const INVESTORS_QUERY_PARAM = "investors";
const COMPLETED = "Completed";
const PENDING = "Pending";
const INVITE_SENT = "Invite Sent";

const RelationshipsContext = createContext(null);

/**
 * Renders a card for the investors in an spv class
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const InvestorsCard = ({ spvClass }) => {
  const currentTab = useCurrentTab(INVESTORS_QUERY_PARAM);
  const navigate = useNavigate();

  // Get SPV Invites
  const spvInviteQueryParams = {
    "filter[spv_class]": spvClass.apiId,
    include: "marketOperator",
  };
  const {
    isError: isErrorSPVInvites,
    isLoading: isLoadingSPVInvites,
    data: spvInvites,
    relationships: spvInviteRelationships,
  } = useFetch(QUERY_KEYS.SPV_INVITE.list({ ...spvInviteQueryParams }));

  // Get Onboard requests
  const onboardRequestQueryParams = {
    "filter[spv_class]": spvClass.apiId,
    include: "spvInvite",
  };
  const {
    isError: isErrorOnboardingRequests,
    isLoading: isLoadingOnboardingRequests,
    data: onboardingRequests,
    relationships: onboardingRequestRelationships,
  } = useFetch(QUERY_KEYS.ONBOARDING_REQUEST.list({ ...onboardRequestQueryParams }));

  const completedRequests = useMemo(
    () => onboardingRequests?.filter(request => request.completedOn),
    [onboardingRequests]
  );
  const pendingRequests = useMemo(
    () => onboardingRequests?.filter(request => !request.completedOn),
    [onboardingRequests]
  );

  const tabArray = useMemo(
    () => [
      {
        label: COMPLETED,
        count: completedRequests?.length,
      },
      { label: PENDING, count: pendingRequests?.length },
      { label: INVITE_SENT, count: spvInvites?.length },
    ],
    [completedRequests, pendingRequests, spvInvites]
  );

  const navigateToSourceProfile = useCallback(
    ({ row: { original: onboardingRequest } }) => {
      navigate(`/clients/${onboardingRequest.sourceId}/`);
    },
    [navigate]
  );

  // Onboard investors
  const newInvestor = (
    <Button
      leadingIcon={MailIcon}
      url={`/spvs/class/onboarding/${spvClass.apiId}`}
      linkType={LinkTypes.LOCAL_LINK}
    >
      Invite Investors
    </Button>
  );

  if (isErrorSPVInvites) {
    return <div>An error has occurred loading Invites Sent</div>;
  } else if (isErrorOnboardingRequests) {
    return <div>An error has occurred loading Completed and Pending Requests</div>;
  }

  return (
    <StyledCard title="Investors" headerTrailingContent={newInvestor}>
      <TabList tabs={tabArray} paramName={INVESTORS_QUERY_PARAM} />
      <RelationshipsContext.Provider
        value={{ spvInviteRelationships, onboardingRequestRelationships }}
      >
        {currentTab === COMPLETED && (
          <Table
            usePercentageColumnWidths
            emptyTablePlaceholder="No Onboarded Investors"
            isLoading={isLoadingOnboardingRequests}
            data={completedRequests || []}
            columns={completedColumns}
            navigateToSourceProfile={navigateToSourceProfile}
          />
        )}
        {currentTab === PENDING && (
          <Table
            usePercentageColumnWidths
            emptyTablePlaceholder="No Investors Pending Onboarding"
            isLoading={isLoadingOnboardingRequests}
            data={pendingRequests || []}
            columns={pendingColumns}
          />
        )}
        {currentTab === INVITE_SENT && (
          <Table
            usePercentageColumnWidths
            emptyTablePlaceholder="No Invites Sent"
            isLoading={isLoadingSPVInvites}
            data={spvInvites || []}
            columns={inviteSentColumns}
          />
        )}
      </RelationshipsContext.Provider>
    </StyledCard>
  );
};

InvestorsCard.propTypes = {
  spvClass: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvestorsCard;
export { RelationshipsContext };
