const DISCONNECT_SOURCE_CHOICES = ["Buy Broker", "Sell Broker", "Issuer"];

const BUY_REASONS = [
  "Client doesn't have funds",
  "Client doesn't have investment committee approval",
  "Client changes their mind on doing the deal at all",
  "Client changes terms of the trade",
  "Client won’t sign fee agreement",
  "Client is fishing",
  "Other (user error, duplicate, bad data)",
].map(reason => ({ label: reason, value: reason }));

const SELL_REASONS = [
  "Client doesn't own the stock in structure conveyed",
  "Client doesn't have investment committee approval",
  "Client changes their mind on doing the deal at all",
  "Client changes terms of the trade",
  "Client won’t sign fee agreement",
  "Client is fishing",
  "Other (user error, duplicate, bad data)",
].map(reason => ({ label: reason, value: reason }));

const ISSUER_REASONS = ["Company Blocked Trade", "Other (user error, duplicate, bad data)"].map(
  reason => ({
    label: reason,
    value: reason,
  })
);

export { DISCONNECT_SOURCE_CHOICES, BUY_REASONS, SELL_REASONS, ISSUER_REASONS };
