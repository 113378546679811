import React from "react";
import styled from "styled-components";

import { DelimitedNumberInput, InputRow, RadioGroup, Select } from "forms/FormComponents";
import { isInteger, isNotNegative, isValidDecimal } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

import {
  divisionOfFeesOptions,
  feeAgreementOptions,
  FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE,
  FEE_AGREEMENT_TYPE_RATE_CARD,
  FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES,
} from "./constants";

const StyledFractionOfFeesContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledDivisionSpan = styled.span`
  margin-top: 20px;
`;

/**
 * Field set for client default fee agreement
 *
 * @returns {Element}
 */
const FeeAgreementInvoicingFieldSet = () => {
  const feeAgreementType = useFormValue("feeAgreementType");
  const feeAgreementDivisionOfFees = useFormValue("feeAgreementDivisionOfFees");
  return (
    <>
      <InputRow>
        <RadioGroup
          label="Default Fee Agreement"
          items={feeAgreementOptions}
          name="feeAgreementType"
        />
      </InputRow>
      {(feeAgreementType === FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE ||
        feeAgreementType === FEE_AGREEMENT_TYPE_RATE_CARD) && (
        <>
          <InputRow>
            <Select
              name="feeAgreementDivisionOfFees"
              options={divisionOfFeesOptions}
              placeholder="Unit"
              label="Unit"
              noSort
            />
          </InputRow>
          <InputRow>
            {feeAgreementDivisionOfFees === FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES ? (
              <StyledFractionOfFeesContainer>
                <DelimitedNumberInput
                  title="Numerator"
                  name="feeAgreementAmountNumerator"
                  placeholder="E.g. 1"
                  validate={[isNotNegative, isInteger]}
                />
                <StyledDivisionSpan> / </StyledDivisionSpan>
                <DelimitedNumberInput
                  title="Denominator"
                  name="feeAgreementAmountDenominator"
                  placeholder="E.g. 3"
                  validate={[isNotNegative, isInteger]}
                />
              </StyledFractionOfFeesContainer>
            ) : (
              <DelimitedNumberInput
                title="Amount"
                name="feeAgreementAmount"
                placeholder="E.g. 2"
                validate={[isNotNegative, isValidDecimal]}
              />
            )}
          </InputRow>
        </>
      )}
    </>
  );
};

export default FeeAgreementInvoicingFieldSet;
