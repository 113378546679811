import convertNumToReadable from "./convertNumToReadable";
import { isString, last } from "utils";

const FIVE_MILLION = 5000000;
const TEN_MILLION = 10000000;
const TWENTY_FIVE_MILLION = 25000000;
const HUNDRED_MILLION = 100000000;

/**
 * Convert a number into a bucketed number (e.g: 11mn => 10mn - 25mn).
 * This has some similarities to ``convert_total_value_bucketed``
 *
 * @param {number} numRaw - the number to be converted
 * @param {number} precision - optional argument to specify number of decimal places
 * @returns {string} bucket value representation of num
 */
const convertTotalValueBucketed = (numRaw, precision) => {
  const num = isString(numRaw) ? Number.parseFloat(numRaw) : numRaw;

  const totalValueBuckets = [FIVE_MILLION, TEN_MILLION, TWENTY_FIVE_MILLION, HUNDRED_MILLION];

  let bucketString = `>$${convertNumToReadable(last(totalValueBuckets), precision)}`;
  // eslint-disable-next-line consistent-return
  totalValueBuckets.forEach((bucketValue, index) => {
    if (num < bucketValue) {
      bucketString = index
        ? `$${convertNumToReadable(totalValueBuckets[index - 1], precision)} - ` +
          `$${convertNumToReadable(bucketValue, precision)}`
        : `<$${convertNumToReadable(bucketValue, precision)}`;
      return false;
    }
  });
  return bucketString;
};

export default convertTotalValueBucketed;
