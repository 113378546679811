import axios from "axios";
import Cookies from "js-cookie";

import { ONE_MINUTE } from "utils/constants";

import { queryClient } from "./queryClient";

let token = null;

let refreshTimeout = null;
// Timeout is 30 minutes, give a small buffer
const REFRESH_INTERVAL = ONE_MINUTE * 29;
const MINUTES_IN_DAY = 60 * 24 * ONE_MINUTE;
const cookieExpiry = REFRESH_INTERVAL / MINUTES_IN_DAY;

const setToken = newToken => {
  token = newToken;
  Cookies.set("api-token", token, { secure: true, expires: cookieExpiry });
  axios.defaults.headers["Authorization"] = `Token ${token}`;
  refreshTimeout = setInterval(refresh, REFRESH_INTERVAL);
};

const isAuthenticated = () => {
  if (token !== null) {
    return true;
  }
  const _token = Cookies.get("api-token");
  if (_token) {
    setToken(_token);
    refresh();
    return true;
  }
  return false;
};

const refresh = () =>
  axios.get("auth/refresh/").then(() => {
    // Reset the expiry
    Cookies.set("api-token", token, { secure: true, expires: cookieExpiry });
  });

const authenticate = googleToken => {
  // Get credentials via SAML flow before hitting login
  return axios
    .post("auth/login/", {}, { headers: { Authorization: `Bearer ${googleToken}` } })
    .then(response => {
      setToken(response.data.data.token);
      localStorage.setItem("userMetadata", JSON.stringify(response.data.data.userMetadata));
      queryClient.resetQueries();
      return token;
    });
};

const logout = (makeRequest = true) => {
  if (makeRequest) {
    return axios.post("auth/logoutall/", {}).then(() => logout(false));
  }
  if (refreshTimeout) {
    clearInterval(refreshTimeout);
    refreshTimeout = null;
  }
  token = null;
  Cookies.remove("api-token");
  delete axios.defaults.headers["Authorization"];
};

export { authenticate, isAuthenticated, logout };
