import { useCallback } from "react";
import PropTypes from "prop-types";

import { mpTrack, useMpSource } from "utils/mixpanel";

const MATCH_INDEX_HOST = 1;
const MATCH_INDEX_ROUTE = 6;

let zxWindow = null;

/**
 * Helper component to link between ZX and Execution efficiently
 *
 * @param {object} props
 *
 * @returns {Element}
 */
const ZXLink = ({ component: Component, url, children, profileType, ...props }) => {
  const source = useMpSource();
  const onClick = useCallback(
    e => {
      e.preventDefault();

      mpTrack(`navigate to zx ${profileType} profile`, { source });

      const match = url.match(
        /(https?:\/\/((localhost:[0-9]{4})|(zx.([a-z]+.)?zanbato.com)))(\/.*)/
      );
      const host = match[MATCH_INDEX_HOST];
      const route = match[MATCH_INDEX_ROUTE];
      if (host && route) {
        // Not yet opened or tab closed
        if (!zxWindow || !zxWindow.self) {
          zxWindow = window.open(url);
        } else {
          zxWindow.postMessage(`Execution Route:${route}`, host);
          zxWindow.focus();
        }
      }
    },
    [url, profileType, source]
  );

  return (
    <Component url={url} onClick={onClick} {...props}>
      {children}
    </Component>
  );
};

ZXLink.propTypes = {
  profileType: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ZXLink;
