import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useCurrentTab, TabList, Card } from "yuka";

import { QUERY_KEYS, useInfiniteFetch } from "api";
import { BUY } from "utils/constants";

import KYCChecklistTaskSection from "./KYCChecklistTaskSection";
import {
  BUYSIDE_BROKER_DEALER_KYC,
  BUYSIDE_INDIVIDUAL_CLIENT_KYC,
  BUYSIDE_ENTITY_KYC,
  SELLSIDE_BROKER_DEALER_KYC,
  SELLSIDE_INDIVIDUAL_CLIENT_KYC,
  SELLSIDE_ENTITY_KYC,
} from "./constants";

const BD_TAB = "Broker Dealer";
const IC_TAB = "Individual Client";
const ENTITY_TAB = "Entity";

/**
 * Renders the notes tab/form for a connection
 *
 * @param {object} props
 * @returns {React.Element}
 */
const KYCChecklistCard = ({ connection, type }) => {
  const currentTab = useCurrentTab(`${type}Kyc`);
  const [defaultTab, setDefaultTab] = useState(null);
  const {
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data: tasks,
  } = useInfiniteFetch(QUERY_KEYS.TASKS.list({ "filter[trade_id]": connection.tradeId }));

  const brokerDealerKYC = type === BUY ? BUYSIDE_BROKER_DEALER_KYC : SELLSIDE_BROKER_DEALER_KYC;
  const individualClientKYC =
    type === BUY ? BUYSIDE_INDIVIDUAL_CLIENT_KYC : SELLSIDE_INDIVIDUAL_CLIENT_KYC;
  const entityKYC = type === BUY ? BUYSIDE_ENTITY_KYC : SELLSIDE_ENTITY_KYC;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (!isLoading && !defaultTab) {
      if (tasks && tasks.filter(task => task.phase === brokerDealerKYC).length) {
        setDefaultTab(BD_TAB);
      } else if (tasks && tasks.filter(task => task.phase === individualClientKYC).length) {
        setDefaultTab(IC_TAB);
      } else if (tasks && tasks.filter(task => task.phase === entityKYC).length) {
        setDefaultTab(ENTITY_TAB);
      } else {
        setDefaultTab(BD_TAB);
      }
    }
  }, [
    isLoading,
    defaultTab,
    tasks,
    type,
    setDefaultTab,
    brokerDealerKYC,
    individualClientKYC,
    entityKYC,
  ]);

  const tabs = useMemo(
    () => [
      { label: BD_TAB, count: tasks?.filter(task => task.phase === brokerDealerKYC).length },
      { label: IC_TAB, count: tasks?.filter(task => task.phase === individualClientKYC).length },
      { label: ENTITY_TAB, count: tasks?.filter(task => task.phase === entityKYC).length },
    ],
    [tasks, brokerDealerKYC, individualClientKYC, entityKYC]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }
  return (
    <Card title={`${type === BUY ? "Buyside" : "Sellside"} KYC Checklist`}>
      <TabList tabs={tabs} paramName={`${type}Kyc`} defaultTab={defaultTab} />
      {currentTab === BD_TAB && (
        <KYCChecklistTaskSection
          key={brokerDealerKYC}
          connection={connection}
          phase={brokerDealerKYC}
          tasks={tasks.filter(task => task.phase === brokerDealerKYC)}
        />
      )}
      {currentTab === IC_TAB && (
        <KYCChecklistTaskSection
          key={individualClientKYC}
          connection={connection}
          phase={individualClientKYC}
          tasks={tasks.filter(task => task.phase === individualClientKYC)}
        />
      )}
      {currentTab === ENTITY_TAB && (
        <KYCChecklistTaskSection
          key={entityKYC}
          connection={connection}
          phase={entityKYC}
          tasks={tasks.filter(task => task.phase === entityKYC)}
        />
      )}
    </Card>
  );
};

KYCChecklistCard.propTypes = {
  connection: PropTypes.shape({
    phase: PropTypes.string,
    tradeId: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default KYCChecklistCard;
