import React from "react";
import PropTypes from "prop-types";

import { InputRow, Select } from "forms/FormComponents";

/**
 * Field set for contacts invoicing settings
 *
 * @returns {Element}
 */
const ContactsInvoicingFieldSet = ({ contacts }) => (
  <>
    <InputRow>
      {contacts.length > 0 ? (
        <InputRow>
          <Select
            options={contacts}
            optionLabelKey="name"
            optionValueKey="apiId"
            name="defaultAttn"
            multiple
            placeholder="Select the contacts to include in the ATTN"
            title="ATTN on Headers"
          />
        </InputRow>
      ) : null}
    </InputRow>
    <InputRow>
      {contacts.length > 0 ? (
        <InputRow>
          <Select
            options={contacts}
            optionLabelKey="name"
            optionValueKey="apiId"
            name="defaultCc"
            multiple
            placeholder="Select the contacts to CC"
            title="CC on Headers"
          />
        </InputRow>
      ) : null}
    </InputRow>
  </>
);

ContactsInvoicingFieldSet.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ).isRequired,
};

export default ContactsInvoicingFieldSet;
