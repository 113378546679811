import React, { forwardRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";

import { body1, caption2, FontColors } from "../../Typography";

const Label = styled.label`
  + & {
    margin-top: 16px;
  }
  text-transform: none; // override default label style
  letter-spacing: initial; // override default label style
  display: flex;
  gap: 12px;
  position: relative;
  width: fit-content;
  white-space: ${props => (props.$wrap ? "initial" : "nowrap")};
`;

// Class name to retain inherited styles while migrating
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledHelpText = styled.span`
  ${caption2}
  ${props => {
    if (props.$error) {
      return FontColors.sell;
    } else if (props.$disabled) {
      return FontColors.theme30;
    }
    return FontColors.theme50;
  }}
`;

const StyledLabel = styled.span`
  ${body1}
  ${props => (props.$disabled ? FontColors.theme30 : FontColors.theme50)}
`;

const InputAlignWrapper = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
`;

/**
 * Base component used to wrap an underlying field with a label and error text
 *
 * @param {object} props
 * @param {object} ref
 *
 * @returns {Element}
 */
const _InlineValidatedField = forwardRef((props, ref) => {
  const subprops = _.omit(props, [
    "component",
    "className",
    "label",
    "input",
    "meta",
    "onClick",
    "wrapLabel",
  ]);
  const Input = props.component;
  const showError = props.meta?.touched && props?.meta.invalid && !props?.meta.active;
  const errorMessage = showError ? props.meta?.error || props.meta?.submitError : "";

  return (
    <Label
      className={props.className}
      htmlFor={props.input.id ?? props.input.name}
      onClick={props.onClick}
      $wrap={props.wrapLabel}
    >
      <InputAlignWrapper>
        <Input ref={ref} {...subprops} {...props.input} isInvalid={showError} />
      </InputAlignWrapper>
      <Wrapper>
        {props.label && <StyledLabel $disabled={props.input.disabled}>{props.label}</StyledLabel>}
        {props.helpText && (
          <StyledHelpText $disabled={props.input.disabled}>{props.helpText}</StyledHelpText>
        )}
        {errorMessage && <StyledHelpText $error>{errorMessage}</StyledHelpText>}
      </Wrapper>
    </Label>
  );
});

_InlineValidatedField.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  label: PropTypes.node,
  helpText: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  input: PropTypes.shape({
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    type: PropTypes.string,
    isDisabled: PropTypes.bool,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    active: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
  }),
  wrapLabel: PropTypes.bool,
};

_InlineValidatedField.defaultProps = {
  label: "",
  className: "",
  input: null,
  wrapLabel: false,
};

const InlineValidatedField = styled(_InlineValidatedField)``;

InlineValidatedField.displayName = "InlineValidatedField";

export default InlineValidatedField;
