import { useFormValue } from "utils/hooks/form";

import { BUY_REASONS, ISSUER_REASONS, SELL_REASONS } from "./constants";

const useDisconnectReasonOptions = () => {
  const disconnectSource = useFormValue("disconnectSource");

  switch (disconnectSource) {
    case "Buy Broker":
      return BUY_REASONS;
    case "Sell Broker":
      return SELL_REASONS;
    // eslint-disable-next-line no-magic-numbers
    case "Issuer":
      return ISSUER_REASONS;
    default:
      break;
  }
  return [];
};

export default useDisconnectReasonOptions;
