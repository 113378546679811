import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { cleanJsonApiData, fetchPagedData, queryConfig } from "./fetchInternals";

const useInfiniteFetch = queryKey => {
  const queryInfo = useInfiniteQuery(queryKey, fetchPagedData, {
    getNextPageParam: lastPage => {
      if (lastPage.meta.pagination.page < lastPage.meta.pagination.pages) {
        return lastPage.meta.pagination.page + 1;
      }
    },
    ...queryConfig,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => {
    if (!queryInfo.isLoading && queryInfo.data) {
      // Create an object composed of the links and meta from the last page, plus the data and includes from all pages.
      const currentPageNumber = queryInfo.data.pages.length;
      const currentPage = queryInfo.data.pages[currentPageNumber - 1];
      const fullData = {
        links: currentPage.links,
        meta: currentPage.meta,
        data: queryInfo.data.pages.map(page => page.data).flat(),
        included: queryInfo.data.pages.map(page => page.included || []).flat(),
      };
      if (fullData.data.length) {
        return cleanJsonApiData(fullData);
      }
      return fullData;
    }
    return [];
  }, [queryInfo.isLoading, queryInfo.data]);

  const returnInfo = useMemo(
    () => ({
      ...queryInfo,
      ...data,
    }),
    [queryInfo, data]
  );

  return returnInfo;
};

export default useInfiniteFetch;
