import styled from "styled-components";
import { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, ActionChip, PlusIcon, Table } from "yuka";

import EditClientContactsForm from "forms/EditClientContactsForm";

import { useContactsTableConfig, columns } from "./contactsTableConfig";

const StyledContainer = styled.div`
  height: 100%;
  min-height: 0;
`;

const StyledActions = styled.div`
  padding: 0 16px;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const ContactsTab = ({ sourceProfile, source, contacts }) => {
  const [formOpen, setFormOpen] = useState(false);

  const { sortState, onSort } = useContactsTableConfig();

  const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);

  return (
    <StyledCard title="Contacts" cardStyle={CardStyles.SECTIONED}>
      <StyledActions>
        <ActionChip onClick={openForm} leadingIcon={PlusIcon} text="Add New Contact" />
      </StyledActions>
      {formOpen && (
        <EditClientContactsForm
          sourceProfile={sourceProfile}
          source={source}
          contacts={contacts}
          onClose={() => closeForm()}
        />
      )}
      <StyledContainer>
        <Table
          usePercentageColumnWidths
          emptyTablePlaceholder="No contacts"
          sortState={sortState}
          onSort={onSort}
          data={contacts || []}
          columns={columns}
        />
      </StyledContainer>
    </StyledCard>
  );
};

ContactsTab.propTypes = {
  sourceProfile: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    apiType: PropTypes.string.isRequired,
    kycStatus: PropTypes.string.isRequired,
    sourceType: PropTypes.string.isRequired,
  }).isRequired,
  source: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    searchId: PropTypes.number,
  }).isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      name: PropTypes.string,
      position: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      isHighlighted: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default ContactsTab;
