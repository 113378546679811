import { useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { FloatingNavigation, NavigationItem, useTabRoute } from "yuka";

import { QUERY_KEYS, getRelatedObj, useFetch } from "api";
import { FEATURE_FLAG_SPV_ONBOARDING } from "utils/featureFlags";
import { MixpanelSourceContext, mpTrack } from "utils/mixpanel";
import { FrontendError, Loading } from "templates/ErrorPages";
import NotesCard from "templates/NotesCard";

import SPVClassProfileHeader from "./SPVClassProfileHeader";
import InvestorsCard from "./InvestorsCard";
import DocumentsCard from "./DocumentsCard";
import SettingsCard from "./SettingsCard";

const DOCUMENTS = "Documents";
const INVESTORS = "Investors";
const NOTES = "Notes";
const SETTINGS = "Settings";

const TABS = FEATURE_FLAG_SPV_ONBOARDING()
  ? [INVESTORS, DOCUMENTS, NOTES, SETTINGS]
  : [DOCUMENTS, NOTES, SETTINGS];

const StyledSPVClassProfile = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
  height: 100%;
  min-height: 0;
`;

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SPVClassProfileView = () => {
  const [currentTab, setCurrentTab] = useTabRoute(TABS, "tab");
  const { id } = useParams();

  const {
    isError,
    isLoading,
    data: spvClass,
    relationships,
  } = useFetch(QUERY_KEYS.SPV_CLASSES.detail(id, [], { include: "spv" }));

  const mpTrackTabChange = useCallback(
    tab => {
      setCurrentTab(tab);
      mpTrack("toggle SPV Class profile tab", { tab });
    },
    [setCurrentTab]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <FrontendError />;
  }

  const spv = getRelatedObj(spvClass.spv, relationships);

  return (
    <MixpanelSourceContext.Provider value="spv class profile">
      <SPVClassProfileHeader spvClass={spvClass} spv={spv} />
      <StyledSPVClassProfile>
        <StyledSideBar>
          <FloatingNavigation>
            {TABS.map(tab => (
              <NavigationItem
                key={tab}
                onClick={() => mpTrackTabChange(tab)}
                selected={tab === currentTab}
              >
                {tab}
              </NavigationItem>
            ))}
          </FloatingNavigation>
        </StyledSideBar>
        {FEATURE_FLAG_SPV_ONBOARDING() && currentTab === INVESTORS && (
          <InvestorsCard spvClass={spvClass} />
        )}
        {currentTab === DOCUMENTS && <DocumentsCard spvClass={spvClass} />}
        {currentTab === SETTINGS && <SettingsCard spvClass={spvClass} spv={spv} />}
        {currentTab === NOTES && (
          <NotesCard queryKey={QUERY_KEYS.SPV_CLASS_NOTES} apiId={spvClass.apiId} />
        )}
      </StyledSPVClassProfile>
    </MixpanelSourceContext.Provider>
  );
};

export default SPVClassProfileView;
