import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { FontColors, body1, overline } from "yuka";

import { FormSection } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";
import CommissionUtils from "utils/commissions/CommissionUtils";

import CommissionRow from "./CommissionRow";
import { CLIENT_TO_Z, CLIENT_TO_BROKER, BROKER_TO_Z, Z_TO_BROKER } from "./constants";

const StyledOverline = styled.div`
  ${overline};
  ${FontColors.theme50};
  margin-left: auto;
  width: 172px;
`;

const StyledBody1 = styled.div`
  ${body1};
  margin-left: auto;
  width: 256px;
  text-align: center;
`;

/**
 * Set of fields for commission objects on an ticket.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const CommissionFieldSet = ({ connection, isBuy }) => {
  const commission = useFormValue(`${isBuy ? "buy" : "sell"}`, {});

  const clientToZFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        commission.clientToZCommissionAmount,
        commission.clientToZCommissionType,
        connection
      ),
    [commission.clientToZCommissionAmount, commission.clientToZCommissionType, connection]
  );

  const clientToBrokerFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        commission.clientToBrokerCommissionAmount,
        commission.clientToBrokerCommissionType,
        connection
      ),
    [commission.clientToBrokerCommissionAmount, commission.clientToBrokerCommissionType, connection]
  );

  const brokerToZFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        commission.brokerToZCommissionAmount,
        commission.brokerToZCommissionType,
        connection,
        clientToZFee,
        clientToBrokerFee,
        commission.brokerToZCommissionAmountNumerator,
        commission.brokerToZCommissionAmountDenominator
      ),
    [
      commission.brokerToZCommissionAmount,
      commission.brokerToZCommissionType,
      connection,
      clientToZFee,
      clientToBrokerFee,
      commission.brokerToZCommissionAmountNumerator,
      commission.brokerToZCommissionAmountDenominator,
    ]
  );

  const zToBrokerFee = useMemo(
    () =>
      CommissionUtils.calculatedFees(
        commission.zToBrokerCommissionAmount,
        commission.zToBrokerCommissionType,
        connection,
        clientToZFee,
        clientToBrokerFee,
        commission.zToBrokerCommissionAmountNumerator,
        commission.zToBrokerCommissionAmountDenominator
      ),
    [
      commission.zToBrokerCommissionAmount,
      commission.zToBrokerCommissionType,
      connection,
      clientToZFee,
      clientToBrokerFee,
      commission.zToBrokerCommissionAmountNumerator,
      commission.zToBrokerCommissionAmountDenominator,
    ]
  );

  const client = `${isBuy ? "Buy" : "Sell"}`;
  const types = [
    [`${client}er Fee to Z`, CLIENT_TO_Z],
    [`${client}er Fee to ${client}side Broker`, CLIENT_TO_BROKER],
    [`${client}side Broker Fee to Z`, BROKER_TO_Z],
    [`Z fee to ${client}side Broker`, Z_TO_BROKER],
  ];
  return (
    <FormSection name={isBuy ? "buy" : "sell"}>
      <StyledOverline>Calculated Fees</StyledOverline>
      {types.map(type => (
        <CommissionRow
          key={type[0]}
          title={type[0]}
          type={type[1]}
          connection={connection}
          isBuy={isBuy}
        />
      ))}
      <StyledOverline>Z Net Commission</StyledOverline>
      <StyledBody1>
        {CommissionUtils.displayZNetCommission(clientToZFee, brokerToZFee, zToBrokerFee)}
      </StyledBody1>
    </FormSection>
  );
};

CommissionFieldSet.propTypes = {
  connection: PropTypes.shape({
    shareQuantity: PropTypes.number.isRequired,
    totalValue: PropTypes.number.isRequired,
  }).isRequired,
  isBuy: PropTypes.bool.isRequired,
};

export default CommissionFieldSet;
